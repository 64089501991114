import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentModule} from 'app/component/component.module';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {MhdtddhteComponent} from './mhdtddhte.component';

@NgModule({
    imports: [ComponentModule, CommonModule],
    declarations: [MhdtddhteComponent],
    entryComponents: [MhdtddhteComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(MhdtddhteComponent),
            multi: true,
        },
    ],
})
export class MhdtddhteModule {}
