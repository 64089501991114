import {DrawingActionEnum} from 'app/drawing/enum/action.enum';
import {DrawingStateEnum} from 'app/drawing/enum/state.enum';
import {Resource} from 'app/service/resource/classes/resource.class';
import {fabric} from 'fabric';

export interface DrawnEventInterface {
    action: DrawingActionEnum;
    resource: Resource;
    state?: DrawingStateEnum;
    object?: fabric.Object;
}

export class DrawnEvent implements DrawnEventInterface {
    private constructor(
        public readonly action: DrawingActionEnum,
        public readonly resource: Resource,
        public readonly state?: DrawingStateEnum,
        public readonly object?: fabric.Object
    ) {}

    public static forDrawn(
        resource: Resource,
        state: DrawingStateEnum,
        object: fabric.Object
    ): DrawnEvent {
        return new DrawnEvent(DrawingActionEnum.Drawn, resource, state, object);
    }

    public static forUndo(resource: Resource): DrawnEvent {
        return new DrawnEvent(DrawingActionEnum.Undo, resource);
    }

    public static forRedo(resource: Resource): DrawnEvent {
        return new DrawnEvent(DrawingActionEnum.Redo, resource);
    }

    public static forClear(resource: Resource): DrawnEvent {
        return new DrawnEvent(DrawingActionEnum.Clear, resource);
    }

    public static forStateSwitch(
        resource: Resource,
        state: DrawingStateEnum
    ): DrawnEvent {
        return new DrawnEvent(DrawingActionEnum.StateSwitch, resource, state);
    }
}
