export enum KeyCodeEnum {
    ArrowRight = 'ArrowRight',
    ArrowLeft = 'ArrowLeft',

    Num0 = '0',
    Num1 = '1',
    Num2 = '2',
    Num3 = '3',
    Num4 = '4',
    Num5 = '5',
    Num6 = '6',
    Num7 = '7',
    Num8 = '8',
    Num9 = '9',

    a = 'a',
    b = 'b',
    c = 'c',
    d = 'd',
    e = 'e',
    f = 'f',
    g = 'g',
    h = 'h',
    i = 'i',
    j = 'j',
    k = 'k',
    l = 'l',
    m = 'm',
    n = 'n',
    o = 'o',
    p = 'p',
    q = 'q',
    r = 'r',
    s = 's',
    t = 't',
    u = 'u',
    v = 'v',
    w = 'w',
    x = 'x',
    y = 'y',
    z = 'z',

    Times = '×',
    Semicolon = ';',
    Colon = ':',
    Hyphen = '-',
    Plus = '+',
    PlusMinus = '±',
    Euro = '€',
    AtSign = '@',
    OpenBracket = '(',
    CloseBracket = ')',
    DoubleQuote = '"',
    ForwardSlash = '/',
    Ampersand = '&',
    Period = '.',
    Comma = ',',
    Equals = '=',
    LessThan = '<',
    GreaterThan = '>',
    QuestionMark = '?',
    ExclamationMark = '!',
    // tslint:disable-next-line:quotemark
    Apostrophe = "'",
    Space = ' ',
    Enter = '\n',

    Backspace = 'backspace',
    Shift = 'shift',
}
