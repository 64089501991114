import {Component, OnDestroy} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';
import {DigitalClock} from 'app/tool-layer/tools/digital-clock/classes/digital-clock.class';

@Component({
    selector: 'app-ruler',
    templateUrl: './stopwatch.component.html',
    styleUrls: ['./stopwatch.component.scss'],
})
export class StopwatchComponent extends AbstractTool implements OnDestroy {
    public static readonly id = 'stopwatch';
    public static readonly width = 305;
    public static readonly height = 214;

    public clock = DigitalClock.forZero();
    private interval?: number;

    public ngOnDestroy(): void {
        this.stop();
    }

    public start(): void {
        this.interval = window.setInterval(() => this.tickClock(), 1000);
    }

    public stop(): void {
        this.pause();

        this.clock = DigitalClock.forZero();
    }

    public pause(): void {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = undefined;
        }
    }

    private tickClock(): void {
        this.clock.tick();
    }
}
