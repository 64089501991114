import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    Output,
    QueryList,
    ViewChild,
} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {ScrollIntoViewDirective} from 'app/directive/scroll-into-view/scroll-into-view.directive';

@Component({
    selector: 'app-scroll',
    templateUrl: './scroll.component.html',
    styleUrls: ['./scroll.component.scss'],
})
export class ScrollComponent implements AfterViewInit, OnDestroy {
    @Input()
    public vertical = false;

    @Input()
    public class?: string;

    @Output()
    public updated = new Subject<void>();

    @ViewChild('content')
    private contentComponent!: ElementRef;

    @ContentChildren(ScrollIntoViewDirective)
    private scrollIntoZoomItems!: QueryList<ScrollIntoViewDirective>;

    public scrollXPercentage = 0;
    public scrollYPercentage = 0;

    private scrollableWidth = 0;
    private scrollableHeight = 0;

    public scrollX = 0;
    private scrollY = 0;

    private subs: Subscription[] = [];

    constructor(private elementRef: ElementRef) {}

    static scrollElementIntoView(element: ElementRef): void {
        element.nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
        });
    }

    public ngAfterViewInit(): void {
        this.scrollIntoZoomItems.toArray().forEach(c => {
            if (c.appScrollIntoView) {
                ScrollComponent.scrollElementIntoView(c.element);
            }
            this.subs.push(
                c.scrollIntoView.subscribe(el =>
                    ScrollComponent.scrollElementIntoView(el)
                )
            );
        });
    }

    public ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

    public getNativeElement(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    public getContentElement(): HTMLElement {
        return this.contentComponent.nativeElement;
    }

    public update(): void {
        this.setDimensions();
    }

    public setScrollPercentage(x: number, y: number): void {
        const scrollX = (x / 100) * this.scrollableWidth;
        const scrollY = (y / 100) * this.scrollableHeight;

        this.getNativeElement().scrollTo(scrollX, scrollY);
    }

    private setDimensions(): void {
        const {clientWidth, clientHeight} = this.getNativeElement();
        const {scrollWidth, scrollHeight} = this.getContentElement();

        this.scrollableWidth = scrollWidth - clientWidth;
        this.scrollableHeight = scrollHeight - clientHeight;

        this.calculateScrollPercentage();
    }

    private calculateScrollPercentage(): void {
        this.scrollXPercentage = Math.ceil(
            (this.scrollX / this.scrollableWidth) * 100
        );
        this.scrollYPercentage = Math.ceil(
            (this.scrollY / this.scrollableHeight) * 100
        );

        this.updated.next();
    }

    @HostListener('scroll', ['$event'])
    private onScrollEvent(event: Event): void {
        if (!event || !event.target) {
            return;
        }

        const {scrollLeft, scrollTop} = event.target as HTMLElement;
        this.scrollX = scrollLeft;
        this.scrollY = scrollTop;

        this.calculateScrollPercentage();
    }

    @HostListener('window:resize')
    private onWindowResize(): void {
        this.update();
    }

    @HostBinding('style.overflow-x')
    private get scrollXEnabled(): string {
        return !this.vertical ? 'auto' : 'hidden';
    }

    @HostBinding('style.overflow-y')
    private get scrollYEnabled(): string {
        return this.vertical ? 'auto' : 'hidden';
    }
}
