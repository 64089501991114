import {SvgFontAwesomeEnum} from 'app/enum/svg.enum';
import {ToolRingInterface} from 'app/tool/classes/tool-ring.class';
import {
    ToolRingButtonConfig,
    ToolRingConfigInterface,
    ToolRingFactory,
} from 'app/tool/factory/tool-ring/tool-ring.factory';
import {RingIds} from 'app/tool/rings/ring-ids.enum';
import {CategorySvgMapping} from 'app/toolbox/mapping/category-icon.mapping';
import {ToolboxModalService} from 'app/toolbox/service/modal/modal.service';
import {
    ToolboxItemInterface,
    ToolboxService,
} from 'app/toolbox/service/toolbox/toolbox.service';
import {Subject, Subscription} from 'rxjs';

export interface ToolRingServiceInterface {
    subscribeToToggle: (fn: (id: RingIds) => void) => Subscription;

    getId(): RingIds;
    isActive(): boolean;
    render(): ToolRingInterface[];
}

export abstract class AbstractToolRingService
    implements ToolRingServiceInterface
{
    private readonly toggleSubscription = new Subject<RingIds>();
    private readonly toolboxButtonPrefix = 'toolbox-button-';

    protected constructor(
        protected toolRingFactory: ToolRingFactory,
        protected toolboxService: ToolboxService,
        protected toolboxModalService: ToolboxModalService
    ) {
        this.toolboxService.subscribeToCategoriesChanged(() =>
            this.handleCategoriesChanged()
        );
    }

    public abstract getId(): RingIds;
    public abstract render(): ToolRingInterface[];

    public isActive(): boolean {
        return false;
    }

    public subscribeToToggle(fn: (id: RingIds) => void): Subscription {
        return this.toggleSubscription.subscribe(fn);
    }

    protected toggleRingByServiceId(serviceId?: RingIds): void {
        this.toggleSubscription.next(serviceId);
    }

    protected handleCategoriesChanged(): void {
        this.toggleRingByServiceId();
    }

    protected syncToolboxButtons(
        config: ToolRingConfigInterface,
        ring: ToolRingInterface,
        color: string
    ): void {
        const buttons = config.buttons || [];
        this.toolboxService.getCategories().forEach(category => {
            buttons.push(this.buildToolboxButton(category, color));
        });
        config.buttons = buttons;

        const rendered = this.toolRingFactory.create(config);

        ring.buttons = ring.buttons.filter(
            button => !button.id.startsWith(this.toolboxButtonPrefix)
        );

        rendered.buttons
            .filter(button => button.id.startsWith(this.toolboxButtonPrefix))
            .forEach(button => ring.buttons.push(button));
    }

    private buildToolboxButton(
        item: ToolboxItemInterface,
        color: string
    ): ToolRingButtonConfig {
        let icon = CategorySvgMapping.get(item.value);
        if (!icon) {
            icon = SvgFontAwesomeEnum.SolidToolbox;
        }

        let type = item.type;
        if (!type) {
            type = item.value;
        }

        return {
            id: `${this.toolboxButtonPrefix}-${type}`,
            icon,
            classes: [`svg__button--${color}`],
            callback: () => this.toolboxModalService.open(item.value),
        };
    }
}
