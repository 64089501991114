import {Component, ContentChildren, QueryList} from '@angular/core';
import {
    AssignmentGapChoiceOptionEntryComponent,
    AssignmentGapChoiceOptionEntryInterface,
} from 'app/content/screen/components/assignment-gap-choice/assignment-gap-choice-option-entry/assignment-gap-choice-option-entry.component';
import {StateIconEnum} from 'app/component/state-icon/state-icon.component';

export interface AssignmentGapChoiceOptionInterface {
    checkAnswerState(): boolean;

    checkAnswers(): void;

    reset(): void;

    isWrong(): boolean;

    isChecked(): boolean;
}

@Component({
    selector: 'assignment-gap-choice-option',
    templateUrl: './assignment-gap-choice-option.component.html',
    styleUrls: ['./assignment-gap-choice-option.component.scss'],
})
export class AssignmentGapChoiceOptionComponent
    implements AssignmentGapChoiceOptionInterface
{
    public readonly answerStateEnum = StateIconEnum;

    @ContentChildren(AssignmentGapChoiceOptionEntryComponent)
    public options!: QueryList<AssignmentGapChoiceOptionEntryInterface>;

    public answerState?: StateIconEnum;

    public selectedValue?: string;

    public checkAnswerState(): boolean {
        if (!this.isChecked()) {
            this.answerState = undefined;

            return false;
        }

        if (this.isCorrectAnswer()) {
            this.answerState = StateIconEnum.Correct;

            return true;
        } else {
            this.answerState = StateIconEnum.InCorrect;

            return false;
        }
    }

    public reset(): void {
        this.selectedValue = undefined;
        this.answerState = undefined;
    }

    public checkAnswers(): void {
        this.options.forEach(option => {
            if (option.isCorrect()) {
                this.selectedValue = option.value;
                this.answerState = StateIconEnum.Correct;
            }
        });
    }

    private isCorrectAnswer(): boolean {
        let isCorrect = false;

        this.options.forEach(option => {
            if (option.value === this.selectedValue && option.isCorrect()) {
                isCorrect = true;
            }
        });

        return isCorrect;
    }

    public isWrong(): boolean {
        return (
            undefined !== this.answerState &&
            !(this.isChecked() && StateIconEnum.Correct === this.answerState)
        );
    }

    public isChecked(): boolean {
        return undefined !== this.selectedValue;
    }

    public clearState(): void {
        if (this.isChecked()) {
            this.answerState = undefined;
        }
    }
}
