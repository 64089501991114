import {ExerciseComponentInterface} from 'app/content/screen/exercise/exercise-component.interface';

export abstract class ExerciseComponent implements ExerciseComponentInterface {
    /**
     * @inheritDoc
     */
    public abstract check(): boolean;

    /**
     * @inheritDoc
     */
    public abstract solve(): void;

    /**
     * @inheritDoc
     */
    public abstract reset(): void;

    /**
     * @inheritDoc
     */
    public abstract hasWrong(): boolean;

    /**
     * @inheritDoc
     */
    public abstract retry(): void;

    /**
     * @inheritDoc
     */
    public abstract hasAnswer(): boolean;
}
