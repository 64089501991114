import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-numbers-input',
    templateUrl: './numbers-input.component.html',
    styleUrls: ['./numbers-input.component.scss'],
})
export class NumbersInputComponent {
    @Input()
    public showBackspaceButton = true;

    @Input()
    public showClearButton = true;

    @Input()
    public options: number[][] = [
        [1, 2, 3, 4, 5],
        [6, 7, 8, 9, 0],
    ];

    @Output()
    public readonly numberChoice = new EventEmitter<number>();

    @Output()
    public readonly clear = new EventEmitter<void>();

    @Output()
    public readonly undo = new EventEmitter<void>();

    public onNumberChoice(n: number): void {
        this.numberChoice.emit(n);
    }

    public onClear(): void {
        this.clear.emit();
    }

    public onUndo(): void {
        this.undo.emit();
    }
}
