export enum ColorInterpolationFilterEnum {
    Black = 'srgb-black',
    White = 'srgb-white',
    Blue = 'srgb-blue',
    BlueLight = 'srgb-blue-light',
    BlueDark = 'srgb-blue-dark',
    Red = 'srgb-red',
    Green = 'srgb-green',
    Yellow = 'srgb-yellow',
}

export enum Shadow {
    Inset = 'shadowInset',
    Outset = 'shadowOutset',
}
