import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {ComponentModule} from 'app/component/component.module';
import {NumberLineComponent} from 'app/tool-layer/tools/number-line/number-line.component';
import {LineSvgModule} from 'app/tool-layer/tools/line-svg/line-svg.module';

@NgModule({
    imports: [CommonModule, ComponentModule, LineSvgModule],
    declarations: [NumberLineComponent],
    entryComponents: [NumberLineComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(NumberLineComponent),
            multi: true,
        },
    ],
})
export class NumberLineModule {}
