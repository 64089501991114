import {Injectable} from '@angular/core';
import {
    AbstractBottomSheetService,
    BackDropClass,
} from 'app/cdk/classes/service/bottom-sheet/bottom-sheet.service';
import {ToolboxComponent} from 'app/toolbox/component/toolbox.component';
import {ComponentType} from '@angular/cdk/overlay';

@Injectable()
export class ToolboxModalService extends AbstractBottomSheetService {
    public open(category?: string): void {
        super.open();

        if (!category) {
            return;
        }

        const instance = this.reference.instance as ToolboxComponent;
        instance.showCategoryAfterOpen(category);
    }

    protected getComponentType(): ComponentType<ToolboxComponent> {
        return ToolboxComponent;
    }

    protected disableClose(): boolean {
        return true;
    }

    protected getBackDropClass(): BackDropClass {
        return BackDropClass.Blurred;
    }
}
