import {Injectable} from '@angular/core';
import {UrlMatchResult, UrlSegment} from '@angular/router';

@Injectable()
export class RouteMatcherService {
    public forNavigationComponent(
        segments: UrlSegment[]
    ): UrlMatchResult | null {
        const path = this.buildThePathFromSegments(segments);

        if (this.pathIsContentPath(path)) {
            return null;
        }

        return {consumed: segments};
    }

    public forContentComponent(segments: UrlSegment[]): UrlMatchResult | null {
        const path = this.buildThePathFromSegments(segments);

        if (!this.pathIsContentPath(path)) {
            return null;
        }

        return {consumed: segments};
    }

    private buildThePathFromSegments(segments: UrlSegment[]): string {
        const path = [];

        for (const segment of segments) {
            path.push(segment.path);
        }

        return path.join('/');
    }

    private pathIsContentPath(path: string): boolean {
        return path.indexOf('/content') > 4;
    }
}
