import {ComponentType} from '@angular/cdk/overlay';
import {Injectable} from '@angular/core';
import {
    AbstractBottomSheetService,
    BackDropClass,
} from 'app/cdk/classes/service/bottom-sheet/bottom-sheet.service';
import {ApiToolInterface} from 'app/interface/api-tool.interface';
import {ImageBarComponent} from 'app/tool-layer/tools/image/bar/bar.component';
import {BarCloseStateEnum} from 'app/tool-layer/tools/image/bar/close-state.enum';
import {Subject} from 'rxjs';

@Injectable()
export class ImageBarModalService extends AbstractBottomSheetService {
    public openForTool(tool: ApiToolInterface): Promise<number | undefined> {
        this.open();

        const subject = new Subject<number | undefined>();

        const instance = this.reference.instance as ImageBarComponent;
        instance.setTool(tool);

        const subscription = this.reference.afterDismissed().subscribe(
            val => {
                switch (val) {
                    case BarCloseStateEnum.Close:
                        subject.error(undefined);
                        break;
                    case BarCloseStateEnum.New:
                        subject.next(undefined);
                        break;
                    default:
                        subject.next(val);
                        break;
                }

                subject.complete();
                subscription.unsubscribe();
            },
            () => {
                subject.error(undefined);
                subject.complete();
                subscription.unsubscribe();
            }
        );

        return subject.toPromise();
    }

    protected getComponentType(): ComponentType<ImageBarComponent> {
        return ImageBarComponent;
    }

    protected disableClose(): boolean {
        return true;
    }

    protected getBackDropClass(): BackDropClass {
        return BackDropClass.Blurred;
    }
}
