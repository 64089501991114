import {Pipe, PipeTransform} from '@angular/core';
import {Resource} from 'app/service/resource/classes/resource.class';

@Pipe({
    name: 'methodMapping',
})
export class MethodMappingPipe implements PipeTransform {
    public transform(resource: Resource, prefix?: string): string | null {
        const slug =
            resource.getType() +
            '-' +
            resource.getName().toLowerCase().replace(/[. ]/g, '-');
        return prefix ? prefix + '-' + slug : slug;
    }
}
