import {Injectable} from '@angular/core';
import {BreadcrumbInterface} from 'app/header/component/header.component';
import {Resource} from 'app/service/resource/classes/resource.class';
import {Subject, Subscription} from 'rxjs';
import {ApiResourceEnum} from 'app/interface/api-resource.interface';

@Injectable({
    providedIn: 'root',
})
export class HeaderService {
    private readonly breadcrumbsSubject: Subject<BreadcrumbInterface[]> =
        new Subject<BreadcrumbInterface[]>();
    private readonly logoClick: Subject<void> = new Subject<void>();
    private breadcrumbResource?: Resource;

    public handleLogoClick(): void {
        this.logoClick.next();
    }

    public logoClickSubscribe(fn: () => void): Subscription {
        return this.logoClick.subscribe(fn);
    }

    /**
     * Sets breadcrumbs based on resources.
     * Method and content resources are filtered as they're not suppose to be in the breadcrumb list.
     * Parent routes are used to trigger navigation service to display a list of child resources.
     */
    public setBreadCrumbs(breadcrumb: Resource): void {
        this.breadcrumbResource = breadcrumb;

        const breadcrumbResources = breadcrumb.asArray();
        this.breadcrumbsSubject.next(
            breadcrumbResources
                .filter(
                    resource => resource.getType() !== ApiResourceEnum.Method
                )
                .filter(resource =>
                    (Object.values(ApiResourceEnum) as string[]).includes(
                        resource.getType()
                    )
                )
                .map(resource => {
                    return {
                        value: resource.getName(),
                        href: resource.getParent().getRoute(),
                    };
                })
        );
    }

    public breadcrumbsSubscribe(
        fn: (breadcrumbs: BreadcrumbInterface[]) => void
    ): Subscription {
        return this.breadcrumbsSubject.subscribe(fn);
    }

    public getBreadcrumbResource(): Resource | undefined {
        return this.breadcrumbResource;
    }
}
