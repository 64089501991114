import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {AnalogClockComponent} from 'app/tool-layer/tools/analog-clock/analog-clock.component';
import {ComponentModule} from 'app/component/component.module';

@NgModule({
    imports: [ComponentModule, CommonModule],
    declarations: [AnalogClockComponent],
    entryComponents: [AnalogClockComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(AnalogClockComponent),
            multi: true,
        },
    ],
})
export class AnalogClockModule {}
