import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActiveToolService} from 'app/tool-layer/service/active/active-tool.service';

@Component({
    selector: 'app-counting-hands',
    templateUrl: './counting-hands.component.html',
})
export class CountingHandsComponent extends AbstractTool {
    public static readonly id = 'handen';
    public static readonly width = 220;
    public static readonly height = 300;
    public static readonly hideAddButton = true;

    public count = 0;
    public flipped = false;

    constructor(
        private sanitizer: DomSanitizer,
        private activeToolService: ActiveToolService
    ) {
        super();
    }

    public set injectedData(data: {flipped: boolean}) {
        this.flipped = data.flipped;
    }

    public onHandClick(): void {
        this.count = this.count < 5 ? this.count + 1 : 0;
    }

    public get path(): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(
            `/assets/svg/elements/AT_Handen_${this.count}R.svg`
        );
    }

    public openTool(flipped = false) {
        this.activeToolService.openTool({
            toolId: 'handen',
            toolConfig: {
                data: {flipped},
            },
        });
    }
}
