import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {FloatingSvgComponent} from 'app/tool-layer/tools/floating-svg/floating-svg.component';
import {ComponentModule} from 'app/component/component.module';

@NgModule({
    imports: [CommonModule, ComponentModule],
    declarations: [FloatingSvgComponent],
    entryComponents: [FloatingSvgComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(FloatingSvgComponent),
            multi: true,
        },
    ],
})
export class FloatingSvgModule {}
