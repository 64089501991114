import {Location} from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import {ActivatedRoute, Router, UrlSegment} from '@angular/router';
import {ContentException} from 'app/content/exception/content.exception';
import {ScreenComponent} from 'app/content/screen/component/screen.component';
import {KeyCodeEnum} from 'app/enum/key-code.enum';
import {HeaderService} from 'app/header/service/header.service';
import {SecondScreenService} from 'app/second-screen/service/second-screen.service';
import {ContentService} from 'app/service/content/content.service';
import {
    GoogleAnalyticsActionsEnum,
    GoogleAnalyticsCategoriesEnum,
} from 'app/service/google-analytics/categories.enum';
import {GoogleAnalyticsService} from 'app/service/google-analytics/google-analytics.service';
import {Resource} from 'app/service/resource/classes/resource.class';
import {ZoomEnum} from 'app/enum/zoom.enum';
import {
    ZoomableViewEventInterface,
    ZoomableViewEventTypeEnum,
    ZoomableViewService,
} from 'app/zoomable-view/service/zoomable-view/zoomable-view.service';
import {SwiperDirective} from 'ngx-swiper-wrapper';
import {Subscription} from 'rxjs';
import {SwiperOptions} from 'swiper';

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, AfterViewInit, OnDestroy {
    public readonly swiperOptions: SwiperOptions = {
        direction: 'horizontal',
        slidesPerView: 1,
        allowTouchMove: false,
        spaceBetween: 1,
    };

    private readonly subscriptions: Subscription[] = [];

    @ViewChild(SwiperDirective)
    public swiperDirective!: SwiperDirective;

    @ViewChildren(ScreenComponent)
    public screenComponents!: QueryList<ScreenComponent>;

    public resource!: Resource;
    public screens!: Resource[];

    private currentIndex = 0;
    private secondScreenEnabled = false;

    constructor(
        private location: Location,
        private route: ActivatedRoute,
        private router: Router,
        private headerService: HeaderService,
        private contentService: ContentService,
        private zoomableViewService: ZoomableViewService,
        private gaService: GoogleAnalyticsService,
        private secondScreenService: SecondScreenService,
        private cd: ChangeDetectorRef
    ) {
        this.subscriptions.push(
            this.zoomableViewService.subscribe(event =>
                this.handleZoomableViewEvent(event)
            ),
            this.contentService.subscribeToNavigate(keyCode =>
                this.handleNavigationEvent(keyCode)
            ),
            this.headerService.logoClickSubscribe(() => this.handleLogoClick()),
            this.contentService.subscribeToNavigateToResource(resource =>
                this.handleNavigateToResource(resource)
            ),
            this.contentService.subscribeToScreensUpdate(() => {
                this.updateScreens();
            }),
            this.secondScreenService.subscribeToEnabled(enabled => {
                this.secondScreenEnabled = enabled;
                this.updateScreens();
            })
        );
        this.secondScreenEnabled = this.secondScreenService.getIsEnabled();
    }

    public ngOnInit(): void {
        const resolvedResource: Resource = this.route.snapshot.data.resource;

        if (undefined === resolvedResource) {
            throw ContentException.resourceIsUndefined();
        }

        if (!resolvedResource.hasChildren()) {
            throw ContentException.noResourceChildren();
        }

        this.zoomableViewService.setEnabled(true); // Enable zoom and scaling

        this.resource = resolvedResource;
        this.screens = resolvedResource.getVisibleChildren(
            this.secondScreenEnabled
        );
    }

    public ngAfterViewInit(): void {
        this.setActiveScreenByUrlSegments(this.route.snapshot.url);
        this.update();
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.contentService.clearCurrentResource();
        this.zoomableViewService.setEnabled(false);
    }

    @HostListener('window:keyup', ['$event'])
    public keyEvent(event: KeyboardEvent) {
        this.contentService.navigateSlide(event.key);
    }

    public handleTransitionEnd(): void {
        this.setActiveScreenByIndex(this.swiperDirective.getIndex());
    }

    private updateScreens(): void {
        const currentScreen = this.screens[this.currentIndex];
        const filteredScreens = this.resource.getVisibleChildren(
            this.secondScreenEnabled
        );

        const currentScreenStillActive = filteredScreens.find(
            r => r.getPath() === currentScreen.getPath()
        );
        const index = currentScreenStillActive
            ? filteredScreens.indexOf(currentScreen)
            : 0;

        this.screens = filteredScreens;
        this.cd.detectChanges();

        this.setActiveScreenByIndex(index);
        this.update();
    }

    private handleNavigationEvent(keyCode: KeyCodeEnum): void {
        if (keyCode === KeyCodeEnum.ArrowRight) {
            this.swiperDirective.nextSlide();
        } else if (keyCode === KeyCodeEnum.ArrowLeft) {
            this.swiperDirective.prevSlide();
        }
    }

    private handleZoomableViewEvent(event: ZoomableViewEventInterface): void {
        if (ZoomableViewEventTypeEnum.Scale !== event.type) {
            return;
        }

        this.update();
    }

    private update(): void {
        if (undefined === this.swiperDirective) {
            return;
        }

        this.swiperDirective.update();
    }

    private handleLogoClick(): void {
        this.router.navigate(['/']);
    }

    private setActiveScreenByUrlSegments(segments: UrlSegment[]): void {
        const lastSegment: UrlSegment | undefined =
            segments[segments.length - 1];

        if (undefined === lastSegment) {
            throw ContentException.noUrlSegments();
        }

        if ('content' === lastSegment.path) {
            this.setActiveScreenByIndex(0);

            return;
        }

        const path: string = segments
            .slice(1)
            .filter(segment => segment.path !== 'content')
            .join('/');

        let index: number = this.screens.indexOf(
            this.resource.getChildByPath(path)
        );

        // If no screen was found by thipId, activate the first screen.
        if (index === -1) {
            index = 0;
        }

        this.setActiveScreenByIndex(index);
    }

    private setActiveScreenByIndex(index: number): void {
        this.resetZoom();

        const resource: Resource = this.screens[index];
        const screenComponents: ScreenComponent[] =
            this.screenComponents.toArray();

        this.location.go(resource.getRoute());

        this.gaService.pageView(
            this.location.path(),
            `${resource.getMethod()} ${resource.getName()}`
        );

        this.gaService.event(
            GoogleAnalyticsActionsEnum.NavigationSlideType,
            GoogleAnalyticsCategoriesEnum.Content,
            resource.getType()
        );

        // Set all screens on inactive and load content for current screen
        screenComponents.forEach((s: ScreenComponent) => s.setActive(false));
        screenComponents[index].loadContent(true);

        this.currentIndex = index; // Set current index
        this.headerService.setBreadCrumbs(resource);
        this.contentService.setCurrentResource(resource);
        this.swiperDirective.setIndex(index, 0, true);

        this.preloadScreensBeforeAndAfterIndex(index);
    }

    private handleNavigateToResource(resource: Resource): void {
        const index: number = this.screens.indexOf(resource);

        if (-1 === index) {
            throw new Error(
                'Unable to navigate to resource, resource was not found'
            );
        }

        if (index === this.currentIndex) {
            return;
        }

        this.setActiveScreenByIndex(index);
    }

    private preloadScreensBeforeAndAfterIndex(index: number): void {
        Promise.all(
            this.screenComponents
                .filter((component: ScreenComponent, key: number) =>
                    [index - 1, index + 1].includes(key)
                )
                .map((component: ScreenComponent) => component.loadContent())
        );
    }

    private resetZoom(): void {
        this.zoomableViewService.zoomInOutReset(ZoomEnum.Reset);
    }
}
