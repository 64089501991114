import {
    ChangeDetectorRef,
    Directive,
    HostBinding,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    ZoomableViewEventInterface,
    ZoomableViewEventTypeEnum,
    ZoomableViewService,
} from 'app/zoomable-view/service/zoomable-view/zoomable-view.service';
import {Subscription} from 'rxjs';

@Directive({
    selector: '[appScaleApply]',
})
export class ScaleApplyDirective implements OnInit, OnDestroy {
    @HostBinding('style.transform')
    private transform = 'scale(1)';

    private subscription?: Subscription;

    public constructor(
        private zoomableViewService: ZoomableViewService,
        private changeDetector: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        this.updateScale();

        this.subscription = this.zoomableViewService.subscribe(event =>
            this.handleZoomableViewEvent(event)
        );
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private handleZoomableViewEvent(event: ZoomableViewEventInterface): void {
        if (event.type !== ZoomableViewEventTypeEnum.Scale) {
            return;
        }

        this.updateScale();
    }

    private updateScale(): void {
        this.transform = `scale(${this.zoomableViewService.getScale()})`;
        this.changeDetector.detectChanges();
    }
}
