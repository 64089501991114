export interface PointInterface {
    x: number;
    y: number;
}

export class Point implements PointInterface {
    constructor(public x: number = 0, public y: number = 0) {}
}

export interface CartesianPointInterface extends PointInterface {
    angle: number;
    radius: number;
}

export class CartesianPoint extends Point implements CartesianPointInterface {
    constructor(public radius: number, public angle: number) {
        super(radius * Math.cos(angle), radius * Math.sin(angle));
    }

    public static fromCartesianPoint(
        cartesianPoint: CartesianPointInterface
    ): CartesianPointInterface {
        return new CartesianPoint(cartesianPoint.radius, cartesianPoint.angle);
    }
}
