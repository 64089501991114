import {
    Compiler,
    Component,
    ComponentFactory,
    CUSTOM_ELEMENTS_SCHEMA,
    Injectable,
    ModuleWithProviders,
    NgModule,
    Provider,
    Type,
} from '@angular/core';
import {JitComponentClass} from 'app/content/screen/factory/classes/jit-component.class';
import {ScreenInterface} from 'app/content/screen/factory/interfaces/screen.interface';

@Injectable()
export class ComponentFactoryFactory {
    constructor(private compiler: Compiler) {}

    public create(
        template: string,
        imports: Array<
            Type<unknown> | ModuleWithProviders<{}> | unknown[]
        > = [],
        providers: Provider[] = [],
        componentClass: Type<ScreenInterface> = JitComponentClass
    ): ComponentFactory<ScreenInterface> {
        @Component({template})
        class JitTemplateComponent extends componentClass {}

        @NgModule({
            schemas: [CUSTOM_ELEMENTS_SCHEMA],
            declarations: [JitTemplateComponent],
            imports,
            providers,
        })
        class JitTemplateModule {}

        const moduleWithComponentsFactory =
            this.compiler.compileModuleAndAllComponentsSync(JitTemplateModule);
        const factory = moduleWithComponentsFactory.componentFactories.find(
            factoryResult =>
                factoryResult.componentType === JitTemplateComponent
        );

        if (undefined === factory) {
            throw new Error('Factory not found');
        }

        return factory;
    }
}
