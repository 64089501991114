import {Injectable} from '@angular/core';
import {Resource} from 'app/service/resource/classes/resource.class';
import {ApiResourceEnum} from 'app/interface/api-resource.interface';
import {ResourceService} from 'app/service/resource/resource.service';
import {MethodService} from 'app/service/method/method.service';
import {IconEnum} from 'app/enum/icon.enum';
import {
    ApiMethodCategoriesInterface,
    ApiMethodToolInterface,
} from 'app/interface/api-methods.interface';
import {CategoryIconMapping} from 'app/toolbox/mapping/category-icon.mapping';
import {Subject, Subscription} from 'rxjs';

export interface ToolboxItemInterface {
    id?: number;
    value: string;
    icon?: IconEnum;
    type?: string;
    children?: ToolboxItemInterface[];
}

class ToolboxItem implements ToolboxItemInterface {
    private constructor(
        public value: string,
        public id?: number,
        public icon?: IconEnum,
        public type?: string,
        public children?: ToolboxItemInterface[]
    ) {}

    public static fromCategory(category: string): ToolboxItemInterface {
        return new this(
            category,
            undefined,
            CategoryIconMapping.get(category),
            undefined,
            undefined
        );
    }

    public static fromTool(tool: ApiMethodToolInterface): ToolboxItemInterface {
        return new this(tool.name, tool.id, undefined, tool.type, []);
    }
}

@Injectable()
export class ToolboxService {
    private resourceCode?: string;
    private categories: ToolboxItemInterface[] = [];
    private tools = new Map<string, ToolboxItemInterface[]>();
    private categoriesChangedSubject = new Subject<ToolboxItemInterface[]>();

    constructor(
        private resourceService: ResourceService,
        private methodService: MethodService
    ) {
        this.resourceService.subscribeToCurrentResource(resource =>
            this.onResourceChange(resource)
        );
    }

    public getCategories(): ToolboxItemInterface[] {
        return this.categories;
    }

    public subscribeToCategoriesChanged(
        func: (categories: ToolboxItemInterface[]) => void
    ): Subscription {
        return this.categoriesChangedSubject.subscribe(func);
    }

    public async getToolsForCategory(
        category: string
    ): Promise<ToolboxItemInterface[]> {
        if (!this.resourceCode) {
            return Promise.reject('Current resource code not set');
        }

        const key = `${this.resourceCode}-${category}`;
        let items = this.tools.get(key);
        if (items) {
            return Promise.resolve(items);
        }

        const tools = await this.methodService.getToolsForCategory(
            this.resourceCode,
            category
        );

        items = tools.map(tool => ToolboxItem.fromTool(tool));
        this.tools.set(key, items);

        return items;
    }

    private onResourceChange(resource: Resource): void {
        if (ApiResourceEnum.Method === resource.getType()) {
            return;
        }

        const resourceCode: string = resource.getCode();

        if (this.resourceCode === resourceCode) {
            return;
        }

        this.setCurrentArrangement(resourceCode);

        this.methodService
            .getCategoriesByArrangement(resourceCode)
            .then(categories => this.setCategories(categories));
    }

    private setCategories(categories: ApiMethodCategoriesInterface): void {
        this.categories = (categories as string[]).map(category =>
            ToolboxItem.fromCategory(category)
        );

        this.categoriesChangedSubject.next(this.categories);
    }

    private setCurrentArrangement(resourceCode: string): void {
        this.resourceCode = resourceCode;
    }
}
