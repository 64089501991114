import {Component, HostBinding} from '@angular/core';

@Component({
    selector: 'app-panel',
    templateUrl: './panel.component.html',
    styleUrls: ['./panel.component.scss'],
})
export class PanelComponent {
    public opened = true;

    @HostBinding('class.opened')
    get openedClass() {
        return this.opened;
    }

    public toggle() {
        this.opened = !this.opened;
    }
}
