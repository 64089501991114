import {NgModule} from '@angular/core';
import {ScreenDrawingWrapperDirective} from 'app/content/screen/directive/drawing-wrapper/drawing-wrapper.directive';
import {LinkOpenToolDirective} from 'app/content/screen/directive/link-open-tool/link-open-tool.directive';
import {ScreenInnerPdfScalingDirective} from 'app/content/screen/directive/screen-inner-pdf/scaling.directive';
import {ContentTheoryBodyDirective} from 'app/content/screen/directive/theory-body/theory-body.directive';
import {OriginalContentDirective} from 'app/content/screen/directive/original-content/original-content.directive';
import {ImageDirective} from 'app/content/screen/directive/image/image.directive';
import {FitToParentDirective} from 'app/directive/fit-parent/fit-to-parent.directive';

@NgModule({
    declarations: [
        ContentTheoryBodyDirective,
        OriginalContentDirective,
        ScreenInnerPdfScalingDirective,
        ImageDirective,
        FitToParentDirective,
        LinkOpenToolDirective,
        ScreenDrawingWrapperDirective,
    ],
    exports: [
        ContentTheoryBodyDirective,
        OriginalContentDirective,
        ScreenInnerPdfScalingDirective,
        ImageDirective,
        FitToParentDirective,
        LinkOpenToolDirective,
        ScreenDrawingWrapperDirective,
    ],
    imports: [],
})
export class ContentDirectiveModule {}
