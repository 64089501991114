import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';

@Component({
    selector: 'app-te',
    templateUrl: './te.component.html',
    styleUrls: ['./te.component.scss'],
})
export class TeComponent extends AbstractTool {
    public static readonly id = 'te';
    public static readonly width = 95;
    public static readonly height = 95;
    public rangeModel = {
        T: 0,
        E: 0,
    };

    constructor() {
        super();
    }
}
