import {
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    Renderer2,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {OriginalContentDirective} from 'app/content/screen/directive/original-content/original-content.directive';
import {DropListRef} from '@angular/cdk/drag-drop';
import {DropListComponent} from 'app/cdk/component/drop-list/drop-list.component';
import {StateIconEnum} from 'app/component/state-icon/state-icon.component';
import {ImageDirective} from 'app/content/screen/directive/image/image.directive';
import {DraggablesDirective} from 'app/cdk/directive/draggables/draggables.directive';

interface AssignmentOptionDataInterface {
    id: string;
    value: string;
}

@Component({
    selector: 'assignment-gap-match-option',
    templateUrl: './assignment-gap-match-option.component.html',
    styleUrls: ['./assignment-gap-match-option.component.scss'],
})
export class AssignmentGapMatchOptionComponent {
    private readonly htmlElement: HTMLElement;

    @ContentChild(ImageDirective)
    private imageDirective?: ImageDirective;

    @ViewChild(OriginalContentDirective)
    private originalContent!: OriginalContentDirective;

    @ViewChild(DropListComponent)
    private dropListComponent!: DropListComponent<AssignmentOptionDataInterface>;

    @ViewChild(DraggablesDirective)
    private draggables?: DraggablesDirective<AssignmentOptionDataInterface>[];

    private width?: number;
    private height?: number;
    private readonly original: boolean;

    @Input('data-id')
    private id?: string;

    @Output()
    public clickChange = new EventEmitter<AssignmentGapMatchOptionComponent>();

    @HostBinding('style.top.px')
    private positionTop?: number;

    @HostBinding('style.left.px')
    private positionLeft?: number;

    @HostBinding('class.positioned')
    private positioned = false;

    public valid = false;
    public items: AssignmentOptionDataInterface[] = [];
    public stateIcon: StateIconEnum = StateIconEnum.InCorrect;
    public isDragging = false;
    public checked = false;

    @HostBinding('class.is-selected')
    public selected = false;

    constructor(
        private renderer2: Renderer2,
        private changeDetectorRef: ChangeDetectorRef,
        public readonly elementRef: ElementRef,
        public readonly viewContainerRef: ViewContainerRef
    ) {
        this.htmlElement = this.elementRef.nativeElement as HTMLElement;
        this.positioned = this.htmlElement.hasAttribute('data-position');
        this.original = this.htmlElement.hasAttribute('data-id');
    }

    public setPositionTop(value: number): void {
        this.positionTop = value;
    }

    public setPositionLeft(value: number): void {
        this.positionLeft = value;
    }

    public setHeight(value: number): void {
        this.height = value;
        this.renderer2.setStyle(this.htmlElement, 'height', `${value}px`);
    }

    /**
     * Returns HTMLElement offsetWidth when no width is specified.
     */
    public getHeight(): number {
        const height: number | undefined = this.height;

        return height !== undefined ? height : this.htmlElement.offsetHeight;
    }

    public setWidth(value: number): void {
        this.width = value;
        this.renderer2.setStyle(this.htmlElement, 'width', `${value}px`);
    }

    /**
     * Returns HTMLElement offsetWidth when no width is specified.
     */
    public getWidth(): number {
        const width: number | undefined = this.width;

        return width !== undefined ? width : this.htmlElement.offsetWidth;
    }

    public getValue(): string {
        return this.originalContent.getInnerHTML();
    }

    public setId(value: string): void {
        this.id = value;
    }

    public getId(): string {
        const id: string | undefined = this.id;

        if (undefined === id) {
            throw new Error('Option has no ID');
        }

        return id;
    }

    public getDropListRef(): DropListRef {
        return this.dropListComponent.getDropListRef();
    }

    public detectChanges(): void {
        this.changeDetectorRef.detectChanges();
    }

    public check(): boolean {
        if (0 === this.items.length) {
            return false;
        }

        const valid: boolean = this.items[0].id === this.getId();

        this.setValid(valid);
        this.setChecked(true);
        this.updateHasItemsClass();

        return valid;
    }

    private setChecked(value: boolean): void {
        this.checked = value;
        this.toggleClass('is-checked', value);
    }

    public isChecked(): boolean {
        return this.checked;
    }

    private setValid(value: boolean): void {
        this.valid = value;
        this.stateIcon = value
            ? StateIconEnum.Correct
            : StateIconEnum.InCorrect;

        this.toggleClass('is-valid', value);
    }

    public isValid(): boolean {
        return this.valid;
    }

    public reset(): void {
        this.setChecked(false);
        this.setValid(false);
        this.isDragging = false;
        this.updateHasItemsClass();
    }

    public setClasses(classes: string[]): void {
        this.htmlElement.classList.add(...classes);
    }

    public getClasses(): string[] {
        return this.htmlElement.classList as unknown as string[];
    }

    public addClass(className: string): void {
        this.htmlElement.classList.toggle(className, true);
    }

    public isSelected(): boolean {
        return this.selected;
    }

    public setSelected(selected: boolean): void {
        this.selected = selected;
    }

    public isOriginal(): boolean {
        return this.original;
    }

    @HostListener('click')
    private onClick(): void {
        this.clickChange.emit(this);
    }

    private updateHasItemsClass(): void {
        if (this.isOriginal()) {
            this.toggleClass('has-items', 0 !== this.items.length);
        }
    }

    private toggleClass(className: string, force?: boolean): void {
        this.elementRef.nativeElement.classList.toggle(className, force);
    }
}
