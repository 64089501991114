import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {ComponentModule} from 'app/component/component.module';
import {MetricSystemComponent} from 'app/tool-layer/tools/metric-system/metric-system.component';

@NgModule({
    imports: [CommonModule, ComponentModule],
    declarations: [MetricSystemComponent],
    entryComponents: [MetricSystemComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(MetricSystemComponent),
            multi: true,
        },
    ],
})
export class MetricSystemModule {}
