import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
} from '@angular/core';
import {DrawingService} from 'app/drawing/service/drawing/drawing.service';
import {DrawingStateEnum} from 'app/drawing/enum/state.enum';
import {Subscription} from 'rxjs';
import {DrawingCanvasComponent} from 'app/drawing/component/canvas/canvas.component';
import {
    DrawingComponentInterface,
    DrawingPdfComponentInterface,
} from 'app/drawing/interface/drawing-component.interface';
import {PdfAssetComponent} from 'app/content/screen/components/pdf-asset/pdf-asset.component';

// eslint-disable-next-line
declare let fabric: any | never;

@Component({
    selector: 'screen-drawing',
    templateUrl: 'screen-drawing.component.html',
    styleUrls: ['screen-drawing.component.scss'],
})
export class ScreenDrawingComponent
    implements OnInit, DrawingComponentInterface, DrawingPdfComponentInterface
{
    @ViewChild(DrawingCanvasComponent)
    private canvas!: DrawingCanvasComponent;

    @ViewChild('imgElement')
    private imageElement!: ElementRef<HTMLImageElement>;

    @HostBinding('class.screen-drawing--enabled')
    public drawingActive = false;

    @Input()
    public pdfComponents!: QueryList<DrawingPdfComponentInterface>;

    @Output()
    public drawn = new EventEmitter<fabric.Object>();

    public answerImageElement!: HTMLImageElement;

    private answerShowing = false;

    private drawnCanvasFabric?: fabric.Canvas;

    private drawingStateSubscription?: Subscription;

    public constructor(private drawingService: DrawingService) {
        this.drawingStateChanged(drawingService.state);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public drawnAction(object?: fabric.Object): void {
        throw new Error('Method not implemented.');
    }

    public ngOnInit(): void {
        this.drawingStateSubscription =
            this.drawingService.subscribeToStateChange(change =>
                this.drawingStateChanged(change)
            );
    }

    private drawingStateChanged(change: DrawingStateEnum): void {
        this.drawingActive = this.drawingService.isDrawingActive(change);
    }

    public handleDrawn(canvas: fabric.Object): void {
        this.drawn.emit(canvas);
    }

    public undoAction(object?: fabric.Object): void {
        if (!object) {
            return;
        }

        this.canvas.removeObject(object);
    }

    public redoAction(object?: fabric.Object): void {
        if (!object) {
            return;
        }

        this.canvas.addObject(object);
    }

    public clearAction(): void {
        this.canvas.clear();
    }

    private setImageSrc(src?: string): void {
        if (!this.imageElement) {
            return;
        }

        if (src === undefined) {
            src = PdfAssetComponent.EMPTY_IMAGE;
        }

        const image = this.imageElement.nativeElement;
        image.src = src;
    }

    public showAnswerPdfAction(): void {
        if (undefined === this.answerImageElement) {
            return;
        }

        this.answerShowing = true;
        this.setImageSrc(this.answerImageElement.src);
    }

    public hideAnswerPdfAction(): void {
        if (!this.answerShowing) {
            return;
        }

        this.answerShowing = false;
        this.updateImagePlaceholder();
    }

    private updateImagePlaceholder(): void {
        if (this.drawnCanvasFabric === undefined) {
            return undefined;
        }

        this.answerShowing = false;
        this.setImageSrc(this.drawnCanvasFabric.toDataURL());
    }
}
