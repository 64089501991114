import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-tool-setting',
    templateUrl: './tool-setting.component.html',
    styleUrls: ['./tool-setting.component.scss'],
})
export class ToolSettingComponent {
    @Input()
    public label!: string;
}
