import {Directive, ElementRef, HostListener, OnDestroy} from '@angular/core';
import {Point, PointInterface} from 'app/classes/point.class';
import {
    ZoomableViewEventInterface,
    ZoomableViewEventTypeEnum,
    ZoomableViewService,
} from 'app/zoomable-view/service/zoomable-view/zoomable-view.service';
import {TapEventInterface} from 'app/interface/event.interface';
import {AbstractZoomableViewDirective} from 'app/zoomable-view/classes/abstract-zoomable-view.directive';
import {
    PanPointEvent,
    PanPointEventTypeEnum,
} from 'app/directive/pan-point/pan-point.directive';
import {Subscription} from 'rxjs';
import {UserSelectionArea} from 'app/interface/selection.interface';
import {ZoomEnum} from 'app/enum/zoom.enum';

@Directive({
    selector: '[appZoom]',
})
export class ZoomDirective
    extends AbstractZoomableViewDirective
    implements OnDestroy
{
    private subscriptions: Subscription[] = [];

    constructor(
        elementRef: ElementRef,
        zoomableViewService: ZoomableViewService
    ) {
        super(elementRef, zoomableViewService);
        this.subscriptions.push(
            this.zoomableViewService.subscribe(event =>
                this.handleZoomableViewServiceEvent(event)
            )
        );
        this.subscriptions.push(
            this.zoomableViewService.subscribeToZoom(zoom => this.doZoom(zoom))
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    protected handleAttributeMutationEvent(): void {
        throw new Error('Method not implemented.');
    }

    protected handleZoomableViewServiceEvent(
        event: ZoomableViewEventInterface
    ): void {
        if (
            event.type === ZoomableViewEventTypeEnum.Zoom &&
            event.value &&
            event.value.selection
        ) {
            this.handleSelectionEvent(event);
        }

        // Do not handle scale or enabled zoom events
        if (ZoomableViewEventTypeEnum.Scale === event.type || event.enabled) {
            return;
        }

        this.reset();
    }

    private handleSelectionEvent(event: ZoomableViewEventInterface): void {
        if (!event || !event.value || !event.value.selection) {
            return;
        }

        const {scale, point} = this.transformSelection(event.value.selection);
        this.setTransformPointAndScale(point, scale);
    }

    private transformSelection(selection: UserSelectionArea): {
        scale: number;
        point: PointInterface;
    } {
        const {width, height}: DOMRect =
            this.elementRef.nativeElement.parentElement.getBoundingClientRect();

        const selectionWidth = selection.x2 - selection.x1;
        const selectionHeight = selection.y2 - selection.y1;

        const ratioX = width / selectionWidth;
        const ratioY = height / selectionHeight;
        const smallestRatio = ratioX < ratioY ? ratioX : ratioY;
        const scale = smallestRatio * this.scale;

        const contentElement: DOMRect =
            this.elementRef.nativeElement.getBoundingClientRect();
        const point = {
            x: -1 * (selection.x1 / width) * (scale * contentElement.width),
            y: -1 * (selection.y1 / height) * (scale * contentElement.height),
        };

        // Apply correction to center horizontally or vertically
        if (ratioX > ratioY) {
            const correction = (width - scale * selectionWidth) / 2;
            point.x += correction;
        } else {
            const correction = (height - scale * selectionHeight) / 2;
            point.y += correction;
        }

        point.x = this.getRestrictedValue(point.x, scale, width);
        point.y = this.getRestrictedValue(point.y, scale, height);

        return {scale, point};
    }

    @HostListener(PanPointEventTypeEnum.End, ['$event'])
    @HostListener(PanPointEventTypeEnum.Move, ['$event'])
    private onPanPointMove(event: PanPointEvent): void {
        if (1 === this.scale) {
            return;
        }

        const point: PointInterface = event.point;

        this.setTransform(point.x, point.y, this.scale);

        if (event.type === PanPointEventTypeEnum.End) {
            this.transformPoint = point;
        }
    }

    @HostListener('doubleTap', ['$event'])
    private toggleZoom(event: TapEventInterface): void {
        if (!this.zoomableViewService.isZoomEnabled()) {
            return;
        }

        const scale: number = 1 !== this.scale ? 1 : 2;

        if (1 === scale) {
            this.reset();

            return;
        }

        const elementDOMRect: DOMRect =
            this.elementRef.nativeElement.getBoundingClientRect();
        const parentDOMRect: DOMRect =
            this.elementRef.nativeElement.parentElement.getBoundingClientRect();

        // Get center top/left and deduct parent offsets
        const pointX: number =
            (event.center.x - parentDOMRect.left - elementDOMRect.width / 4) *
            scale;
        const pointY: number =
            (event.center.y - parentDOMRect.top - elementDOMRect.height / 4) *
            scale;

        this.doTransform(scale, new Point(-pointX, -pointY));
    }

    private doZoom(zoom: ZoomEnum): void {
        if (
            ZoomEnum.Reset === zoom ||
            (ZoomEnum.Out === zoom && 1 === this.scale)
        ) {
            this.reset();

            return;
        }

        const elementDOMRect: DOMRect =
            this.elementRef.nativeElement.getBoundingClientRect();
        const parentDOMRect: DOMRect =
            this.elementRef.nativeElement.parentElement.getBoundingClientRect();

        const scale: number = this.scale + zoom;

        // prevent scaling below 1
        if (scale < 1) {
            this.reset();

            return;
        }

        // parent might have a top and/or left offset
        const elementLeft: number = elementDOMRect.left - parentDOMRect.left;
        const elementTop: number = elementDOMRect.top - parentDOMRect.top;

        // Calculate new height and width based on original height and width
        const newElementWidth: number =
            (elementDOMRect.width / this.scale) * scale;
        const newElementHeight: number =
            (elementDOMRect.height / this.scale) * scale;

        const pointX: number =
            elementLeft - (newElementWidth - elementDOMRect.width) / 2;
        const pointY: number =
            elementTop - (newElementHeight - elementDOMRect.height) / 2;

        this.doTransform(scale, new Point(pointX, pointY));
    }

    private doTransform(scale: number, point: PointInterface): void {
        const elementDOMRect: DOMRect =
            this.elementRef.nativeElement.getBoundingClientRect();

        const pointX: number = this.getRestrictedValue(
            point.x,
            scale,
            elementDOMRect.width
        );
        const pointY: number = this.getRestrictedValue(
            point.y,
            scale,
            elementDOMRect.height
        );

        this.setTransformPointAndScale(new Point(pointX, pointY), scale);
    }

    private getRestrictedValue(
        point: number,
        scale: number,
        dimension: number
    ): number {
        const scaledDimension: number = dimension * scale;
        const originalDimension: number = dimension / this.scale;

        if (point > 0) {
            return 0;
        }

        if (scaledDimension + point < originalDimension) {
            return -(scaledDimension - originalDimension);
        }

        return point;
    }
}
