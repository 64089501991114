import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TocComponent} from './component/toc.component';
import {TocService} from 'app/toc/service/toc/toc.service';
import {CdkModule} from 'app/cdk/cdk.module';
import {TocResourceComponent} from 'app/toc/components/toc-resource/toc-resource.component';
import {ContentComponentsModule} from 'app/content/screen/components/components.module';
import {ComponentModule} from 'app/component/component.module';

@NgModule({
    imports: [
        CommonModule,
        ContentComponentsModule,
        CdkModule,
        ComponentModule,
    ],
    declarations: [TocComponent, TocResourceComponent],
    providers: [TocService],
})
export class TocModule {}
