import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';

@Component({
    selector: 'app-percent-circle',
    templateUrl: './percent-circle.component.html',
})
export class PercentCircleComponent extends AbstractTool {
    public static readonly id = 'procentencirkel';
    public static readonly width = 300;
    public static readonly height = 300;

    public distribution = 1;
    public showUnits = true;
    public showNumbers = true;

    public toggleshowUnits(): void {
        this.showUnits = !this.showUnits;
    }

    public toggleShowNumbers(): void {
        this.showNumbers = !this.showNumbers;
    }
}
