import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ComponentModule} from 'app/component/component.module';
import {DrawingCanvasComponent} from 'app/drawing/component/canvas/canvas.component';
import {DrawingLayerComponent} from 'app/drawing/component/layer/layer.component';
import {DrawingServiceModule} from 'app/drawing/service/service.module';
import {KeyboardsModule} from 'app/drawing/component/keyboards/keyboards.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PenComponent} from './pen/pen.component';
import {ToolsComponent} from './tools/tools.component';
import {HighlighterComponent} from './highlighter/highlighter.component';
import {EraserComponent} from './eraser/eraser.component';

@NgModule({
    imports: [
        BrowserModule,
        DrawingServiceModule,
        ComponentModule,
        KeyboardsModule,
        DragDropModule,
    ],
    declarations: [
        DrawingLayerComponent,
        DrawingCanvasComponent,
        PenComponent,
        HighlighterComponent,
        EraserComponent,
        ToolsComponent,
    ],
    exports: [
        DrawingLayerComponent,
        DrawingCanvasComponent,
        PenComponent,
        HighlighterComponent,
        EraserComponent,
        ToolsComponent,
    ],
})
export class DrawingComponentModule {}
