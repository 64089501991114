import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';

export enum TrafficLightStatusEnum {
    Off,
    Red,
    Yellow,
    Green,
}

@Component({
    selector: 'app-tool-traffic-light-component',
    templateUrl: './traffic-light.component.html',
    styleUrls: ['./traffic-light.component.scss'],
})
export class TrafficLightComponent extends AbstractTool {
    public static readonly id = 'verkeerslicht';
    public static readonly width = 140;
    public static readonly height = 400;

    public readonly STATES = TrafficLightStatusEnum;

    public activeState = TrafficLightStatusEnum.Off;

    public switchTrafficLight(state: TrafficLightStatusEnum): void {
        if (this.activeState === state) {
            state = TrafficLightStatusEnum.Off;
        }

        this.activeState = state;
    }
}
