import {Pipe, PipeTransform} from '@angular/core';
import {Resource} from 'app/service/resource/classes/resource.class';
import {ApiResourceEnum} from 'app/interface/api-resource.interface';
import {MethodTypeMapping} from 'app/mapping/method-type.mapping';

@Pipe({name: 'selectMethod'})
export class SelectMethodPipe implements PipeTransform {
    public transform(resources?: Resource[]): string {
        let type: string = ApiResourceEnum.Method;
        let methodTypeValue: string | undefined = MethodTypeMapping.get(type);

        if (undefined !== resources && 0 !== resources.length) {
            type = resources[0].getType();
            methodTypeValue = MethodTypeMapping.get(type);
        }

        if (undefined === methodTypeValue) {
            throw new Error(`No resource type mapping found for "${type}"`);
        }

        return methodTypeValue;
    }
}
