import {Component, HostListener} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';
import {ClockAnalog} from 'app/tool-layer/tools/analog-clock/class/clock-analog.class';
import {HandMoveEnum} from 'app/tool-layer/tools/analog-clock/class/clock.enum';

@Component({
    selector: 'analog-clock-component',
    templateUrl: './analog-clock.component.html',
    styleUrls: ['./analog-clock.component.scss'],
})
export class AnalogClockComponent extends AbstractTool {
    public static readonly id = 'analog_clock';
    public static readonly width = 250;
    public static readonly height = 300;

    public readonly handMoveEnum = HandMoveEnum;
    public readonly numberOfSteps = 60;

    public showNumbers = true;
    public zoomSize = 'default';
    public showHoursHand = true;
    public showMinutesHand = true;
    public showSecondsHand = true;

    public selectedHand?: HandMoveEnum;
    public steps: number[] = [];

    public persistentClock = ClockAnalog.createForCurrentDate();
    public clock = ClockAnalog.createForClock(this.persistentClock);

    constructor() {
        super();

        this.drawSteps();
    }

    public selectHand(hand: HandMoveEnum): void {
        this.selectedHand = hand;
    }

    public clearSelected() {
        this.selectedHand = undefined;
    }

    public handleHandMove(index: number) {
        if (!this.selectedHand) {
            return;
        }

        this.persistentClock.updateTimeByEnumAndDegrees(
            this.selectedHand,
            index
        );

        this.clock.updateByClock(this.persistentClock);
    }

    public setZoomSize(size: string): void {
        this.zoomSize = size;
    }

    @HostListener('document:touchend')
    private onTouchEnd(): void {
        this.clearSelected();
    }

    @HostListener('document:touchmove', ['$event'])
    private onTouchMove(event: TouchEvent): void {
        if (this.selectedHand) {
            const {clientX, clientY} = event.changedTouches[0];

            const element = document.elementFromPoint(
                clientX,
                clientY
            ) as SVGElement;

            if (element.nodeName === 'line') {
                element.dispatchEvent(new Event('mouseover'));
            }
        }
    }

    private drawSteps() {
        let i = 0;
        const degreesPerStep = 360 / this.numberOfSteps;

        while (i < this.numberOfSteps) {
            this.steps.push(degreesPerStep * i);
            i++;
        }
    }
}
