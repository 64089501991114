import {TemplatePortal} from '@angular/cdk/portal';
import {ComponentRef, ViewContainerRef} from '@angular/core';
import {Point, PointInterface} from 'app/classes/point.class';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {StringUtil} from 'app/util/string/string.util';
import {ToolConfig} from 'app/tool-layer/service/active/active-tool.service';

export class ToolInstance {
    public position: PointInterface;
    private settingInstance: boolean;
    private abstractTool?: AbstractTool;
    private componentRef?: ComponentRef<AbstractTool>;

    private constructor(
        public readonly tool: AbstractToolWrapper,
        public readonly type: string,
        public readonly name: string,
        public readonly config?: ToolConfig,
        isSettingInstance?: boolean,
        public component?: TemplatePortal<unknown>,
        public settings?: TemplatePortal<unknown>,
        public readonly generatedId?: string
    ) {
        const deviation = 64;
        this.position = new Point(
            -Math.abs(tool.start.x) / 2 +
                Math.floor(
                    Math.random() * (deviation - -deviation + 1) + -deviation
                ),
            -Math.abs(tool.start.y) / 2 +
                Math.floor(
                    Math.random() * (deviation - -deviation + 1) + -deviation
                )
        );
        this.generatedId = StringUtil.random(6);
        this.settingInstance =
            config && config.parent ? false : isSettingInstance === true;
    }

    public static create(
        tool: AbstractToolWrapper,
        name: string,
        config?: ToolConfig,
        isSettingInstance = false
    ): ToolInstance {
        if (tool.immediatelyAdd && isSettingInstance) {
            isSettingInstance = false;
        }

        return new ToolInstance(tool, tool.id, name, config, isSettingInstance);
    }

    public get isSettingInstance(): boolean {
        return this.settingInstance;
    }

    public shouldBeDemoted(): boolean {
        return (
            !this.settingInstance &&
            !(this.tool.immediatelyAdd && this.tool.hideAddButton)
        );
    }

    public prepare(
        componentRef: ComponentRef<AbstractTool>,
        viewContainerRef: ViewContainerRef
    ): void {
        this.componentRef = componentRef;
        this.abstractTool = componentRef.instance;
        this.abstractTool.instance = this;

        if (this.config && this.config.data) {
            this.abstractTool.injectedData = this.config.data;
        }

        this.component = new TemplatePortal(
            this.abstractTool.tool,
            viewContainerRef
        );
        this.settings = new TemplatePortal(
            this.abstractTool.settings,
            viewContainerRef
        );
    }

    public rememberPosition(point: PointInterface): void {
        this.position = point;
    }

    public promoteToRegularInstance(): void {
        this.settingInstance = false;
    }

    public demoteToSettingInstance(): void {
        this.settingInstance = true;
    }

    public getToolCategory(): string | undefined {
        if (!this.config) {
            return undefined;
        }

        return this.config.category;
    }

    public hideTool(): void {
        if (this.abstractTool && this.abstractTool.onHideTool) {
            this.abstractTool.onHideTool();
        }
    }

    public destroy(): void {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }
}
