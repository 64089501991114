import {Component, OnDestroy} from '@angular/core';
import {Resource} from 'app/service/resource/classes/resource.class';
import {HeaderService} from 'app/header/service/header.service';
import {Subscription} from 'rxjs';
import {BreadcrumbInterface} from 'app/header/component/header.component';
import {ResourceService} from 'app/service/resource/resource.service';

@Component({
    selector: 'app-second-screen-print',
    templateUrl: './second-screen-print.component.html',
    styleUrls: ['./second-screen-print.component.scss'],
})
export class SecondScreenPrintComponent implements OnDestroy {
    public breadcrumbs: BreadcrumbInterface[] = [];

    private breadcrumbSubscription!: Subscription;
    private resourceSubscription!: Subscription;
    private resources?: Resource[];

    constructor(
        private resourceService: ResourceService,
        private headerService: HeaderService
    ) {
        this.resourceSubscription = resourceService.subscribeToCurrentResource(
            resource => this.handleResourceChange(resource)
        );
        this.breadcrumbSubscription = headerService.breadcrumbsSubscribe(
            breadcrumbs => this.handleBreadcrumbsChange(breadcrumbs)
        );

        this.handleResourceChange(resourceService.getCurrentResource());
    }

    public ngOnDestroy() {
        this.breadcrumbSubscription.unsubscribe();
        this.resourceSubscription.unsubscribe();
    }

    public get slides(): Resource[] {
        return this.resources || [];
    }

    private handleResourceChange(resource: Resource | undefined): void {
        if (undefined === resource) {
            return;
        }

        this.resources = resource.getChildren();
    }

    private handleBreadcrumbsChange(breadcrumbs: BreadcrumbInterface[]): void {
        if (undefined === breadcrumbs) {
            return;
        }

        this.breadcrumbs = breadcrumbs;
    }
}
