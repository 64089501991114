import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {HeaderComponent} from 'app/header/component/header.component';
import {HeaderService} from 'app/header/service/header.service';
import {ComponentModule} from 'app/component/component.module';
import {ServiceModule} from 'app/service/service.module';

@NgModule({
    imports: [CommonModule, ComponentModule, ServiceModule, RouterModule],
    declarations: [HeaderComponent],
    providers: [HeaderService],
    exports: [HeaderComponent],
})
export class HeaderModule {}
