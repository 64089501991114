import {Component, OnDestroy} from '@angular/core';
import {PointInterface} from 'app/classes/point.class';
import {BbvmsAudioComponent} from 'app/content/screen/components/bbvms/bbvms-audio/bbvms-audio.component';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';

export enum TimeTypesEnum {
    minutes = 'minutes',
    seconds = 'seconds',
}

@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss'],
})
export class TimerComponent extends AbstractTool implements OnDestroy {
    public static readonly id = 'kookwekker';
    public static readonly width = 300;
    public static readonly height = 350;
    public readonly timeTypes = TimeTypesEnum;

    // Settings
    public audioId?: number;
    public rangeMinutes = 0;
    public rangeSeconds = 5;
    private audioPlayer?: BbvmsAudioComponent;

    // Instance variables
    public showAlarm = false;
    public intervalId?: number | undefined;
    public timerMinutes!: number;
    public timerSeconds!: number;
    public radius!: number;
    public arcShift?: number;
    private cx!: number;
    private cy!: number;
    public x!: number;
    public y!: number;

    constructor() {
        super();

        this.stop();
    }

    private static toRadians(degrees: number): number {
        return degrees * (Math.PI / 180);
    }

    public ngOnDestroy(): void {
        this.stop();
    }

    public rangeUpdated(range: number, type: TimeTypesEnum): void {
        if (type === TimeTypesEnum.minutes) {
            if (range === 0) {
                this.timerSeconds = 5;
                this.rangeSeconds = 5;
            }

            if (range === 60 || (this.timerMinutes === 0 && range > 0)) {
                this.timerSeconds = 0;
                this.rangeSeconds = 0;
            }

            this.timerMinutes = range;
        }

        if (type === TimeTypesEnum.seconds) {
            this.timerSeconds = range;
        }
    }

    public audioIdUpdated(audioPlayer: BbvmsAudioComponent): void {
        this.audioPlayer = audioPlayer;
    }

    public start(): void {
        if (this.intervalId) {
            return;
        }

        this.showAlarm = false;
        this.updateCountDownXYPosition();

        this.intervalId = window.setInterval(() => {
            this.countDown();
        }, 1000);
    }

    public stop(): void {
        this.pause();

        this.showAlarm = false;
        this.timerMinutes = this.rangeMinutes;
        this.timerSeconds = this.rangeSeconds;

        this.initializeCountDownAreaValues();
        this.updateCountDownXYPosition();
    }

    public pause(): void {
        if (this.intervalId) {
            window.clearInterval(this.intervalId);
            this.intervalId = undefined;
        }
    }

    private countDown(): void {
        this.countDownTheTimerByASecond();

        if (this.timerSeconds === 0 && this.timerMinutes === 0) {
            this.finishCountDown();
        }
    }

    private finishCountDown(): void {
        this.stop();

        this.showAlarm = true;
        this.playAlarm();
    }

    private playAlarm(): void {
        if (this.audioPlayer) {
            this.audioPlayer.play();
        }
    }

    private countDownTheTimerByASecond(): void {
        if (this.timerSeconds > 0) {
            this.timerSeconds--;
        } else if (this.timerMinutes > 0) {
            this.timerMinutes--;
            this.timerSeconds = 59;
        }

        this.updateCountDownXYPosition();
    }

    private initializeCountDownAreaValues(): void {
        this.radius = 31;
        this.cx = 42.55;
        this.cy = 39.55;
        this.arcShift = 0;

        // to prevent errors in console, set default point of X and Y.
        this.x = 73.55;
        this.y = 39.55;
    }

    private updateCountDownXYPosition(): void {
        const xy: PointInterface = this.getXYForTimerStockAngle();
        this.x = xy.x;
        this.y = xy.y;
    }

    private getXYForTimerStockAngle(): PointInterface {
        const angle = this.getRadFromSeconds();

        return {
            x: this.cx + this.radius * Math.cos(angle),
            y: this.cy + this.radius * Math.sin(angle),
        };
    }

    private getRadFromSeconds(): number {
        const secondsLeft = this.timerSeconds + this.timerMinutes * 60;
        const degrees = secondsLeft / 10;

        if (degrees > 180) {
            this.arcShift = 1;
        } else {
            this.arcShift = 0;
        }

        return TimerComponent.toRadians(degrees);
    }
}
