import {
    AfterViewInit,
    Component,
    ComponentRef,
    Input,
    OnDestroy,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {ContentComponentsModule} from 'app/content/screen/components/components.module';
import {ContentDirectiveModule} from 'app/content/screen/directive/directive.module';
import {ComponentFactoryFactory} from 'app/content/screen/factory/component/component-factory.factory';
import {ScreenInterface} from 'app/content/screen/factory/interfaces/screen.interface';
import {ContentService} from 'app/service/content/content.service';
import {Resource} from 'app/service/resource/classes/resource.class';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({
    selector: 'app-screen',
    templateUrl: './screen.component.html',
    styleUrls: ['./screen.component.scss'],
})
export class ScreenComponent implements OnDestroy, AfterViewInit {
    @ViewChild('jitComponentContainer', {read: ViewContainerRef, static: true})
    public jitComponentContainer!: ViewContainerRef;

    @Input()
    public resource!: Resource;

    @Input()
    public teacherMode = false;

    @Input()
    public autoload = false;

    public error = false;

    private jitComponent?: ComponentRef<ScreenInterface>;
    private contentLoaded = false;
    private active = false;

    constructor(
        private contentService: ContentService,
        private componentFactoryFactory: ComponentFactoryFactory
    ) {}

    private static wrapContent(content: string): string {
        return `<screen-content-wrapper>${content}</screen-content-wrapper>`;
    }

    public ngOnDestroy(): void {
        this.jitComponent = undefined;
    }

    public ngAfterViewInit() {
        if (this.autoload) {
            this.loadContent();
        }
    }

    public loadContent(active = false): Promise<void> {
        return this.fetchContent().then(() => {
            if (!active) {
                return;
            }

            this.setActive(active);
        });
    }

    public setActive(active: boolean): void {
        this.active = active;
    }

    private fetchContent(): Promise<void> {
        if (this.contentLoaded) {
            return Promise.resolve();
        }

        this.contentLoaded = true;

        return this.contentService
            .get(
                this.resource.getCode(),
                this.resource.getThipId(),
                this.teacherMode
            )
            .then((content: string) => this.buildJitComponent(content))
            .catch(reason => {
                this.contentLoaded = false;
                this.error = true;
                throw reason;
            });
    }

    private buildJitComponent(content: string): void {
        const componentFactory = this.componentFactoryFactory.create(
            ScreenComponent.wrapContent(content),
            [ContentDirectiveModule, ContentComponentsModule]
        );

        this.jitComponent =
            this.jitComponentContainer.createComponent(componentFactory);

        this.jitComponent.instance.setResource(this.resource);
    }
}
