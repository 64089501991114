import {
    Component,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import {AbstractBottomSheetComponent} from 'app/cdk/classes/component/bottom-sheet/bottom-sheet.component';
import {StateIconEnum} from 'app/component/state-icon/state-icon.component';
import {ContentService} from 'app/service/content/content.service';
import {
    GoogleAnalyticsActionsEnum,
    GoogleAnalyticsCategoriesEnum,
} from 'app/service/google-analytics/categories.enum';
import {GoogleAnalyticsService} from 'app/service/google-analytics/google-analytics.service';
import {Resource} from 'app/service/resource/classes/resource.class';
import {TocResourceComponent} from 'app/toc/components/toc-resource/toc-resource.component';
import {Subscription} from 'rxjs';

interface CategorizedResourcesInterface {
    [category: string]: Resource[];
}

@Component({
    selector: 'app-toc',
    templateUrl: './toc.component.html',
    styleUrls: ['./toc.component.scss'],
})
export class TocComponent
    extends AbstractBottomSheetComponent
    implements OnInit, OnDestroy
{
    public currentResource!: Resource;

    public state?: StateIconEnum;
    public categorizedResources?: CategorizedResourcesInterface;

    private hasChanged = false;
    private subscriptions: Subscription[] = [];

    @ViewChildren(TocResourceComponent)
    private resourceComponents!: QueryList<TocResourceComponent>;

    constructor(
        private contentService: ContentService,
        private gaService: GoogleAnalyticsService
    ) {
        super();
    }

    public ngOnInit(): void {
        const resource = this.contentService.getCurrentResource();

        if (undefined === resource) {
            throw new Error('Unable to load contents, no current resource');
        }

        this.currentResource = resource;
        this.categorizeResources();

        this.subscriptions.push(
            this.subscribeToDismiss(() => this.handleDismiss())
        );

        this.gaService.event(
            GoogleAnalyticsActionsEnum.TableOfContentsShow,
            GoogleAnalyticsCategoriesEnum.TableOfContents
        );
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    public get isLoading(): boolean {
        return this.resourceComponents
            ? this.resourceComponents.filter(c => c.loading).length > 0
            : false;
    }

    public flagForReload(): void {
        this.hasChanged = true;
    }

    public handleClick(resource: Resource): void {
        this.dismiss();
        this.contentService.navigateToResource(resource);
    }

    private handleDismiss(): void {
        if (this.hasChanged) {
            this.contentService.triggerScreensUpdate();
        }

        this.gaService.event(
            GoogleAnalyticsActionsEnum.TableOfContentsDismiss,
            GoogleAnalyticsCategoriesEnum.TableOfContents
        );
    }

    public originalOrder(): number {
        return 0;
    }

    private categorizeResources(): void {
        const parentResource: Resource = this.currentResource.getParent();
        const categories: string[] = parentResource.getChildCategories();
        const categorizedResources: CategorizedResourcesInterface = {};

        // Map categories to object
        categories.forEach(category => (categorizedResources[category] = []));

        parentResource.getChildren().forEach(resource => {
            categorizedResources[resource.getCategory()].push(resource);
        });

        this.categorizedResources = categorizedResources;
    }
}
