import {Injectable} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {RingIds} from 'app/tool/rings/ring-ids.enum';

@Injectable({
    providedIn: 'root',
})
export class OuterRingService {
    private outerRingChange = new Subject<RingIds>();

    public subscribeToOuterRingChange(fn: (id: RingIds) => void): Subscription {
        return this.outerRingChange.subscribe(fn);
    }

    public activateRing(id: RingIds): void {
        this.outerRingChange.next(id);
    }
}
