import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

export interface ImageComponentInterface {
    selected: boolean;
    selectedChange: EventEmitter<string | undefined>;

    imageClickEmitChange(event: Event): void;
}

@Component({
    selector: 'figure',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements ImageComponentInterface, OnInit {
    @Input()
    public selected = false;
    @Output()
    public readonly selectedChange = new EventEmitter<string | undefined>();

    public isSmall!: boolean;

    constructor(private element: ElementRef) {}

    public ngOnInit(): void {
        this.isSmall =
            this.element.nativeElement.classList.contains('asset--small');
    }

    public imageClickEmitChange(event: Event): void {
        event.preventDefault();

        this.handleSelected();
    }

    private handleSelected(): void {
        this.selected = true;
        this.selectedChange.emit(this.getImageSrc());
    }

    private getImageSrc(): string | undefined {
        const imageElement: HTMLImageElement =
            this.element.nativeElement.querySelector('img');

        if (!imageElement) {
            return undefined;
        }

        return imageElement.src;
    }
}
