import {EventEmitter, Injectable} from '@angular/core';
import {
    GoogleAnalyticsActionsEnum,
    GoogleAnalyticsCategoriesEnum,
} from 'app/service/google-analytics/categories.enum';
import {GoogleAnalyticsService} from 'app/service/google-analytics/google-analytics.service';
import {Resource} from 'app/service/resource/classes/resource.class';
import {Subscription} from 'rxjs';

export enum AnswersStateEnum {
    Undo = 'undo',
    CheckIsOk = 'checkIsOk',
    ShowCorrectAnswer = 'showCorrectAnswer',
}

export interface AnswersUpdateEventInterface {
    state(): AnswersStateEnum;

    resource(): Resource;
}

export class AnswersUpdateEvent implements AnswersUpdateEventInterface {
    public constructor(
        private CurrentState: AnswersStateEnum,
        private CurrentResource: Resource
    ) {}

    public state(): AnswersStateEnum {
        return this.CurrentState;
    }

    public resource(): Resource {
        return this.CurrentResource;
    }
}

@Injectable()
export class AnswersService {
    private update: EventEmitter<AnswersUpdateEventInterface> =
        new EventEmitter<AnswersUpdateEventInterface>();

    public constructor(private gaService: GoogleAnalyticsService) {}

    private static getGaEventAction(
        state: AnswersStateEnum
    ): GoogleAnalyticsActionsEnum {
        switch (state) {
            case AnswersStateEnum.CheckIsOk:
                return GoogleAnalyticsActionsEnum.AnswersCheckIsOk;
            case AnswersStateEnum.Undo:
                return GoogleAnalyticsActionsEnum.AnswersUndo;
            case AnswersStateEnum.ShowCorrectAnswer:
                return GoogleAnalyticsActionsEnum.AnswersShowCorrectAnswer;
        }
    }

    public subscribe(
        fn: (event: AnswersUpdateEventInterface) => void
    ): Subscription {
        return this.update.subscribe(fn);
    }

    public undo(resource: Resource | undefined): void {
        this.emitUpdate(AnswersStateEnum.Undo, resource);
    }

    public checkIsOk(resource: Resource | undefined): void {
        this.emitUpdate(AnswersStateEnum.CheckIsOk, resource);
    }

    public showCorrectAnswer(resource: Resource | undefined): void {
        this.emitUpdate(AnswersStateEnum.ShowCorrectAnswer, resource);
    }

    private emitUpdate(
        state: AnswersStateEnum,
        resource: Resource | undefined
    ): void {
        if (resource === undefined) {
            return;
        }

        this.gaService.event(
            AnswersService.getGaEventAction(state),
            GoogleAnalyticsCategoriesEnum.Exercises
        );

        this.update.emit(new AnswersUpdateEvent(state, resource));
    }
}
