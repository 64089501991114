import {NgModule} from '@angular/core';
import {AnswersService} from 'app/service/answers/answers.service';
import {ApiRequestService} from 'app/service/api-request/api-request.service';
import {HttpClientModule} from '@angular/common/http';
import {DeeplinkService} from 'app/service/deeplink/deeplink.service';
import {GoogleAnalyticsService} from 'app/service/google-analytics/google-analytics.service';
import {ReadSpeakerService} from 'app/service/read-speaker/read-speaker.service';
import {ToolService} from 'app/service/tool/tool.service';
import {UtilModule} from 'app/util/util.module';
import {ResourceService} from 'app/service/resource/resource.service';
import {ContentService} from 'app/service/content/content.service';
import {MethodService} from 'app/service/method/method.service';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {BbvmsService} from 'app/service/bbvms/bbvms.service';
import {UserService} from 'app/service/user/user.service';

@NgModule({
    imports: [HttpClientModule, UtilModule, MatBottomSheetModule],
    declarations: [],
    providers: [
        ApiRequestService,
        ResourceService,
        MethodService,
        ContentService,
        AnswersService,
        BbvmsService,
        UserService,
        ReadSpeakerService,
        ToolService,
        GoogleAnalyticsService,
        DeeplinkService,
    ],
})
export class ServiceModule {}
