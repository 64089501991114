import {
    AfterContentInit,
    Component,
    ContentChildren,
    Input,
    OnDestroy,
    QueryList,
} from '@angular/core';
import {
    AssignmentSelectOptionComponent,
    AssignmentSelectOptionInterface,
} from 'app/content/screen/components/assignment-select-option/assignment-select-option.component';
import {AssignmentSingleSelectComponent} from 'app/content/screen/components/assignment-single-select/assignment-single-select.component';
import {ExerciseComponentInterface} from 'app/content/screen/exercise/exercise-component.interface';

export interface AssignmentMultipleSelectGroupInterface
    extends ExerciseComponentInterface {
    groupId: string;

    hasAnswer(): boolean;
    setup(): void;
}

@Component({
    selector: 'assignment-multiple-choice-options',
    templateUrl: './assignment-multiple-select-group.component.html',
    styleUrls: ['./assignment-multiple-select-group.component.scss'],
})
export class AssignmentMultipleSelectGroupComponent
    extends AssignmentSingleSelectComponent
    implements
        AssignmentMultipleSelectGroupInterface,
        AfterContentInit,
        OnDestroy
{
    @Input()
    public groupId!: string;

    @ContentChildren(AssignmentSelectOptionComponent)
    protected options!: QueryList<AssignmentSelectOptionInterface>;

    protected getOptionGroupId(): string {
        return `${this.groupId}-${this.id}`;
    }

    public check(): boolean {
        let allOk = true;

        this.options.forEach(option => {
            option.checkAnswerState();
            if (option.isWrong() || option.shouldHaveBeenChecked()) {
                allOk = false;
            }
        });

        return allOk;
    }

    public hasAnswer(): boolean {
        return this.options.filter(option => option.isChecked()).length > 0;
    }
}
