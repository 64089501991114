import {EventEmitter, Injectable} from '@angular/core';
import {
    GoogleAnalyticsActionsEnum,
    GoogleAnalyticsCategoriesEnum,
} from 'app/service/google-analytics/categories.enum';
import {GoogleAnalyticsService} from 'app/service/google-analytics/google-analytics.service';
import {ActiveChangeEvent} from 'app/tool-layer/class/active-change-event';
import {ToolInstance} from 'app/tool-layer/class/tool-instance';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {ToolBeltService} from 'app/tool-layer/service/belt/tool-belt.service';
import {Subscription} from 'rxjs';
import {ToolService} from 'app/service/tool/tool.service';
import {IMAGE_TOOL_ID} from 'app/tool-layer/tools/image/image.const';

export interface ToolConfig {
    category?: string;
    hideHandles?: boolean;
    parent?: ToolInstance;
    data?: unknown;
}

export interface OpenToolEvent {
    toolId: string;
    toolName?: string;
    toolConfig?: ToolConfig;
}

@Injectable()
export class ActiveToolService {
    private readonly changeEmitter = new EventEmitter<ActiveChangeEvent>();
    private activeToolsList: Array<ToolInstance> = [];
    private transparencyEnabled = false;

    public constructor(
        private toolBeltService: ToolBeltService,
        private toolService: ToolService,
        private gaService: GoogleAnalyticsService
    ) {}

    public subscribe(
        callback: (event: ActiveChangeEvent) => void
    ): Subscription {
        return this.changeEmitter.subscribe(callback);
    }

    public openTool(event: OpenToolEvent): ToolInstance {
        const tool = this.toolBeltService.findToolById(event.toolId);
        this.gaService.event(
            GoogleAnalyticsActionsEnum.ToolUse,
            GoogleAnalyticsCategoriesEnum.ToolLayer,
            tool.id
        );

        if (
            tool.settingsInstanceOnly &&
            this.findActiveToolsCountByType(tool.id) > 0
        ) {
            return this.returnExistingToolByType(tool.id);
        }

        if (
            tool.limit &&
            tool.limit > 0 &&
            this.findActiveToolsCountByType(tool.id) >= tool.limit
        ) {
            return this.returnExistingToolByType(tool.id);
        }

        const toolName = event.toolName || tool.id;
        const instance = this.prepareTool(tool, toolName, event.toolConfig);
        this.activeToolsList.push(instance);
        this.changeEmitter.emit(ActiveChangeEvent.forAddTool(instance));

        return instance;
    }

    public openByTool(tool: ToolInstance): ToolInstance {
        if (
            tool.type !== 'getallenmuur' &&
            tool.isSettingInstance &&
            !tool.tool.settingsInstanceOnly
        ) {
            tool.promoteToRegularInstance();

            return tool;
        }

        return this.openTool({
            toolId: tool.type,
            toolName: tool.name,
            toolConfig: {
                category:
                    tool.config && tool.config.category
                        ? tool.config.category
                        : undefined,
            },
        });
    }

    public closeByType(type: string): void {
        this.gaService.event(
            GoogleAnalyticsActionsEnum.ToolClose,
            GoogleAnalyticsCategoriesEnum.ToolLayer,
            type
        );

        this.activeToolsList
            .filter((t: ToolInstance) => t.type === type)
            .forEach((t: ToolInstance) => this.deleteTool(t, true));

        this.changeEmitter.emit(ActiveChangeEvent.forDeleteType(type));
    }

    public activateByType(type: string): void {
        const existingTool = this.activeToolsList.find(
            (t: ToolInstance) => t.type === type
        );

        if (existingTool) {
            this.changeEmitter.emit(
                ActiveChangeEvent.forActivateTool(existingTool)
            );
        }
    }

    public deleteTool(tool: ToolInstance, force = false): void {
        this.removeAllChildTools(tool);

        if (this.shouldDemoteToolInstance(tool, force)) {
            tool.demoteToSettingInstance();

            return;
        }

        this.removeToolInstance(tool);
    }

    public closeAllTools(): void {
        this.activeToolsList.forEach(tool => tool.destroy());
        this.activeToolsList = [];
        this.changeEmitter.emit(ActiveChangeEvent.forActivateTool());
        this.changeEmitter.emit(ActiveChangeEvent.forCloseAllTools());
    }

    public toggleTransparency(enabled: boolean): void {
        this.transparencyEnabled = enabled;
        this.changeEmitter.emit(ActiveChangeEvent.forTransparency());

        this.gaService.event(
            GoogleAnalyticsActionsEnum.ToolTransparency,
            GoogleAnalyticsCategoriesEnum.ToolLayer,
            this.transparencyEnabled ? '1' : '0'
        );
    }

    public hasTransparencyEnabled(): boolean {
        return this.transparencyEnabled;
    }

    public async openToolOrImage(toolOrImage: string): Promise<void> {
        const tools = await this.toolService.getByName(toolOrImage);

        if (tools.length === 0) {
            this.openToolByName(toolOrImage);
            return;
        }

        const {name, type, id} = tools[0];

        if (type === IMAGE_TOOL_ID) {
            this.openImageToolById(id);
        } else {
            this.openTool({
                toolId: type,
                toolName: name,
            });
        }
    }

    private openToolByName(name: string): void {
        const existingToolIds = this.toolBeltService.getToolIds();
        const existingTool = existingToolIds.find(id => name.startsWith(id));

        if (existingTool) {
            this.openTool({toolId: existingTool});
        }
    }

    private openImageToolById(id: number): void {
        this.openTool({
            toolId: IMAGE_TOOL_ID,
            toolConfig: {
                data: {
                    id,
                    openImmediately: true,
                },
            },
        });
    }

    private prepareTool(
        tool: AbstractToolWrapper,
        toolName: string,
        toolConfig?: ToolConfig
    ): ToolInstance {
        const toolsCount = this.findActiveToolsCountByType(tool.id);

        return ToolInstance.create(
            tool,
            toolName,
            toolConfig,
            toolsCount === 0
        );
    }

    private returnExistingToolByType(type: string, index = 0): ToolInstance {
        const existingToolOfType = this.findActiveToolsByType(type)[index];
        this.changeEmitter.emit(
            ActiveChangeEvent.forActivateTool(existingToolOfType)
        );
        return existingToolOfType;
    }

    private shouldDemoteToolInstance(
        tool: ToolInstance,
        force: boolean
    ): boolean {
        return (
            !force &&
            this.findActiveToolsCountByType(tool.type) === 1 &&
            tool.shouldBeDemoted()
        );
    }

    private removeAllChildTools(tool: ToolInstance): void {
        this.activeToolsList
            .filter((t: ToolInstance) => t.config && t.config.parent === tool)
            .forEach((t: ToolInstance) => this.removeToolInstance(t));
    }

    private removeToolInstance(t: ToolInstance) {
        this.activeToolsList.splice(this.activeToolsList.indexOf(t), 1);
        this.changeEmitter.emit(ActiveChangeEvent.forDeleteTool(t));
        t.destroy();
    }

    private findActiveToolsByType(type: string): ToolInstance[] {
        return this.activeToolsList.filter(
            foundTool => foundTool.type === type
        );
    }

    private findActiveToolsCountByType(type: string): number {
        return this.findActiveToolsByType(type).length;
    }
}
