import {Component, HostListener, Input} from '@angular/core';
import {StateIconEnum} from 'app/component/state-icon/state-icon.component';

export interface AssignmentHottextOptionInterface {
    isHighlighted: boolean;
    reset(): void;
    highlightIfCorrect(): void;
    checkAnswerState(): boolean;
    isWrong(): boolean;
}

@Component({
    selector: 'assignment-hottext-option',
    templateUrl: './assignment-hottext-option.component.html',
    styleUrls: ['./assignment-hottext-option.component.scss'],
})
export class AssignmentHottextOptionComponent
    implements AssignmentHottextOptionInterface
{
    public readonly answerStateEnum = StateIconEnum;

    @Input('data-correct')
    public isCorrect!: boolean | string;

    public isHighlighted = false;
    public answerState?: StateIconEnum;

    @HostListener('click', ['$event'])
    public handleOnClickAndInvertHighlight(): void {
        this.answerState = undefined;
        this.isHighlighted = !this.isHighlighted;
    }

    public checkAnswerState(): boolean {
        if (!this.isHighlighted) {
            this.answerState = undefined;

            return !this.isCorrectOption();
        }

        if (this.isCorrectOption()) {
            this.answerState = StateIconEnum.Correct;

            return true;
        } else {
            this.answerState = StateIconEnum.InCorrect;

            return false;
        }
    }

    public isWrong(): boolean {
        return (
            undefined !== this.answerState &&
            !(this.isHighlighted && StateIconEnum.Correct === this.answerState)
        );
    }

    public reset(): void {
        this.isHighlighted = false;
        this.answerState = undefined;
    }

    public highlightIfCorrect(): void {
        this.isHighlighted = this.isCorrectOption();
    }

    private isCorrectOption(): boolean {
        return this.isCorrect === true || this.isCorrect === 'true';
    }
}
