import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-range-slider',
    templateUrl: './range-slider.component.html',
    styleUrls: ['./range-slider.component.scss'],
})
export class RangeSliderComponent {
    @Input()
    public disabled = false;
    @Input()
    public label!: string;
    @Input()
    public range = 0;
    @Input()
    public max = 10;
    @Input()
    public min = 0;
    @Output()
    public rangeChange = new EventEmitter<number>();

    public rangeChanged(): void {
        this.rangeChange.emit(this.range);
    }
}
