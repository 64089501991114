import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {ToolRingInterface} from 'app/tool/classes/tool-ring.class';
import {Radius} from 'app/tool/enum/radius.enum';
import {ColorInterpolationFilterEnum, Shadow} from 'app/tool/enum/filter.enum';
import {ToolService} from 'app/tool/service/tool.service';
import {Subscription} from 'rxjs';
import {ColorEnum} from 'app/enum/color.enum';
import {ToolException} from 'app/tool/exception/tool.exception';

interface FilterColorInterpolationInterface {
    id: ColorInterpolationFilterEnum;
    hex: ColorEnum;
}

@Component({
    selector: 'app-tool-inner',
    templateUrl: './inner.component.html',
    styleUrls: ['./inner.component.scss'],
})
export class ToolInnerComponent implements OnInit, OnDestroy {
    public readonly shadow = Shadow;
    public readonly radius = Radius;
    public readonly margin: number = 12;
    public readonly interpolationFilters: FilterColorInterpolationInterface[] =
        [
            {
                id: ColorInterpolationFilterEnum.Black,
                hex: ColorEnum.ToolRingIconBlack,
            },
            {
                id: ColorInterpolationFilterEnum.White,
                hex: ColorEnum.ToolRingIconWhite,
            },
            {
                id: ColorInterpolationFilterEnum.Blue,
                hex: ColorEnum.ToolRingIconBlue,
            },
            {
                id: ColorInterpolationFilterEnum.BlueLight,
                hex: ColorEnum.ToolRingIconBlueLight,
            },
            {
                id: ColorInterpolationFilterEnum.BlueDark,
                hex: ColorEnum.ToolRingIconBlueDark,
            },
            {
                id: ColorInterpolationFilterEnum.Red,
                hex: ColorEnum.ToolRingIconRed,
            },
            {
                id: ColorInterpolationFilterEnum.Green,
                hex: ColorEnum.ToolRingIconGreen,
            },
            {
                id: ColorInterpolationFilterEnum.Yellow,
                hex: ColorEnum.ToolRingIconYellow,
            },
        ];

    public rings!: ToolRingInterface[];
    public init = false;
    public dynamicSize!: number;
    public viewBox!: number[];

    @Output()
    public ringSizeChanged: EventEmitter<number> = new EventEmitter<number>();

    private ringsChangedSubscription: Subscription;

    constructor(private toolService: ToolService) {
        this.ringsChangedSubscription = this.toolService.subscribeToRingsChange(
            () => this.updateActiveRing()
        );
    }

    public ngOnInit(): void {
        this.init = true;
        this.updateActiveRing();
    }

    public ngOnDestroy(): void {
        this.ringsChangedSubscription.unsubscribe();
    }

    private updateActiveRing(): void {
        this.rings = this.toolService.getRings();

        this.updateRingsContainerStyles();
    }

    private updateRingsContainerStyles(): void {
        const radiusValues: number[] = this.rings
            .filter(container => container.visible)
            .map(container => container.radius);

        if (0 === radiusValues.length) {
            throw ToolException.forNoActiveVisibleRingsFound();
        }

        const visibleMaxRadius = Math.max(...radiusValues);
        const sizeValue = visibleMaxRadius * 2 + this.margin;

        this.dynamicSize = sizeValue;
        this.viewBox = [
            -(sizeValue / 2),
            -(sizeValue / 2),
            sizeValue,
            sizeValue,
        ];

        this.ringSizeChanged.emit(sizeValue);
    }
}
