import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent implements OnChanges {
    @Input()
    public total!: number;

    @Input()
    public current = 1;

    public progress!: string;
    private minPercentage = 6; // percentage should never lower than the border radius

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.current || changes.total) {
            this.updatePercentage();
        }
    }

    private updatePercentage(): void {
        const percentage: number = Math.floor(
            (this.current / this.total) * 100
        );

        this.progress = `${
            percentage > this.minPercentage ? percentage : this.minPercentage
        }%`;
    }
}
