import {
    Directive,
    HostListener,
    AfterViewInit,
    ElementRef,
    QueryList,
    ContentChildren,
    EventEmitter,
    Output,
    OnDestroy,
    Renderer2,
} from '@angular/core';
import {Subscription} from 'rxjs';

@Directive({
    selector: '[appRotateHandle]',
})
export class RotateHandleDirective {
    @Output()
    public readonly startDrag: EventEmitter<MouseEvent | TouchEvent> =
        new EventEmitter<MouseEvent | TouchEvent>();

    @HostListener('touchstart', ['$event'])
    @HostListener('mousedown', ['$event'])
    private start($event: MouseEvent | TouchEvent): void {
        this.startDrag.emit($event);
    }
}

@Directive({
    selector: '[appRotate]',
})
export class RotateDirective implements AfterViewInit, OnDestroy {
    public rotating = false;
    private subs: Subscription[] = [];

    @ContentChildren(RotateHandleDirective)
    private handles!: QueryList<RotateHandleDirective>;

    constructor(private hostElement: ElementRef, private renderer: Renderer2) {}

    public ngAfterViewInit(): void {
        // Add subscriptions to all handles inside the host element
        this.handles.forEach((handle: RotateHandleDirective) => {
            this.subs.push(handle.startDrag.subscribe(() => this.start()));
        });
    }

    public ngOnDestroy() {
        // Unsubscribe all subscriptions on destroy
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    private start(): void {
        this.rotating = true;
    }

    private rotateElement(cursorX: number, cursorY: number): void {
        const {x, y, width, height} =
            this.hostElement.nativeElement.getBoundingClientRect();
        const radians = Math.atan2(
            cursorX - (x + width / 2),
            cursorY - (y + height / 2)
        );
        const degree = radians * (180 / Math.PI) * -1 + 270;
        this.setHostElementTransform(degree);
    }

    private setHostElementTransform(angle: number) {
        this.renderer.setStyle(
            this.hostElement.nativeElement,
            'transform',
            `rotate(${angle}deg)`
        );
    }

    @HostListener('document:mouseup')
    @HostListener('document:touchend')
    private stop(): void {
        this.rotating = false;
    }

    @HostListener('document:mousemove', ['$event'])
    private handleMouseMove($event: MouseEvent): void {
        if (this.rotating) {
            $event.preventDefault();
            $event.stopPropagation();
            this.rotateElement($event.clientX, $event.clientY);
        }
    }

    @HostListener('document:touchmove', ['$event'])
    private handleTouchMove($event: TouchEvent): void {
        if (this.rotating) {
            const {clientX, clientY} = $event.touches[0];
            this.rotateElement(clientX, clientY);
        }
    }
}
