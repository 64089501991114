import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-circle-selector',
    templateUrl: './circle-selector.component.html',
    styleUrls: ['./circle-selector.component.scss'],
})
export class CircleSelectorComponent {
    @Input()
    public options: Array<{label: string; value: string | number}> = [];

    @Input()
    public selectedValue?: string | number;

    @Output()
    private readonly selectedValueChange = new EventEmitter<string | number>();

    public setValue(value: string | number): void {
        this.selectedValue = value;
        this.selectedValueChange.emit(value);
    }
}
