import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

/* eslint-disable  @typescript-eslint/no-explicit-any */

@Injectable()
export class OAuthInterceptor implements HttpInterceptor {
    public static readonly KEY_OAUTH_CALLBACK = 'oauthCallbackURL';
    public static readonly KEY_OAUTH_RELOAD_URL_CACHE = 'oauthReloadUrlCache';

    private static setWindowLocationHref(value: string): void {
        if (value !== window.location.href) {
            window.location.href = value;
        }
    }

    constructor(@Inject('LocalStorage') private localStorage: Storage) {
        const oauthCallbackURL: string | null = this.localStorage.getItem(
            OAuthInterceptor.KEY_OAUTH_CALLBACK
        );
        if (null !== oauthCallbackURL) {
            this.localStorage.removeItem(OAuthInterceptor.KEY_OAUTH_CALLBACK);
            OAuthInterceptor.setWindowLocationHref(oauthCallbackURL);
        }
    }

    public intercept(
        httpRequest: HttpRequest<any>,
        httpHandler: HttpHandler
    ): Observable<HttpEvent<any>> {
        const headers = new HttpHeaders({'X-Frontend': 'true'});
        const clonedRequest = httpRequest.clone({
            headers,
        });

        return httpHandler
            .handle(clonedRequest)
            .pipe(
                catchError((error: HttpErrorResponse) =>
                    this.handleServerError(error)
                )
            );
    }

    private handleServerError({
        status,
        error,
    }: HttpErrorResponse): Observable<any> {
        const parsedError =
            typeof error === 'string' ? JSON.parse(error) : error;

        if (
            403 === status &&
            undefined !== parsedError.url &&
            this.maySaveUrlToLocalStorage(window.location.href)
        ) {
            this.localStorage.setItem(
                OAuthInterceptor.KEY_OAUTH_CALLBACK,
                window.location.href
            );
            OAuthInterceptor.setWindowLocationHref(parsedError.url);
        }

        return new Observable();
    }

    private maySaveUrlToLocalStorage(url: string): boolean {
        if (
            null ===
            this.localStorage.getItem(OAuthInterceptor.KEY_OAUTH_CALLBACK)
        ) {
            return true;
        }

        return url.includes('/deeplink/');
    }
}
