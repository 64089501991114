import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';
import {SvgOptionInterface} from 'app/component/svg-input/svg-input.component';
import {SvgElementsEnum} from 'app/enum/svg.enum';

@Component({
    selector: 'app-geld',
    templateUrl: './geld.component.html',
})
export class GeldComponent extends AbstractTool {
    public static readonly id = 'geld';
    public static readonly width = 0;
    public static readonly height = 0;
    public static readonly settingsInstanceOnly = true;

    public svgList!: SvgOptionInterface[];

    constructor() {
        super();

        this.svgList = [
            SvgElementsEnum.MoneyEuro5ct,
            SvgElementsEnum.MoneyEuro10ct,
            SvgElementsEnum.MoneyEuro20ct,
            SvgElementsEnum.MoneyEuro50ct,
            SvgElementsEnum.MoneyEuro1,
            SvgElementsEnum.MoneyEuro2,
            SvgElementsEnum.MoneyEuro5,
            SvgElementsEnum.MoneyEuro10,
            SvgElementsEnum.MoneyEuro20,
            SvgElementsEnum.MoneyEuro50,
            SvgElementsEnum.MoneyEuro100,
            SvgElementsEnum.MoneyEuro200,
            SvgElementsEnum.MoneyEuro500,
        ].map((path: SvgElementsEnum) => {
            return {path};
        });
    }
}
