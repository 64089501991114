import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {UserService} from 'app/service/user/user.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UserGuard implements CanActivate {
    constructor(private userService: UserService) {}

    canActivate(): Observable<boolean> {
        return this.userService.fetchUser().pipe(map(user => !!user));
    }
}
