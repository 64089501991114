import {Type} from '@angular/core';
import {Point, PointInterface} from 'app/classes/point.class';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';

export class AbstractToolWrapper {
    private constructor(
        public readonly id: string,
        public readonly start: PointInterface,
        public readonly component: Type<AbstractTool>,
        public readonly settingsInstanceOnly?: boolean,
        public readonly hideAddButton?: boolean,
        public readonly immediatelyAdd?: boolean,
        public readonly limit?: number
    ) {}

    public static create(component: Type<AbstractTool>): AbstractToolWrapper {
        // Don't mind the tslint ignore thingy, we know (because of the interface) that the 'id'
        // property must be there.

        // tslint:disable:no-string-literal

        const anyComponent = component as never;
        if (
            isNaN(anyComponent['width']) ||
            isNaN(anyComponent['height']) ||
            !anyComponent['id']
        ) {
            throw new Error(
                'Component has not all required properties "width", "height" and "id".'
            );
        }

        const start = new Point(
            Number(anyComponent['width']),
            Number(anyComponent['height'])
        );

        return new AbstractToolWrapper(
            anyComponent['id'],
            start,
            component,
            anyComponent['settingsInstanceOnly'],
            anyComponent['hideAddButton'],
            anyComponent['immediatelyAdd'],
            anyComponent['limit']
        );
    }
}
