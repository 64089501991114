import {Component} from '@angular/core';
import {DrawingStateEnum} from 'app/drawing/enum/state.enum';
import {DrawingService} from 'app/drawing/service/drawing/drawing.service';

@Component({
    selector: 'app-tools',
    templateUrl: './tools.component.html',
    styleUrls: ['./tools.component.scss'],
})
export class ToolsComponent {
    constructor(private drawingService: DrawingService) {}

    public get showPen(): boolean {
        return this.drawingService.state === DrawingStateEnum.Pen;
    }

    public get showHighlighter(): boolean {
        return this.drawingService.state === DrawingStateEnum.Highlighter;
    }

    public get showEraser(): boolean {
        return this.drawingService.state === DrawingStateEnum.Eraser;
    }

    public onClose(): void {
        this.drawingService.state = DrawingStateEnum.Reset;
    }
}
