import {Component, HostBinding, HostListener, Input} from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
    @Input()
    @HostBinding('class')
    public modifiers: string[] = [];

    @Input()
    @HostBinding('class.disabled')
    public disabled = false;

    @Input()
    public src?: string;

    @Input()
    public target = '_blank';

    @HostListener('click')
    private handleClick(): void {
        if (!this.src) {
            return;
        }

        window.open(this.src, this.target);
    }
}
