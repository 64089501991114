import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'svgUrl',
})
export class UrlPipe implements PipeTransform {
    public transform(value: string): string {
        return `url(#${value})`;
    }
}
