import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {AbstractResourceComponentClass} from 'app/content/screen/factory/resource-service';
import {DrawingPdfComponentInterface} from 'app/drawing/interface/drawing-component.interface';
import {DrawingToScreenCommunicatorService} from 'app/drawing/service/screen-communicator/screen-communicator.service';
import {Resource} from 'app/service/resource/classes/resource.class';
import {SideEnum} from 'app/content/screen/components/pdf-asset/side.enum';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let fabric: any | never;

@Component({
    selector: 'pdf-asset',
    templateUrl: 'pdf-asset.component.html',
    styleUrls: ['pdf-asset.component.scss'],
    providers: [
        {
            provide: AbstractResourceComponentClass,
            useExisting: forwardRef(() => PdfAssetComponent),
        },
    ],
})
export class PdfAssetComponent
    extends AbstractResourceComponentClass
    implements AfterViewInit, OnChanges, DrawingPdfComponentInterface
{
    public static readonly EMPTY_IMAGE =
        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

    @ViewChild('canvasElement')
    private canvasElement!: ElementRef<HTMLCanvasElement>;
    @ViewChild('imgElement')
    private imageElement!: ElementRef<HTMLImageElement>;

    @Input('data-img')
    public img!: string;
    @Input('data-answer-img')
    public answerImg?: string;
    @Input('data-scale')
    public scale?: string | number;
    @Input('data-side')
    public side?: string;

    public readonly sideEnum = SideEnum;
    public imageLoaded = false;
    public width?: string | number = undefined;
    public height?: string | number = undefined;

    public answerImageElement!: HTMLImageElement;
    private answerShowing = false;

    private drawnCanvasFabric!: fabric.Canvas;
    private answerCanvas!: HTMLCanvasElement;
    private objectsMap = new Map<fabric.Object, fabric.Image>();

    public constructor(
        private drawingCommunicator: DrawingToScreenCommunicatorService,
        private elementRef: ElementRef,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    private static getCanvas2dContext(
        canvas: HTMLCanvasElement
    ): CanvasRenderingContext2D {
        const context = canvas.getContext('2d');
        if (!context) {
            throw new Error('Could not create canvas');
        }

        return context;
    }

    public async ngAfterViewInit(): Promise<void> {
        await this.renderPdf();
        await this.renderAnswerPdf();
        this.clearAction();
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.img || changes.side) {
            await this.renderPdf();
            await this.renderAnswerPdf();
        }

        if (changes.answerImage) {
            this.setupAnswerCanvas();
            await this.renderAnswerPdf();
        }
    }

    public setResource(resource: Resource): void {
        if (undefined === this.answerImg) {
            resource.pdfTypeHasNoAnswers();
        }
    }

    public drawnAction(object?: fabric.Object): void {
        if (!object) {
            return;
        }

        const clone = fabric.util.object.clone(object);
        this.drawingCommunicator.updateObjectScaleByElement(
            this.elementRef.nativeElement,
            clone,
            Number(this.scale || 1)
        );

        clone.set({
            stroke: '#000000',
        });

        this.drawOntoDrawnCanvas(clone, object);
    }

    public undoAction(object?: fabric.Object): void {
        if (!object) {
            return;
        }

        const element = this.objectsMap.get(object);
        if (!element) {
            return;
        }

        this.drawnCanvasFabric.remove(element);
        this.objectsMap.delete(object);

        this.updateImagePlaceholder();
    }

    public redoAction(object?: fabric.Object): void {
        if (!object) {
            return;
        }
        this.drawnAction(object);
    }

    public clearAction(): void {
        this.objectsMap.clear();

        if (this.drawnCanvasFabric) {
            this.drawnCanvasFabric.clear();
            this.drawnCanvasFabric.dispose();
        }

        this.drawnCanvasFabric = new fabric.Canvas(null, {
            width: Number(this.getWidth()),
            height: Number(this.height),
            renderOnAddRemove: true,
        });

        this.hideAnswerPdfAction();
        this.setupAnswerCanvas();
        this.updateImagePlaceholder();
    }

    public showAnswerPdfAction(): void {
        if (undefined === this.answerImageElement) {
            return;
        }

        this.answerShowing = true;
        this.setImageSrc(this.answerImageElement.src);
    }

    public hideAnswerPdfAction(): void {
        if (!this.answerShowing) {
            return;
        }

        this.answerShowing = false;
        this.updateImagePlaceholder();
    }

    private async renderPdf(): Promise<void> {
        if (!this.img) {
            return;
        }

        const image = await this.loadImage(this.img);
        if (undefined === image) {
            return;
        }

        this.width = image.width;
        this.height = image.height;
        this.imageLoaded = true;
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();

        if (!this.canvasElement) {
            return;
        }
        const canvas = this.canvasElement.nativeElement;
        this.updateCanvas(canvas);

        const ctx = PdfAssetComponent.getCanvas2dContext(canvas);
        ctx.drawImage(image, 0, 0);
    }

    private updateCanvas(canvas: HTMLCanvasElement): void {
        canvas.width = Number(this.getWidth());
        canvas.height = Number(this.height);

        const ctx = PdfAssetComponent.getCanvas2dContext(canvas);
        if (this.side === SideEnum.Right) {
            ctx.translate(-this.getWidth(), 0);
        }
    }

    private async renderAnswerPdf(): Promise<void> {
        if (!this.answerImg) {
            return;
        }

        const image = await this.loadImage(this.answerImg);
        if (undefined === image) {
            return;
        }

        this.answerImageElement = image;
        this.setupAnswerCanvas();
    }

    private getWidth(): number {
        let width = Number(this.width);

        if (this.side === SideEnum.Left || this.side === SideEnum.Right) {
            width = width / 2;
        }

        return width;
    }

    private loadImage(url: string): Promise<HTMLImageElement | undefined> {
        return new Promise(resolve => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = () => resolve(img);
            img.onerror = error => {
                console.error('Failed to resolve image ' + url, error);
                resolve(undefined);
            };
            img.src = url;
        });
    }

    private setImageSrc(src?: string): void {
        if (!this.imageElement) {
            return;
        }

        if (src === undefined) {
            src = PdfAssetComponent.EMPTY_IMAGE;
        }

        const image = this.imageElement.nativeElement;
        image.src = src;
    }

    private setupAnswerCanvas(): void {
        if (!this.answerImageElement) {
            return;
        }

        this.answerCanvas = this.createBlankCanvas();
        this.updateCanvas(this.answerCanvas);

        const context = PdfAssetComponent.getCanvas2dContext(this.answerCanvas);
        context.drawImage(this.answerImageElement, 0, 0);
    }

    private async drawOntoDrawnCanvas(
        canvas: fabric.Object,
        original: fabric.Object
    ): Promise<void> {
        if (!this.answerCanvas) {
            return Promise.reject('No answer canvas found');
        }

        const drawnImage = await this.overlapDrawnAndAnswerImage(canvas);
        if (null === drawnImage) {
            return Promise.reject('Drawn image is null');
        }

        this.objectsMap.set(original, drawnImage);
        this.drawnCanvasFabric.add(drawnImage);

        this.updateImagePlaceholder();
    }

    private async overlapDrawnAndAnswerImage(
        canvas: fabric.Object
    ): Promise<fabric.Image> {
        const compositeCanvas = this.createBlankCanvas();
        const context = PdfAssetComponent.getCanvas2dContext(compositeCanvas);

        context.drawImage(
            canvas.toCanvasElement(),
            canvas.left as number,
            canvas.top as number
        );
        context.globalCompositeOperation = 'source-atop';
        context.drawImage(this.answerCanvas, 0, 0);

        const image = new Image(
            this.answerCanvas.width,
            this.answerCanvas.height
        );

        return new Promise((resolve, reject) => {
            image.onload = () => {
                const fabricImage = new fabric.Image(image, {left: 0, top: 0});

                resolve(fabricImage);
            };
            image.onerror = e => reject(e);
            image.src = compositeCanvas.toDataURL();
        });
    }

    private createBlankCanvas(): HTMLCanvasElement {
        const canvas = document.createElement('canvas');
        canvas.width = Number(this.getWidth());
        canvas.height = Number(this.height);

        return canvas;
    }

    private updateImagePlaceholder(): void {
        this.answerShowing = false;
        this.setImageSrc(this.drawnCanvasFabric.toDataURL());
    }
}
