import {
    Component,
    ComponentFactoryResolver,
    DoCheck,
    forwardRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {AssignmentGapMatchComponent} from 'app/content/screen/components/assignment-gap-match/assignment-gap-match.component';
import {ExerciseComponent} from 'app/content/screen/exercise/exercise.component';

@Component({
    selector: 'assignment-gap-match-text',
    templateUrl: './assignment-gap-match-text.component.html',
    styleUrls: ['./assignment-gap-match-text.component.scss'],
    providers: [
        {
            provide: ExerciseComponent,
            useExisting: forwardRef(() => AssignmentGapMatchTextComponent),
        },
    ],
})
export class AssignmentGapMatchTextComponent
    extends AssignmentGapMatchComponent
    implements DoCheck
{
    @ViewChild('optionsTarget', {read: ViewContainerRef})
    protected optionsViewContainerRef!: ViewContainerRef;
    protected ready = false;

    constructor(componentFactoryResolver: ComponentFactoryResolver) {
        super(componentFactoryResolver);
    }

    protected getOptionsViewContainerRef(): ViewContainerRef {
        return this.optionsViewContainerRef;
    }

    public ngDoCheck(): void {
        if (!this.hasViewInitialized || this.ready) {
            return;
        }

        this.ready = true;
        this.applyMaxOptionWidth();
    }

    protected applyMaxOptionWidth(): void {
        const maxWidth: number = this.getMaxOptionWidth();

        [...this.dynamicOptionComponents, ...this.optionComponents].forEach(
            component => {
                component.setWidth(maxWidth);
            }
        );
    }

    protected getMaxOptionWidth(): number {
        return Math.max(
            ...this.dynamicOptionComponents.map(dynamicComponent =>
                dynamicComponent.getWidth()
            )
        );
    }
}
