import {KeyCodeEnum} from 'app/enum/key-code.enum';
import {EventEmitter, Output} from '@angular/core';

export abstract class AbstractKeyboard {
    public keysEnum = KeyCodeEnum;
    public shift = false;
    public numeric = false;

    @Output()
    public closed = new EventEmitter<void>();

    @Output()
    public deleted = new EventEmitter<void>();

    @Output()
    public keyChoice = new EventEmitter<string>();

    public recordKey(event: MouseEvent | TouchEvent): void {
        const element = event.currentTarget as HTMLInputElement;
        const key = element.id ? (element.id as KeyCodeEnum) : undefined;

        if (!key) {
            return;
        }

        this.handleKeyChoice(key);
    }

    public toggleShift(): void {
        this.shift = !this.shift;
    }

    public toggleNumeric(): void {
        this.numeric = !this.numeric;
    }

    public close(): void {
        this.closed.emit();
    }

    private handleKeyChoice(key: KeyCodeEnum): void {
        const modifiedKey =
            /^[a-z]$/.test(key) && this.shift ? key.toUpperCase() : key;
        this.keyChoice.emit(modifiedKey);
    }
}
