export const environment = {
    production: true,
    apiUrl: 'https://test.digibord.thiememeulenhoff.nl',
    sentryDsn:
        'https://89f74c0352c44a7d995f04fd8faba288@o37556.ingest.sentry.io/5728370',
    environment: 'test',
    googleAnalyticsCode: 'G-GPKDYW6S0T',
    thasToken:
        'b9e4e0e92a139b5506619139e0181caf1c0d90a0baf6b2cfad5ede2d8d840dd9',
};
