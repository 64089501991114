import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {RulerComponent} from './ruler.component';
import {DirectiveModule} from 'app/directive/directive.module';

@NgModule({
    imports: [CommonModule, DirectiveModule],
    declarations: [RulerComponent],
    entryComponents: [RulerComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(RulerComponent),
            multi: true,
        },
    ],
})
export class RulerModule {}
