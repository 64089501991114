import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Component, ComponentRef, OnDestroy} from '@angular/core';
import {
    ApiToolInterface,
    ApiToolScreenshotInterface,
} from 'app/interface/api-tool.interface';
import {ToolService} from 'app/service/tool/tool.service';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';
import {ActiveToolService} from 'app/tool-layer/service/active/active-tool.service';
import {ImageBarModalService} from 'app/tool-layer/tools/image/bar/bar.service';
import {ImageOverlayComponent} from 'app/tool-layer/tools/image/overlay/overlay.component';
import {IMAGE_TOOL_ID} from 'app/tool-layer/tools/image/image.const';

interface Config {
    id?: number;
    openImmediately?: boolean;
}

@Component({
    selector: 'app-tool-image-component',
    templateUrl: 'image.component.html',
    styleUrls: ['image.component.scss'],
})
export class ImageComponent extends AbstractTool implements OnDestroy {
    public static readonly id = IMAGE_TOOL_ID;
    public static readonly width = 0;
    public static readonly height = 0;
    public static readonly immediatelyAdd = true;
    public static readonly hideAddButton = true;

    private id!: number;
    private openImmediately!: boolean;
    private overlayRef?: OverlayRef;
    private overlayComponent?: ImageOverlayComponent;
    private overlayComponentRef?: ComponentRef<ImageOverlayComponent>;

    public constructor(
        private toolService: ToolService,
        private barModalService: ImageBarModalService,
        private activeToolService: ActiveToolService,
        private overlay: Overlay
    ) {
        super();
    }

    public ngOnDestroy(): void {
        this.onHideTool();
    }

    public set injectedData(data: Config) {
        if (!data.id) {
            return;
        }

        this.id = data.id;
        this.openImmediately = data.openImmediately === true;

        this.loadTool();
    }

    public onHideTool(): void {
        if (this.overlayRef) {
            this.overlayRef.detach();
            this.overlayRef.dispose();
            this.overlayRef = undefined;
        }

        if (this.overlayComponentRef) {
            this.overlayComponentRef.destroy();
            this.overlayComponentRef = undefined;
        }
    }

    private closeTool(): void {
        this.onHideTool();

        const instance = this.instance;
        if (instance) {
            this.activeToolService.closeByType(instance.type);
        }
    }

    private loadTool(): void {
        this.toolService
            .getById(this.id)
            .then(tool => this.handleToolLoaded(tool));
    }

    private handleToolLoaded(tool: ApiToolInterface): void {
        if (this.openImmediately) {
            this.showOverlay(tool);
        } else {
            this.showToolBar(tool);
        }
    }

    private showToolBar(tool: ApiToolInterface): void {
        this.barModalService.openForTool(tool).then(
            value => {
                if (typeof value === 'number') {
                    this.loadToolScreenshotCompletely(value, tool);
                } else if (typeof value === 'object') {
                    const subTool: ApiToolInterface = JSON.parse(
                        JSON.stringify(value)
                    );
                    subTool.id = this.id;

                    this.showOverlay(subTool);
                } else {
                    this.showOverlay(tool);
                }
            },
            () => this.closeTool()
        );
    }

    private loadToolScreenshotCompletely(
        id: number,
        tool: ApiToolInterface
    ): void {
        this.toolService.getPaperToolById(id).then(screenshot => {
            this.showOverlay(tool, screenshot);
        });
    }

    private showOverlay(
        tool: ApiToolInterface,
        screenshot?: ApiToolScreenshotInterface
    ): void {
        this.overlayRef = this.overlay.create({
            height: '100vh',
            width: '100vw',
            panelClass: 'panel-image',
        });

        const portal = new ComponentPortal(ImageOverlayComponent);
        this.overlayComponentRef = this.overlayRef.attach(portal);

        this.overlayComponent = this.overlayComponentRef.instance;
        this.overlayComponent.closeEmitter.subscribe(() => this.closeTool());
        this.overlayComponent.setTool(tool, screenshot);
    }
}
