import {
    AfterViewInit,
    Component,
    ContentChild,
    EventEmitter,
    HostBinding,
    HostListener,
    OnDestroy,
    Output,
} from '@angular/core';
import {BbvmsAudioComponent} from 'app/content/screen/components/bbvms/bbvms-audio/bbvms-audio.component';
import {BbvmsEnum} from 'app/content/screen/components/bbvms/bbvms.enum';
import {Subscription} from 'rxjs';

@Component({
    selector: 'dialog-entry',
    templateUrl: './dialog-entry.component.html',
    styleUrls: ['./dialog-entry.component.scss'],
})
export class DialogEntryComponent implements AfterViewInit, OnDestroy {
    @ContentChild(BbvmsAudioComponent)
    private bbvmsAudioComponent!: BbvmsAudioComponent;

    @HostBinding('class.highlight')
    private isHighlighted = false;

    public get highlighted(): boolean {
        return this.isHighlighted;
    }

    @HostBinding('class.disabled')
    private isDisabled = false;

    public set setDisabled(disabled: boolean) {
        this.isDisabled = disabled;
    }

    private bbvmsAudioSubscription!: Subscription;

    @Output()
    public clicked: EventEmitter<MouseEvent | TouchEvent> = new EventEmitter();

    @HostListener('click', ['$event'])
    public onClick($event: MouseEvent | TouchEvent): void {
        this.clicked.emit($event);
    }

    public ngAfterViewInit(): void {
        this.bbvmsAudioSubscription =
            this.bbvmsAudioComponent.subscribeToEvents((event: BbvmsEnum) =>
                this.handleBbvmsEvent(event)
            );
    }

    public subscribeToEvents(fn: (event: BbvmsEnum) => void): Subscription {
        return this.bbvmsAudioComponent.subscribeToEvents(fn);
    }

    public ngOnDestroy(): void {
        this.bbvmsAudioSubscription.unsubscribe();
    }

    public play(): Promise<void> {
        return this.bbvmsAudioComponent.play();
    }

    public stop(): void {
        this.bbvmsAudioComponent.stop();
    }

    private handleBbvmsEvent(event: BbvmsEnum): void {
        if (event === BbvmsEnum.Playing) {
            this.isHighlighted = true;
        }

        if (event === BbvmsEnum.Idle) {
            this.isHighlighted = false;
        }
    }
}
