import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentModule} from 'app/component/component.module';
import {PipeModule} from 'app/pipe/pipe.module';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {TimerComponent} from 'app/tool-layer/tools/timer/timer.component';
import {ContentComponentsModule} from 'app/content/screen/components/components.module';
import {SelectAudioOptionModule} from 'app/tool-layer/tools/select-audio-option/select-audio-option.module';

@NgModule({
    imports: [
        ComponentModule,
        CommonModule,
        SelectAudioOptionModule,
        ContentComponentsModule,
        PipeModule,
    ],
    declarations: [TimerComponent],
    entryComponents: [TimerComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(TimerComponent),
            multi: true,
        },
    ],
})
export class TimerModule {}
