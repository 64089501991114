export enum GoogleAnalyticsCategoriesEnum {
    ReadSpeaker = 'readspeaker',
    TableOfContents = 'toc',
    Toolbox = 'toolbox',
    ToolLayer = 'tools',
    Exercises = 'exercises',
    Draw = 'draw',
    Answers = 'answers',
    Zoom = 'navigation',
    Content = 'navigation',
    Media = 'media',
}

export enum GoogleAnalyticsActionsEnum {
    Empty = '',

    AnswersUndo = 'exercise-reset',
    AnswersCheckIsOk = 'exercise-check',
    AnswersShowCorrectAnswer = 'exercise-solve',

    DrawPdfMagicWand = 'gui-draw-answers',
    DrawPdfShowAnswers = 'gui-draw-all-answers',

    DrawActionStart = 'gui-draw-start',
    DrawActionUndo = 'gui-draw-undo',
    DrawActionRedo = 'gui-draw-redo',
    DrawActionErase = 'gui-draw-erase',

    DrawStatePen = 'selectpen',
    DrawStatePenMarker = 'selectmarker',

    ZoomReset = 'gui-zoom-reset',
    ZoomFit = 'gui-zoom-fit',
    ZoomIn = 'gui-zoom-in',
    ZoomOut = 'gui-zoom-out',
    ZoomPoint = 'gui-zoom-point',

    NavigationNext = 'gui-next',
    NavigationPrevious = 'gui-previous',
    NavigationSlideType = 'gui-slide-type',

    ToolUse = 'usetool',
    ToolClose = 'closetool',
    ToolTransparency = 'tool-transparency',

    ReadSpeakerStart = 'readspeaker-start',
    ReadSpeakerStop = 'readspeaker-stop',

    TableOfContentsShow = 'toc-show',
    TableOfContentsDismiss = 'toc-dismiss',

    ToolBoxShow = 'toolbox-show',
    ToolBoxCategory = 'toolbox-category',

    MediaAudioPlay = 'audio-play',
    MediaVideoPlay = 'video-play',
}

export enum GoogleAnalyticsLabelEnum {
    Empty = '',

    DrawStatePen = 'gui-draw-pen',
    DrawStateMarker = 'gui-draw-pen-highlighter',
}
