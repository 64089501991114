import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {StringUtil} from 'app/util/string/string.util';
import {environment} from 'app/environment';
import {Observable} from 'rxjs';

// For some reason there's no typing for HttpClient options...
export interface HttpClientOptionsInterface {
    headers?: HttpHeaders | {[header: string]: string | string[]};
    observe?: string;
    params?: HttpParams | {[param: string]: string | string[]};
    reportProgress?: boolean;
    responseType?: string;
    withCredentials?: boolean;
}

export interface ParamsInterface {
    urlParams?: Map<string, string>;
    queryParams?: HttpParams;
    options?: HttpClientOptionsInterface;
}

@Injectable()
export class ApiRequestService {
    constructor(private httpClient: HttpClient) {}

    private static getOptions(params?: ParamsInterface): object {
        let defaultOptions: HttpClientOptionsInterface = {};

        if (undefined !== params && undefined !== params.options) {
            defaultOptions = Object.assign(defaultOptions, params.options);
        }

        if (undefined !== params && undefined !== params.queryParams) {
            defaultOptions.params = params.queryParams;
        }

        return defaultOptions;
    }

    private static getUrl(url: string, params?: ParamsInterface): string {
        if (undefined === params || undefined === params.urlParams) {
            return environment.apiUrl + url;
        }

        return (
            environment.apiUrl +
            StringUtil.getMappedString(url, params.urlParams)
        ).replace('undefined', 'null');
    }

    public get<RESPONSE>(
        url: string,
        params?: ParamsInterface
    ): Observable<RESPONSE> {
        return this.httpClient.get<RESPONSE>(
            ApiRequestService.getUrl(url, params),
            ApiRequestService.getOptions(params)
        );
    }

    public post<RESPONSE, BODY>(
        url: string,
        body: BODY,
        params?: ParamsInterface
    ): Observable<RESPONSE> {
        return this.httpClient.post<RESPONSE>(
            ApiRequestService.getUrl(url, params),
            body,
            ApiRequestService.getOptions(params)
        );
    }
}
