import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {ComponentModule} from 'app/component/component.module';
import {DiceComponent} from 'app/tool-layer/tools/dice/dice.component';

@NgModule({
    imports: [CommonModule, ComponentModule],
    declarations: [DiceComponent],
    entryComponents: [DiceComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(DiceComponent),
            multi: true,
        },
    ],
})
export class DiceModule {}
