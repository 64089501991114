import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ContentChild,
    forwardRef,
    Renderer2,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {AssignmentGapMatchComponent} from 'app/content/screen/components/assignment-gap-match/assignment-gap-match.component';
import {ExerciseComponent} from 'app/content/screen/exercise/exercise.component';
import {ImageDirective} from 'app/content/screen/directive/image/image.directive';
import {FitToParentDirective} from 'app/directive/fit-parent/fit-to-parent.directive';
import {AssignmentGapMatchOptionComponent} from 'app/content/screen/components/assignment-gap-match/assignment-gap-match-option/assignment-gap-match-option.component';

@Component({
    selector: 'assignment-gap-match-image',
    templateUrl: './assignment-gap-match-image.component.html',
    styleUrls: ['./assignment-gap-match-image.component.scss'],
    providers: [
        {
            provide: ExerciseComponent,
            useExisting: forwardRef(() => AssignmentGapMatchImageComponent),
        },
    ],
})
export class AssignmentGapMatchImageComponent
    extends AssignmentGapMatchComponent
    implements AfterViewInit
{
    @ContentChild(ImageDirective)
    private image!: ImageDirective;

    @ViewChild('optionsTarget', {read: ViewContainerRef})
    private optionsViewContainerRef!: ViewContainerRef;

    @ViewChild(FitToParentDirective)
    private fitParentDirective!: FitToParentDirective;

    public contentWrapperWidth?: number;
    public contentWrapperHeight?: number;

    constructor(
        private renderer: Renderer2,
        private changeDetectorRef: ChangeDetectorRef,
        componentFactoryResolver: ComponentFactoryResolver
    ) {
        super(componentFactoryResolver);
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();

        if (this.image.hasLoaded()) {
            this.afterImageLoad();

            return;
        }

        this.image.subscribe(() => this.afterImageLoad());
    }

    protected getOptionsViewContainerRef(): ViewContainerRef {
        return this.optionsViewContainerRef;
    }

    private afterImageLoad(): void {
        const widthRatio: number = this.image.getWidth() / 100;
        const heightRatio: number = this.image.getHeight() / 100;

        // First put elements where they belong
        this.optionComponents.forEach(component =>
            this.setOptionComponentPosition(component, widthRatio, heightRatio)
        );

        // After positioning the option elements, scale the content to fit the container
        this.scaleWrapper();
    }

    /**
     * Parent should determine where to display/position the children. In this case the child
     * has the position values (from content), so we'll need those to position it.
     */
    private setOptionComponentPosition(
        component: AssignmentGapMatchOptionComponent,
        widthRatio: number,
        heightRatio: number
    ): void {
        const componentElement: HTMLElement =
            component.elementRef.nativeElement;
        const optionPosition: string | null =
            componentElement.getAttribute('data-position');

        if (null === optionPosition) {
            throw new Error(
                'Option component is missing required "data-position" attribute'
            );
        }

        const [left, top, right, bottom] = optionPosition
            .split(',')
            .map(position => Number(position));

        const positionTop: number = Math.floor(top * heightRatio);
        const positionRight: number = Math.floor((left + right) * widthRatio);
        const positionBottom: number = Math.floor((top + bottom) * heightRatio);
        const positionLeft: number = Math.floor(left * widthRatio);

        component.setPositionLeft(positionLeft);
        component.setPositionTop(positionTop);
        component.setWidth(positionRight - positionLeft);
        component.setHeight(positionBottom - positionTop);
    }

    private scaleWrapper(): void {
        // For some reason, the div wrapping the image does not match the image dimensions
        this.contentWrapperWidth = this.image.getWidth();
        this.contentWrapperHeight = this.image.getHeight();

        // Styling has a minor delay, so trigger changes before scaling
        this.changeDetectorRef.detectChanges();

        // Scale wrapper to fit parent
        this.fitParentDirective.scale();
    }
}
