import {Injectable} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {ActivatedRoute, Params} from '@angular/router';
import {ZoomableViewService} from 'app/zoomable-view/service/zoomable-view/zoomable-view.service';
import {ZoomEnum} from 'app/enum/zoom.enum';

@Injectable({
    providedIn: 'root',
})
export class SecondScreenService {
    private isEnabled?: boolean;
    private enabledSubject = new Subject<boolean>();
    private routeSubscription!: Subscription;

    constructor(
        private route: ActivatedRoute,
        private zoomableViewService: ZoomableViewService
    ) {
        this.routeSubscription = route.queryParams.subscribe(params =>
            this.handleQueryParamsChange(params)
        );
    }

    public getIsEnabled(): boolean {
        return this.isEnabled || false;
    }

    public subscribeToEnabled(fn: (value: boolean) => void): Subscription {
        return this.enabledSubject.subscribe(fn);
    }

    public toggleEnabled(enabled?: boolean) {
        const isEnabled = enabled !== undefined ? enabled : !this.isEnabled;
        this.isEnabled = isEnabled;
        this.enabledSubject.next(isEnabled);
        this.triggerRescale();
    }

    private handleQueryParamsChange(params: Params): void {
        if (params && params.instruction && params.instruction === '1') {
            this.toggleEnabled(true);
        }
    }

    private triggerRescale(): void {
        this.zoomableViewService.zoomInOutReset(ZoomEnum.Reset);
    }
}
