import {
    AfterViewInit,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    ContentChildren,
    OnDestroy,
    QueryList,
    ViewContainerRef,
} from '@angular/core';
import {AssignmentGapMatchOptionComponent} from 'app/content/screen/components/assignment-gap-match/assignment-gap-match-option/assignment-gap-match-option.component';
import {DropListRef, transferArrayItem} from '@angular/cdk/drag-drop';
import {ExerciseComponent} from 'app/content/screen/exercise/exercise.component';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';

export abstract class AssignmentGapMatchComponent
    extends ExerciseComponent
    implements AfterViewInit, OnDestroy
{
    private readonly componentFactory: ComponentFactory<AssignmentGapMatchOptionComponent>;

    @ContentChildren(AssignmentGapMatchOptionComponent, {descendants: true})
    protected optionComponents!: QueryList<AssignmentGapMatchOptionComponent>;

    protected dynamicOptionComponents: AssignmentGapMatchOptionComponent[] = [];
    protected hasViewInitialized = false;

    private activeOption?: AssignmentGapMatchOptionComponent = undefined;
    private subscriptions: Array<Subscription> = [];

    protected abstract getOptionsViewContainerRef(): ViewContainerRef;

    protected constructor(componentFactoryResolver: ComponentFactoryResolver) {
        super();
        this.componentFactory =
            componentFactoryResolver.resolveComponentFactory(
                AssignmentGapMatchOptionComponent
            );
    }

    public ngAfterViewInit(): void {
        const shuffledOptions: AssignmentGapMatchOptionComponent[] = _.shuffle(
            this.optionComponents.toArray()
        );

        shuffledOptions.forEach(option =>
            this.dynamicOptionComponents.push(
                this.createComponentFromOption(option)
            )
        );

        this.registerOnClickHandlerForOptions();
        this.initDragDrop();
        this.hasViewInitialized = true;
    }

    public ngOnDestroy(): void {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    public check(): boolean {
        let allValid = true;

        this.optionComponents.forEach(component => {
            if (!component.check()) {
                allValid = false;
            }
        });

        return allValid;
    }

    public solve(): void {
        this.reset();

        [...this.dynamicOptionComponents, ...this.optionComponents].forEach(
            component => {
                this.transferItems(component, this.optionComponents.toArray());
                component.detectChanges();
            }
        );

        this.check();
    }

    public reset(): void {
        [...this.dynamicOptionComponents, ...this.optionComponents].forEach(
            component => {
                this.transferItems(component, this.dynamicOptionComponents);
                component.reset();
            }
        );
    }

    public hasAnswer(): boolean {
        return this.dynamicOptionComponents.some(
            option => option.items.length === 0
        );
    }

    public hasWrong(): boolean {
        return this.optionComponents.some(
            component => component.isChecked() && !component.isValid()
        );
    }

    public retry(): void {
        this.optionComponents
            .filter(component => component.isChecked() && !component.isValid())
            .forEach(component => {
                this.transferItems(component, this.dynamicOptionComponents);
                component.reset();
            });
    }

    protected transferItems(
        sourceComponent: AssignmentGapMatchOptionComponent,
        targetComponents: AssignmentGapMatchOptionComponent[]
    ): void {
        sourceComponent.items.forEach(item => {
            const targetComponent = this.findComponentInCollectionById(
                targetComponents,
                item.id
            );

            // No need to transfer when item is already where it belongs
            if (targetComponent.items.includes(item)) {
                return;
            }

            transferArrayItem(
                sourceComponent.items,
                targetComponent.items,
                0,
                0
            );
        });
    }

    /**
     * Creating components takes place at AfterViewInit, we need a ViewContainerRef to
     * create the dynamic components.
     */
    protected createComponentFromOption(
        optionComponent: AssignmentGapMatchOptionComponent
    ): AssignmentGapMatchOptionComponent {
        const componentRef: ComponentRef<AssignmentGapMatchOptionComponent> =
            this.createComponentRef();
        const componentInstance = componentRef.instance;

        componentInstance.setId(optionComponent.getId());
        componentInstance.setClasses(optionComponent.getClasses());
        componentInstance.items = [
            {
                id: optionComponent.getId(),
                value: optionComponent.getValue(),
            },
        ];

        // Trigger change detection, else we'll get an error
        componentRef.changeDetectorRef.detectChanges();

        return componentInstance;
    }

    protected findComponentInCollectionById(
        components: AssignmentGapMatchOptionComponent[],
        componentId: string
    ): AssignmentGapMatchOptionComponent {
        const component = components.find(
            dynamicComponent => componentId === dynamicComponent.getId()
        );

        if (undefined === component) {
            throw new Error(`Component with ID#${componentId} was not found`);
        }

        return component;
    }

    /**
     * Component refs are available at AfterViewInit
     */
    private createComponentRef(): ComponentRef<AssignmentGapMatchOptionComponent> {
        return this.getOptionsViewContainerRef().createComponent<AssignmentGapMatchOptionComponent>(
            this.componentFactory
        );
    }

    /**
     * Using cdkDropListGroup directive doesn't work because of the ng-content items.
     * We'll have to manually connect the lists to each other.
     */
    private initDragDrop(): void {
        const optionDropLists: DropListRef[] = this.dynamicOptionComponents.map(
            optionComponent => optionComponent.getDropListRef()
        );
        const dynamicDropLists: DropListRef[] = this.optionComponents.map(
            optionComponent => optionComponent.getDropListRef()
        );
        const dropLists: DropListRef[] = [
            ...dynamicDropLists,
            ...optionDropLists,
        ];

        dropLists.forEach((dropList: DropListRef) => {
            dropList.connectedTo(
                dropLists.filter(
                    connectToDropList => connectToDropList !== dropList
                )
            );
        });
    }

    private registerOnClickHandlerForOptions(): void {
        const options = [
            ...this.optionComponents,
            ...this.dynamicOptionComponents,
        ];

        options.map(option =>
            this.subscriptions.push(
                option.clickChange.subscribe(() =>
                    this.handleOptionClick(option)
                )
            )
        );
    }

    private handleOptionClick(option: AssignmentGapMatchOptionComponent): void {
        if (this.activeOption === option) {
            this.unsetActiveOption();

            return;
        }

        if (
            this.activeOption &&
            (option.items.length > 0 || this.activeOption.items.length > 0)
        ) {
            if (option.items.length > 0 && this.activeOption.items.length > 0) {
                this.moveItemBetweenOptions(option, this.activeOption, 0, 1);
                this.moveItemBetweenOptions(this.activeOption, option, 0, 0);
            } else if (this.activeOption.items.length > 0) {
                this.moveItemBetweenOptions(this.activeOption, option);
            } else {
                this.moveItemBetweenOptions(option, this.activeOption);
            }

            this.unsetActiveOption();

            return;
        }

        this.unsetActiveOption();

        this.activeOption = option;
        this.activeOption.setSelected(true);
    }

    private unsetActiveOption(): void {
        if (this.activeOption) {
            this.activeOption.setSelected(false);
            this.activeOption = undefined;
        }
    }

    private moveItemBetweenOptions(
        option1: AssignmentGapMatchOptionComponent,
        option2: AssignmentGapMatchOptionComponent,
        currentIndex = 0,
        targetIndex = 0
    ): void {
        transferArrayItem(
            option1.getDropListRef().data,
            option2.getDropListRef().data,
            currentIndex,
            targetIndex
        );

        option1.detectChanges();
        option2.detectChanges();
    }
}
