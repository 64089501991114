import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ContentService} from 'app/service/content/content.service';
import {Resource} from 'app/service/resource/classes/resource.class';

@Component({
    selector: 'app-second-screen',
    templateUrl: './second-screen.component.html',
    styleUrls: ['./second-screen.component.scss'],
})
export class SecondScreenComponent implements OnDestroy, OnInit {
    public activeResource?: Resource;
    private readonly contentSubscription?: Subscription;

    constructor(
        private contentService: ContentService,
        private cd: ChangeDetectorRef
    ) {
        this.contentSubscription = contentService.subscribeToCurrentResource(
            resource => this.handleScreenChange(resource as Resource)
        );
    }

    public ngOnInit(): void {
        const currentResource = this.contentService.getCurrentResource();

        if (currentResource) {
            this.handleScreenChange(currentResource);
        }
    }

    public ngOnDestroy(): void {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
    }

    private handleScreenChange(resource: Resource): void {
        this.activeResource = undefined;
        this.cd.detectChanges();
        this.activeResource = resource;
    }
}
