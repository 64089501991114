import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CdkModule} from 'app/cdk/cdk.module';
import {ComponentModule} from 'app/component/component.module';
import {DrawingModule} from 'app/drawing/drawing.module';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {ImageBarComponent} from 'app/tool-layer/tools/image/bar/bar.component';
import {ImageBarModalService} from 'app/tool-layer/tools/image/bar/bar.service';
import {ImageComponent} from 'app/tool-layer/tools/image/image.component';
import {ImageOverlayComponent} from 'app/tool-layer/tools/image/overlay/overlay.component';
import {SwiperModule} from 'ngx-swiper-wrapper';

@NgModule({
    imports: [
        CommonModule,
        DrawingModule,
        ComponentModule,
        CdkModule,
        SwiperModule,
    ],
    declarations: [ImageComponent, ImageOverlayComponent, ImageBarComponent],
    entryComponents: [ImageComponent, ImageOverlayComponent],
    providers: [
        ImageBarModalService,
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(ImageComponent),
            multi: true,
        },
    ],
})
export class ImageModule {}
