import {Directive, ElementRef} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

@Directive({
    selector: 'img',
})
export class ImageDirective {
    public readonly image: HTMLImageElement;

    private loaded = false;

    private loadedSubject: Subject<ImageDirective> = new Subject();

    constructor(elementRef: ElementRef) {
        this.image = elementRef.nativeElement;
        this.image.onload = () => {
            this.loaded = true;
            this.loadedSubject.next(this);
        };
    }

    /**
     * We're using pipe().take(1) because image load will only trigger once,
     * this will auto remove subscriptions when it's triggered.
     */
    public subscribe(
        fn: (imageDirective: ImageDirective) => void
    ): Subscription {
        return this.loadedSubject.pipe(take(1)).subscribe(fn);
    }

    public getWidth(): number {
        return this.image.offsetWidth;
    }

    public getHeight(): number {
        return this.image.offsetHeight;
    }

    public hasLoaded(): boolean {
        return this.loaded;
    }
}
