/* eslint-disable @typescript-eslint/no-explicit-any */

import {
    Directive,
    DoCheck,
    ElementRef,
    EmbeddedViewRef,
    Input,
    IterableChanges,
    IterableDiffer,
    IterableDiffers,
    TemplateRef,
    ViewContainerRef,
    ViewRef,
} from '@angular/core';
import {DragDropService} from 'app/cdk/service/drag-drop/drag-drop.service';
import {DragRef} from '@angular/cdk/drag-drop';
import {Subject, Subscription} from 'rxjs';

interface DraggableDataInterface {
    viewRef: ViewRef;
    dragRef: DragRef;
}

@Directive({selector: '[appDraggables]'})
export class DraggablesDirective<T> implements DoCheck {
    @Input()
    private appDraggablesOf!: any[];

    private iterableDiffer: IterableDiffer<T[]>;

    private draggableData: Map<T, DraggableDataInterface> = new Map();

    private changesSubject: Subject<DragRef[]> = new Subject();

    constructor(
        private dragDropService: DragDropService,
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private iterableDiffers: IterableDiffers
    ) {
        this.iterableDiffer = this.iterableDiffers.find([]).create();
    }

    public subscribeToChanges(fn: (dragRefs: DragRef[]) => void): Subscription {
        return this.changesSubject.subscribe(fn);
    }

    public ngDoCheck(): void {
        const changes: IterableChanges<T[]> | null = this.iterableDiffer.diff(
            this.appDraggablesOf
        );

        if (null === changes) {
            return;
        }

        changes.forEachRemovedItem(iterableRecord =>
            this.removeEmbeddedView(iterableRecord.item)
        );
        changes.forEachAddedItem(iterableRecord =>
            this.addEmbeddedView(iterableRecord.item)
        );

        this.changesSubject.next(this.getDragRefs());
    }

    public getDragRefs(): DragRef[] {
        const dragRefs: DragRef[] = [];

        this.draggableData.forEach(data => {
            dragRefs.push(data.dragRef);
        });

        return dragRefs;
    }

    private removeEmbeddedView(item: any): void {
        const draggableData: DraggableDataInterface | undefined =
            this.draggableData.get(item);

        if (undefined === draggableData) {
            return;
        }

        const viewIndex = this.viewContainerRef.indexOf(draggableData.viewRef);

        this.viewContainerRef.remove(viewIndex);
        this.draggableData.delete(item);
    }

    private addEmbeddedView(item: any): void {
        const viewRef: EmbeddedViewRef<any> = this.createEmbeddedView(item);
        const rootElement: HTMLElement = viewRef.rootNodes[0];
        const dragRef: DragRef = this.dragDropService.createDragRef(
            new ElementRef(rootElement)
        );

        this.draggableData.set(item, {viewRef, dragRef});
    }

    private createEmbeddedView(item: any): EmbeddedViewRef<any> {
        return this.viewContainerRef.createEmbeddedView(this.templateRef, {
            $implicit: item,
        });
    }
}
