import {Component, ElementRef, Input, OnInit} from '@angular/core';

export interface AssignmentGapChoiceOptionEntryInterface {
    readonly entryId: string;
    value: string;
    isCorrect(): boolean;
}

@Component({
    selector: 'assignment-gap-choice-option-entry',
    templateUrl: './assignment-gap-choice-option-entry.component.html',
    styleUrls: ['./assignment-gap-choice-option-entry.component.scss'],
})
export class AssignmentGapChoiceOptionEntryComponent implements OnInit {
    public value!: string;

    @Input('id')
    public entryId!: string;

    @Input('data-correct')
    public correctOption!: boolean | string;

    constructor(private element: ElementRef) {}

    public ngOnInit(): void {
        this.value = this.element.nativeElement.innerText;
    }

    public isCorrect(): boolean {
        return this.correctOption === true || this.correctOption === 'true';
    }
}
