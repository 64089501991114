import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {DialogService} from 'app/cdk/service/dialog/dialog.service';
import {DrawingCanvasComponent} from 'app/drawing/component/canvas/canvas.component';
import {DrawingActionEnum} from 'app/drawing/enum/action.enum';
import {DrawingStateEnum} from 'app/drawing/enum/state.enum';
import {DrawingService} from 'app/drawing/service/drawing/drawing.service';
import {
    ApiToolInterface,
    ApiToolScreenshotInterface,
} from 'app/interface/api-tool.interface';
import {ExtendedObject} from 'app/interface/fabric';
import {
    SavedPaperToolInterface,
    ToolService,
} from 'app/service/tool/tool.service';
import {Subscription} from 'rxjs';

// eslint-disable-next-line
declare let fabric: any | never;

@Component({
    selector: 'app-tool-image-overlay-component',
    templateUrl: 'overlay.component.html',
    styleUrls: ['overlay.component.scss'],
})
export class ImageOverlayComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(DrawingCanvasComponent)
    private canvas!: DrawingCanvasComponent;

    @Output()
    public closeEmitter = new EventEmitter();

    public preview?: string;

    private toolInstance!: ApiToolInterface;
    private toolScreenshot?: ApiToolScreenshotInterface;
    private drawingActionSubscription?: Subscription;
    private drawingStateSubscription?: Subscription;
    private undoQueue: fabric.Object[] = [];
    private redoQueue: fabric.Object[] = [];
    private backgroundAdded = false;

    public constructor(
        private drawingService: DrawingService,
        private toolService: ToolService,
        private dialogService: DialogService,
        private changeRef: ChangeDetectorRef
    ) {}

    public setTool(
        tool: ApiToolInterface,
        screenshot?: ApiToolScreenshotInterface
    ): void {
        this.toolInstance = tool;
        this.toolScreenshot = screenshot;

        this.handleLoadCanvasImage();
    }

    public ngOnInit(): void {
        this.drawingActionSubscription = this.drawingService.subscribeToActions(
            action => this.handleDrawingAction(action)
        );
        this.drawingStateSubscription =
            this.drawingService.subscribeToStateChange(state =>
                this.handleDrawingStateChanged(state)
            );
    }

    public ngAfterViewInit(): void {
        this.handleLoadCanvasImage();
    }

    public ngOnDestroy(): void {
        if (this.drawingActionSubscription) {
            this.drawingActionSubscription.unsubscribe();
        }

        this.drawingService.unsubscribeFromActions();
    }

    public handleDrawn(canvas: fabric.Object): void {
        this.undoQueue.push(canvas);
        this.redoQueue = [];
        this.preview = this.canvas.getPreview();
    }

    public print(): void {
        window.print();
    }

    public save(): void {
        if (!this.canvas) {
            return;
        }

        const json = this.canvas.toJson();
        const preview = this.canvas.getPreview();
        if (!json || !preview) {
            return;
        }

        if (this.toolScreenshot === undefined) {
            this.dialogService
                .input({
                    title: 'Tool opslaan',
                    text: 'Geef je tool een naam',
                    userInput: `${
                        this.toolInstance.name
                    } ${new Date().toLocaleString()}`,
                })
                .subscribe(userInput => {
                    if (!userInput) {
                        return;
                    }

                    this.saveNewTool(userInput, json, preview);
                });
        } else {
            this.updateExistingTool(json, preview);
        }
    }

    private saveNewTool(title: string, json: string, preview: string): void {
        this.toolService
            .saveTool(this.toolInstance.id, title, json, preview)
            .then((saved: SavedPaperToolInterface) => {
                this.toolScreenshot = {
                    id: saved.id,
                    title,
                    preview,
                };

                this.alertAfterSave();
            });
    }

    private updateExistingTool(json: string, preview: string): void {
        if (!this.toolScreenshot) {
            return;
        }

        this.toolService
            .updateTool(
                this.toolScreenshot.id,
                this.toolInstance.id,
                this.toolScreenshot.title,
                json,
                preview
            )
            .then(() => this.alertAfterSave());
    }

    private alertAfterSave(): void {
        this.dialogService.alert({
            title: 'Gelukt!',
            text: 'Je tekening is opgeslagen. Je kunt hem terugvinden in je toolbox.',
        });
    }

    private handleDrawingAction(action: DrawingActionEnum): void {
        switch (action) {
            case DrawingActionEnum.Undo:
                this.handleUndo();
                break;

            case DrawingActionEnum.Redo:
                this.handleRedo();
                break;

            case DrawingActionEnum.Clear:
                this.handleClear();
                break;
        }
    }

    private handleDrawingStateChanged(state: DrawingStateEnum): void {
        if (state !== DrawingStateEnum.Disabled) {
            return;
        }

        this.closeEmitter.emit();
    }

    private handleUndo(): void {
        if (this.toolScreenshot !== undefined && this.undoQueue.length === 1) {
            return;
        }

        const object = this.undoQueue.pop();
        if (!object) {
            return;
        }

        this.redoQueue.push(object);
        this.canvas.removeObject(object);
    }

    private handleRedo(): void {
        const object = this.redoQueue.pop();
        if (!object) {
            return;
        }

        this.undoQueue.push(object);
        this.canvas.addObject(object);
    }

    private handleClear(): void {
        this.undoQueue = [];
        this.redoQueue = [];

        this.canvas.clear();
    }

    private handleLoadCanvasImage(): void {
        if (this.backgroundAdded || !this.toolInstance || !this.canvas) {
            return;
        }

        if (this.toolScreenshot === undefined) {
            this.addBackgroundImage();
        } else {
            this.loadFromScreenshot();
        }
    }

    private loadFromScreenshot(): void {
        if (
            !this.toolScreenshot ||
            !this.toolScreenshot.objects ||
            !this.canvas
        ) {
            return;
        }

        const promise = this.canvas.fromJson(this.toolScreenshot.objects);
        promise.then(() => {
            this.doneLoading();
        });
    }

    private addBackgroundImage(): void {
        if (!this.toolInstance || !this.canvas) {
            return;
        }

        fabric.Image.fromURL(
            this.toolInstance.imageUrl,

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (image: any) => this.handleBackgroundImage(image),
            {
                crossOrigin: 'anonymous',
            }
        );
    }

    private handleBackgroundImage(image: any): void {
        fabric.Image.fromURL(
            image.toDataURL({}),
            (img: ExtendedObject) => {
                const canvas = this.canvas;

                img.erasable = false;
                if (this.toolInstance && this.toolInstance.resize) {
                    // TODO: implement this correctly, or remove completely (TLA-2173)
                    img.scaleToHeight(canvas.height);
                }
                canvas.addObject(img);
                img.center();

                this.doneLoading();
            },
            {
                crossOrigin: 'anonymous',
            }
        );
    }

    private doneLoading(): void {
        this.backgroundAdded = true;
        this.drawingService.state = DrawingStateEnum.Pen;
        this.changeRef.detectChanges();
    }
}
