import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {Router} from '@angular/router';
import {createErrorHandler, TraceService} from '@sentry/angular';
import {DrawingModule} from 'app/drawing/drawing.module';
import {environment} from 'app/environment';
import {RoutingModule} from 'app/routing.module';
import {AppComponent} from 'app/app.component';
import {HeaderModule} from 'app/header/header.module';
import {ContentModule} from 'app/content/content.module';
import {PageModule} from 'app/page/page.module';
import {DirectiveModule} from 'app/directive/directive.module';
import {DeeplinkService} from 'app/service/deeplink/deeplink.service';
import {GoogleAnalyticsService} from 'app/service/google-analytics/google-analytics.service';
import {ToolLayerModule} from 'app/tool-layer/tool-layer.module';
import {ZoomableViewModule} from 'app/zoomable-view/zoomable-view.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ToolModule} from 'app/tool/tool.module';
import {ToolBoxModule} from 'app/toolbox/tool-box.module';
import {TocModule} from 'app/toc/toc.module';
import {ComponentModule} from 'app/component/component.module';
import 'hammerjs';
import {
    NgxGoogleAnalyticsModule,
    NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import {SecondScreenModule} from 'app/second-screen/second-screen.module';
import {httpInterceptorProviders} from 'app/interceptor';
import {AnalyticsService} from './service/analytics/analytics.service';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HammerModule,
        RoutingModule,
        HeaderModule,
        ContentModule,
        PageModule,
        DirectiveModule,
        ZoomableViewModule,
        DragDropModule,
        ToolModule,
        ComponentModule,
        TocModule,
        ToolBoxModule,
        ToolLayerModule,
        DrawingModule,
        NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsCode),
        NgxGoogleAnalyticsRouterModule,
        SecondScreenModule,
    ],
    providers: [
        httpInterceptorProviders,
        {
            provide: 'LocalStorage',
            useValue: window.localStorage,
        },
        {
            provide: Location,
            useValue: location,
        },
        {
            provide: ErrorHandler,
            useValue: createErrorHandler({
                logErrors: true,
                showDialog: false,
            }),
        },
        {
            provide: TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [TraceService, GoogleAnalyticsService, DeeplinkService],
            multi: true,
        },
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(analyticsService: AnalyticsService) {}
}
