import {ApiMapInterface} from 'app/interface/api-map.interface';
import {
    ApiResourceEnum,
    ApiResourceInterface,
} from 'app/interface/api-resource.interface';
import {ResourceException} from 'app/service/resource/exception/resource.exception';
import {ApiMethodGroupInterface} from 'app/interface/api-methods.interface';
import * as _ from 'lodash';
import {TocResourceState} from 'app/enum/toc.enum';

export const PDF_TYPE = 'pdf';
export const ASSET_LARGE_SCREEN = 'assetLargeScreen';

export class Resource {
    private parent?: Resource;
    private children: Resource[] = [];
    private pdfHasAnswers = true;
    private assetLargeScreen = true;

    protected constructor(
        private name: string,
        private type: string,
        private path: string,
        private question: boolean,
        private revision?: number,
        private category?: string,
        private visible?: boolean,
        private additionalContent?: boolean
    ) {}

    public static fromMethod(method: string): Resource {
        return new Resource(method, ApiResourceEnum.Method, method, false);
    }

    public static fromMethodGroups(
        groups: ApiMethodGroupInterface[]
    ): Resource {
        if (0 === groups.length) {
            throw ResourceException.noApiGroups();
        }

        const method: Resource = Resource.fromMethod(groups[0].method);
        method.children = groups.map((group: ApiMethodGroupInterface) =>
            Resource.fromApiMethodGroup(group)
        );

        return method;
    }

    public static fromApiMap(apiMap: ApiMapInterface): Resource {
        const self: ApiMethodGroupInterface | ApiResourceInterface =
            apiMap.self;
        const selfResource: Resource = this.isMethodGroup(self)
            ? Resource.fromApiMethodGroup(self)
            : Resource.fromApiResource(self);

        selfResource.children = apiMap.children.map(
            (apiResource: ApiResourceInterface) => {
                const childResource: Resource =
                    this.fromApiResource(apiResource);
                childResource.parent = selfResource;

                return childResource;
            }
        );

        return selfResource;
    }

    public static fromApiResource(apiResource: ApiResourceInterface): Resource {
        const parent = apiResource.parent;
        const resource: Resource = new Resource(
            apiResource.name,
            apiResource.type,
            apiResource.contentPath,
            apiResource.question,
            apiResource.revision,
            apiResource.category,
            apiResource.visible,
            apiResource.additionalContent
        );

        if (undefined !== parent) {
            resource.parent = this.isMethodGroup(parent)
                ? Resource.fromApiMethodGroup(parent)
                : Resource.fromApiResource(parent);
        }

        return resource;
    }

    public static fromApiMethodGroup(group: ApiMethodGroupInterface): Resource {
        const groupResource: Resource = new Resource(
            group.name,
            ApiResourceEnum.MethodGroup,
            group.code,
            false,
            undefined
        );

        // Group parent is always the method
        groupResource.parent = this.fromMethod(group.method);

        return groupResource;
    }

    private static isMethodGroup(
        item: ApiResourceInterface | ApiMethodGroupInterface
    ): item is ApiMethodGroupInterface {
        return 'code' in item;
    }

    public isScreenType(): boolean {
        const types: string[] = Object.values(ApiResourceEnum) as string[];

        return !types.includes(this.type);
    }

    public isPdfTypeWithAnswers(): boolean {
        return this.getType() === PDF_TYPE && this.pdfHasAnswers;
    }

    public isAssetLargeScreenType(): boolean {
        return this.getType() === ASSET_LARGE_SCREEN && this.assetLargeScreen;
    }

    public pdfTypeHasNoAnswers(): void {
        this.pdfHasAnswers = false;
    }

    public isFirstChildScreenType(): boolean {
        const firstChild: Resource | undefined = this.getFirstChild();

        return undefined !== firstChild && firstChild.isScreenType();
    }

    public getThipId(): string {
        const thipId: string | undefined = this.path.split('/').pop();

        if (undefined === thipId) {
            throw ResourceException.unableToExtractThipId(this.path);
        }

        return thipId;
    }

    public getName(): string {
        return this.name;
    }

    public getType(): string {
        return this.type;
    }

    public getCategory(): string {
        const category: string | undefined = this.category;

        if (undefined === category) {
            throw ResourceException.noCategory();
        }

        return category;
    }

    public getParent(): Resource {
        const parent: Resource | undefined = this.parent;

        if (undefined === parent) {
            throw ResourceException.noParent();
        }

        return parent;
    }

    public getState(): TocResourceState {
        if (this.visible && this.additionalContent) {
            return TocResourceState.OptionalAndActive;
        } else if (this.visible) {
            return TocResourceState.Active;
        } else {
            return TocResourceState.Inactive;
        }
    }

    public getChildren(): Resource[] {
        if (undefined === this.children) {
            return [];
        }

        return this.children;
    }

    public getVisibleChildren(showAll = false): Resource[] {
        if (showAll) {
            return this.getChildren();
        }

        const visibleChildren = this.children.filter(
            (r: Resource) => r.visible
        );

        return visibleChildren.length ? visibleChildren : [this.children[0]];
    }

    public setVisible(value: boolean): void {
        this.visible = value;
    }

    public isVisible(): boolean | undefined {
        return this.visible;
    }

    public isAdditionalContent(): boolean | undefined {
        return this.additionalContent;
    }

    public getChildCategories(): string[] {
        const children: Resource[] = this.getChildren();

        if (0 === children.length) {
            throw ResourceException.noChildResources();
        }

        const categories: string[] = children.map(resource =>
            resource.getCategory()
        );

        return categories.filter(
            (category: string, index: number) =>
                categories.indexOf(category) === index
        );
    }

    public getChildAt(index: number): Resource | undefined {
        return this.children[index];
    }

    public getPath(): string {
        if (
            !(
                [
                    ApiResourceEnum.Method,
                    ApiResourceEnum.MethodGroup,
                ] as string[]
            ).includes(this.type)
        ) {
            const group: Resource = this.getByType(ApiResourceEnum.MethodGroup);

            return `${group.getPath()}/${this.path}`;
        }

        return this.path;
    }

    public isQuestion(): boolean {
        return this.question;
    }

    public getRevision(): number | undefined {
        return this.revision;
    }

    public getCode(): string {
        return this.getByType(ApiResourceEnum.MethodGroup).path;
    }

    public getMethod(): string {
        return this.getByType(ApiResourceEnum.Method).name;
    }

    public getRoute(): string {
        return this.asArray()
            .map(resource =>
                resource.isScreenType()
                    ? `content/${resource.getThipId()}`
                    : resource.getThipId()
            )
            .join('/');
    }

    public asArray(): Resource[] {
        const resources: Resource[] = [];

        if (undefined !== this.parent) {
            resources.push(...this.parent.asArray());
        }

        resources.push(this);

        return resources;
    }

    public getByType(type: ApiResourceEnum): Resource {
        const resource: Resource | undefined = _.find(this.asArray(), [
            'type',
            type,
        ]);

        if (undefined === resource) {
            throw ResourceException.resourceNotFoundByType(type);
        }

        return resource;
    }

    public getChildByPath(path: string): Resource {
        for (const child of this.children) {
            if (path === child.getPath()) {
                return child;
            }
        }

        throw ResourceException.childNotFoundByPath(path);
    }

    public getFirstChild(): Resource {
        if (!this.hasChildren()) {
            throw ResourceException.noChildResources();
        }

        return this.children[0];
    }

    public hasChildren(): boolean {
        return this.children.length > 0;
    }

    public indexOf(resource: Resource, showAll = false): number {
        return this.getVisibleChildren(showAll).indexOf(resource);
    }

    public isFirstChild(showAll = false): boolean {
        const siblings = this.getParent().getVisibleChildren(showAll);
        if (!siblings) {
            return false;
        }
        return siblings.indexOf(this) === 0;
    }

    public isLastChild(showAll = false): boolean {
        const siblings = this.getParent().getVisibleChildren(showAll);
        if (!siblings) {
            return false;
        }
        return siblings.indexOf(this) === siblings.length - 1;
    }
}
