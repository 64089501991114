import {DigitalClockSegments} from 'app/tool-layer/tools/digital-clock/classes/digital-clock-segments.class';

export enum TimeSegmentEnum {
    Hours = 'hours',
    Minutes = 'minutes',
    Seconds = 'seconds',
}
export class DigitalClock {
    public readonly hoursSegments = new DigitalClockSegments();
    public readonly minutesSegments = new DigitalClockSegments();
    public readonly secondsSegments = new DigitalClockSegments();

    private constructor() {}

    public static forNow(): DigitalClock {
        const clock = new DigitalClock();
        clock.updateByDate(new Date());

        return clock;
    }

    public static forZero(): DigitalClock {
        return new DigitalClock();
    }

    public updateTime(time: number, segment: TimeSegmentEnum): void {
        switch (segment) {
            case TimeSegmentEnum.Hours:
                this.hoursSegments.updateTimeSegment(time);
                break;
            case TimeSegmentEnum.Minutes:
                this.minutesSegments.updateTimeSegment(time);
                break;
            case TimeSegmentEnum.Seconds:
                this.secondsSegments.updateTimeSegment(time);
                break;
        }
    }

    public tick(seconds = 1): void {
        const date = new Date();
        date.setHours(this.hoursSegments.value);
        date.setMinutes(this.minutesSegments.value);
        date.setSeconds(this.secondsSegments.value);

        const increasedDate = new Date();
        increasedDate.setTime(date.getTime() + seconds * 1000);

        this.updateByDate(increasedDate);
    }

    private updateByDate(date: Date): void {
        this.updateTime(date.getHours(), TimeSegmentEnum.Hours);
        this.updateTime(date.getMinutes(), TimeSegmentEnum.Minutes);
        this.updateTime(date.getSeconds(), TimeSegmentEnum.Seconds);
    }
}
