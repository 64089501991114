import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';

@Component({
    selector: 'app-number-wall',
    templateUrl: './number-wall.component.html',
})
export class NumberWallComponent extends AbstractTool {
    public static readonly id = 'getallenmuur';
    public static readonly width = 410;
    public static readonly height = 260;

    public numRows = 3; // default for a new tool instance
    public activeField = 0; // set focus on index 0 straight away after creation
    public values: {[key: number]: string} = {};

    public setActiveField(index: number): void {
        this.activeField = index;
    }

    public setNumRows(num: number): void {
        this.numRows = num;
    }

    public handleClear(): void {
        this.values = {};
    }

    public handleUndo(): void {
        if (this.activeField === null || !(this.activeField in this.values)) {
            return;
        }

        const currentValue = this.values[this.activeField];
        if (currentValue && currentValue.length > 1) {
            this.values[this.activeField] = currentValue.slice(0, -1);
        } else {
            delete this.values[this.activeField];
        }
    }

    public handleNumberChoice(value: number): void {
        if (this.activeField === null) {
            return;
        }

        const fieldExists = this.activeField in this.values;
        const currentValue = this.values[this.activeField];

        if (!fieldExists || !currentValue || currentValue === '0') {
            this.values[this.activeField] = value.toString();
        } else if (currentValue.length < 3) {
            this.values[this.activeField] += value.toString();
        }
    }
}
