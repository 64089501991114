import {Injectable, OnDestroy} from '@angular/core';
import {DrawingActionEnum} from 'app/drawing/enum/action.enum';
import {DrawnEvent, DrawnEventInterface} from 'app/drawing/event/drawn-event';
import {DrawingService} from 'app/drawing/service/drawing/drawing.service';
import {ContentService} from 'app/service/content/content.service';
import {Resource} from 'app/service/resource/classes/resource.class';
import {ZoomableViewService} from 'app/zoomable-view/service/zoomable-view/zoomable-view.service';
import {fabric} from 'fabric';
import {Subject, Subscription} from 'rxjs';

interface BoundsInterface {
    top: number;
    left: number;
    factor: number;
}

@Injectable()
export class DrawingToScreenCommunicatorService implements OnDestroy {
    private subjectsByResource = new Map<
        string,
        Subject<DrawnEventInterface>
    >();
    private subscriptions: Subscription[] = [];

    public constructor(
        private contentService: ContentService,
        private drawingService: DrawingService,
        private zoomService: ZoomableViewService
    ) {
        this.subscriptions.push(
            drawingService.subscribeToActions(action =>
                this.handleDrawingAction(action)
            )
        );
    }

    private static getResourceId(resource: Resource): string {
        return resource.getPath();
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    public subscribe(
        resource: Resource,
        callback: (event: DrawnEventInterface) => void
    ): Subscription {
        const subject = this.getSubjectForResource(resource);

        return subject.subscribe(callback);
    }

    public drawn(canvas: fabric.Object): void {
        const resource = this.getResource();
        const subject = this.getSubjectForResource(resource);

        subject.next(
            DrawnEvent.forDrawn(resource, this.drawingService.state, canvas)
        );
    }

    public updateObjectScaleByElement(
        element: HTMLElement,
        object: fabric.Object,
        scale = 1
    ): void {
        const bounds = this.calculateDrawnBoundary(element, object, scale);
        if (!bounds) {
            return;
        }

        this.updateObjectScaleByBoundary(bounds, object);
    }

    private calculateDrawnBoundary(
        element: HTMLElement,
        object: fabric.Object,
        scale: number
    ): BoundsInterface | undefined {
        const realScale = this.zoomService.getScale();

        const objectCanvasKlass = object.canvas;
        if (!objectCanvasKlass) {
            return undefined;
        }

        const objectCanvas = objectCanvasKlass.getElement();
        const objectBounds = objectCanvas.getBoundingClientRect();
        const elementBounds = element.getBoundingClientRect();

        const CANVAS_BORDER_WIDTH = 1;
        const factor = scale;

        const left =
            (elementBounds.left - objectBounds.left - CANVAS_BORDER_WIDTH) /
            realScale;
        const top = elementBounds.top - objectBounds.top - CANVAS_BORDER_WIDTH;

        return {left, top, factor};
    }

    private updateObjectScaleByBoundary(
        bounds: BoundsInterface,
        object: fabric.Object
    ): void {
        if (!object.top || !object.left) {
            return;
        }

        object.set({
            strokeWidth: object.strokeWidth,
            stroke: object.stroke,
            top: object.top / bounds.factor - bounds.top / bounds.factor - 3,
            left: object.left / bounds.factor - bounds.left / bounds.factor - 3,
        });
        object.scale(1 / bounds.factor);
    }

    private handleDrawingAction(action: DrawingActionEnum): void {
        const resource = this.getResource();
        const subject = this.getSubjectForResource(resource);

        switch (action) {
            case DrawingActionEnum.StateSwitch:
                subject.next(
                    DrawnEvent.forStateSwitch(
                        resource,
                        this.drawingService.state
                    )
                );
                break;

            case DrawingActionEnum.Undo:
                subject.next(DrawnEvent.forUndo(resource));
                break;

            case DrawingActionEnum.Redo:
                subject.next(DrawnEvent.forRedo(resource));
                break;

            case DrawingActionEnum.Clear:
                subject.next(DrawnEvent.forClear(resource));
                break;
        }
    }

    public getResource(): Resource {
        const resource = this.contentService.getCurrentResource();
        if (!resource) {
            throw new Error('No current resource set');
        }

        return resource;
    }

    private getSubjectForResource(
        resource: Resource
    ): Subject<DrawnEventInterface> {
        const resourceId =
            DrawingToScreenCommunicatorService.getResourceId(resource);

        let subject = this.subjectsByResource.get(resourceId);
        if (!subject) {
            subject = new Subject<DrawnEventInterface>();
            this.subjectsByResource.set(resourceId, subject);
        }

        return subject;
    }
}
