export class UndefinedFaderException extends Error {
    protected constructor(fader: string) {
        super(`${fader} fader is undefined`);
    }

    public static withMissingActiveFader(): UndefinedFaderException {
        return new UndefinedFaderException('Active');
    }

    public static withMissingInactiveFader(): UndefinedFaderException {
        return new UndefinedFaderException('Inactive');
    }
}
