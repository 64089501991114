import {ComponentRef, Injectable} from '@angular/core';
import {ContentService} from 'app/service/content/content.service';
import {
    Package,
    Selections,
    WordsResponse,
} from 'app/tool-layer/tools/word-flasher/models/flasher.models';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {Overlay} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {WordFlasherPlayerComponent} from 'app/tool-layer/tools/word-flasher/components/word-flasher-player/word-flasher-player.component';
import {ApiRequestService} from 'app/service/api-request/api-request.service';

@Injectable()
export class FlasherService {
    private arrangementCode!: string;
    private contentIdentifier!: string;
    private readonly flasherApiPath = '/api/v2/flasher/';

    public constructor(
        private apiRequestService: ApiRequestService,
        private contentService: ContentService,
        private overlay: Overlay
    ) {}

    public init(): void {
        const resource = this.contentService.getCurrentResource();
        if (resource) {
            const parent = resource.getParent();
            if (parent) {
                this.arrangementCode = parent.getCode();
                this.contentIdentifier = parent.getThipId();
            }
        }
    }

    public getAllPackages(): Observable<Package[]> {
        return this.apiRequestService
            .get<Package[]>(`${this.flasherApiPath}${this.arrangementCode}`)
            .pipe(shareReplay());
    }

    public getWordsByUrl(url: string): Observable<WordsResponse> {
        return this.apiRequestService.get<WordsResponse>(url);
    }

    public getInitialPackage(): Package {
        return {
            url: `${this.flasherApiPath}${this.arrangementCode}/${this.contentIdentifier}`,
            name: '',
            contentIdentifier: this.contentIdentifier,
        };
    }

    public startPlayer(config: Selections): void {
        const overlayRef = this.overlay.create({
            height: '100vh',
            width: '100vw',
            panelClass: 'panel-flasher',
        });
        const playerPortal = new ComponentPortal(WordFlasherPlayerComponent);
        const containerRef: ComponentRef<WordFlasherPlayerComponent> =
            overlayRef.attach(playerPortal);
        containerRef.instance.playerConfig = config;
        containerRef.instance.playerClose.subscribe(() => {
            overlayRef.dispose();
        });
    }
}
