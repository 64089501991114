import {Injectable} from '@angular/core';
import {
    GoogleAnalyticsActionsEnum,
    GoogleAnalyticsCategoriesEnum,
    GoogleAnalyticsLabelEnum,
} from 'app/service/google-analytics/categories.enum';
import {GoogleAnalyticsService as GAService} from 'ngx-google-analytics';

@Injectable()
export class GoogleAnalyticsService {
    constructor(private gaService: GAService) {
        gaService.set(['anonymizeIp', true]);
    }

    public event(
        action: GoogleAnalyticsActionsEnum,
        category: GoogleAnalyticsCategoriesEnum,
        label:
            | GoogleAnalyticsLabelEnum
            | string = GoogleAnalyticsLabelEnum.Empty
    ): void {
        this.gaService.event(action, category, label);
    }

    public pageView(path: string, title: string): void {
        this.gaService.pageView(path, title);
    }
}
