import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    QueryList,
} from '@angular/core';
import {
    KaraokeEntryComponent,
    KaraokeEntryComponentInterface,
} from 'app/content/screen/components/karaoke/karaoke-entry/karaoke-entry.component';

@Component({
    selector: 'karaoke',
    templateUrl: './karaoke.component.html',
    styleUrls: ['./karaoke.component.scss'],
})
export class KaraokeComponent implements AfterViewInit {
    @ContentChildren(KaraokeEntryComponent)
    private karaokeEntryComponents!: QueryList<KaraokeEntryComponentInterface>;

    public readIds!: string;

    public constructor(private changeRef: ChangeDetectorRef) {}

    public ngAfterViewInit(): void {
        this.setupReadSpeakerUrl();
    }

    private setupReadSpeakerUrl(): void {
        const ids: string[] = [];

        this.karaokeEntryComponents.forEach(entry => {
            ids.push(entry.textEntryId);
        });

        this.readIds = ids.join(',');
        this.changeRef.detectChanges();
    }
}
