import {Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[originalContent]',
})
export class OriginalContentDirective {
    private readonly content: HTMLCollection;

    constructor(private elementRef: ElementRef) {
        const content: HTMLElement = this.elementRef.nativeElement;
        content.classList.add('original-content');

        this.content = content.children;
    }

    public getContent(): HTMLCollection {
        return this.content;
    }

    public getInnerHTML(): string {
        return this.elementRef.nativeElement.innerHTML;
    }
}
