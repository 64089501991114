import {Injectable} from '@angular/core';
import {
    ApiToolInterface,
    ApiToolListInterface,
    ApiToolScreenshotInterface,
} from 'app/interface/api-tool.interface';
import {
    ApiRequestService,
    ParamsInterface,
} from 'app/service/api-request/api-request.service';
import {ContentService} from 'app/service/content/content.service';

interface SavePaperToolInterface {
    id?: number;
    toolId: number;
    title: string;
    preview: string;
    objects: string;
}

interface RenamePaperToolInterface {
    id: number;
    title: string;
}

interface RemovePaperToolInterface {
    id: number;
}

export interface SavedPaperToolInterface {
    id: number;
}

@Injectable()
export class ToolService {
    public constructor(
        private apiRequestService: ApiRequestService,
        private contentService: ContentService
    ) {}

    public getById(id: number): Promise<ApiToolInterface> {
        const resource = this.contentService.getCurrentResource();
        if (!resource) {
            throw new Error('No current resource found!');
        }
        const arrangementCode = resource.getCode();

        const url = '/api/v2/tool/:arrangementCode/show/:id';
        const params: ParamsInterface = {
            urlParams: new Map<string, string>([
                ['arrangementCode', arrangementCode],
                ['id', String(id)],
            ]),
        };

        return this.apiRequestService
            .get<ApiToolInterface>(url, params)
            .toPromise();
    }

    public getByName(name: string): Promise<Array<ApiToolListInterface>> {
        const resource = this.contentService.getCurrentResource();
        if (!resource) {
            throw new Error('No current resource found!');
        }
        const arrangementCode = resource.getCode();

        const url = '/api/v2/tool/:arrangementCode/name/:name';
        const params: ParamsInterface = {
            urlParams: new Map<string, string>([
                ['arrangementCode', arrangementCode],
                ['name', name],
            ]),
        };

        return this.apiRequestService
            .get<Array<ApiToolListInterface>>(url, params)
            .toPromise();
    }

    public getPaperToolById(id: number): Promise<ApiToolScreenshotInterface> {
        const url = '/api/v2/papertools/:id';
        const params: ParamsInterface = {
            urlParams: new Map<string, string>([['id', String(id)]]),
        };

        return this.apiRequestService
            .get<ApiToolScreenshotInterface>(url, params)
            .toPromise();
    }

    public saveTool(
        toolId: number,
        title: string,
        json: string,
        preview: string
    ): Promise<SavedPaperToolInterface> {
        return this.apiRequestService
            .post<SavedPaperToolInterface, SavePaperToolInterface>(
                '/api/v2/papertools/save',
                {
                    toolId,
                    title,
                    preview,
                    objects: json,
                }
            )
            .toPromise();
    }

    public updateTool(
        id: number,
        toolId: number,
        title: string,
        json: string,
        preview: string
    ): Promise<SavedPaperToolInterface> {
        return this.apiRequestService
            .post<SavedPaperToolInterface, SavePaperToolInterface>(
                '/api/v2/papertools/save',
                {
                    id,
                    toolId,
                    title,
                    preview,
                    objects: json,
                }
            )
            .toPromise();
    }

    public renameTool(
        id: number,
        title: string
    ): Promise<SavedPaperToolInterface> {
        return this.apiRequestService
            .post<SavedPaperToolInterface, RenamePaperToolInterface>(
                '/api/v2/papertools/rename',
                {
                    id,
                    title,
                }
            )
            .toPromise();
    }

    public deleteTool(id: number): Promise<void> {
        return this.apiRequestService
            .post<void, RemovePaperToolInterface>('/api/v2/papertools/remove', {
                id,
            })
            .toPromise();
    }
}
