import {
    Directive,
    forwardRef,
    Input,
    OnDestroy,
    QueryList,
} from '@angular/core';
import {AbstractResourceComponentClass} from 'app/content/screen/factory/resource-service';
import {DrawingActionEnum} from 'app/drawing/enum/action.enum';
import {DrawingStateEnum} from 'app/drawing/enum/state.enum';
import {DrawnEventInterface} from 'app/drawing/event/drawn-event';
import {DrawingPdfComponentInterface} from 'app/drawing/interface/drawing-component.interface';
import {DrawingService} from 'app/drawing/service/drawing/drawing.service';
import {DrawingToScreenCommunicatorService} from 'app/drawing/service/screen-communicator/screen-communicator.service';
import {Resource} from 'app/service/resource/classes/resource.class';
import {Subscription} from 'rxjs';
import {ScreenDrawingComponent} from 'app/content/screen/components/screen-drawing/screen-drawing.component';

@Directive({
    selector: 'app-screen-drawing-wrapper',
    providers: [
        {
            provide: AbstractResourceComponentClass,
            useExisting: forwardRef(() => ScreenDrawingWrapperDirective),
        },
    ],
})
export class ScreenDrawingWrapperDirective
    extends AbstractResourceComponentClass
    implements OnDestroy
{
    @Input()
    public drawingComponent!: ScreenDrawingComponent;
    @Input()
    public pdfComponents!: QueryList<DrawingPdfComponentInterface>;

    private subscriptions = new Array<Subscription>();
    private undoQueue: DrawnEventInterface[] = [];
    private redoQueue: DrawnEventInterface[] = [];
    private resource!: Resource;

    public constructor(
        private drawingService: DrawingService,
        private drawingCommunicator: DrawingToScreenCommunicatorService
    ) {
        super();
    }

    public ngOnDestroy(): void {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    public setResource(resource: Resource): void {
        this.resource = resource;

        this.subscriptions.push(
            this.drawingCommunicator.subscribe(resource, change =>
                this.handleDrawn(change)
            )
        );

        this.subscriptions.push(
            this.drawingComponent.drawn.subscribe(object => {
                const event = {
                    action: DrawingActionEnum.Drawn,
                    resource: this.resource,
                    state: this.drawingService.state,
                    object,
                };

                this.drawnAction(event);
            })
        );
    }

    private handleDrawn(event: DrawnEventInterface): void {
        if (this.drawingCommunicator.getResource() !== this.resource) {
            return;
        }

        switch (event.action) {
            case DrawingActionEnum.Drawn:
                this.drawnAction(event);
                break;

            case DrawingActionEnum.StateSwitch:
                this.stateSwitchAction(event);
                break;

            case DrawingActionEnum.Undo:
                this.undoAction();
                break;

            case DrawingActionEnum.Redo:
                this.redoAction();
                break;

            case DrawingActionEnum.Clear:
                this.clearAction();
                break;
        }
    }

    private drawnAction(event: DrawnEventInterface): void {
        this.undoQueue.push(event);
        this.redoQueue = [];

        switch (event.state) {
            case DrawingStateEnum.PdfMagicWand:
                this.pdfComponents.forEach(c => c.drawnAction(event.object));
                this.drawingComponent.undoAction(event.object);
                break;
            case DrawingStateEnum.PdfShowAnswer:
                return;
        }

        this.pdfComponents.forEach(c => c.hideAnswerPdfAction());
    }

    private stateSwitchAction(event: DrawnEventInterface): void {
        switch (event.state) {
            case DrawingStateEnum.PdfShowAnswer:
                this.pdfComponents.forEach(c => c.showAnswerPdfAction());
                return;
        }

        this.pdfComponents.forEach(c => c.hideAnswerPdfAction());
    }

    private undoAction(): void {
        const event = this.undoQueue.pop();
        if (!event) {
            return;
        }
        this.redoQueue.push(event);

        if (!event.state || !event.object) {
            return;
        }

        switch (event.state) {
            case DrawingStateEnum.PdfMagicWand:
            case DrawingStateEnum.PdfShowAnswer:
                this.pdfComponents.forEach(c => c.undoAction(event.object));
                break;

            default:
                this.drawingComponent.undoAction(event.object);
                break;
        }
    }

    private redoAction(): void {
        const event = this.redoQueue.pop();
        if (!event) {
            return;
        }
        this.undoQueue.push(event);

        if (!event.state || !event.object) {
            return;
        }

        switch (event.state) {
            case DrawingStateEnum.PdfMagicWand:
            case DrawingStateEnum.PdfShowAnswer:
                this.pdfComponents.forEach(c => c.redoAction(event.object));
                break;

            default:
                this.drawingComponent.redoAction(event.object);
                break;
        }
    }

    private clearAction(): void {
        if (this.undoQueue.length === 0 && this.redoQueue.length === 0) {
            return;
        }

        this.drawingService.requestToClear().subscribe(clear => {
            if (!clear) {
                return;
            }

            this.undoQueue = [];
            this.redoQueue = [];

            this.drawingComponent.clearAction();
            this.pdfComponents.forEach(c => c.clearAction());
        });
    }
}
