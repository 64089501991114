import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';

@Directive({
    selector: '[appScrollIntoView]',
})
export class ScrollIntoViewDirective implements OnChanges {
    @Input()
    public appScrollIntoView = false;

    @Output()
    public readonly scrollIntoView = new EventEmitter<ElementRef>();

    constructor(private elRef: ElementRef) {}

    public get element(): ElementRef {
        return this.elRef;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (true === changes.appScrollIntoView.currentValue) {
            this.scrollIntoView.emit(this.elRef);
        }
    }
}
