import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserSelectionArea} from 'app/interface/selection.interface';
import {ZoomableViewService} from 'app/zoomable-view/service/zoomable-view/zoomable-view.service';
import {Subscription} from 'rxjs';
import {DrawingStateEnum} from 'app/drawing/enum/state.enum';
import {DrawingService} from 'app/drawing/service/drawing/drawing.service';

@Component({
    selector: 'app-zoomable-view',
    templateUrl: './zoomable-view.component.html',
    styleUrls: ['./zoomable-view.component.scss'],
})
export class ZoomableViewComponent implements OnDestroy, OnInit {
    public getUserSelection = false;
    private subscription!: Subscription;

    public drawingActive = false;

    private drawingStateSubscription?: Subscription;

    constructor(
        private zoomableViewService: ZoomableViewService,
        private drawingService: DrawingService
    ) {
        this.subscription = zoomableViewService.subscribeToScissors(enabled => {
            this.getUserSelection = enabled;
        });
    }

    public ngOnInit(): void {
        this.drawingStateSubscription =
            this.drawingService.subscribeToStateChange(change =>
                this.drawingStateChanged(change)
            );
    }

    private drawingStateChanged(change: DrawingStateEnum): void {
        this.drawingActive = this.drawingService.isDrawingActive(change);
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public onUserSelection(selection: UserSelectionArea): void {
        this.zoomableViewService.zoomToSelection(selection);
    }
}
