import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {ComponentModule} from 'app/component/component.module';
import {CountingTriangleComponent} from 'app/tool-layer/tools/counting-triangle/counting-triangle.component';

@NgModule({
    imports: [CommonModule, ComponentModule],
    declarations: [CountingTriangleComponent],
    entryComponents: [CountingTriangleComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(CountingTriangleComponent),
            multi: true,
        },
    ],
})
export class CountingTriangleModule {}
