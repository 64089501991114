import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent {
    @Input()
    public enabled = false;
    @Output()
    public enabledChange = new EventEmitter<boolean>();

    @Input()
    public showLabel = false;

    public toggle(): void {
        this.enabled = !this.enabled;
        this.enabledChange.emit(this.enabled);
    }
}
