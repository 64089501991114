export class ResourceException extends Error {
    public static noParent(): ResourceException {
        return new ResourceException('Resource has no parent');
    }

    public static noApiGroups(): ResourceException {
        return new ResourceException('Expected at least one group');
    }

    public static unableToExtractThipId(path: string): ResourceException {
        return new ResourceException(
            `Unable to extract thip id from path "${path}"`
        );
    }

    public static resourceNotFoundByType(type: string): ResourceException {
        return new ResourceException(`No resource found with type "${type}"`);
    }

    public static noChildResources(): ResourceException {
        return new ResourceException('Resource has no child resources');
    }

    public static childNotFoundByPath(path: string): ResourceException {
        return new ResourceException(`No child found by path "${path}"`);
    }

    public static noCategory(): ResourceException {
        return new ResourceException('Resource has no category');
    }
}
