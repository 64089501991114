import {NgModule} from '@angular/core';
import {AbacusModule} from 'app/tool-layer/tools/abacus/abacus.module';
import {HtethdModule} from 'app/tool-layer/tools/htethd/htethd.module';
import {ImageModule} from 'app/tool-layer/tools/image/image.module';
import {StopwatchModule} from 'app/tool-layer/tools/stopwatch/stopwatch.module';
import {TrafficLightModule} from './traffic-light/traffic-light.module';
import {DigitalClockModule} from 'app/tool-layer/tools/digital-clock/digital-clock.module';
import {TeModule} from 'app/tool-layer/tools/te/te.module';
import {HteModule} from 'app/tool-layer/tools/hte/hte.module';
import {DhteModule} from 'app/tool-layer/tools/dhte/dhte.module';
import {TddhteModule} from 'app/tool-layer/tools/tddhte/tddhte.module';
import {HdtddhteModule} from 'app/tool-layer/tools/hdtddhte/hdtddhte.module';
import {MhdtddhteModule} from 'app/tool-layer/tools/mhdtddhte/mhdtddhte.module';
import {RulerModule} from 'app/tool-layer/tools/ruler/ruler.module';
import {AnalogClockModule} from 'app/tool-layer/tools/analog-clock/analog-clock.module';
import {MagicSquareModule} from 'app/tool-layer/tools/magic-square/magic-square.module';
import {JumpingModule} from 'app/tool-layer/tools/jumping/jumping.module';
import {MagicTriangleModule} from 'app/tool-layer/tools/magic-triangle/magic-triangle.module';
import {NumberWallModule} from 'app/tool-layer/tools/number-wall/number-wall.module';
import {RatioTableModule} from 'app/tool-layer/tools/ratio-table/ratio-table.module';
import {CountingTriangleModule} from 'app/tool-layer/tools/counting-triangle/counting-triangle.module';
import {TimerModule} from 'app/tool-layer/tools/timer/timer.module';
import {CountUpAndSubstractModule} from 'app/tool-layer/tools/count-up-and-substract/count-up-and-substract.module';
import {PositionOnTheNumberLineModule} from 'app/tool-layer/tools/position-on-the-number-line/position-on-the-number-line.module';
import {PercentCircleModule} from 'app/tool-layer/tools/percent-circle/percent-circle.module';
import {FloatingSvgModule} from 'app/tool-layer/tools/floating-svg/floating-svg.module';
import {Chain20Module} from 'app/tool-layer/tools/chain-20/chain-20.module';
import {Chain100Module} from 'app/tool-layer/tools/chain-100/chain-100.module';
import {GeldModule} from 'app/tool-layer/tools/geld/geld.module';
import {SplitsDiagramModule} from 'app/tool-layer/tools/splits-diagram/splits-diagram.module';
import {ChipsModule} from 'app/tool-layer/tools/chips/chips.module';
import {DiceModule} from 'app/tool-layer/tools/dice/dice.module';
import {PercentStripModule} from 'app/tool-layer/tools/percent-strip/percent-strip.module';
import {MetricSystemModule} from 'app/tool-layer/tools/metric-system/metric-system.module';
import {CountingHandsModule} from 'app/tool-layer/tools/counting-hands/counting-hands.module';
import {SplitsFlatModule} from 'app/tool-layer/tools/splits-flat/splits-flat.module';
import {WordFlasherModule} from 'app/tool-layer/tools/word-flasher/word-flasher.module';
import {NumberLineModule} from 'app/tool-layer/tools/number-line/number-line.module';

@NgModule({
    imports: [
        TrafficLightModule,
        DigitalClockModule,
        TeModule,
        HteModule,
        DhteModule,
        TddhteModule,
        HdtddhteModule,
        MhdtddhteModule,
        RulerModule,
        AnalogClockModule,
        MagicSquareModule,
        StopwatchModule,
        JumpingModule,
        MagicTriangleModule,
        NumberWallModule,
        RatioTableModule,
        CountingTriangleModule,
        TimerModule,
        CountUpAndSubstractModule,
        PositionOnTheNumberLineModule,
        HtethdModule,
        PercentCircleModule,
        FloatingSvgModule,
        Chain20Module,
        Chain100Module,
        GeldModule,
        SplitsDiagramModule,
        ChipsModule,
        DiceModule,
        PercentStripModule,
        MetricSystemModule,
        CountingHandsModule,
        SplitsFlatModule,
        AbacusModule,
        WordFlasherModule,
        ImageModule,
        NumberLineModule,
    ],
})
export class ToolsModule {}
