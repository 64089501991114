import {Inject, Injectable} from '@angular/core';
import {ToolRingInterface} from 'app/tool/classes/tool-ring.class';
import {ToolRingServiceInterface} from 'app/tool/rings/base/abstract-tool-ring.service';
import {RingIds} from 'app/tool/rings/ring-ids.enum';
import {TOOL_RING_SERVICES} from 'app/tool/rings/rings.module';
import {Subject, Subscription} from 'rxjs';
import {Location} from '@angular/common';
import {SecondScreenService} from 'app/second-screen/service/second-screen.service';

@Injectable()
export class ToolService {
    private visible = false;
    private visibleSubject: Subject<boolean> = new Subject();
    private ringContainerChangeSubject: Subject<void> = new Subject();
    private activeRingService!: ToolRingServiceInterface;

    constructor(
        @Inject(TOOL_RING_SERVICES)
        private toolRingServices: ToolRingServiceInterface[],
        private secondScreenService: SecondScreenService,
        private location: Location
    ) {
        this.toolRingServices.forEach(service => {
            if (service.isActive()) {
                this.activateRing(service.getId());
            }

            service.subscribeToToggle(id => this.activateRing(id));
        });
        this.location.onUrlChange(url => this.handleUrlChange(url));
        this.secondScreenService.subscribeToEnabled(enabled =>
            this.handleSecondScreenEnabledChange(enabled)
        );
    }

    public subscribeToVisible(fn: (value: boolean) => void): Subscription {
        return this.visibleSubject.subscribe(fn);
    }

    public subscribeToRingsChange(fn: () => void): Subscription {
        return this.ringContainerChangeSubject.subscribe(fn);
    }

    public getRings(): ToolRingInterface[] {
        return this.activeRingService.render().reverse();
    }

    public isVisible(): boolean {
        return this.visible;
    }

    public setVisible(value: boolean): void {
        if (value === this.visible) {
            return;
        }

        this.visible = value;
        this.visibleSubject.next(value);
    }

    public activateRing(ringId: RingIds): void {
        for (const ring of this.toolRingServices) {
            if (ring.getId() === ringId) {
                this.activeRingService = ring;

                break;
            }
        }

        this.ringContainerChangeSubject.next();
    }

    private handleUrlChange(url: string): void {
        if (!this.secondScreenService.getIsEnabled()) {
            this.setVisible(url.includes('/content/'));
        }
    }

    private handleSecondScreenEnabledChange(enabled: boolean): void {
        if (enabled) {
            this.setVisible(false);
        }
    }
}
