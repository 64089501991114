import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {
    AfterViewInit,
    Component,
    OnDestroy,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {ToolInnerComponent} from 'app/tool/component/inner/inner.component';
import {ToolService} from 'app/tool/service/tool.service';
import {DragDropUtil} from 'app/util/drag-drop/drag-drop.util';
import {Subscription} from 'rxjs';
import {Point, PointInterface} from 'app/classes/point.class';
import {CdkDragEnd} from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-tool',
    templateUrl: './tool.component.html',
    styleUrls: ['./tool.component.scss'],
})
export class ToolComponent implements AfterViewInit, OnDestroy {
    @ViewChild('component')
    private component!: TemplateRef<ToolInnerComponent>;

    public position: PointInterface = {x: 0, y: 0};
    private previousSize!: number;

    private toolVisibleSubscription?: Subscription;
    private overlayRef?: OverlayRef;

    constructor(
        private toolService: ToolService,
        private dragDropUtil: DragDropUtil,
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef
    ) {}

    public ngAfterViewInit(): void {
        this.handleVisibilityToggle(this.toolService.isVisible());

        this.toolVisibleSubscription = this.toolService.subscribeToVisible(
            toggle => this.handleVisibilityToggle(toggle)
        );
    }

    public ngOnDestroy(): void {
        if (this.toolVisibleSubscription) {
            this.toolVisibleSubscription.unsubscribe();
        }

        this.hideTool();
    }

    public handleDragEnd(event: CdkDragEnd): void {
        let positionNew = new Point(
            (this.position.x += event.distance.x),
            (this.position.y += event.distance.y)
        );

        positionNew = this.dragDropUtil.forDragEndEvent(positionNew, event);

        this.position = positionNew;
    }

    public updateRingPosition(size: number): void {
        if (!this.previousSize) {
            this.previousSize = size;
        }

        if (this.previousSize === size) {
            return;
        }

        const correction = (this.previousSize - size) / 2;

        this.position =
            correction < 0
                ? {
                      x: this.position.x - correction * -1,
                      y: this.position.y + correction * -1,
                  }
                : {
                      x: this.position.x + correction,
                      y: this.position.y - correction,
                  };

        this.previousSize = size;
    }

    private handleVisibilityToggle(visible: boolean): void {
        if (visible) {
            this.showTool();
        } else {
            this.hideTool();
        }
    }

    private showTool(): void {
        const portal = new TemplatePortal(
            this.component,
            this.viewContainerRef
        );

        this.overlayRef = this.overlay.create({
            height: '100vh',
            width: '100vw',
            panelClass: 'panel-tool',
        });
        this.overlayRef.attach(portal);
    }

    private hideTool(): void {
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = undefined;
        }
    }
}
