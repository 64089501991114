import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SecondScreenComponent} from './components/second-screen/second-screen.component';
import {SecondScreenSlidesComponent} from './components/second-screen-slides/second-screen-slides.component';
import {ScreenModule} from 'app/content/screen/screen.module';
import {ComponentModule} from 'app/component/component.module';
import {DirectiveModule} from 'app/directive/directive.module';
import {SecondScreenPrintComponent} from './components/second-screen-print/second-screen-print.component';

@NgModule({
    declarations: [
        SecondScreenComponent,
        SecondScreenSlidesComponent,
        SecondScreenPrintComponent,
    ],
    imports: [CommonModule, ScreenModule, ComponentModule, DirectiveModule],
    exports: [
        SecondScreenComponent,
        SecondScreenSlidesComponent,
        SecondScreenPrintComponent,
    ],
})
export class SecondScreenModule {}
