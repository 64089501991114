import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';

@Component({
    selector: 'app-split-diagram',
    templateUrl: './splits-diagram.component.html',
    styleUrls: ['./splits-diagram.component.scss'],
})
export class SplitsDiagramComponent extends AbstractTool {
    public static readonly id = 'splitsschema';
    public static readonly width = 200;
    public static readonly height = 115;

    public activeField!: number;
    public values: {[key: number]: string} = {};

    constructor() {
        super();
    }

    public setActiveField(index: number): void {
        this.activeField = index;
    }

    public handleClear(): void {
        this.values = {};
    }

    public handleUndo(): void {
        if (this.activeField === null || !(this.activeField in this.values)) {
            return;
        }

        const currentValue = this.values[this.activeField];
        if (currentValue && currentValue.length > 1) {
            this.values[this.activeField] = currentValue.slice(0, -1);
        } else {
            delete this.values[this.activeField];
        }
    }

    public handleNumberChoice(value: number): void {
        if (this.activeField === null) {
            return;
        }

        const fieldExists = this.activeField in this.values;
        const currentValue = this.values[this.activeField];

        if (!fieldExists || !currentValue || currentValue === '0') {
            this.values[this.activeField] = value.toString();
        } else if (currentValue.length < 3) {
            this.values[this.activeField] += value.toString();
        }
    }
}
