import {Injectable} from '@angular/core';
import {ApiRequestService} from 'app/service/api-request/api-request.service';
import {ApiMapInterface} from 'app/interface/api-map.interface';
import {Resource} from 'app/service/resource/classes/resource.class';
import {ActivatedRouteSnapshot, Resolve, UrlSegment} from '@angular/router';
import {
    ApiResourceEnum,
    ApiResourceInterface,
} from 'app/interface/api-resource.interface';
import {
    ApiMethodGroupInterface,
    ApiMethodsInterface,
} from 'app/interface/api-methods.interface';
import {MethodService} from 'app/service/method/method.service';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {UserService} from 'app/service/user/user.service';
import {ApiUserScreensActiveStatusInterface} from 'app/interface/api-user-response.interface';

enum UrlSegmentEnum {
    None = 0,
    Method = 1,
}

@Injectable({
    providedIn: 'root',
})
export class ResourceService implements Resolve<Resource | undefined> {
    private currentResource?: Resource;
    private currentResourceSubject: Subject<Resource> = new Subject();

    constructor(
        private apiRequestService: ApiRequestService,
        private methodService: MethodService,
        private userService: UserService
    ) {}

    public getCurrentResource(): Resource | undefined {
        return this.currentResource;
    }

    public setCurrentResource(resource: Resource): void {
        this.currentResource = resource;
        this.currentResourceSubject.next(resource);
    }

    public subscribeToCurrentResource(
        fn: (resource: Resource) => void
    ): Subscription {
        return this.currentResourceSubject.subscribe(fn);
    }

    public resolve(
        route: ActivatedRouteSnapshot
    ): Promise<Resource | undefined> {
        const urlSegments: UrlSegment[] = route.url;
        const contentPath: string = route.url.slice(1).join('/');

        // Prevent fetching resource twice when navigating to content
        if (
            undefined !== this.currentResource &&
            contentPath.startsWith(this.currentResource.getPath())
        ) {
            return Promise.resolve(this.currentResource);
        }

        switch (urlSegments.length) {
            case UrlSegmentEnum.None:
                return Promise.resolve(undefined);

            case UrlSegmentEnum.Method:
                return this.methodService
                    .getGroupsByMethod(urlSegments[0].path)
                    .then((groups: ApiMethodGroupInterface[]) =>
                        Resource.fromMethodGroups(groups)
                    );

            default:
                return this.loadResources(urlSegments);
        }
    }

    public getByResource(resource?: Resource): Promise<Resource[]> {
        if (undefined === resource) {
            return this.methodService
                .all()
                .then(data => this.loadMethods(data));
        }

        const resourcePath: string = resource.getPath();
        if (ApiResourceEnum.Method === resource.getType()) {
            return this.methodService
                .getGroupsByMethod(resourcePath)
                .then(data => this.loadMethodGroups(data));
        }

        return this.getByPath(resourcePath);
    }

    private getByPath(path: string): Promise<Resource[]> {
        return this.get(path).then(resource => resource.getChildren());
    }

    private loadMethodGroups(
        methodGroups: ApiMethodGroupInterface[]
    ): Resource[] {
        return methodGroups.map(methodGroup =>
            Resource.fromApiMethodGroup(methodGroup)
        );
    }

    private loadMethods(data: ApiMethodsInterface): Resource[] {
        return (data as string[]).map(method => Resource.fromMethod(method));
    }

    private loadResources(segments: UrlSegment[]): Promise<Resource> {
        const contentSegment: UrlSegment | undefined = segments.filter(
            segment => segment.path === 'content'
        )[0];
        const pathEndIndex: number =
            undefined !== contentSegment
                ? segments.indexOf(contentSegment)
                : segments.length;
        const pathSegments: UrlSegment[] = segments.slice(1, pathEndIndex);

        return this.get(pathSegments.join('/'));
    }

    private injectActiveStatesForResourceChildren(
        r: ApiMapInterface
    ): Observable<ApiMapInterface> {
        const ids = r.children.map((child: ApiResourceInterface) => {
            return child.contentPath.split('/').pop();
        }) as string[];

        return this.userService.getUserScreensByIds(ids).pipe(
            mergeMap((activeStatuses: ApiUserScreensActiveStatusInterface) => {
                r.children.forEach((res: ApiResourceInterface) => {
                    const thipId = res.contentPath.split('/').pop() as string;
                    if (activeStatuses[thipId] !== undefined) {
                        res.visible = activeStatuses[thipId];
                    }
                });
                return of(r);
            })
        );
    }

    private get(path: string): Promise<Resource> {
        return this.apiRequestService
            .get<ApiMapInterface>(`/api/v2/resource/${path}`)
            .pipe(
                mergeMap((r: ApiMapInterface) => {
                    const {type} = r.self as ApiResourceInterface;
                    return type === 'itemMap'
                        ? this.injectActiveStatesForResourceChildren(r)
                        : of(r);
                })
            )
            .toPromise()
            .then(apiMap => {
                const resource: Resource = Resource.fromApiMap(apiMap);

                this.setCurrentResource(resource);

                return resource;
            });
    }
}
