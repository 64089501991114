import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ToolboxComponent} from 'app/toolbox/component/toolbox.component';
import {CdkModule} from 'app/cdk/cdk.module';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {ComponentModule} from 'app/component/component.module';
import {ToolboxModalService} from 'app/toolbox/service/modal/modal.service';
import {ToolboxService} from 'app/toolbox/service/toolbox/toolbox.service';
import {DirectiveModule} from 'app/directive/directive.module';

@NgModule({
    imports: [
        BrowserModule,
        CdkModule,
        SwiperModule,
        ComponentModule,
        DirectiveModule,
    ],
    declarations: [ToolboxComponent],
    providers: [ToolboxModalService, ToolboxService],
})
export class ToolBoxModule {
    constructor(private toolboxService: ToolboxService) {}
}
