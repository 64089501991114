import {Injectable} from '@angular/core';
import {TocComponent} from 'app/toc/component/toc.component';
import {
    AbstractBottomSheetService,
    BackDropClass,
} from 'app/cdk/classes/service/bottom-sheet/bottom-sheet.service';
import {ComponentType} from '@angular/cdk/overlay';

@Injectable()
export class TocService extends AbstractBottomSheetService {
    protected disableClose(): boolean {
        return true;
    }

    protected getBackDropClass(): BackDropClass {
        return BackDropClass.Blurred;
    }

    protected getComponentType(): ComponentType<TocComponent> {
        return TocComponent;
    }
}
