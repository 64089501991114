import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScrollIndicatorComponent} from 'app/component/scroll-indicator/scroll-indicator.component';
import {ToggleComponent} from 'app/component/toggle/toggle.component';
import {ServiceModule} from 'app/service/service.module';
import {ButtonComponent} from './button/button.component';
import {DirectiveModule} from 'app/directive/directive.module';
import {ProgressComponent} from './progress/progress.component';
import {ScrollComponent} from 'app/component/scroll/scroll.component';
import {StateIconComponent} from 'app/component/state-icon/state-icon.component';
import {ReadSpeakerButtonComponent} from './read-speaker-button/read-speaker-button.component';
import {ToolSettingComponent} from 'app/component/setting/tool-setting.component';
import {RangeSliderComponent} from 'app/component/setting/range-slider/range-slider.component';
import {FormsModule} from '@angular/forms';
import {CheckBoxComponent} from 'app/component/check-box/check-box.component';
import {NumbersInputComponent} from 'app/component/numbers-input/numbers-input.component';
import {RadioInputComponent} from './radio-input/radio-input.component';
import {SvgInputComponent} from './svg-input/svg-input.component';
import {CircleSelectorComponent} from './circle-selector/circle-selector.component';
import {HandleComponent} from './handle/handle.component';
import {ResourceToggleComponent} from './resource-toggle/resource-toggle.component';
import {PanelComponent} from './panel/panel.component';
import {ControlDiameterComponent} from './control-diameter/control-diameter.component';
import {ColorSelectorComponent} from './color-selector/color-selector.component';

@NgModule({
    imports: [CommonModule, ServiceModule, DirectiveModule, FormsModule],
    declarations: [
        ButtonComponent,
        ProgressComponent,
        ScrollComponent,
        ScrollIndicatorComponent,
        StateIconComponent,
        ReadSpeakerButtonComponent,
        ToggleComponent,
        ToolSettingComponent,
        RangeSliderComponent,
        CheckBoxComponent,
        NumbersInputComponent,
        RadioInputComponent,
        SvgInputComponent,
        CircleSelectorComponent,
        HandleComponent,
        ResourceToggleComponent,
        PanelComponent,
        ControlDiameterComponent,
        ColorSelectorComponent,
    ],
    exports: [
        ButtonComponent,
        ProgressComponent,
        ScrollComponent,
        ScrollIndicatorComponent,
        StateIconComponent,
        ReadSpeakerButtonComponent,
        ToggleComponent,
        ToolSettingComponent,
        RangeSliderComponent,
        CheckBoxComponent,
        NumbersInputComponent,
        RadioInputComponent,
        SvgInputComponent,
        CircleSelectorComponent,
        HandleComponent,
        ResourceToggleComponent,
        PanelComponent,
        ControlDiameterComponent,
        ColorSelectorComponent,
    ],
})
export class ComponentModule {}
