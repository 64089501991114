import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';

interface Toggle {
    order: number;
    shown: boolean;
    offset: number;
}

// Order matters
const togglesTypes = [
    'cubicConnection',
    'cubicMeter',
    'surface',
    'length',
    'cubicLitre',
    'weight',
] as const;

// Convert to Type
type Toggles = typeof togglesTypes[number];

@Component({
    selector: 'app-metric-system',
    templateUrl: './metric-system.component.html',
})
export class MetricSystemComponent extends AbstractTool {
    public static readonly id = 'metriek_stelsel';
    public static readonly width = 720;
    public static readonly height = 367;

    public showJumps = true;
    public offsetPerItem = 80;

    public toggles: Record<Toggles, Toggle> = {
        cubicMeter: {
            order: 0,
            shown: true,
            offset: 0,
        },
        cubicConnection: {
            order: 1,
            shown: true,
            offset: 0,
        },
        surface: {
            order: 2,
            shown: true,
            offset: 0,
        },
        length: {
            order: 3,
            shown: true,
            offset: 0,
        },
        cubicLitre: {
            order: 4,
            shown: true,
            offset: 0,
        },
        weight: {
            order: 5,
            shown: true,
            offset: 0,
        },
    };

    public toggleValue(toggle: Toggle): void {
        toggle.shown = !toggle.shown;
        this.setOffsets();
    }

    public setOffsets(): void {
        let offset = 0;

        togglesTypes.forEach((_, index) => {
            const current = this.toggles[togglesTypes[index]];
            if (index > 0) {
                const prev = this.toggles[togglesTypes[index - 1]];
                if (!prev.shown) {
                    offset -= this.offsetPerItem;
                }
                if (current.shown) {
                    current.offset = offset;
                }
            }
        });
    }
}
