import {IconEnum} from 'app/enum/icon.enum';
import {SvgFontAwesomeEnum, SvgIcomoonEnum} from 'app/enum/svg.enum';

export const CategoryIconMapping = new Map<string, IconEnum>([
    ['Algemeen', IconEnum.FasSolidToolbox],
    ['Rekenen', IconEnum.ImCalculate],
    ['Meten', IconEnum.FasSolidRuler],
    ['Geld', IconEnum.FasSolidMoneyBill],
    ['Tijd', IconEnum.FasSolidClock],
    ['Timer', IconEnum.FasSolidStopwatch],
    ['Taal', IconEnum.ImLanguage],
    ['Zoom in', IconEnum.FasSolidZoom],
    ['Zoom uit', IconEnum.FasSolidZoomOut],
]);

export const CategorySvgMapping = new Map<
    string,
    SvgFontAwesomeEnum | SvgIcomoonEnum
>([
    ['Algemeen', SvgFontAwesomeEnum.SolidToolbox],
    ['Rekenen', SvgIcomoonEnum.Numbers],
    ['Meten', SvgFontAwesomeEnum.SolidRuler],
    ['Geld', SvgFontAwesomeEnum.SolidMoneyBill],
    ['Tijd', SvgFontAwesomeEnum.SolidClock],
    ['Timer', SvgFontAwesomeEnum.SolidStopwatch],
    ['Taal', SvgIcomoonEnum.Language],
    ['Zoom in', SvgFontAwesomeEnum.SolidZoomIn],
    ['Zoom uit', SvgFontAwesomeEnum.SolidZoomOut],
]);
