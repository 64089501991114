import {Directive, EventEmitter, HostListener, Output} from '@angular/core';
import {TapEventInterface} from 'app/interface/event.interface';

@Directive({
    selector: '[appDoubleTappable]',
})
export class DoubleTapDirective {
    @Output()
    public readonly doubleTap: EventEmitter<TapEventInterface> =
        new EventEmitter();

    @HostListener('tap', ['$event'])
    private onTap(event: TapEventInterface): void {
        if (event.tapCount !== 2) {
            return;
        }

        this.doubleTap.emit(event);
    }
}
