import {Injectable} from '@angular/core';
import {
    ApiRequestService,
    ParamsInterface,
} from 'app/service/api-request/api-request.service';
import {
    ApiMethodCategoriesInterface,
    ApiMethodGroupInterface,
    ApiMethodsInterface,
    ApiMethodToolInterface,
} from 'app/interface/api-methods.interface';
import {NotFoundException} from 'app/exception/not-found.exception';

// TODO: THIP-5050 Update the method calls when slugs are available

@Injectable()
export class MethodService {
    constructor(private apiRequestService: ApiRequestService) {}

    public all(): Promise<ApiMethodsInterface> {
        return this.apiRequestService
            .get<ApiMethodsInterface>('/api/v2/methods')
            .toPromise();
    }

    public getGroupsByMethod(
        method: string
    ): Promise<ApiMethodGroupInterface[]> {
        const params: ParamsInterface = {
            urlParams: new Map<string, string>([['method', method]]),
        };

        return this.apiRequestService
            .get<ApiMethodGroupInterface[]>(
                '/api/v2/methods/:method/groups',
                params
            )
            .toPromise();
    }

    public getMethod(method: string): Promise<string> {
        return this.all().then((methods: ApiMethodsInterface) => {
            if (!(methods as string[]).includes(method)) {
                throw new NotFoundException(`Method "${method}" not found`);
            }

            return method;
        });
    }

    public getCategoriesByArrangement(
        arrangement: string
    ): Promise<ApiMethodCategoriesInterface> {
        const params: ParamsInterface = {
            urlParams: new Map<string, string>([['arrangement', arrangement]]),
        };

        return this.apiRequestService
            .get<ApiMethodCategoriesInterface>(
                '/api/v2/tool/:arrangement/categories',
                params
            )
            .toPromise();
    }

    public getToolsForCategory(
        arrangement: string,
        category: string
    ): Promise<ApiMethodToolInterface[]> {
        const params: ParamsInterface = {
            urlParams: new Map<string, string>([
                ['arrangement', arrangement],
                ['category', category],
            ]),
        };

        return this.apiRequestService
            .get<ApiMethodToolInterface[]>(
                '/api/v2/tool/:arrangement/category/:category',
                params
            )
            .toPromise();
    }

    public getGroupByMethodAndCode(
        method: string,
        code: string
    ): Promise<ApiMethodGroupInterface> {
        return this.getGroupsByMethod(method).then(
            (apiGroups: ApiMethodGroupInterface[]) => {
                const apiGroup: ApiMethodGroupInterface | undefined = apiGroups
                    .filter(apiGroupsGroup => apiGroupsGroup.code === code)
                    .shift();

                if (undefined === apiGroup) {
                    throw new Error(
                        `Group not found for method "${method}" with code "${code}"`
                    );
                }

                return apiGroup;
            }
        );
    }
}
