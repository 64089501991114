import {
    AfterContentInit,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    Input,
    QueryList,
} from '@angular/core';
import {BbvmsAudioComponent} from 'app/content/screen/components/bbvms/bbvms-audio/bbvms-audio.component';
import {ImageDirective} from 'app/content/screen/directive/image/image.directive';
import {AssignmentGapMatchOptionComponent} from 'app/content/screen/components/assignment-gap-match/assignment-gap-match-option/assignment-gap-match-option.component';

enum AssignmentGapMatchRowType {
    Audio = 'audio',
    Image = 'image',
    Text = 'text',
}

@Component({
    selector: 'assignment-gap-match-row',
    templateUrl: './assignment-gap-match-row.component.html',
    styleUrls: ['./assignment-gap-match-row.component.scss'],
})
export class AssignmentGapMatchRowComponent implements AfterContentInit {
    @ContentChild(BbvmsAudioComponent)
    public readonly bbvmsAudioComponent?: BbvmsAudioComponent;

    @ContentChild(ImageDirective)
    public readonly imageDirective?: ImageDirective;

    @ContentChildren(AssignmentGapMatchOptionComponent)
    protected optionComponents!: QueryList<AssignmentGapMatchOptionComponent>;

    @Input('type')
    public readonly type?: string;

    public audioPlaying = false;
    public centerContent = false;
    public readonly rowTypes = AssignmentGapMatchRowType;

    public constructor(private elementRef: ElementRef) {}

    public handleClick(): void {
        if (undefined === this.bbvmsAudioComponent) {
            return;
        }

        this.audioPlaying = true;
        this.bbvmsAudioComponent
            .play()
            .finally(() => (this.audioPlaying = false));
    }

    public ngAfterContentInit(): void {
        if (undefined !== this.type) {
            this.addTypeToOptionComponentClasses(this.type);
        }
    }

    protected addTypeToOptionComponentClasses(className: string): void {
        this.optionComponents.forEach(component =>
            component.addClass(className)
        );
    }

    protected getClassList(): DOMTokenList {
        return this.elementRef.nativeElement.classList;
    }
}
