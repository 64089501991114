import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    NgZone,
    ViewChild,
} from '@angular/core';
import {AbstractBottomSheetComponent} from 'app/cdk/classes/component/bottom-sheet/bottom-sheet.component';
import {
    GoogleAnalyticsActionsEnum,
    GoogleAnalyticsCategoriesEnum,
} from 'app/service/google-analytics/categories.enum';
import {GoogleAnalyticsService} from 'app/service/google-analytics/google-analytics.service';
import {ActiveToolService} from 'app/tool-layer/service/active/active-tool.service';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {
    ToolboxItemInterface,
    ToolboxService,
} from 'app/toolbox/service/toolbox/toolbox.service';
import {ScrollComponent} from 'app/component/scroll/scroll.component';

@Component({
    selector: 'app-toolbox',
    templateUrl: './toolbox.component.html',
    styleUrls: ['./toolbox.component.scss'],
})
export class ToolboxComponent
    extends AbstractBottomSheetComponent
    implements AfterViewInit
{
    //
    public swiperOptions: SwiperConfigInterface = {};
    public items: ToolboxItemInterface[] = [];
    public activeCategory?: ToolboxItemInterface;

    @ViewChild('scroll')
    public scrollComponent?: ScrollComponent;

    constructor(
        private toolboxService: ToolboxService,
        private activeToolService: ActiveToolService,
        private gaService: GoogleAnalyticsService,
        private changeDetectorRef: ChangeDetectorRef,
        private zone: NgZone
    ) {
        super();
    }

    public ngAfterViewInit(): void {
        if (!this.activeCategory) {
            this.handleShowCategories();
        }

        this.gaService.event(
            GoogleAnalyticsActionsEnum.ToolBoxShow,
            GoogleAnalyticsCategoriesEnum.Toolbox
        );
    }

    public async handleItemClick(item: ToolboxItemInterface): Promise<void> {
        if (item.type) {
            this.openToolType(item.type, item.value, item.id);

            return;
        }

        await this.loadItemCategory(item);
    }

    public handleShowCategories(): void {
        this.activeCategory = undefined;
        this.updateItemsList(this.toolboxService.getCategories());
    }

    public showCategoryAfterOpen(category: string): void {
        for (const categoryItem of this.toolboxService.getCategories()) {
            if (categoryItem.value === category) {
                this.loadItemCategory(categoryItem);

                return;
            }
        }
    }

    private async loadItemCategory(item: ToolboxItemInterface): Promise<void> {
        if (!item.value) {
            return Promise.reject();
        }

        this.activeCategory = item;
        this.gaService.event(
            GoogleAnalyticsActionsEnum.ToolBoxCategory,
            GoogleAnalyticsCategoriesEnum.Toolbox,
            item.value
        );

        const items = await this.toolboxService.getToolsForCategory(item.value);
        this.updateItemsList(items);
    }

    private updateItemsList(items: ToolboxItemInterface[]): void {
        this.items = items;
        this.changeDetectorRef.detectChanges();

        if (this.scrollComponent) {
            this.scrollComponent.update();
        }
    }

    private openToolType(
        type: string,
        name: string,
        id: number | undefined
    ): void {
        try {
            this.activeToolService.openTool({
                toolId: type,
                toolName: name,
                toolConfig: {
                    category: this.activeCategory
                        ? this.activeCategory.value
                        : undefined,
                    data: {
                        id,
                    },
                },
            });

            this.zone.run(() => {
                this.dismiss();
            });
        } catch (e) {
            console.log('Could not open tool', e);
        }
    }
}
