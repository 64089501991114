export enum DrawingStateEnum {
    Disabled = 'Disabled',

    Calculator = 'Calculator',
    Keyboard = 'Keyboard',

    Pen = 'Pen',

    Highlighter = 'Highlighter',

    PdfMagicWand = 'PdfMagicWand',
    PdfShowAnswer = 'PdfShowAnswer',
    Reset = 'Reset',
    Cut = 'Cut',

    Eraser = 'Eraser',
}
