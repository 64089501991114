export enum SvgFontAwesomeEnum {
    SolidArrowLeft = '/assets/svg/font-awesome/solid/arrow-left.svg',
    SolidArrowAltLeft = '/assets/svg/font-awesome/solid/arrow-alt-left.svg',
    SolidArrowAltRight = '/assets/svg/font-awesome/solid/arrow-alt-right.svg',
    SolidArrowsAlt = '/assets/svg/font-awesome/solid/arrows-alt.svg',
    SolidCalculator = '/assets/svg/font-awesome/solid/calculator.svg',
    SolidCheck = '/assets/svg/font-awesome/solid/check.svg',
    SolidEdit = '/assets/svg/font-awesome/solid/edit.svg',
    SolidEllipsisVertical = '/assets/svg/font-awesome/solid/ellipsis-v.svg',
    SolidEllipsisHorizontal = '/assets/svg/font-awesome/solid/ellipsis-h.svg',
    SolidEye = '/assets/svg/font-awesome/solid/eye.svg',
    SolidFlushed = '/assets/svg/font-awesome/solid/flushed.svg',
    SolidHighLighter = '/assets/svg/font-awesome/solid/highlighter.svg',
    SolidKeyboard = '/assets/svg/font-awesome/solid/keyboard.svg',
    SolidList = '/assets/svg/font-awesome/solid/list.svg',
    SolidMagicWand = '/assets/svg/font-awesome/solid/magic.svg',
    SolidPenAlt = '/assets/svg/font-awesome/solid/pen-alt.svg',
    SolidRedoAlt = '/assets/svg/font-awesome/solid/redo-alt.svg',
    SolidTimes = '/assets/svg/font-awesome/solid/times.svg',
    SolidToolbox = '/assets/svg/font-awesome/solid/toolbox.svg',
    SolidUndoAlt = '/assets/svg/font-awesome/solid/undo-alt.svg',
    SolidZoomIn = '/assets/svg/font-awesome/solid/search-plus.svg',
    SolidZoomOut = '/assets/svg/font-awesome/solid/search-minus.svg',
    SolidMinimize = '/assets/svg/font-awesome/solid/compress-arrows.svg',
    SolidClock = '/assets/svg/font-awesome/solid/clock.svg',
    SolidRuler = '/assets/svg/font-awesome/solid/ruler.svg',
    SolidMoneyBill = '/assets/svg/font-awesome/solid/money-bill.svg',
    SolidStopwatch = '/assets/svg/font-awesome/solid/stopwatch.svg',
    SolidLanguage = '/assets/svg/font-awesome/solid/language.svg',
    SolidCut = '/assets/svg/font-awesome/solid/cut.svg',
    SolidEraser = '/assets/svg/font-awesome/solid/eraser-solid.svg',
}

export enum SvgElementsEnum {
    Label = '/assets/svg/elements/label.svg',
    MoneyEuro1 = '/assets/svg/elements/AT_geld_1 euro.svg',
    MoneyEuro2 = '/assets/svg/elements/AT_geld_2 euro.svg',
    MoneyEuro5 = '/assets/svg/elements/AT_geld_5.svg',
    MoneyEuro5ct = '/assets/svg/elements/AT_geld_5 euro ct.svg',
    MoneyEuro10 = '/assets/svg/elements/AT_geld_10.svg',
    MoneyEuro10ct = '/assets/svg/elements/AT_geld_10 euro ct.svg',
    MoneyEuro20 = '/assets/svg/elements/AT_geld_20.svg',
    MoneyEuro20ct = '/assets/svg/elements/AT_geld_20 euro ct.svg',
    MoneyEuro50 = '/assets/svg/elements/AT_geld_50.svg',
    MoneyEuro50ct = '/assets/svg/elements/AT_geld_50 euro ct.svg',
    MoneyEuro100 = '/assets/svg/elements/AT_geld_100.svg',
    MoneyEuro200 = '/assets/svg/elements/AT_geld_200.svg',
    MoneyEuro500 = '/assets/svg/elements/AT_geld_500.svg',
    CupPink = '/assets/svg/elements/AT_beker.svg',
    FicheBlue = '/assets/svg/elements/AT_Fiche_blauw.svg',
    FicheYellow = '/assets/svg/elements/AT_Fiche_geel.svg',
    FicheGreen = '/assets/svg/elements/AT_Fiche_groen.svg',
    FicheMagenta = '/assets/svg/elements/AT_Fiche_magenta.svg',
    FichePurple = '/assets/svg/elements/AT_Fiche_paars.svg',
    FicheRed = '/assets/svg/elements/AT_Fiche_rood.svg',
    Dice1 = '/assets/svg/elements/AT_dobbelsteen_2D_1.svg',
    Dice2 = '/assets/svg/elements/AT_dobbelsteen_2D_2.svg',
    Dice3 = '/assets/svg/elements/AT_dobbelsteen_2D_3.svg',
    Dice4 = '/assets/svg/elements/AT_dobbelsteen_2D_4.svg',
    Dice5 = '/assets/svg/elements/AT_dobbelsteen_2D_5.svg',
    Dice6 = '/assets/svg/elements/AT_dobbelsteen_2D_6.svg',
    CountingHand0R = '/assets/svg/elements/AT_Handen_0R.svg',
    CountingHand1R = '/assets/svg/elements/AT_Handen_1R.svg',
    CountingHand2R = '/assets/svg/elements/AT_Handen_2R.svg',
    CountingHand3R = '/assets/svg/elements/AT_Handen_3R.svg',
    CountingHand4R = '/assets/svg/elements/AT_Handen_4R.svg',
    CountingHand5R = '/assets/svg/elements/AT_Handen_5R.svg',
}

export enum SvgIcomoonEnum {
    Numbers = '/assets/svg/icomoon/numbers.svg',
    Language = '/assets/svg/icomoon/language.svg',
}
