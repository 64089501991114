import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {
    DEFAULT_COLOR_OPTION,
    DEFAULT_HIGHLIGHTER_COLOR_OPTION,
    DEFAULT_DIAMETER_OPTION,
    DEFAULT_ERASER_DIAMETER_OPTION,
    DEFAULT_HIGHLIGHTER_DIAMETER_OPTION,
} from 'app/drawing/consts';
import {Color} from 'app/drawing/interface/color.interface';

@Injectable({
    providedIn: 'root',
})
export class DrawingRootService {
    lineWidthSubject = new BehaviorSubject<number>(DEFAULT_DIAMETER_OPTION);
    lineColorSubject = new BehaviorSubject<Color>(DEFAULT_COLOR_OPTION);
    lineWidth$ = this.lineWidthSubject.asObservable();
    lineColor$ = this.lineColorSubject.asObservable();

    highlighterLineWidthSubject = new BehaviorSubject<number>(
        DEFAULT_HIGHLIGHTER_DIAMETER_OPTION
    );
    highlighterLineColorSubject = new BehaviorSubject<Color>(
        DEFAULT_HIGHLIGHTER_COLOR_OPTION
    );
    highlighterLineWidth$ = this.highlighterLineWidthSubject.asObservable();
    highlighterLineColor$ = this.highlighterLineColorSubject.asObservable();

    eraserLineWidthSubject = new BehaviorSubject<number>(
        DEFAULT_ERASER_DIAMETER_OPTION
    );
    eraserLineWidth$ = this.eraserLineWidthSubject.asObservable();

    public setLineWidth(lineWidth: number) {
        this.lineWidthSubject.next(lineWidth);
    }

    public setLineColor(lineColor: Color) {
        this.lineColorSubject.next(lineColor);
    }

    public setHighlighterLineWidth(lineWidth: number) {
        this.highlighterLineWidthSubject.next(lineWidth);
    }

    public setHighlighterLineColor(lineColor: Color) {
        this.highlighterLineColorSubject.next(lineColor);
    }

    public setEraserLineWidth(lineWidth: number) {
        this.eraserLineWidthSubject.next(lineWidth);
    }
}
