import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {ComponentModule} from 'app/component/component.module';
import {CountingHandsComponent} from 'app/tool-layer/tools/counting-hands/counting-hands.component';

@NgModule({
    imports: [CommonModule, ComponentModule],
    declarations: [CountingHandsComponent],
    entryComponents: [CountingHandsComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(CountingHandsComponent),
            multi: true,
        },
    ],
})
export class CountingHandsModule {}
