import {Component, DoCheck, ViewChild} from '@angular/core';
import {OriginalContentDirective} from 'app/content/screen/directive/original-content/original-content.directive';

@Component({
    selector: '[tableCellTranslation]',
    templateUrl: './translation-table-cell.component.html',
    styleUrls: ['./translation-table-cell.component.scss'],
})
export class TranslationTableCellComponent implements DoCheck {
    @ViewChild(OriginalContentDirective)
    public originalContentDirective!: OriginalContentDirective;

    public isLoading = true;
    public paragraphParts: string[] = [];

    public ngDoCheck(): void {
        if (this.isLoading && undefined !== this.originalContentDirective) {
            this.isLoading = false;
            this.load();
        }
    }

    private load(): void {
        const content: HTMLCollection =
            this.originalContentDirective.getContent();
        const element: HTMLElement | undefined = content[0] as HTMLElement;

        if (
            !(element instanceof HTMLParagraphElement) ||
            !element.innerHTML.includes('=')
        ) {
            this.paragraphParts.push(element.innerText);

            return;
        }

        this.paragraphParts.push(...element.innerText.split('='));
    }
}
