import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {ComponentModule} from 'app/component/component.module';
import {ChipsComponent} from 'app/tool-layer/tools/chips/chips.component';

@NgModule({
    imports: [CommonModule, ComponentModule],
    declarations: [ChipsComponent],
    entryComponents: [ChipsComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(ChipsComponent),
            multi: true,
        },
    ],
})
export class ChipsModule {}
