import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';
import {SvgOptionInterface} from 'app/component/svg-input/svg-input.component';
import {SvgElementsEnum} from 'app/enum/svg.enum';

@Component({
    selector: 'app-chips',
    templateUrl: './chips.component.html',
})
export class ChipsComponent extends AbstractTool {
    public static readonly id = 'fiches';
    public static readonly width = 0;
    public static readonly height = 0;
    public static readonly settingsInstanceOnly = true;

    public svgList!: SvgOptionInterface[];

    constructor() {
        super();

        this.svgList = [
            SvgElementsEnum.FicheBlue,
            SvgElementsEnum.FicheYellow,
            SvgElementsEnum.FicheGreen,
            SvgElementsEnum.FicheMagenta,
            SvgElementsEnum.FichePurple,
            SvgElementsEnum.FicheRed,
        ].map((path: SvgElementsEnum) => {
            return {path};
        });

        this.svgList.push({
            path: SvgElementsEnum.CupPink,
            zIndex: 2,
        });
    }
}
