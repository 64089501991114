import {
    AfterViewInit,
    Directive,
    ElementRef,
    Input,
    Renderer2,
} from '@angular/core';

interface ElementDimensions {
    width: number;
    height: number;
}

@Directive({
    selector: '[appFitToParent]',
})
export class FitToParentDirective implements AfterViewInit {
    @Input()
    private readonly fitToElement?: HTMLElement;

    @Input()
    private autoFit = true;

    private readonly element: HTMLElement;

    private scaleValue = 1;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        this.element = this.elementRef.nativeElement;
        if (undefined === this.fitToElement) {
            this.fitToElement = elementRef.nativeElement.parentElement;
        }
    }

    public ngAfterViewInit(): void {
        if (!this.autoFit) {
            return;
        }

        this.scale();
    }

    public scale(): void {
        const parentDimensions: ElementDimensions =
            this.getFitToElementDimensions();
        const elementDimensions: ElementDimensions =
            this.getElementDimensions();

        if (
            parentDimensions.width >= elementDimensions.width &&
            parentDimensions.height >= elementDimensions.height
        ) {
            return;
        }

        const scale: number = Math.min(
            parentDimensions.width / elementDimensions.width,
            parentDimensions.height / elementDimensions.height
        );

        this.scaleValue = scale;
        this.setTransform(scale);
    }

    public getScale(): number {
        return this.scaleValue;
    }

    private getElementDimensions(): ElementDimensions {
        return {
            width: this.element.offsetWidth,
            height: this.element.offsetHeight,
        };
    }

    private getFitToElementDimensions(): ElementDimensions {
        const fitToElement: HTMLElement = this.getFitToElement();

        return {
            width: fitToElement.offsetWidth,
            height: fitToElement.offsetHeight,
        };
    }

    private getFitToElement(): HTMLElement {
        const fitToElement: HTMLElement | undefined = this.fitToElement;

        if (undefined === fitToElement) {
            throw new Error('Element has no parent element');
        }

        return fitToElement;
    }

    private setTransform(scale: number): void {
        this.renderer.setStyle(
            this.element,
            'transform',
            `scale(${scale}) translateZ(0)`
        );
    }
}
