import {Injectable} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {Resource} from 'app/service/resource/classes/resource.class';

@Injectable({
    providedIn: 'root',
})
export class NavigationResourceContainer {
    private readonly itemsSubject: Subject<Resource[]> = new Subject();
    private resources: Resource[] = [];

    public subscribe(fn: (resources: Resource[]) => void): Subscription {
        return this.itemsSubject.subscribe(fn);
    }

    public add(resource: Resource): void {
        if (this.resources.includes(resource)) {
            return;
        }

        this.resources.push(resource);
        this.itemsSubject.next(this.resources);
    }

    public removeAfter(resource: Resource): void {
        const itemIndex: number = this.indexOf(resource);

        if (-1 === itemIndex) {
            throw new Error('Item not found');
        }

        this.set(this.resources.slice(0, itemIndex));
    }

    public indexOf(resource: Resource): number {
        return this.resources.indexOf(resource);
    }

    public last(): Resource | undefined {
        return this.resources[this.resources.length - 1];
    }

    public all(): Resource[] {
        return this.resources;
    }

    public clear(): void {
        this.set([]);
    }

    public set(resources: Resource[]): void {
        this.resources = resources;
        this.itemsSubject.next(this.resources);
    }
}
