import {
    Component,
    ComponentFactoryResolver,
    Injector,
    OnDestroy,
    OnInit,
    ViewContainerRef,
} from '@angular/core';
import {
    ActiveChangeEvent,
    ChangeTypeEnum,
} from 'app/tool-layer/class/active-change-event';
import {ToolInstance} from 'app/tool-layer/class/tool-instance';
import {ActiveToolService} from 'app/tool-layer/service/active/active-tool.service';
import {RingIds} from 'app/tool/rings/ring-ids.enum';
import {ToolService} from 'app/tool/service/tool.service';
import {ToolboxModalService} from 'app/toolbox/service/modal/modal.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-tool-layer',
    templateUrl: './tool-layer.component.html',
    styleUrls: ['./tool-layer.component.scss'],
})
export class ToolLayerComponent implements OnInit, OnDestroy {
    public activeTool?: ToolInstance;
    public tools = new Map<string, Array<ToolInstance>>();
    public transparencyEnabled = false;

    private toolsBackup?: Map<string, Array<ToolInstance>>;
    private subscriptions: Subscription[] = [];

    public constructor(
        private activeToolService: ActiveToolService,
        private injector: Injector,
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private toolboxModalService: ToolboxModalService,
        private toolService: ToolService
    ) {}

    public ngOnInit(): void {
        this.updateTransparencyState();

        this.subscriptions.push(
            this.activeToolService.subscribe(tool =>
                this.handleToolServiceEvent(tool)
            )
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    private handleToolServiceEvent(event: ActiveChangeEvent): void {
        switch (event.change) {
            case ChangeTypeEnum.AddTool:
                this.addNewTool(event.tool as ToolInstance);
                break;

            case ChangeTypeEnum.DeleteTool:
                this.deleteTool(event.tool as ToolInstance);
                break;

            case ChangeTypeEnum.DeleteType:
                this.deleteToolType(event.type as string);
                break;

            case ChangeTypeEnum.Transparency:
                this.updateTransparencyState();
                break;

            case ChangeTypeEnum.ActivateTool:
                this.activateTool(event.tool as ToolInstance);
                break;

            case ChangeTypeEnum.CloseAll:
                this.clearToolLayer();
                break;
        }
    }

    private clearToolLayer(): void {
        this.tools.clear();
        this.activeTool = undefined;
    }

    private addNewTool(tool: ToolInstance): void {
        this.restoreToolsBackupIfPossible();

        this.prepareToolForBinding(tool);
        this.toolService.activateRing(RingIds.EditRing);

        let toolList = this.tools.get(tool.type);
        if (undefined === toolList) {
            toolList = [];
        }

        toolList.push(tool);
        this.tools.set(tool.type, toolList);

        this.activeTool =
            tool.config && tool.config.parent ? tool.config.parent : tool;
    }

    private activateTool(tool: ToolInstance): void {
        this.restoreToolsBackupIfPossible();

        if (this.activeTool === tool) {
            return;
        }

        this.activeTool = tool;
    }

    private deleteTool(tool: ToolInstance): void {
        const values = this.tools.get(tool.type);
        if (!values) {
            return;
        }

        const index = values.findIndex(instance => {
            return instance === tool;
        });

        values.splice(index, 1);
        if (values.length === 0) {
            this.tools.delete(tool.type);
        }

        if (!this.activeTool || this.activeTool === tool) {
            this.activeTool = this.findActiveToolReplacement();
        }

        this.handleNoActiveTools();
    }

    private deleteToolType(toolType: string): void {
        const hasToolsOfType = this.tools.get(toolType);
        if (!hasToolsOfType) {
            return;
        }

        this.tools.delete(toolType);

        this.activeTool = this.findActiveToolReplacement();
        this.handleNoActiveTools();
    }

    private updateTransparencyState(): void {
        this.transparencyEnabled =
            this.activeToolService.hasTransparencyEnabled();
    }

    private restoreToolsBackupIfPossible(): void {
        if (!this.toolsBackup) {
            return;
        }

        this.tools = this.toolsBackup;
        this.toolsBackup = undefined;
    }

    private prepareToolForBinding(tool: ToolInstance): void {
        const type = this.componentFactoryResolver.resolveComponentFactory(
            tool.tool.component
        );

        const instance = type.create(this.injector);
        instance.changeDetectorRef.detectChanges();

        tool.prepare(instance, this.viewContainerRef);
    }

    private findActiveToolReplacement(): ToolInstance | undefined {
        if (this.tools.size === 0) {
            return undefined;
        }
        const keysArray = Array.from(this.tools.keys());
        const values = this.tools.get(keysArray[0]);

        return values ? values[0] : undefined;
    }

    private handleNoActiveTools(): void {
        if (this.tools.size > 0) {
            return;
        }

        this.toolService.activateRing(RingIds.NavigationRing);

        this.toolboxModalService.open(
            this.activeTool ? this.activeTool.getToolCategory() : undefined
        );
    }
}
