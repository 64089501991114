import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {AudioOptionMapping} from 'app/tool-layer/tools/select-audio-option/mapping/audio-option.mapping';
import {BbvmsAudioComponent} from 'app/content/screen/components/bbvms/bbvms-audio/bbvms-audio.component';

@Component({
    selector: 'app-select-audio-option',
    templateUrl: './select-audio-option.component.html',
    styleUrls: ['./select-audio-option.component.scss'],
})
export class SelectAudioOptionComponent {
    public readonly options = AudioOptionMapping;

    @ViewChild(BbvmsAudioComponent)
    public bbvmsAudioComponent!: BbvmsAudioComponent;

    @Input()
    public value?: number;
    @Output()
    public readonly valueChange = new EventEmitter<number>();

    public valueChanged(): void {
        this.valueChange.emit(this.value);
    }

    public playAudio(): void {
        if (!this.value) {
            return;
        }

        this.bbvmsAudioComponent.play();
    }
}
