import {AfterViewInit, Component, Input, OnDestroy} from '@angular/core';
import {ThasMediaEvent} from '@thiememeulenhoff/analytics-service-client';
import {AnalyticsService} from 'app/service/analytics/analytics.service';
import {
    GoogleAnalyticsActionsEnum,
    GoogleAnalyticsCategoriesEnum,
} from 'app/service/google-analytics/categories.enum';
import {GoogleAnalyticsService} from 'app/service/google-analytics/google-analytics.service';
import {
    ReadSpeakerEvent,
    ReadSpeakerEventsEnum,
    ReadSpeakerService,
} from 'app/service/read-speaker/read-speaker.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'read-speaker-button',
    templateUrl: 'read-speaker-button.component.html',
    styleUrls: ['./read-speaker-button.component.scss'],
})
export class ReadSpeakerButtonComponent implements AfterViewInit, OnDestroy {
    private readonly subscription?: Subscription;

    @Input()
    public readId!: string;

    public playing = false;

    public constructor(
        private readSpeakerService: ReadSpeakerService,
        private googleAnalytics: GoogleAnalyticsService,
        private analyticsService: AnalyticsService
    ) {
        this.subscription = this.readSpeakerService.subscribeToEvents(event =>
            this.handleReadSpeakerServiceEvent(event)
        );
    }

    public ngAfterViewInit(): void {
        this.readSpeakerService.setupService();
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public startPlaying(): void {
        this.readSpeakerService.stopPlaying();
        this.readSpeakerService.startedPlaying(this.readId);

        this.googleAnalytics.event(
            GoogleAnalyticsActionsEnum.ReadSpeakerStart,
            GoogleAnalyticsCategoriesEnum.ReadSpeaker,
            this.readId
        );

        const thasEvent = new ThasMediaEvent('readspeaker', 'started');
        this.analyticsService.send(thasEvent);

        this.playing = true;
    }

    public stopPlaying(): void {
        this.readSpeakerService.stoppedPlaying(this.readId);

        this.googleAnalytics.event(
            GoogleAnalyticsActionsEnum.ReadSpeakerStop,
            GoogleAnalyticsCategoriesEnum.ReadSpeaker,
            this.readId
        );

        const thasEvent = new ThasMediaEvent('readspeaker', 'ended');
        this.analyticsService.send(thasEvent);
    }

    private handleReadSpeakerServiceEvent(event: ReadSpeakerEvent): void {
        if (
            event.status === ReadSpeakerEventsEnum.StoppedPlaying &&
            (event.readId === undefined || this.readId === event.readId)
        ) {
            this.readSpeakerService.stopPlaying();
            this.playing = false;
        }

        if (
            event.status === ReadSpeakerEventsEnum.StartedPlaying &&
            this.readId !== event.readId
        ) {
            this.playing = false;
        }
    }
}
