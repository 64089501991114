import {CdkDragEnd, CdkDragMove} from '@angular/cdk/drag-drop';
import {
    Component,
    DoCheck,
    EventEmitter,
    Input,
    IterableDiffer,
    IterableDiffers,
    Output,
} from '@angular/core';
import {Point} from 'app/classes/point.class';
import {FLOATING_SVG_REMOVE_CLASSNAME} from 'app/component/svg-input/svg-input.component';
import {SvgFontAwesomeEnum} from 'app/enum/svg.enum';
import {ToolInstance} from 'app/tool-layer/class/tool-instance';
import {ActiveToolService} from 'app/tool-layer/service/active/active-tool.service';
import {DragDropUtil} from 'app/util/drag-drop/drag-drop.util';
import {DrawingService} from 'app/drawing/service/drawing/drawing.service';
import {DrawingStateEnum} from 'app/drawing/enum/state.enum';

@Component({
    selector: 'app-tool-instances',
    templateUrl: './tool-instances.component.html',
    styleUrls: ['./tool-instances.component.scss'],
})
export class ToolInstancesComponent implements DoCheck {
    public readonly fa = SvgFontAwesomeEnum;

    @Input()
    public tools!: Map<string, Array<ToolInstance>>;

    @Input()
    public activeTool!: ToolInstance;
    @Output()
    public activeToolChange = new EventEmitter<ToolInstance>();

    public floatingIndex = true;

    public toolsList: Array<ToolInstance> = [];
    public toolBeingDragged?: ToolInstance;
    public removeOnDrop = false;

    private toolsKeysDiffer!: IterableDiffer<string>;
    private toolsValuesDiffers = new Map<
        string,
        IterableDiffer<ToolInstance>
    >();

    public constructor(
        private activeToolService: ActiveToolService,
        private dragDropUtil: DragDropUtil,
        private iterableDiffers: IterableDiffers,
        private drawingService: DrawingService
    ) {
        this.toolsKeysDiffer = this.iterableDiffers.find([]).create();
    }

    public ngDoCheck(): void {
        if (this.checkAndHandleToolsKeysDiff()) {
            return;
        }

        if (this.checkAndHandleToolsValuesDiff()) {
            return;
        }

        const state = this.drawingService.state;
        this.floatingIndex = state !== DrawingStateEnum.Disabled;
    }

    public handleToolClick(tool: ToolInstance): void {
        if (
            tool.config &&
            tool.config.parent &&
            tool.config.parent.name !== 'geld'
        ) {
            tool = tool.config.parent;
        }

        if (this.activeTool === tool) {
            return;
        }

        this.activeTool = tool;
        if (
            tool.config &&
            tool.config.parent &&
            tool.config.parent.name === 'geld'
        ) {
            this.moveActiveToolToForeGround();
            return;
        }

        this.activeToolChange.emit(this.activeTool);
        this.moveActiveToolToForeGround();
    }

    public handleCloseTool(tool: ToolInstance): void {
        this.activeToolService.deleteTool(tool);
    }

    public onDragMoved(tool: ToolInstance, event: CdkDragMove): void {
        if (!tool.config || (tool.config && !tool.config.parent)) {
            return;
        }
        if (this.toolBeingDragged !== tool) {
            this.toolBeingDragged = tool;
        }
        const {x, y} = event.pointerPosition;
        const elements = document.elementsFromPoint(x, y);
        this.removeOnDrop = !!elements.find((e: Element) =>
            e.classList.contains(FLOATING_SVG_REMOVE_CLASSNAME)
        );
    }

    public onDragEnded(tool: ToolInstance, event: CdkDragEnd): void {
        if (this.removeOnDrop) {
            this.activeToolService.deleteTool(tool);
            this.toolBeingDragged = undefined;
            this.removeOnDrop = false;
        } else {
            this.updateToolPosition(tool, event);
        }
    }

    public updateToolPosition(tool: ToolInstance, event: CdkDragEnd): void {
        let positionNew = new Point(
            tool.position.x + event.distance.x,
            tool.position.y + event.distance.y
        );

        positionNew = this.dragDropUtil.forDragEndEvent(positionNew, event);

        tool.rememberPosition(positionNew);
    }

    private checkAndHandleToolsKeysDiff(): boolean {
        const changesKeys = this.toolsKeysDiffer.diff(this.tools.keys());
        if (changesKeys) {
            this.updateToolsListArray();

            return true;
        }

        return false;
    }

    private checkAndHandleToolsValuesDiff(): boolean {
        for (const key of this.tools.keys()) {
            let differ = this.toolsValuesDiffers.get(key);
            if (!differ) {
                differ = this.iterableDiffers.find([]).create();
                this.toolsValuesDiffers.set(key, differ);
            }

            if (differ.diff(this.tools.get(key))) {
                this.updateToolsListArray();

                return true;
            }
        }

        return false;
    }

    private updateToolsListArray(): void {
        const mountedTools = this.getFlattenedToolsList();
        const notActiveTools = this.getNotActivatedTools(mountedTools);

        this.toolsList.push(...notActiveTools);

        const obsoleteTools = this.getObsoleteTools(mountedTools);
        for (const obsoleteTool of obsoleteTools) {
            this.toolsList.splice(this.toolsList.indexOf(obsoleteTool), 1);
        }

        this.moveActiveToolToForeGround();
    }

    private getFlattenedToolsList(): Array<ToolInstance> {
        const activeTools: Array<ToolInstance> = [];

        for (const values of this.tools.values()) {
            activeTools.push(...values);
        }

        return activeTools;
    }

    private getNotActivatedTools(
        checkList: Array<ToolInstance>
    ): Array<ToolInstance> {
        const notActiveTools: Array<ToolInstance> = [];
        notActiveTools.push(
            ...checkList.filter(tool => !this.toolsList.includes(tool))
        );

        return notActiveTools;
    }

    private getObsoleteTools(
        checkList: Array<ToolInstance>
    ): Array<ToolInstance> {
        const obsoleteTools: Array<ToolInstance> = [];

        for (const tool of this.toolsList) {
            if (!checkList.includes(tool)) {
                obsoleteTools.push(tool);
            }
        }

        return obsoleteTools;
    }

    private moveActiveToolToForeGround(): void {
        const tool = this.activeTool;

        const index = this.toolsList.indexOf(tool);
        if (index < 0 || this.toolsList.length === index - 1) {
            return;
        }

        this.toolsList.splice(this.toolsList.indexOf(tool), 1);
        this.toolsList.push(tool);
    }
}
