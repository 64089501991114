import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {AlertDialogComponent} from './alert-dialog/alert-dialog.component';
import {InputDialogComponent} from './input-dialog/input-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        ConfirmDialogComponent,
        AlertDialogComponent,
        InputDialogComponent,
    ],
    entryComponents: [
        ConfirmDialogComponent,
        AlertDialogComponent,
        InputDialogComponent,
    ],
    imports: [CommonModule, MatDialogModule, FormsModule],
})
export class DialogModule {}
