import {NgModule} from '@angular/core';
import {AssignmentMultipleSelectGroupComponent} from 'app/content/screen/components/assignment-multiple-select/assignment-multiple-select-options/assignment-multiple-select-group.component';
import {AssignmentMultipleSelectComponent} from 'app/content/screen/components/assignment-multiple-select/assignment-multiple-select.component';
import {AssignmentSelectOptionComponent} from 'app/content/screen/components/assignment-select-option/assignment-select-option.component';
import {AssignmentSingleSelectComponent} from 'app/content/screen/components/assignment-single-select/assignment-single-select.component';
import {BbvmsAudioComponent} from 'app/content/screen/components/bbvms/bbvms-audio/bbvms-audio.component';
import {BbvmsVideoComponent} from 'app/content/screen/components/bbvms/bbvms-video/bbvms-video.component';
import {EquationComponent} from 'app/content/screen/components/equation/equation.component';
import {ContentInteractionContainerComponent} from 'app/content/screen/components/interaction-container/interaction-container.component';
import {PdfDownloadButtonComponent} from 'app/content/screen/components/pdf-download-button/pdf-download-button.component';
import {PdfAssetComponent} from 'app/content/screen/components/pdf-asset/pdf-asset.component';
import {ScreenContentWrapperComponent} from 'app/content/screen/components/screen-content-wrapper/screen-content-wrapper.component';
import {ScreenDrawingComponent} from 'app/content/screen/components/screen-drawing/screen-drawing.component';
import {TranslationTableCellComponent} from 'app/content/screen/components/translation-table-cell/translation-table-cell.component';
import {WordsEntryComponent} from 'app/content/screen/components/words/words-entry/words-entry.component';
import {WordsComponent} from 'app/content/screen/components/words/words.component';
import {ImageComponent} from 'app/content/screen/components/image/image.component';
import {ContentDirectiveModule} from 'app/content/screen/directive/directive.module';
import {CommonModule} from '@angular/common';
import {AssignmentHottextComponent} from 'app/content/screen/components/assignment-hottext/assignment-hottext.component';
import {AssignmentHottextOptionComponent} from 'app/content/screen/components/assignment-hottext/assignment-hottext-option/assignment-hottext-option.component';
import {DialogComponent} from 'app/content/screen/components/dialog/dialog.component';
import {DialogEntryComponent} from 'app/content/screen/components/dialog/dialog-entry/dialog-entry.component';
import {KaraokeComponent} from 'app/content/screen/components/karaoke/karaoke.component';
import {KaraokeEntryComponent} from 'app/content/screen/components/karaoke/karaoke-entry/karaoke-entry.component';
import {ComponentModule} from 'app/component/component.module';
import {AssignmentGapMatchRowComponent} from 'app/content/screen/components/assignment-gap-match/assignment-gap-match-row/assignment-gap-match-row.component';
import {AssignmentGapMatchOptionComponent} from 'app/content/screen/components/assignment-gap-match/assignment-gap-match-option/assignment-gap-match-option.component';
import {AssignmentGapMatchTextComponent} from 'app/content/screen/components/assignment-gap-match/assignment-gap-match-text/assignment-gap-match-text.component';
import {AssignmentGapMatchImageComponent} from 'app/content/screen/components/assignment-gap-match/assignment-gap-match-image/assignment-gap-match-image.component';
import {AssignmentGapMatchGameComponent} from 'app/content/screen/components/assignment-gap-match/assignment-gap-match-game/assignment-gap-match-game.component';
import {DirectiveModule} from 'app/directive/directive.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {AssignmentGapChoiceRowComponent} from 'app/content/screen/components/assignment-gap-choice/assignment-gap-choice-row/assignment-gap-choice-row.component';
import {AssignmentGapChoiceComponent} from 'app/content/screen/components/assignment-gap-choice/assignment-gap-choice.component';
import {AssignmentGapChoiceOptionComponent} from 'app/content/screen/components/assignment-gap-choice/assignment-gap-choice-option/assignment-gap-choice-option.component';
import {AssignmentGapChoiceOptionEntryComponent} from 'app/content/screen/components/assignment-gap-choice/assignment-gap-choice-option-entry/assignment-gap-choice-option-entry.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {CdkModule} from 'app/cdk/cdk.module';
import {DrawingModule} from 'app/drawing/drawing.module';
import {PipeModule} from 'app/pipe/pipe.module';
import {OkStampComponent} from 'app/content/screen/components/ok-stamp/ok-stamp.component';
import {ZoomableViewModule} from 'app/zoomable-view/zoomable-view.module';

const components = [
    TranslationTableCellComponent,
    BbvmsVideoComponent,
    BbvmsAudioComponent,
    WordsComponent,
    WordsEntryComponent,
    ImageComponent,
    AssignmentHottextComponent,
    AssignmentHottextOptionComponent,
    PdfDownloadButtonComponent,
    AssignmentSingleSelectComponent,
    AssignmentSelectOptionComponent,
    AssignmentMultipleSelectComponent,
    AssignmentMultipleSelectGroupComponent,
    DialogComponent,
    DialogEntryComponent,
    AssignmentGapMatchTextComponent,
    AssignmentGapMatchRowComponent,
    AssignmentGapMatchOptionComponent,
    AssignmentGapMatchImageComponent,
    AssignmentGapMatchGameComponent,
    AssignmentGapChoiceComponent,
    AssignmentGapChoiceRowComponent,
    AssignmentGapChoiceOptionComponent,
    AssignmentGapChoiceOptionEntryComponent,
    KaraokeComponent,
    KaraokeEntryComponent,
    PdfAssetComponent,
    ScreenDrawingComponent,
    ScreenContentWrapperComponent,
    OkStampComponent,
    ContentInteractionContainerComponent,
    EquationComponent,
];

@NgModule({
    declarations: components,
    exports: components,
    imports: [
        ContentDirectiveModule,
        CommonModule,
        ComponentModule,
        DirectiveModule,
        DragDropModule,
        CdkModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        PipeModule,
        DrawingModule,
        ZoomableViewModule,
    ],
})
export class ContentComponentsModule {}
