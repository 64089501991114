import {Inject, Injectable} from '@angular/core';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';

@Injectable()
export class ToolBeltService {
    public constructor(
        @Inject(AbstractToolWrapper)
        private tools: Array<AbstractToolWrapper>
    ) {}

    public getToolIds(): string[] {
        return this.tools.map(tool => tool.id);
    }

    public findToolById(toolId: string): AbstractToolWrapper {
        for (const tool of this.tools) {
            if (tool.id === toolId) {
                return tool;
            }
        }

        throw new Error(`No tool with ID ${toolId} found.`);
    }
}
