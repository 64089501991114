import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';

@Component({
    selector: 'app-ruler',
    templateUrl: './ruler.component.html',
})
export class RulerComponent extends AbstractTool {
    public static readonly id = 'liniaal';
    public static readonly width = 723;
    public static readonly height = 58;
}
