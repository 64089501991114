import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';

@Component({
    selector: 'app-hdtddhte',
    templateUrl: './hdtddhte.component.html',
    styleUrls: ['./hdtddhte.component.scss'],
})
export class HdtddhteComponent extends AbstractTool {
    public static readonly id = 'hdtddhte';
    public static readonly width = 250;
    public static readonly height = 95;
    public rangeModel = {
        Hd: 0,
        Td: 0,
        D: 0,
        H: 0,
        T: 0,
        E: 0,
    };

    constructor() {
        super();
    }
}
