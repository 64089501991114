import {Injectable} from '@angular/core';
import {ToolRingInterface} from 'app/tool/classes/tool-ring.class';
import {Radius} from 'app/tool/enum/radius.enum';
import {
    ToolRingConfigInterface,
    ToolRingFactory,
} from 'app/tool/factory/tool-ring/tool-ring.factory';
import {SvgFontAwesomeEnum} from 'app/enum/svg.enum';
import {AbstractToolRingService} from 'app/tool/rings/base/abstract-tool-ring.service';
import {RingIds} from 'app/tool/rings/ring-ids.enum';
import {ToolboxModalService} from 'app/toolbox/service/modal/modal.service';
import {ToolboxService} from 'app/toolbox/service/toolbox/toolbox.service';
import {ZoomableViewService} from 'app/zoomable-view/service/zoomable-view/zoomable-view.service';
import {ZoomEnum} from 'app/enum/zoom.enum';
import {ColorInterpolationFilterEnum} from 'app/tool/enum/filter.enum';
import {OuterRingService} from 'app/tool/service/outer-ring.service';

@Injectable()
export class ZoomRingService extends AbstractToolRingService {
    private zoom!: ToolRingInterface;

    constructor(
        protected toolRingFactory: ToolRingFactory,
        protected toolboxService: ToolboxService,
        protected toolboxModalService: ToolboxModalService,
        private zoomableViewService: ZoomableViewService,
        private outerRingService: OuterRingService
    ) {
        super(toolRingFactory, toolboxService, toolboxModalService);

        this.setRingInstance();
    }

    public getId(): RingIds {
        return RingIds.ZoomRing;
    }

    public render(): ToolRingInterface[] {
        this.syncToolboxButtons(this.config(), this.zoom, 'blue');

        return [this.zoom];
    }

    protected handleCategoriesChanged(): void {
        this.syncToolboxButtons(this.config(), this.zoom, 'blue');

        super.handleCategoriesChanged();
    }

    private activateExerciseRing(): void {
        this.outerRingService.activateRing(RingIds.ExerciseRing);
    }

    private config(): ToolRingConfigInterface {
        return {
            id: 'zoom',
            radius: Radius.Large,
            rotate: 198,
            visible: false,
            slices: 10,
            classes: ['svg__circle--blue'],
            buttons: [
                {
                    id: 'back',
                    icon: SvgFontAwesomeEnum.SolidArrowLeft,
                    iconFilter: ColorInterpolationFilterEnum.BlueDark,
                    classes: ['svg__button--blue-light'],
                    callback: () => this.activateExerciseRing(),
                },
                {
                    id: 'zoom-in',
                    icon: SvgFontAwesomeEnum.SolidZoomIn,
                    classes: ['svg__button--blue-light'],
                    callback: () =>
                        this.zoomableViewService.zoomInOutReset(ZoomEnum.In),
                },
                {
                    id: 'zoom-out',
                    icon: SvgFontAwesomeEnum.SolidZoomOut,
                    classes: ['svg__button--blue-light'],
                    callback: () =>
                        this.zoomableViewService.zoomInOutReset(ZoomEnum.Out),
                },
                {
                    id: 'minimize',
                    icon: SvgFontAwesomeEnum.SolidMinimize,
                    classes: ['svg__button--blue-light'],
                    callback: () =>
                        this.zoomableViewService.zoomInOutReset(ZoomEnum.Reset),
                },
            ],
        };
    }

    private setRingInstance(): void {
        this.zoom = this.toolRingFactory.create(this.config());
    }
}
