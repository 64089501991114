import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';

export interface JumpingStep {
    value: number;
    distance?: number;
    coords: {
        x: number;
    };
}

@Component({
    selector: 'app-jumping',
    templateUrl: './jumping.component.html',
    styleUrls: ['./jumping.component.scss'],
})
export class JumpingComponent extends AbstractTool {
    public static readonly id = 'springen';
    public static readonly width = 1034;
    public static readonly height = 105;

    public start = 0;
    public distance = 100;
    public direction = 1;
    public steps: JumpingStep[] = [];

    public total = 0;
    public lastPointX = 0;

    public readonly firstPointCoords = {
        y: 62,
        x: 0,
    };
    public readonly verticalLineCoords = {
        y1: 56,
        y2: 70,
    };
    public readonly lowerTextCoords = {
        y: 90,
    };
    public readonly lineCoords = {
        x1: 0,
        y1: 62,
        x2: 1024,
        y2: 62,
    };

    constructor() {
        super();
        this.firstPointCoords.x = this.direction === 1 ? 10 : 1014;
    }

    public handleStartChange($event: number): void {
        this.start = $event;
        this.render();
    }

    public handleDistanceChange($event: number): void {
        this.distance = $event;
        this.render();
    }

    public render(): void {
        this._setSteps(this.steps);
    }

    public handleClear(): void {
        this.total = 0;
        this.steps = [];
    }

    public handleUndo(): void {
        this._removeStep();
    }

    public setDirection($event: number): void {
        this.direction = $event;
        this._setSteps(this.steps);
    }

    public addStep(value: number): void {
        this._setSteps([
            ...this.steps,
            {
                value,
                coords: {x: 0},
            },
        ]);
    }

    public textOffset(value: number): number {
        if (value >= 0) {
            return value < 10 ? 3 : value < 100 ? 7 : 10;
        } else {
            return value > -10 ? 7 : value > -100 ? 10 : 14;
        }
    }

    public arcTipRotate(value: number): number {
        value = value / 10;
        return Math.abs(value) < 20
            ? this.direction * 45 - (value / 20) * 45
            : 0 - (value / 100) * 30;
    }

    private _setSteps(steps: JumpingStep[]): void {
        this.firstPointCoords.x = this.direction === 1 ? 10 : 1014;
        this.total = this.start;
        let lastPointX = this.firstPointCoords.x;
        this.steps = steps.map((s: JumpingStep) => {
            s.distance =
                ((s.value * 100) / (this.distance / 10)) * this.direction;
            s.coords.x = lastPointX;
            lastPointX += s.distance;
            this.total += s.value * this.direction;
            return s;
        });
    }

    private _removeStep(): void {
        if (this.steps.length) {
            const lastStep = this.steps.pop();
            if (lastStep) {
                this.total -= lastStep.value;
            }
        }
    }
}
