import {Radius} from 'app/tool/enum/radius.enum';

export class ToolException extends Error {
    public static forContainerNotFound(radius: Radius): ToolException {
        return new ToolException(`Container with radius "${radius}" not found`);
    }

    public static forResourceUndefined(): ToolException {
        return new ToolException('Resource is undefined');
    }

    public static forNoActiveVisibleRingsFound(): ToolException {
        return new ToolException('No active visible rings found');
    }
}
