import {Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[appTheoryBody]',
})
export class ContentTheoryBodyDirective {
    constructor(private elementRef: ElementRef) {
        console.log(elementRef);
    }
}
