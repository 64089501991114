import {Component, HostBinding} from '@angular/core';

@Component({
    selector: 'ok-stamp',
    templateUrl: './ok-stamp.component.html',
    styleUrls: ['./ok-stamp.component.scss'],
})
export class OkStampComponent {
    @HostBinding('class.visible')
    public showOkStamp = false;
}
