import {Location} from '@angular/common';
import {
    AfterViewInit,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {NavigationResourceContainer} from 'app/page/navigation/classes/navigation-resource-container.class';
import {AbstractCrossFader} from 'app/directive/fader/classes/fader.abstract';
import {ActivatedRoute, Router} from '@angular/router';
import {FaderDirective} from 'app/directive/fader/fader.directive';
import {GoogleAnalyticsService} from 'app/service/google-analytics/google-analytics.service';
import {Resource} from 'app/service/resource/classes/resource.class';
import {ResourceService} from 'app/service/resource/resource.service';
import {Subscription} from 'rxjs';
import {HeaderService} from 'app/header/service/header.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent
    extends AbstractCrossFader
    implements OnInit, AfterViewInit, OnDestroy
{
    @ViewChild('activeFaderContainer', {read: FaderDirective})
    public activeFaderContainer?: FaderDirective;

    @ViewChild('inactiveFaderContainer', {read: FaderDirective})
    public inactiveFaderContainer?: FaderDirective;

    public resource?: Resource;
    public selectedResources: Resource[] = [];
    public currentResources: Resource[] = [];
    public inactiveResources: Resource[] = [];
    public loading = false;
    public previousResource?: Resource;
    private logoClickSubscription: Subscription;
    private itemsSubscription: Subscription;

    constructor(
        private navigationResourceContainer: NavigationResourceContainer,
        private location: Location,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private resourceService: ResourceService,
        private headerService: HeaderService,
        private gaService: GoogleAnalyticsService
    ) {
        super();
        this.logoClickSubscription = this.headerService.logoClickSubscribe(() =>
            this.handleNavigationClick()
        );
        this.itemsSubscription = this.navigationResourceContainer.subscribe(
            resources => (this.selectedResources = resources)
        );
    }

    public ngOnInit(): void {
        this.previousResource = this.headerService.getBreadcrumbResource();

        const resource: Resource | undefined = this.getResolvedResource();
        if (undefined !== resource) {
            if (this.navigateToContentIfPossible(resource.getChildren())) {
                return;
            }

            this.navigationResourceContainer.set(resource.asArray());
            this.resource = this.navigationResourceContainer.last();
            this.currentResources = resource.getChildren();
        }
    }

    public ngAfterViewInit(): void {
        if (undefined === this.resource) {
            this.navigationResourceContainer.clear();

            this.resourceService
                .getByResource(this.resource)
                .then((resources: Resource[]) => {
                    this.currentResources = resources;
                });
        }
    }

    public ngOnDestroy() {
        this.logoClickSubscription.unsubscribe();
        this.itemsSubscription.unsubscribe();
    }

    @HostListener('window:popstate', ['$event'])
    public handleHistoryBack(): void {
        const selectedResourcesLength: number = this.selectedResources.length;

        if (0 === selectedResourcesLength) {
            return;
        }

        this.handleNavigationHistoryClick(
            this.selectedResources[selectedResourcesLength - 1]
        );
    }

    public handleNavigationHistoryClick(resource: Resource): void {
        if (this.loading) {
            return;
        }

        this.navigationResourceContainer.removeAfter(resource);
        this.handleNavigationClick(this.navigationResourceContainer.last());
    }

    public handleNavigationClick(resource?: Resource): void {
        this.resource = resource; // Set current navigation item

        if (resource !== undefined) {
            this.loading = true;
            this.navigationResourceContainer.add(resource);
        }

        // TODO DIGB-1239: Pass reason to error handler
        this.crossFade().catch(reason => console.error(reason));
    }

    public async handleNavigateToPreviousResource(): Promise<void> {
        if (!this.previousResource) {
            return;
        }

        this.resourceService.setCurrentResource(this.previousResource);
        await this.router.navigate([this.previousResource.getRoute()]);
    }

    protected preCrossFade(): Promise<void> {
        const resource: Resource | undefined = this.resource;

        this.inactiveResources = this.currentResources; // Prepare swap

        return this.resourceService
            .getByResource(resource)
            .then((resources: Resource[]) => {
                if (
                    undefined !== resource &&
                    this.navigateToContentIfPossible(resources)
                ) {
                    return;
                }

                if (undefined === this.resource) {
                    this.navigationResourceContainer.clear();
                }

                this.location.go(
                    undefined !== resource ? resource.getRoute() : ''
                ); // Update URL
                this.gaService.pageView(
                    this.location.path(),
                    `${resource ? resource.getMethod() : 'no method'} ${
                        resource ? resource.getName() : ''
                    }`.trim()
                );
                this.currentResources = resources;
                this.inactiveResources = [];
            })
            .catch(reason => console.error(reason)); // TODO DIGB-1239: Pass reason to error handler
    }

    protected postCrossFade(): Promise<void> {
        return new Promise(resolve => {
            this.loading = false;

            resolve();
        });
    }

    protected getActiveFaderContainer(): FaderDirective | undefined {
        return this.activeFaderContainer;
    }

    protected getInactiveFaderContainer(): FaderDirective | undefined {
        return this.inactiveFaderContainer;
    }

    private navigateToContentIfPossible(children: Resource[] = []): boolean {
        const childResource: Resource | undefined = children[0];

        if (undefined === childResource || !childResource.isScreenType()) {
            return false;
        }

        this.router.navigate([childResource.getRoute()]);

        return true;
    }

    private getResolvedResource(): Resource | undefined {
        return this.activatedRoute.snapshot.data.resource;
    }
}
