import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FaderDirective} from 'app/directive/fader/fader.directive';
import {DoubleTapDirective} from 'app/directive/double-tap/double-tap.directive';
import {PanPointDirective} from 'app/directive/pan-point/pan-point.directive';
import {
    RotateDirective,
    RotateHandleDirective,
} from './rotate/rotate.directive';
import {ScrollIntoViewDirective} from './scroll-into-view/scroll-into-view.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [
        FaderDirective,
        DoubleTapDirective,
        PanPointDirective,
        RotateDirective,
        RotateHandleDirective,
        ScrollIntoViewDirective,
    ],
    exports: [
        FaderDirective,
        DoubleTapDirective,
        PanPointDirective,
        RotateDirective,
        RotateHandleDirective,
        ScrollIntoViewDirective,
    ],
})
export class DirectiveModule {}
