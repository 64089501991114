import {Radius} from 'app/tool/enum/radius.enum';
import {ToolRingButtonIconInterface} from 'app/tool/classes/tool-ring-button-icon.class';
import {ColorInterpolationFilterEnum} from 'app/tool/enum/filter.enum';
import {CartesianPointInterface} from 'app/classes/point.class';

export interface ToolRingButtonInterface {
    id: string;
    iconPoint: CartesianPointInterface;
    instructions: string;
    icon: string;
    iconFilter: ColorInterpolationFilterEnum;
    classes: string[];

    handleClick(): void;
    useAltIcon(use: boolean): void;
    isActive(): boolean;
    isDisabled(): boolean;
}

export class ToolRingButton implements ToolRingButtonInterface {
    public readonly instructions: string;
    public icon: string;
    public classes: string[] = ['svg__button'];

    constructor(
        public readonly id: string,
        private readonly radius: Radius,
        private readonly buttonIcon: ToolRingButtonIconInterface,
        public readonly iconFilter: ColorInterpolationFilterEnum,
        private readonly startPoint: CartesianPointInterface,
        private readonly endPoint: CartesianPointInterface,
        public readonly iconPoint: CartesianPointInterface,
        private readonly click: (self: ToolRingButtonInterface) => void,
        public readonly isActiveCallback?: (
            self: ToolRingButtonInterface
        ) => boolean,
        public readonly isDisabledCallback?: (
            self: ToolRingButtonInterface
        ) => boolean,
        classes: string[] = []
    ) {
        this.icon = buttonIcon.path;
        this.instructions = this.getPathInstructions();
        this.classes.push(...classes);
    }

    public handleClick(): void {
        this.click(this);
    }

    public useAltIcon(use = false): void {
        const icon: ToolRingButtonIconInterface = this.buttonIcon;
        icon.useAltPath(use);
        this.icon = icon.path;
    }

    public isActive(): boolean {
        return (
            this.isActiveCallback !== undefined && this.isActiveCallback(this)
        );
    }

    public isDisabled(): boolean {
        return (
            this.isDisabledCallback !== undefined &&
            this.isDisabledCallback(this)
        );
    }

    private getPathInstructions(): string {
        const instructions: (string | number)[][] = [
            ['M', 0, 0], // Move: x, y
            ['L', this.startPoint.x, this.startPoint.y], // Line: x, y
            [
                'A',
                this.radius,
                this.radius,
                0,
                0,
                1,
                this.endPoint.x,
                this.endPoint.y,
            ], // Arc: rX,rY rotation, arc, sweep, eX,eY
            this.isCircle() // We'll need 2 arcs for circles
                ? [
                      'A',
                      this.radius,
                      this.radius,
                      0,
                      0,
                      1,
                      -this.endPoint.x,
                      this.endPoint.y,
                  ]
                : [],
            ['Z'],
        ];

        const flattenedInstructions: (string | number)[] = [];

        instructions
            .filter(instruction => 0 !== instruction.length)
            .forEach(instruction => flattenedInstructions.push(...instruction));

        return flattenedInstructions.join(' ');
    }

    private isCircle(): boolean {
        return 0 === this.iconPoint.x && 0 === this.iconPoint.y;
    }
}
