import {Component, Output, EventEmitter} from '@angular/core';
import {Observable} from 'rxjs';
import {DrawingRootService} from 'app/drawing/service/drawing/drawing-root.service';
import {DEFAULT_HIGHLIGHTER_DIAMETER_OPTIONS} from 'app/drawing/consts';
import {Color} from 'app/drawing/interface/color.interface';

@Component({
    selector: 'app-highlighter',
    templateUrl: './highlighter.component.html',
    styleUrls: ['./highlighter.component.scss'],
})
export class HighlighterComponent {
    @Output()
    public closed = new EventEmitter<void>();

    options = DEFAULT_HIGHLIGHTER_DIAMETER_OPTIONS;

    size$: Observable<number>;
    color$: Observable<Color>;

    constructor(private drawingRootService: DrawingRootService) {
        this.size$ = this.drawingRootService.highlighterLineWidth$;
        this.color$ = this.drawingRootService.highlighterLineColor$;
    }

    public close(): void {
        this.closed.emit();
    }

    public onDiameterChange(diameter: number) {
        this.drawingRootService.setHighlighterLineWidth(diameter);
    }

    public onColorChange(color: Color) {
        this.drawingRootService.setHighlighterLineColor(color);
    }
}
