import {
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import {Resource} from 'app/service/resource/classes/resource.class';
import {TocResourceState} from 'app/enum/toc.enum';
import {ResourceToggleComponent} from 'app/component/resource-toggle/resource-toggle.component';

@Component({
    selector: 'app-toc-resource',
    templateUrl: './toc-resource.component.html',
    styleUrls: ['./toc-resource.component.scss'],
})
export class TocResourceComponent {
    @Input()
    public resource!: Resource;

    @Output()
    public readonly clicked = new EventEmitter<Resource>();

    @Output()
    public readonly changed = new EventEmitter<void>();

    @Input()
    public isActive = false;

    @ViewChild(ResourceToggleComponent)
    private toggleComponent!: ResourceToggleComponent;

    public get loading(): boolean {
        return this.toggleComponent.loading;
    }

    public handleChange(): void {
        this.changed.emit();
    }

    @HostListener('click')
    private onClick(): void {
        if (this.isDeactivated) {
            return;
        }

        this.clicked.emit(this.resource);
    }

    @HostBinding('class.is-deactivated')
    private get isDeactivated(): boolean {
        return (
            this.resource &&
            this.resource.getState() === TocResourceState.Inactive
        );
    }
}
