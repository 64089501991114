import {NgModule} from '@angular/core';
import {NavigationComponent} from 'app/page/navigation/component/navigation.component';
import {NavigationResourceContainer} from 'app/page/navigation/classes/navigation-resource-container.class';
import {DirectiveModule} from 'app/directive/directive.module';
import {RouterModule} from '@angular/router';
import {ComponentModule} from 'app/component/component.module';
import {CommonModule} from '@angular/common';
import {SelectMethodPipe} from 'app/page/navigation/pipe/select-method.pipe';
import {PipeModule} from 'app/pipe/pipe.module';

@NgModule({
    imports: [
        DirectiveModule,
        RouterModule,
        ComponentModule,
        CommonModule,
        PipeModule,
    ],
    declarations: [NavigationComponent, SelectMethodPipe],
    providers: [NavigationResourceContainer],
    exports: [NavigationComponent],
})
export class NavigationModule {}
