import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ServiceModule} from 'app/service/service.module';
import {ScreenModule} from 'app/content/screen/screen.module';
import {ContentComponent} from 'app/content/component/content.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DirectiveModule} from 'app/directive/directive.module';
import {ComponentModule} from 'app/component/component.module';
import {SwiperModule} from 'ngx-swiper-wrapper';

@NgModule({
    imports: [
        CommonModule,
        ServiceModule,
        ScreenModule,
        DragDropModule,
        DirectiveModule,
        ComponentModule,
        SwiperModule,
    ],
    declarations: [ContentComponent],
    exports: [ContentComponent],
})
export class ContentModule {}
