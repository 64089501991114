import {Injectable} from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class StringUtil {
    public static getMappedString(
        mappableString: string,
        propertiesMap?: Map<string, string>
    ): string {
        if (undefined === propertiesMap) {
            return mappableString;
        }

        propertiesMap.forEach((value: string, key: string) => {
            mappableString = mappableString.replace(`:${key}`, value);
        });

        return mappableString;
    }

    public static random(length: number): string {
        return Math.random()
            .toString(36)
            .substring(length + 1);
    }

    public static prependWithZeros(value: string, length: number) {
        _.times(length - value.length, () => {
            value = '0' + value;
        });

        return value;
    }
}
