import {TemplateRef, ViewChild} from '@angular/core';
import {ToolInstance} from 'app/tool-layer/class/tool-instance';

export abstract class AbstractTool {
    /**
     * The technical ID which connect the API tool configurations to the specific tool.
     */
    public static readonly id: string;

    /**
     * The full tool component width in pixels (including the drag/delete buttons).
     */
    public static readonly width: number;

    /**
     * The full tool component height in pixels (including the drag/delete buttons).
     */
    public static readonly height: number;

    /**
     * Is the tool only for showing settings?
     */
    public static readonly settingsInstanceOnly: boolean = false;

    /**
     * Do we want to hide the add button in the settings bar?
     */
    public static readonly hideAddButton: boolean = false;

    /**
     * How many instances of this tool are allowed?
     * limit < 0 = unlimited
     */
    public static readonly limit: number = 0;

    /**
     * Do not use promotion and demotion; immediately add and delete the tool instances.
     */
    public static readonly immediatelyAdd: boolean = false;

    @ViewChild('contentTemplate')
    protected toolRef!: TemplateRef<unknown>;

    @ViewChild('settingsTemplate')
    protected settingsRef!: TemplateRef<unknown>;

    private toolInstance?: ToolInstance;

    public set instance(instance: ToolInstance | undefined) {
        this.toolInstance = instance;
    }

    public get instance(): ToolInstance | undefined {
        return this.toolInstance;
    }

    public set injectedData(data: unknown | undefined) {
        // void
    }

    public get tool(): TemplateRef<unknown> {
        return this.toolRef;
    }

    public get settings(): TemplateRef<unknown> {
        return this.settingsRef;
    }

    /**
     * The callback events below are used in special occasions.
     */

    /**
     * Called when the tool layer hides the active tools, but doesn't destroy them.
     */
    public onHideTool(): void {
        //
    }
}
