import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';
import {Bead} from 'app/tool-layer/tools/abacus/classes/bead.class';

@Component({
    selector: 'app-abacus',
    templateUrl: './abacus.component.html',
    styleUrls: ['./abacus.component.scss'],
})
export class AbacusComponent extends AbstractTool {
    public static readonly id = 'rekenrek';
    public static readonly width = 600;
    public static readonly height = 150;

    public readonly beadsList: Bead[] = [];
    private readonly numberOfBeats = 20;

    constructor() {
        super();

        this.generateBeadsList();
    }

    private generateBeadsList(): void {
        for (let i = 1; i <= this.numberOfBeats; i++) {
            const newBead = Bead.create(i);

            this.beadsList.push(newBead);
        }
    }

    private getBeadsBetween(
        selectedBead: Bead,
        startIndex: number,
        endIndex: number
    ): Bead[] {
        return this.beadsList.filter(b => {
            return (
                b.index >= startIndex &&
                b.index <= endIndex &&
                b.orientation === selectedBead.orientation &&
                !b.isMoving
            );
        });
    }

    private moveBead(bead: Bead): void {
        const element = document.getElementById(bead.id);
        if (!element) {
            return;
        }

        element.setAttribute(
            'transform',
            `translate(${bead.getTranslateOffset()} 0)`
        );

        if (bead.isMaxPosition()) {
            bead.updateStartPosition();
        } else {
            requestAnimationFrame(() => {
                this.moveBead(bead);
            });

            bead.increasePosition();
        }
    }

    private selectBeads(selectedBead: Bead): Bead[] {
        if (selectedBead.orientation === 1) {
            return this.getBeadsBetween(
                selectedBead,
                selectedBead.index,
                selectedBead.index <= 10 ? 10 : 20
            );
        }

        return this.getBeadsBetween(
            selectedBead,
            selectedBead.index <= 10 ? 1 : 11,
            selectedBead.index
        );
    }

    public moveBeads(event: MouseEvent): void {
        const element = event.currentTarget as Element;
        const id = element.id;
        const selectedBead = this.beadsList.filter(b => {
            return b.id === id;
        })[0];

        const beads = this.selectBeads(selectedBead);
        beads.forEach(bead => {
            bead.startMoving();
            this.moveBead(bead);
        });
    }
}
