import {Component, HostBinding, Input} from '@angular/core';

@Component({
    selector: 'app-handle',
    templateUrl: './handle.component.html',
    styleUrls: ['./handle.component.scss'],
})
export class HandleComponent {
    @Input()
    @HostBinding('class.active')
    public active = false;

    @Input()
    @HostBinding('class.small')
    public small = false;
}
