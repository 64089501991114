import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {PositionOnTheNumberLineComponent} from './position-on-the-number-line.component';
import {ComponentModule} from 'app/component/component.module';
import {FormsModule} from '@angular/forms';
import {LineSvgModule} from 'app/tool-layer/tools/line-svg/line-svg.module';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
    imports: [
        CommonModule,
        ComponentModule,
        FormsModule,
        DragDropModule,
        LineSvgModule,
    ],
    declarations: [PositionOnTheNumberLineComponent],
    entryComponents: [PositionOnTheNumberLineComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(
                PositionOnTheNumberLineComponent
            ),
            multi: true,
        },
    ],
})
export class PositionOnTheNumberLineModule {}
