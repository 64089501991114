import {InjectionToken, NgModule} from '@angular/core';
import {DrawingModule} from 'app/drawing/drawing.module';
import {ServiceModule} from 'app/service/service.module';
import {ToolRingServiceInterface} from 'app/tool/rings/base/abstract-tool-ring.service';
import {EditRingService} from 'app/tool/rings/edit-ring/edit-ring.service';
import {ExerciseRingService} from 'app/tool/rings/exercise-ring/exercise-ring.service';
import {NavigationRingService} from 'app/tool/rings/navigation-ring/navigation-ring.service';
import {ViewRingService} from 'app/tool/rings/view-ring/view-ring.service';
import {ZoomRingService} from 'app/tool/rings/zoom-ring/zoom-ring.service';

// This token allows all tool ring services to be injected into the ToolService
export const TOOL_RING_SERVICES = new InjectionToken<ToolRingServiceInterface>(
    'ToolRingService'
);

@NgModule({
    imports: [ServiceModule, DrawingModule],
    providers: [
        NavigationRingService,
        EditRingService,
        ExerciseRingService,
        ZoomRingService,
        ViewRingService,
        {
            provide: TOOL_RING_SERVICES,
            useExisting: NavigationRingService,
            multi: true,
        },
        {
            provide: TOOL_RING_SERVICES,
            useExisting: EditRingService,
            multi: true,
        },
    ],
})
export class RingsModule {}
