import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';

@Component({
    selector: 'app-counting-triangle',
    templateUrl: './counting-triangle.component.html',
})
export class CountingTriangleComponent extends AbstractTool {
    public static readonly id = 'rekendriehoek';
    public static readonly width = 300;
    public static readonly height = 280;

    public activeField = 0; // set focus on index 0 straight away after creation
    public values: {[key: number]: string} = {};
    public isMultiply = false;

    public setActiveField(index: number): void {
        this.activeField = index;
    }

    public toggleMultiply(): void {
        this.isMultiply = !this.isMultiply;
    }

    public handleClear(): void {
        this.values = {};
    }

    public handleUndo(): void {
        if (this.activeField === null || !(this.activeField in this.values)) {
            return;
        }

        const currentValue = this.values[this.activeField];
        if (currentValue && currentValue.length > 1) {
            this.values[this.activeField] = currentValue.slice(0, -1);
        } else {
            delete this.values[this.activeField];
        }
    }

    public handleNumberChoice(value: number): void {
        if (this.activeField === null) {
            return;
        }

        const fieldExists = this.activeField in this.values;
        const currentValue = this.values[this.activeField];

        if (!fieldExists || !currentValue || currentValue === '0') {
            this.values[this.activeField] = value.toString();
        } else if (currentValue.length < 3) {
            this.values[this.activeField] += value.toString();
        }
    }
}
