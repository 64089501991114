import {
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    OnDestroy,
} from '@angular/core';
import {
    ZoomableViewEventInterface,
    ZoomableViewEventTypeEnum,
    ZoomableViewService,
} from 'app/zoomable-view/service/zoomable-view/zoomable-view.service';
import {AbstractZoomableViewDirective} from 'app/zoomable-view/classes/abstract-zoomable-view.directive';
import {Point} from 'app/classes/point.class';
import {Subscription} from 'rxjs';
import {SecondScreenService} from 'app/second-screen/service/second-screen.service';

enum ElementDimensions {
    Height = 920,
    Width = 1480,
}

@Directive({
    selector: '[appScale]',
})
export class ScaleDirective
    extends AbstractZoomableViewDirective
    implements OnDestroy
{
    private zoomSubscription: Subscription;
    private secondScreenSubscription: Subscription;

    @HostBinding('style.height')
    private height = '100%';

    @HostBinding('style.width')
    private width = '100%';

    constructor(
        elementRef: ElementRef,
        zoomableViewService: ZoomableViewService,
        secondScreenService: SecondScreenService
    ) {
        super(elementRef, zoomableViewService);
        this.zoomSubscription = zoomableViewService.subscribe(event =>
            this.handleZoomViewServiceEvent(event)
        );
        this.secondScreenSubscription = secondScreenService.subscribeToEnabled(
            () => this.doScale()
        );
    }

    public ngOnDestroy(): void {
        this.zoomSubscription.unsubscribe();
        this.secondScreenSubscription.unsubscribe();
    }

    protected handleAttributeMutationEvent(): void {}

    protected handleZoomViewServiceEvent(
        event: ZoomableViewEventInterface
    ): void {
        const eventType: ZoomableViewEventTypeEnum = event.type;

        if (
            ZoomableViewEventTypeEnum.Zoom === eventType &&
            this.zoomableViewService.isZooming()
        ) {
            return;
        }

        if (
            ZoomableViewEventTypeEnum.Scale === eventType &&
            !this.zoomableViewService.isScaleEnabled()
        ) {
            this.reset();

            return;
        }

        this.setScaleEnabled(event.enabled);
        this.doScale();
    }

    protected reset(): void {
        super.reset();
        this.setScaleEnabled(false);
    }

    private setScaleEnabled(value: boolean): void {
        this.width = value ? `${ElementDimensions.Width}px` : '100%';
        this.height = value ? `${ElementDimensions.Height}px` : '100%';
    }

    @HostListener('window:resize')
    private doScale(): void {
        if (
            !this.zoomableViewService.isScaleEnabled() ||
            this.zoomableViewService.isZooming() ||
            this.zoomableViewService.isScissorsEnabled()
        ) {
            return;
        }

        this.doTransform();
    }

    private getScaleByParentDomRect(parentDOMRect: DOMRect): number {
        const scaleHeight: number =
            parentDOMRect.height / ElementDimensions.Height;
        const scaleWidth: number =
            parentDOMRect.width / ElementDimensions.Width;

        if (
            parentDOMRect.height > ElementDimensions.Height &&
            parentDOMRect.width > ElementDimensions.Width
        ) {
            return Math.min(
                parentDOMRect.height / ElementDimensions.Height,
                parentDOMRect.width / ElementDimensions.Width
            );
        }

        return Math.min(scaleHeight, scaleWidth);
    }

    private doTransform(): void {
        const parentDOMRect: DOMRect = this.getParentDOMRect();
        const scale: number = this.getScaleByParentDomRect(parentDOMRect);

        const left: number =
            (parentDOMRect.width - ElementDimensions.Width * scale) / 2;
        const top: number =
            (parentDOMRect.height - ElementDimensions.Height * scale) / 2;

        this.setTransformPointAndScale(
            new Point(left > 0 ? left : 0, top > 0 ? top : 0),
            scale
        );
    }

    private getParentDOMRect(): DOMRect {
        return (
            this.nativeElement.parentNode as HTMLDivElement
        ).getBoundingClientRect();
    }
}
