import {
    AfterContentInit,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    DragRef,
    DropListRef,
    moveItemInArray,
    transferArrayItem,
} from '@angular/cdk/drag-drop';
import {Point} from '@angular/cdk/drag-drop/drag-ref';
import {DraggablesDirective} from 'app/cdk/directive/draggables/draggables.directive';
import {DragDropService} from 'app/cdk/service/drag-drop/drag-drop.service';
import {Subscription} from 'rxjs';

export interface DropListEventInterface {
    item: DragRef;
    container: DropListRef;
}

export interface DropListIndexedEventInterface extends DropListEventInterface {
    currentIndex: number;
}

export interface DropListDroppedEventInterface
    extends DropListIndexedEventInterface {
    previousIndex: number;
    previousContainer: DropListRef;
    isPointerOverContainer: boolean;
    distance: Point;
}

@Component({
    selector: 'app-drop-list',
    templateUrl: './drop-list.component.html',
    styleUrls: ['./drop-list.component.scss'],
})
export class DropListComponent<I>
    implements OnInit, AfterContentInit, OnDestroy
{
    @ContentChild(DraggablesDirective)
    private draggablesDirective!: DraggablesDirective<I>;

    private readonly dropListRef: DropListRef;

    @Input()
    @HostBinding('class.no-placeholders')
    public noPlaceholders = true;

    @HostBinding('class.element-over')
    public onElementOver = false;

    @Input()
    private items: I[] = [];

    @Output()
    public readonly itemsChange = new EventEmitter<I[]>();

    @Input()
    private maxItems = Infinity;

    private dragRefs: DragRef[] = [];

    private subscriptions: Subscription[] = [];

    constructor(
        private elementRef: ElementRef,
        private dragDropService: DragDropService
    ) {
        this.dropListRef = this.dragDropService.createDropListRef(
            this.elementRef
        );

        // Set enterPredicate function, to support item limits
        this.dropListRef.enterPredicate = () =>
            this.dragRefs.length !== this.maxItems;

        // Bind events
        this.subscriptions.push(
            this.dropListRef.exited.subscribe(() => this.onExited()),
            this.dropListRef.entered.subscribe(() => this.onEntered()),
            this.dropListRef.dropped.subscribe(event => this.onDropped(event))
        );
    }

    public ngOnInit(): void {
        this.dropListRef.data = this.items;
    }

    /**
     * Apparently when programmatically using the service for drag and drop, it doesnt automatically
     * update the dragRef items. Dropping an item in a list creates a new DragDirective, so with each
     * move we'll have to update the DropListRef items.
     */
    public ngAfterContentInit(): void {
        this.draggablesDirective.subscribeToChanges(dragRefs =>
            this.updateDropList(dragRefs)
        );

        this.updateDropList(this.draggablesDirective.getDragRefs());
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public getDropListRef(): DropListRef {
        return this.dropListRef;
    }

    private onDropped(event: DropListDroppedEventInterface): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }

        this.onElementOver = false;
    }

    private updateDropList(dragRefs: DragRef[]): void {
        this.dragRefs = dragRefs;
        this.dropListRef.withItems(this.dragRefs);
        this.itemsChange.emit(this.items);
    }

    private onEntered(): void {
        this.onElementOver = true;
    }

    private onExited(): void {
        this.onElementOver = false;
    }
}
