import {
    AfterContentInit,
    Component,
    ContentChildren,
    forwardRef,
    Input,
    OnDestroy,
    QueryList,
} from '@angular/core';
import {
    AssignmentSelectOptionComponent,
    AssignmentSelectOptionInterface,
} from 'app/content/screen/components/assignment-select-option/assignment-select-option.component';
import {ExerciseComponent} from 'app/content/screen/exercise/exercise.component';
import {StringUtil} from 'app/util/string/string.util';
import {Subscription} from 'rxjs';

@Component({
    selector: 'assignment-single-select',
    templateUrl: './assignment-single-select.component.html',
    styleUrls: ['./assignment-single-select.component.scss'],
    providers: [
        {
            provide: ExerciseComponent,
            useExisting: forwardRef(() => AssignmentSingleSelectComponent),
        },
    ],
})
export class AssignmentSingleSelectComponent
    extends ExerciseComponent
    implements AfterContentInit, OnDestroy
{
    @Input()
    public id?: string;

    @Input('data-multiple-select')
    public multipleSelect?: string | boolean;

    @ContentChildren(AssignmentSelectOptionComponent)
    protected options!: QueryList<AssignmentSelectOptionInterface>;

    protected subscriptions: Subscription[] = [];

    public ngAfterContentInit(): void {
        if (!this.id) {
            this.id = StringUtil.random(6);
        }

        this.setup();
    }

    public ngOnDestroy(): void {
        this.cleanSubscriptions();
    }

    public setup(): void {
        this.cleanSubscriptions();

        this.options.forEach(option => {
            option.groupId = this.getOptionGroupId();
            option.multipleSelect = this.isMultipleSelect();

            this.subscriptions.push(
                option.selectedChange.subscribe(
                    (changedOption: AssignmentSelectOptionInterface) =>
                        this.handleOptionSelected(changedOption)
                )
            );
        });
    }

    public check(): boolean {
        let allOk = true;

        this.options.forEach(option => {
            option.checkAnswerState();
            if (option.isWrong()) {
                allOk = false;
            }
        });

        return allOk;
    }

    public reset(): void {
        this.options.forEach(option => option.reset());
    }

    public solve(): void {
        this.options.forEach(option => {
            option.highlightIfCorrect();
            option.checkAnswerState();
        });
    }

    public hasAnswer(): boolean {
        return this.options.some(option => option.isChecked());
    }

    public hasWrong(): boolean {
        return this.options.some(option => option.isWrong());
    }

    public retry(): void {
        this.options.forEach(option => {
            if (option.isWrong()) {
                option.reset();
            }
        });
    }

    protected getOptionGroupId(): string {
        return String(this.id);
    }

    private cleanSubscriptions(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private handleOptionSelected(
        changedOption: AssignmentSelectOptionInterface
    ): void {
        this.options.forEach(option => {
            if (option !== changedOption) {
                option.reset();
            }
        });
    }

    private isMultipleSelect(): boolean {
        return this.multipleSelect === 'true' || this.multipleSelect === true;
    }
}
