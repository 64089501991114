import {CdkDragEnd} from '@angular/cdk/drag-drop';
import {PointInterface} from 'app/classes/point.class';

export class DragDropUtil {
    private static adjustPositionOffset(
        currentAxisValue: number,
        elementLimit: number,
        boundaryLimit: number,
        offset: number
    ): number {
        const boundaryBefore = offset * -1;
        const boundaryAfter = boundaryLimit - (elementLimit + offset);

        if (currentAxisValue < boundaryBefore) {
            return boundaryBefore;
        } else if (currentAxisValue > boundaryAfter) {
            return boundaryAfter;
        }

        return currentAxisValue;
    }

    public forDragEndEvent(
        position: PointInterface,
        event: CdkDragEnd
    ): PointInterface {
        const element = event.source.element.nativeElement;
        const boundaryElement = event.source.boundaryElement as HTMLElement;

        const elementRect = element.getBoundingClientRect();
        const boundaryRect = boundaryElement.getBoundingClientRect();

        position.x = DragDropUtil.adjustPositionOffset(
            position.x,
            elementRect.width,
            boundaryRect.width,
            element.offsetLeft
        );
        position.y = DragDropUtil.adjustPositionOffset(
            position.y,
            elementRect.height,
            boundaryRect.height,
            element.offsetTop
        );

        return position;
    }
}
