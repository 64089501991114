import {ElementRef, HostBinding} from '@angular/core';
import {ZoomableViewService} from 'app/zoomable-view/service/zoomable-view/zoomable-view.service';
import {Point, PointInterface} from 'app/classes/point.class';

export abstract class AbstractZoomableViewDirective extends MutationObserver {
    @HostBinding('style.transform')
    protected transform = 'translate(0px, 0px) scale(1) translateZ(0)';

    @HostBinding('style.transform-origin')
    private transformOrigin = 'top left';

    private attributeTimeOut?: ReturnType<typeof setTimeout>;

    protected readonly nativeElement: HTMLDivElement;
    protected transformPoint: PointInterface = new Point();
    protected scale = 1;
    protected attributeTimeOutValue = 500;

    /**
     * Triggered by DOM changes through MutationObserver
     */
    protected abstract handleAttributeMutationEvent(
        attributeMutationRecord: MutationRecord
    ): void;

    protected constructor(
        protected elementRef: ElementRef,
        protected zoomableViewService: ZoomableViewService
    ) {
        super(mutationRecords => this.onMutationEvent(mutationRecords));

        this.nativeElement = this.elementRef.nativeElement;
        this.observe(this.nativeElement, {
            attributes: true,
            attributeOldValue: true,
        });
    }

    protected setTransform(x: number, y: number, scale: number): void {
        this.zoomableViewService.setScaleAmount(scale);
        this.transform = `translate(${x}px, ${y}px) scale(${scale}) translateZ(0)`;
    }

    protected setTransformPointAndScale(
        transformPoint: PointInterface,
        scale: number
    ): void {
        this.scale = scale;
        this.transformPoint = transformPoint;

        this.setTransform(transformPoint.x, transformPoint.y, scale);
    }

    protected reset(): void {
        this.setTransformPointAndScale(new Point(), 1);
    }

    /**
     * Updating DOM properties using Angular has a small delay before it's actually applied to the DOM.
     * Extending MutationObserver and observing it's DOM attributes allows us to monitor changes to the
     * DOM when they're actually applied to the DOM. We can then be 100% sure the change happened and
     * trigger any followup events or logic.
     */
    private onMutationEvent(mutationRecords: MutationRecord[]): void {
        const attributeMutationRecord: MutationRecord | undefined =
            mutationRecords.filter(mutationRecord => {
                return null !== mutationRecord.oldValue;
            })[0];

        if (undefined === attributeMutationRecord) {
            return;
        }

        if (undefined !== this.attributeTimeOut) {
            clearTimeout(this.attributeTimeOut);
        }

        this.attributeTimeOut = setTimeout(() => this.attributeTimeOutValue);
    }
}
