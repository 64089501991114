export const ShowTimes = [
    {
        label: '1',
        value: 1000,
    },
    {
        label: '2',
        value: 2000,
    },
    {
        label: '5',
        value: 5000,
    },
    {
        label: '10',
        value: 10000,
    },
    {
        label: '20',
        value: 20000,
    },
    {
        label: '30',
        value: 30000,
    },
];

export const HiddenTimes = ShowTimes;

export const NumberOfWords = [
    {
        label: '5',
        value: 5,
    },
    {
        label: '10',
        value: 10,
    },
    {
        label: '15',
        value: 15,
    },
    {
        label: '20',
        value: 20,
    },
];
