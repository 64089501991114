declare global {
    interface Window {
        usabilla_live: unknown;
        lightningjs: {
            require: (a: string, b: string) => {};
        };
    }
}

export const enableUsabilla = () => {
    window.usabilla_live = window.lightningjs.require(
        'usabilla_live',
        '//w.usabilla.com/c35ebf5726b1.js'
    );
};
