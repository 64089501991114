import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';
import {combineLatest, Subject, Subscription} from 'rxjs';

interface StripItem {
    x: number;
    hidden: boolean;
}

@Component({
    selector: 'app-percent-strip',
    templateUrl: './percent-strip.component.html',
    styleUrls: ['./percent-strip.component.scss'],
})
export class PercentStripComponent
    extends AbstractTool
    implements OnInit, OnDestroy
{
    public static readonly id = 'procentenstrook';
    public static readonly width = 690;
    public static readonly height = 150;

    private startX = 7.62;
    private incrementX = 5;
    private sub!: Subscription;

    public numberOfStrips = 100;
    public showPercentages = true;
    public strips: Array<StripItem> = [];
    public isMouseDown$: Subject<boolean> = new Subject();
    public isMouseOver$: Subject<boolean> = new Subject();
    public mouseMove$: Subject<number> = new Subject();

    public ngOnInit(): void {
        this.strips = this.buildStrips(this.numberOfStrips); // Enable all strips by default
        this.sub = combineLatest([
            this.isMouseOver$,
            this.isMouseDown$,
            this.mouseMove$,
        ]).subscribe(([over, down, stripIndex]) => {
            if (over && down) {
                this.strips = this.buildStrips(stripIndex);
            }
        });
    }

    public buildStrips(hiddenFrom: number): Array<StripItem> {
        const items = [];
        for (let i = 0; i < this.numberOfStrips; i++) {
            items.push(
                this.getStrip(this.startX + this.incrementX * i, i > hiddenFrom)
            );
        }
        return items;
    }

    public getStrip(x: number, hidden: boolean): StripItem {
        return {
            x,
            hidden,
        };
    }

    public stripTracker(index: number): number {
        return index;
    }

    public toggleshowPercentages(): void {
        this.showPercentages = !this.showPercentages;
    }

    public ngOnDestroy(): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }
}
