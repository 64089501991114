import {Injectable} from '@angular/core';
import {
    ThasClient,
    ThasClientEnvironment,
    ThasEvent,
} from '@thiememeulenhoff/analytics-service-client';
import {environment} from 'app/environment';
import {ApiUserInterface} from 'app/interface/api-user-response.interface';
import {UserService} from '../user/user.service';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    private client?: ThasClient;

    constructor(private userService: UserService) {
        this.setup();
    }

    private setup(): void {
        if (!environment.thasToken) {
            return;
        }

        this.client = new ThasClient({
            token: environment.thasToken,
            environment: environment.environment as ThasClientEnvironment,
            windowAccessEnabled: false,
        });

        this.subscribeToUserChanges();
    }

    public send(event: ThasEvent): void {
        if (!this.client) {
            return;
        }

        this.client.send(event);
    }

    private subscribeToUserChanges(): void {
        this.userService.user$.subscribe(user => this.setActorByUser(user));
    }

    private setActorByUser(user?: ApiUserInterface): void {
        if (!this.client) {
            return;
        }

        const actor = user
            ? {
                  actor: user.userIdentifier,
                  role: user.role,
              }
            : undefined;

        this.client.setActor(actor);
    }
}
