import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    Input,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {BaseBbvmsComponent} from 'app/content/screen/components/bbvms/bbvms.component';
import {
    GoogleAnalyticsActionsEnum,
    GoogleAnalyticsCategoriesEnum,
} from 'app/service/google-analytics/categories.enum';
import {GoogleAnalyticsService} from 'app/service/google-analytics/google-analytics.service';
import {ContentService} from 'app/service/content/content.service';
import {AnalyticsService} from 'app/service/analytics/analytics.service';
import {ThasMediaEvent} from '@thiememeulenhoff/analytics-service-client';

@Component({
    selector: 'bbvms-video',
    templateUrl: './bbvms-video.component.html',
    styleUrls: ['./bbvms-video.component.scss'],
})
export class BbvmsVideoComponent
    extends BaseBbvmsComponent
    implements AfterViewInit, AfterViewChecked
{
    @ViewChild('bbvmsContainer', {read: ViewContainerRef, static: true})
    public bbvmsContainer!: ViewContainerRef;

    @Input('data-video-id')
    public videoId!: string;

    constructor(
        private gaService: GoogleAnalyticsService,
        private analyticsService: AnalyticsService,
        contentService: ContentService
    ) {
        super(contentService);
    }

    protected getBbvmsContainer(): ViewContainerRef {
        return this.bbvmsContainer;
    }

    protected getAssetId(): string | undefined {
        return this.videoId;
    }

    public play(event?: Event): Promise<void> {
        const thasEvent = new ThasMediaEvent(
            'video',
            'started',
            this.getAssetId()
        );
        this.analyticsService.send(thasEvent);

        this.gaService.event(
            GoogleAnalyticsActionsEnum.MediaVideoPlay,
            GoogleAnalyticsCategoriesEnum.Media
        );

        return super.play(event);
    }
}
