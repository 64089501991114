import {ToolRingButtonInterface} from 'app/tool/classes/tool-ring-button.class';
import {Shadow} from 'app/tool/enum/filter.enum';
import {Radius} from 'app/tool/enum/radius.enum';

export interface ToolRingInterface {
    id: string;
    radius: Radius;
    shadow: Shadow;
    classes: string[];
    buttons: ToolRingButtonInterface[];
    visible?: boolean;
}

export class ToolRing implements ToolRingInterface {
    public readonly classes: string[] = ['svg__circle'];
    public readonly shadow: Shadow;
    public visible: boolean;

    constructor(
        public id: string,
        public radius: Radius,
        public buttons: ToolRingButtonInterface[] = [],
        visible?: boolean,
        shadow?: Shadow,
        classes?: string[]
    ) {
        (this.visible = undefined !== visible ? visible : true),
            (this.shadow = shadow || Shadow.Outset);
        this.classes.push(...(classes || []));
    }
}
