import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeeplinkComponent} from 'app/page/deeplink/deeplink.component';
import {NotFoundComponent} from 'app/page/not-found/not-found.component';
import {NavigationModule} from 'app/page/navigation/navigation.module';

@NgModule({
    declarations: [NotFoundComponent, DeeplinkComponent],
    imports: [CommonModule, NavigationModule],
    exports: [NotFoundComponent, DeeplinkComponent],
})
export class PageModule {}
