export class ContentException extends Error {
    public static resourceIsUndefined(): ContentException {
        return new ContentException('Resource is undefined');
    }

    public static noResourceChildren(): ContentException {
        return new ContentException('Resource has no children');
    }

    public static noUrlSegments(): ContentException {
        return new ContentException('No segments to extract resource from');
    }
}
