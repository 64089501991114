import {Component, OnDestroy, OnInit} from '@angular/core';
import {DrawingStateEnum} from 'app/drawing/enum/state.enum';
import {DrawingService} from 'app/drawing/service/drawing/drawing.service';
import {DrawingToScreenCommunicatorService} from 'app/drawing/service/screen-communicator/screen-communicator.service';
import {Subscription} from 'rxjs';

// eslint-disable-next-line
declare let fabric: any | never;

@Component({
    selector: 'app-drawing-layer',
    templateUrl: 'layer.component.html',
})
export class DrawingLayerComponent implements OnInit, OnDestroy {
    public drawingEnabled = false;
    private drawingStateSubscription?: Subscription;

    public constructor(
        private drawingService: DrawingService,
        private drawingToScreenCommunicator: DrawingToScreenCommunicatorService
    ) {
        this.drawingStateChanged(drawingService.state);
    }

    public ngOnInit(): void {
        this.drawingStateSubscription =
            this.drawingService.subscribeToStateChange(change =>
                this.drawingStateChanged(change)
            );
    }

    public ngOnDestroy(): void {
        if (this.drawingStateSubscription) {
            this.drawingStateSubscription.unsubscribe();
        }
    }

    public handleDrawn(canvas: fabric.Object): void {
        this.drawingToScreenCommunicator.drawn(canvas);
    }

    private drawingStateChanged(change: DrawingStateEnum): void {
        this.drawingEnabled = this.drawingService.isDrawingActive(change);
    }
}
