import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';

@Component({
    selector: 'app-htethd',
    templateUrl: './htethd.component.html',
})
export class HtethdComponent extends AbstractTool {
    public static readonly id = 'htethd';
    public static readonly width = 450;
    public static readonly height = 150;

    public rangeModel = {
        H: 0,
        T: 0,
        E: 0,
        t: 0,
        h: 0,
        d: 0,
    };

    constructor() {
        super();
    }
}
