import {PortalModule} from '@angular/cdk/portal';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ComponentModule} from 'app/tool-layer/component/component.module';
import {ToolLayerComponent} from 'app/tool-layer/component/layer/tool-layer.component';
import {ServiceModule} from 'app/tool-layer/service/service.module';
import {ToolsModule} from 'app/tool-layer/tools/tools.module';

@NgModule({
    imports: [
        BrowserModule,
        PortalModule,
        ToolsModule,
        ServiceModule,
        ComponentModule,
    ],
    exports: [ToolLayerComponent],
})
export class ToolLayerModule {}
