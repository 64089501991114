import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {BaseBbvmsComponent} from 'app/content/screen/components/bbvms/bbvms.component';
import {
    GoogleAnalyticsActionsEnum,
    GoogleAnalyticsCategoriesEnum,
} from 'app/service/google-analytics/categories.enum';
import {GoogleAnalyticsService} from 'app/service/google-analytics/google-analytics.service';
import {ContentService} from 'app/service/content/content.service';
import {AnalyticsService} from 'app/service/analytics/analytics.service';
import {ThasMediaEvent} from '@thiememeulenhoff/analytics-service-client';

export interface BbvmsAudioComponentInterface {
    audioId: string | number;
    selected: boolean;
    selectedChange: EventEmitter<void>;
    isSmall: boolean;

    play(event: Event): void;
}

@Component({
    selector: 'bbvms-audio',
    templateUrl: './bbvms-audio.component.html',
    styleUrls: ['./bbvms-audio.component.scss'],
})
export class BbvmsAudioComponent
    extends BaseBbvmsComponent
    implements
        BbvmsAudioComponentInterface,
        OnInit,
        AfterViewChecked,
        OnChanges
{
    @ViewChild('bbvmsContainer', {read: ViewContainerRef, static: true})
    public bbvmsContainer!: ViewContainerRef;

    @Input('data-audio-id')
    public audioId!: string | number;

    @Input()
    public autoplay = false;

    @Input()
    public selected = false;
    @Output()
    public readonly selectedChange = new EventEmitter<void>();

    public isSmall!: boolean;
    public isIconOnly!: boolean;

    /**
     * TODO DIGB-1333: The icon part of this component need refactoring, parent element should determine it's size.
     */
    constructor(
        private element: ElementRef,
        private gaService: GoogleAnalyticsService,
        private analyticsService: AnalyticsService,
        private contentService: ContentService
    ) {
        super(contentService);
    }

    public ngOnInit(): void {
        this.handleComponentChanges();
        if (this.autoplay) {
            this.play();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.handleComponentChanges(changes);
    }

    public play(event?: Event): Promise<void> {
        const promise: Promise<void> = super.play(event);

        this.selected = true;
        this.selectedChange.emit();

        this.gaService.event(
            GoogleAnalyticsActionsEnum.MediaAudioPlay,
            GoogleAnalyticsCategoriesEnum.Media
        );

        const thasEvent = new ThasMediaEvent(
            'audio',
            'started',
            this.getAssetId()
        );
        this.analyticsService.send(thasEvent);

        return promise;
    }

    protected onStateChange(): void {
        super.onStateChange();

        if (this.isIdle()) {
            this.selected = false;
        }
    }

    protected getBbvmsContainer(): ViewContainerRef {
        return this.bbvmsContainer;
    }

    protected getAssetId(): string | undefined {
        return this.audioId ? String(this.audioId) : undefined;
    }

    private handleComponentChanges(changes?: SimpleChanges): void {
        const classList: DOMTokenList = this.element.nativeElement.classList;
        this.isSmall = classList.contains('asset--small');
        this.isIconOnly = classList.contains('asset--icon-only');

        if (changes && changes.audioId) {
            this.resetAndLoadPlayer();
            if (this.autoplay) {
                this.play();
            }
        }
    }
}
