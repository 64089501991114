import {
    Component,
    AfterContentInit,
    ContentChild,
    OnDestroy,
    Output,
    EventEmitter,
    HostListener,
    HostBinding,
    Input,
} from '@angular/core';
import {
    ImageComponent,
    ImageComponentInterface,
} from 'app/content/screen/components/image/image.component';
import {
    BbvmsAudioComponent,
    BbvmsAudioComponentInterface,
} from 'app/content/screen/components/bbvms/bbvms-audio/bbvms-audio.component';
import {Subscription} from 'rxjs';

export interface SelectedEvent {
    entry: WordsEntryComponent;
    child?: object;
    image?: string;
}

export interface WordsEntryComponentInterface {
    selectedChange: EventEmitter<SelectedEvent>;

    resetSelection(event: SelectedEvent): void;
}

@Component({
    selector: 'words-entry',
    templateUrl: './words-entry.component.html',
    styleUrls: ['./words-entry.component.scss'],
})
export class WordsEntryComponent
    implements WordsEntryComponentInterface, AfterContentInit, OnDestroy
{
    @Input()
    @HostBinding('class.words-entry--selected')
    public selected = false;
    @Output()
    public selectedChange = new EventEmitter<SelectedEvent>();

    @ContentChild(ImageComponent)
    private image?: ImageComponentInterface;

    @ContentChild(BbvmsAudioComponent)
    private audio?: BbvmsAudioComponentInterface;

    private subscriptions: Subscription[] = [];

    public ngAfterContentInit(): void {
        if (this.audio) {
            this.subscriptions.push(
                this.audio.selectedChange.subscribe(() => {
                    this.selectedChange.emit({
                        entry: this,
                        child: this.audio,
                    });
                })
            );
        }

        if (this.image) {
            this.subscriptions.push(
                this.image.selectedChange.subscribe(
                    (imageSrc: string | undefined) => {
                        this.selectedChange.emit({
                            entry: this,
                            child: this.image,
                            image: imageSrc,
                        });
                    }
                )
            );
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    @HostListener('click', ['$event'])
    public onEmitPhotoAndPlaySound(event: Event): void {
        if (event.defaultPrevented) {
            return;
        }

        // Important: order is key here. Send image last so parent
        // may be able to handle the image src.

        if (this.audio) {
            this.audio.play(event);
        }

        if (this.image) {
            this.image.imageClickEmitChange(event);
        }

        this.selected = true;
    }

    public resetSelection(event: SelectedEvent): void {
        if (this === event.entry && undefined === event.child) {
            return;
        }

        if (this.audio && this.audio !== event.child) {
            this.audio.selected = false;
        }

        if (this.image && this.image !== event.child) {
            this.image.selected = false;
        }

        if (this !== event.entry) {
            this.selected = false;
        }
    }
}
