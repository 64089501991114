import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentModule} from 'app/component/component.module';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {TddhteComponent} from './tddhte.component';

@NgModule({
    imports: [ComponentModule, CommonModule],
    declarations: [TddhteComponent],
    entryComponents: [TddhteComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(TddhteComponent),
            multi: true,
        },
    ],
})
export class TddhteModule {}
