import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LineSvgComponent} from 'app/tool-layer/tools/line-svg/line-svg.component';

@NgModule({
    declarations: [LineSvgComponent],
    imports: [CommonModule],
    exports: [LineSvgComponent],
})
export class LineSvgModule {}
