import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import {ScrollComponent} from 'app/component/scroll/scroll.component';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-scroll-indicator',
    templateUrl: './scroll-indicator.component.html',
    styleUrls: ['./scroll-indicator.component.scss'],
})
export class ScrollIndicatorComponent implements OnChanges, OnDestroy {
    @Input('scroll')
    public scroll?: ScrollComponent;

    public indicatorBars: number[] = [];

    private barCount = 0;
    private subscriptions: Subscription[] = [];
    private readonly SCROLL_INDICATOR_MAX_ITEMS = 8;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.scroll) {
            this.deregister();
            this.register();
        }
    }

    public ngOnDestroy(): void {
        this.deregister();
    }

    public get activeBar(): number {
        if (!this.scroll) {
            return 0;
        }

        const rounded = Math.ceil(
            (this.scroll.scrollXPercentage + 1) / (100 / this.barCount)
        );

        return rounded === 0
            ? 1
            : rounded > this.barCount
            ? this.barCount
            : rounded;
    }

    public handleIndicatorClick(bar: number): void {
        if (!this.scroll) {
            return;
        }

        const percentage = (bar / (this.barCount - 1)) * 100;
        this.scroll.setScrollPercentage(percentage, 0);
    }

    private register(): void {
        if (!this.scroll) {
            return;
        }

        this.subscriptions.push(
            this.scroll.updated.subscribe(() => this.updateIndicatorBarCount())
        );
    }

    private deregister(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    private updateIndicatorBarCount(): void {
        if (!this.scroll) {
            return;
        }

        const {clientWidth} = this.scroll.getNativeElement();
        const {scrollWidth} = this.scroll.getContentElement();

        this.barCount = Math.min(
            Math.ceil(scrollWidth / clientWidth),
            this.SCROLL_INDICATOR_MAX_ITEMS
        );
        this.indicatorBars.length = 0;
        this.indicatorBars.push(..._.range(0, this.barCount));
    }
}
