import {ElementRef, Injectable} from '@angular/core';
import {DragDrop, DragRef, DropListRef} from '@angular/cdk/drag-drop';

@Injectable()
export class DragDropService {
    constructor(private dragDrop: DragDrop) {}

    public createDragRef(elementRef: ElementRef): DragRef {
        return this.dragDrop.createDrag(elementRef);
    }

    public createDropListRef(elementRef: ElementRef): DropListRef {
        return this.dragDrop.createDropList(elementRef);
    }
}
