import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentModule} from 'app/component/component.module';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {HdtddhteComponent} from './hdtddhte.component';

@NgModule({
    imports: [ComponentModule, CommonModule],
    declarations: [HdtddhteComponent],
    entryComponents: [HdtddhteComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(HdtddhteComponent),
            multi: true,
        },
    ],
})
export class HdtddhteModule {}
