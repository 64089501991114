import {Injectable} from '@angular/core';
import {KeyCodeEnum} from 'app/enum/key-code.enum';
import {SvgFontAwesomeEnum} from 'app/enum/svg.enum';
import {ContentService} from 'app/service/content/content.service';
import {Resource} from 'app/service/resource/classes/resource.class';
import {TocService} from 'app/toc/service/toc/toc.service';
import {ToolRingInterface} from 'app/tool/classes/tool-ring.class';
import {Radius} from 'app/tool/enum/radius.enum';
import {
    ToolRingConfigInterface,
    ToolRingFactory,
} from 'app/tool/factory/tool-ring/tool-ring.factory';
import {AbstractToolRingService} from 'app/tool/rings/base/abstract-tool-ring.service';
import {ExerciseRingService} from 'app/tool/rings/exercise-ring/exercise-ring.service';
import {RingIds} from 'app/tool/rings/ring-ids.enum';
import {ViewRingService} from 'app/tool/rings/view-ring/view-ring.service';
import {ToolboxModalService} from 'app/toolbox/service/modal/modal.service';
import {ToolboxService} from 'app/toolbox/service/toolbox/toolbox.service';
import {ZoomRingService} from 'app/tool/rings/zoom-ring/zoom-ring.service';
import {OuterRingService} from 'app/tool/service/outer-ring.service';

@Injectable()
export class NavigationRingService extends AbstractToolRingService {
    private readonly toggle!: ToolRingInterface;
    private readonly inner!: ToolRingInterface;
    private outer!: ToolRingInterface;

    constructor(
        protected toolRingFactory: ToolRingFactory,
        protected toolboxService: ToolboxService,
        protected toolboxModalService: ToolboxModalService,
        private tocService: TocService,
        private exerciseRingService: ExerciseRingService,
        private viewRingService: ViewRingService,
        private contentService: ContentService,
        private zoomRingService: ZoomRingService,
        private outerRingService: OuterRingService
    ) {
        super(toolRingFactory, toolboxService, toolboxModalService);

        this.contentService.subscribeToCurrentResource(resource =>
            this.onCurrentResourceUpdated(resource)
        );

        outerRingService.subscribeToOuterRingChange(id =>
            this.onOuterRingChange(id)
        );

        [this.toggle, this.inner] = this.toolRingFactory.createAll(
            this.config()
        );

        this.updateOuter(this.currentResourceIsQuestion());
    }

    public getId(): RingIds {
        return RingIds.NavigationRing;
    }

    public isActive(): boolean {
        return true;
    }

    public render(): ToolRingInterface[] {
        const rings = [this.toggle, this.inner];
        if (this.outer) {
            rings.push(this.outer);
        }

        return rings;
    }

    private config(): ToolRingConfigInterface[] {
        return [
            {
                id: 'toggle',
                radius: Radius.Small,
                visible: true,
                buttons: [
                    {
                        id: 'content-times',
                        icon: {
                            path: SvgFontAwesomeEnum.SolidEllipsisVertical,
                            altPath: SvgFontAwesomeEnum.SolidTimes,
                        },
                        classes: [
                            'svg__button--toggler',
                            'svg__button--green-light',
                        ],
                        callback: () => this.toggleExerciseRing(),
                    },
                ],
            },
            {
                id: 'navigation',
                radius: Radius.Medium,
                rotate: 315,
                visible: true,
                buttons: [
                    {
                        id: 'content-next',
                        icon: SvgFontAwesomeEnum.SolidArrowAltRight,
                        classes: ['svg__button--navigate-next'],
                        isDisabledCallback: () => {
                            return this.currentResourceIsLast();
                        },
                        callback: () =>
                            this.contentService.navigateSlide(
                                KeyCodeEnum.ArrowRight
                            ),
                    },
                    {
                        id: 'content-edit',
                        icon: SvgFontAwesomeEnum.SolidEdit,
                        callback: () =>
                            this.toggleRingByServiceId(RingIds.EditRing),
                    },
                    {
                        id: 'content-prev',
                        icon: SvgFontAwesomeEnum.SolidArrowAltLeft,
                        classes: ['svg__button--navigate-previous'],
                        isDisabledCallback: () => {
                            return this.currentResourceIsFirst();
                        },
                        callback: () =>
                            this.contentService.navigateSlide(
                                KeyCodeEnum.ArrowLeft
                            ),
                    },
                    {
                        id: 'content-toc',
                        icon: SvgFontAwesomeEnum.SolidList,
                        callback: () => this.tocService.open(),
                    },
                ],
            },
        ];
    }

    private onOuterRingChange(id: RingIds): void {
        switch (id) {
            case RingIds.ZoomRing:
                this.outer = this.zoomRingService.render()[0];
                break;
            case RingIds.ExerciseRing:
                this.outer = this.exerciseRingService.render()[0];
                break;
            case RingIds.ViewRing:
                this.outer = this.viewRingService.render()[0];
                break;
        }

        this.outer.visible = true;
        this.toggleRingByServiceId();
    }

    private updateOuter(isQuestion: boolean): void {
        this.outer = isQuestion
            ? this.exerciseRingService.render()[0]
            : this.viewRingService.render()[0];

        this.toggleRingByServiceId();
    }

    private toggleExerciseRing(visible?: boolean): void {
        this.outer.visible =
            visible !== undefined ? visible : !this.outer.visible;
        this.toggle.buttons[0].useAltIcon(this.outer.visible);

        this.toggleRingByServiceId();
    }

    private onCurrentResourceUpdated(resource: Resource | undefined): void {
        this.updateOuter(resource ? resource.isQuestion() : false);

        if (!resource) {
            this.toggleRingByServiceId();

            return;
        }

        this.toggleExerciseRing(resource.isQuestion());
    }

    private currentResourceIsQuestion(): boolean {
        const resource = this.contentService.getCurrentResource();
        if (!resource) {
            return false;
        }

        return resource.isQuestion();
    }

    private currentResourceIsFirst(): boolean {
        const resource = this.contentService.getCurrentResource();
        if (!resource) {
            return false;
        }

        return resource.isFirstChild();
    }

    private currentResourceIsLast(): boolean {
        const resource = this.contentService.getCurrentResource();
        if (!resource) {
            return false;
        }

        return resource.isLastChild();
    }
}
