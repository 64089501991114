import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DrawingCanvasComponent} from 'app/drawing/component/canvas/canvas.component';
import {DrawingActionEnum} from 'app/drawing/enum/action.enum';
import {DrawingService} from 'app/drawing/service/drawing/drawing.service';
import {fabric} from 'fabric';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-tool-drawing-canvas',
    templateUrl: 'tool-canvas.component.html',
})
export class ToolDrawingCanvasComponent implements OnInit, OnDestroy {
    @ViewChild(DrawingCanvasComponent)
    private canvas!: DrawingCanvasComponent;

    private drawingActionSubscription?: Subscription;
    private undoQueue: fabric.Object[] = [];
    private redoQueue: fabric.Object[] = [];

    public constructor(private drawingService: DrawingService) {}

    public ngOnInit(): void {
        this.drawingActionSubscription = this.drawingService.subscribeToActions(
            action => this.handleDrawingAction(action)
        );
    }

    public ngOnDestroy(): void {
        if (this.drawingActionSubscription) {
            this.drawingActionSubscription.unsubscribe();
        }

        this.drawingService.unsubscribeFromActions();
    }

    public handleDrawn(canvas: fabric.Object): void {
        this.undoQueue.push(canvas);
        this.redoQueue = [];
    }

    public handleDrawingAction(action: DrawingActionEnum): void {
        switch (action) {
            case DrawingActionEnum.Undo:
                this.handleUndo();
                break;

            case DrawingActionEnum.Redo:
                this.handleRedo();
                break;

            case DrawingActionEnum.Clear:
                this.handleClear();
                break;
        }
    }

    private handleUndo(): void {
        const object = this.undoQueue.pop();
        if (!object) {
            return;
        }

        this.redoQueue.push(object);
        this.canvas.removeObject(object);
    }

    private handleRedo(): void {
        const object = this.redoQueue.pop();
        if (!object) {
            return;
        }

        this.undoQueue.push(object);
        this.canvas.addObject(object);
    }

    private handleClear(): void {
        this.undoQueue = [];
        this.redoQueue = [];

        this.canvas.clear();
    }
}
