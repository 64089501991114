import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-bottom-sheet-title',
    templateUrl: './bottom-sheet-title.component.html',
    styleUrls: ['./bottom-sheet-title.component.scss'],
})
export class BottomSheetTitleComponent {
    @Input()
    public disabled = false;

    @Output()
    public readonly dismiss: EventEmitter<void> = new EventEmitter();

    public onDismiss(): void {
        if (this.disabled) {
            return;
        }
        this.dismiss.emit();
    }
}
