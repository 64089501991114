import {
    AfterViewChecked,
    AfterViewInit,
    OnDestroy,
    ViewContainerRef,
} from '@angular/core';
import {BbvmsEnum} from 'app/content/screen/components/bbvms/bbvms.enum';
import {Subject, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {ContentService} from 'app/service/content/content.service';

export interface BluebillywigPlayer {
    on(event: string, callback: () => void): void;
    off(event: string): void;
    play(event: string): void;
    seek(position: number): void;
    getState(): string;
    getDuration(): number;
    destruct(): void;
}

interface Bluebillywig {
    Player: new (url: string, options: {}) => BluebillywigPlayer;
}

declare global {
    interface Window {
        bluebillywig: Bluebillywig | null;
    }
}

/**
 * Documentation: https://support.bluebillywig.com/topics/player-api/
 */
export abstract class BaseBbvmsComponent
    implements AfterViewInit, AfterViewChecked, OnDestroy
{
    private player?: BluebillywigPlayer;
    private eventsSubject: Subject<BbvmsEnum> = new Subject();
    private finishedSubject: Subject<void> = new Subject();
    private subscriptions: Subscription[] = [];

    protected abstract getAssetId(): string | undefined;

    protected abstract getBbvmsContainer(): ViewContainerRef;

    protected constructor(contentService: ContentService) {
        this.subscriptions.push(
            contentService.subscribeToCurrentResource(() => this.stop())
        );
    }

    public ngAfterViewInit(): void {
        this.loadPlayer();
    }

    public ngAfterViewChecked(): void {
        this.loadPlayer();
    }

    public ngOnDestroy(): void {
        if (this.player && this.player.destruct) {
            this.player.destruct();
        }
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    public subscribeToEvents(fn: (event: BbvmsEnum) => void): Subscription {
        return this.eventsSubject.subscribe(fn);
    }

    public play(event?: Event): Promise<void> {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const player = this.player;
        if (undefined === player) {
            return Promise.reject('Player has not (yet) been loaded');
        }

        return new Promise(resolve => {
            this.finishedSubject.pipe(take(1)).subscribe(() => resolve());
            player.play('play');
        });
    }

    public stop(): void {
        const player = this.player;

        if (undefined === player || this.isIdle()) {
            return;
        }

        player.seek(player.getDuration());
    }

    public isIdle(): boolean {
        return this.getState() === BbvmsEnum.Idle;
    }

    protected resetAndLoadPlayer(): void {
        this.player = undefined;
        this.loadPlayer();
    }

    protected loadPlayer(): void {
        const player = this.player;
        if (undefined !== player) {
            return;
        }

        const assetId = this.getAssetId();
        if (!assetId) {
            return;
        }

        const bluebillywig = window.bluebillywig;
        if (!bluebillywig) {
            return;
        }

        const url = `https://thiememeulenhoff.bbvms.com/p/basic_digibord_responsive/c/${this.getAssetId()}.json`;
        this.player = new bluebillywig.Player(url, {
            target: this.getNativeElement(),
            autoPlay: true,
        });

        // https://support.bluebillywig.com/player-api/events-modes-and-phases/#4-0-states
        this.player.on('statechange', () => this.onStateChange());
        this.player.on('ended', () => this.finishedSubject.next());
        this.player.on('retractfullscreen', () =>
            this.handleFullScreenRetracted()
        );
    }

    protected onStateChange(): void {
        const player = this.player;

        if (player) {
            this.eventsSubject.next(player.getState() as BbvmsEnum);
        }
    }

    protected getState(): string | undefined {
        const player = this.player;
        if (player) {
            return player.getState();
        }

        return undefined;
    }

    protected getNativeElement(): HTMLElement {
        return this.getBbvmsContainer().element.nativeElement;
    }

    protected handleFullScreenRetracted(): void {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 100);
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 250);
    }
}
