import {ApiMethodGroupInterface} from 'app/interface/api-methods.interface';

export enum ApiResourceEnum {
    Method = 'method',
    MethodGroup = 'methodGroup',
    Chapter = 'chapter',
    Paragraph = 'paragraph',
    ItemMap = 'itemMap',
}

export interface ApiResourceInterface {
    contentPath: string;
    name: string;
    revision: number;
    type: string;
    question: boolean;
    additionalContent?: boolean;
    visible?: boolean;
    parent?: ApiResourceInterface | ApiMethodGroupInterface;
    category?: string;
}
