import {AfterViewInit, Component} from '@angular/core';

declare const MathJax: {typeset: () => void};

@Component({
    selector: 'equation',
    templateUrl: 'equation.component.html',
})
export class EquationComponent implements AfterViewInit {
    public ngAfterViewInit(): void {
        this.initializeMath();
    }

    private initializeMath(): void {
        MathJax.typeset();
    }
}
