import {Directive, HostListener, Input} from '@angular/core';
import {ActiveToolService} from 'app/tool-layer/service/active/active-tool.service';

@Directive({
    selector: '[linkOpenTool]',
})
export class LinkOpenToolDirective {
    @Input()
    public linkOpenTool!: string;

    public constructor(private activeToolService: ActiveToolService) {}

    @HostListener('click', ['$event'])
    private onClick(event: Event) {
        event.preventDefault();

        const name = this.linkOpenTool;
        void this.activeToolService.openToolOrImage(name);
    }
}
