import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {HiddenTimes, NumberOfWords, ShowTimes} from './data/flasher-options';
import {catchError, filter, switchMap} from 'rxjs/operators';
import {
    Package,
    Selections,
    WordsResponse,
} from 'app/tool-layer/tools/word-flasher/models/flasher.models';
import {FlasherService} from 'app/tool-layer/tools/word-flasher/service/flasher.service';

@Component({
    selector: 'app-word-flasher',
    templateUrl: './word-flasher.component.html',
    styleUrls: ['./word-flasher.component.scss'],
})
export class WordFlasherComponent
    extends AbstractTool
    implements OnInit, OnDestroy
{
    public static readonly id = 'woordflitser';
    public static readonly width = 260;
    public static readonly height = 475;
    public static readonly limit = 1;
    public static readonly immediatelyAdd = true;

    private subs: Array<Subscription> = [];
    public showPackageSelector = false;
    public allPackages$!: Observable<Package[]>;
    public showTimes = ShowTimes;
    public hiddenTimes = HiddenTimes;
    public numberOfWords = NumberOfWords;
    public selectedPackage$: Subject<Package> = new Subject();
    public selections: Selections = {
        package: null,
        showTime: 1000,
        hiddenTime: 1000,
        numberOfWords: 5,
        items: [],
    };

    constructor(private flasherService: FlasherService) {
        super();
        flasherService.init();
    }

    public ngOnInit(): void {
        // Monitor the selectedPackage stream and when it emits, GET the word list for the package
        this.subs.push(
            this.selectedPackage$
                .pipe(
                    filter(value => !!value),
                    switchMap((packageToLoad: Package) => {
                        // Reset package and items in selections in order to show a loading message and disable the start button.
                        this.selections = {
                            ...this.selections,
                            items: [],
                            package: null,
                        };
                        return this.getWordsByUrl(packageToLoad.url);
                    })
                )
                .subscribe((words: WordsResponse) => {
                    this.selections = {
                        ...this.selections,
                        items: words.items,
                        package: words.name,
                    };
                })
        );

        // Set initial flasher for this lesson
        this.setFlasherForLesson();

        // Setup observable to load all Word Packages
        this.allPackages$ = this.flasherService.getAllPackages();
    }

    public setFlasherForLesson(): void {
        const initialPackage: Package = this.flasherService.getInitialPackage();
        this.selectedPackage$.next(initialPackage);
    }

    public startFlits(): void {
        this.flasherService.startPlayer(this.selections);
    }

    public selectPackage(wordPackage: Package): void {
        this.selectedPackage$.next(wordPackage);
    }

    public ngOnDestroy(): void {
        this.subs.map(sub => sub.unsubscribe());
    }

    private getWordsByUrl(url: string): Observable<WordsResponse> {
        return this.flasherService.getWordsByUrl(url).pipe(
            catchError(() =>
                of({
                    items: [],
                    name: 'Selecteer...',
                } as WordsResponse)
            )
        );
    }
}
