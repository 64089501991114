import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {
    Selections,
    SequenceItem,
    WordItem,
} from 'app/tool-layer/tools/word-flasher/models/flasher.models';

@Component({
    selector: 'app-word-flasher-player',
    templateUrl: './word-flasher-player.component.html',
    styleUrls: ['./word-flasher-player.component.scss'],
})
export class WordFlasherPlayerComponent implements OnInit {
    public readonly playerClass = 'word-flasher-player';
    public timeOut?: number;
    public sequence: Array<SequenceItem> = [];
    public currentItemIndex = 0;
    public currentItem!: SequenceItem;
    public controlsActive = false;
    public sequenceDone = false;
    public paused = false;
    public audioId?: string;

    @Input()
    public playerConfig!: Selections;

    @Output()
    public playerClose = new EventEmitter();

    ngOnInit(): void {
        this.sequence = this.buildSequence(this.playerConfig);
        this.setCurrentItemToIndex();
        this.doSequence();
    }

    public startOver(): void {
        clearTimeout(this.timeOut);
        this.currentItemIndex = 0;
        this.setCurrentItemToIndex();
        this.doSequence();
    }

    public pauseSequence(): void {
        clearTimeout(this.timeOut);
        this.controlsActive = true;

        if (
            !this.sequence[this.currentItemIndex].text &&
            !this.sequence[this.currentItemIndex].image
        ) {
            this.currentItemIndex--;
            this.setCurrentItemToIndex();
        }
    }

    public continueSequence(): void {
        this.doSequence();
    }

    public goNext(): void {
        if (this.currentItemIndex < this.sequence.length - 2) {
            this.currentItemIndex = this.currentItemIndex + 2;
            this.setCurrentItemToIndex();
        }
    }

    public goPrevious(): void {
        if (this.currentItemIndex > 0) {
            this.currentItemIndex = this.currentItemIndex - 2;
            this.setCurrentItemToIndex();
        }
    }

    public setCurrentItemToIndex(): void {
        this.currentItem = this.sequence[this.currentItemIndex];
        this.setAudioId(
            this.currentItem.audio ? this.currentItem.audio.id : undefined
        );
    }

    public closePlayer(): void {
        this.playerClose.emit();
    }

    private doSequence(): void {
        this.controlsActive = false;
        this.timeOut = window.setTimeout(() => {
            this.processSequence();
        }, this.currentItem.time);
    }

    public processSequence(): void {
        this.currentItemIndex++;
        if (this.currentItemIndex === this.sequence.length) {
            clearTimeout(this.timeOut);
            this.currentItemIndex = 0;
            this.setCurrentItemToIndex();
            this.sequenceDone = true;
        } else {
            this.setCurrentItemToIndex();
            this.doSequence();
        }
    }

    private buildSequence(config: Selections): SequenceItem[] {
        const sequence: SequenceItem[] = [];
        const showTime = config.showTime;
        const hiddenTime = config.hiddenTime;
        const items = this.getRandomItems(config.items, config.numberOfWords);

        items.forEach(item => {
            sequence.push({
                text: item.text,
                audio: item.audio,
                image: item.image,
                time: showTime,
            });
            sequence.push({
                text: null,
                audio: null,
                image: null,
                time: hiddenTime,
            });
        });

        return sequence;
    }

    public getRandomItems(
        items: WordItem[],
        numItems: number
    ): Array<WordItem> {
        return [...items].sort(() => 0.5 - Math.random()).slice(0, numItems);
    }

    private setAudioId(id: string | undefined): void {
        if (id === undefined || this.audioId === id) {
            this.audioId = undefined;
        }
        this.audioId = id;
    }

    @HostListener('click', ['$event'])
    private handleClick(event: Event): void {
        const target = event.target as HTMLElement;
        if (target.classList.contains(this.playerClass)) {
            this.pauseSequence();
        }
    }
}
