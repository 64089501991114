export enum ColorEnum {
    ToolRingIconWhite = '#FFFFFF',
    ToolRingIconBlack = '#000000',
    ToolRingIconGreen = '#55A932',
    ToolRingIconBlue = '#0091D5',
    ToolRingIconRed = '#C72424',
    ToolRingIconBlueLight = '#00FFFF',
    ToolRingIconBlueDark = '#2e7fa1',
    ToolRingIconYellow = '#FEFE00',
}
