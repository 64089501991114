import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import {ApiUserScreenInterface} from 'app/interface/api-user-response.interface';
import {UserService} from 'app/service/user/user.service';
import {Resource} from 'app/service/resource/classes/resource.class';

@Component({
    selector: 'app-resource-toggle',
    templateUrl: './resource-toggle.component.html',
    styleUrls: ['./resource-toggle.component.scss'],
})
export class ResourceToggleComponent {
    public loading = false;

    @Input()
    public resource!: Resource;

    @Output()
    public readonly changed = new EventEmitter<void>();

    constructor(private userService: UserService) {}

    @HostListener('click', ['$event'])
    private onToggleClick($event: Event): void {
        $event.stopPropagation();

        if (this.loading) {
            return;
        }

        this.loading = true;

        this.userService
            .toggle(this.resource.getThipId(), !this.resource.isVisible())
            .subscribe((result: ApiUserScreenInterface) => {
                this.loading = false;
                this.resource.setVisible(result.isActive);
                this.changed.emit();
            });
    }
}
