import {FaderDirective} from 'app/directive/fader/fader.directive';
import {UndefinedFaderException} from 'app/directive/fader/exception/undefined-fader.exception';

export abstract class AbstractCrossFader {
    private delay = 5;
    private animationTime = 500; // Must match time in SCSS

    protected abstract getActiveFaderContainer(): FaderDirective | undefined;
    protected abstract getInactiveFaderContainer(): FaderDirective | undefined;

    /**
     * Triggered before cross-fading the containers
     */
    protected abstract preCrossFade(): Promise<void>;

    /**
     * Triggered after cross-fading the containers
     */
    protected abstract postCrossFade(): Promise<void>;

    protected crossFade(): Promise<void> {
        const activeFaderContainer: FaderDirective | undefined =
            this.getActiveFaderContainer();
        const inactiveFaderContainer: FaderDirective | undefined =
            this.getInactiveFaderContainer();

        if (undefined === activeFaderContainer) {
            throw UndefinedFaderException.withMissingActiveFader();
        }

        if (undefined === inactiveFaderContainer) {
            throw UndefinedFaderException.withMissingInactiveFader();
        }

        return Promise.resolve()
            .then(() => {
                activeFaderContainer.fadeOut(true);

                // Add a tiny delay because transitions seem to respond a bit too slow
                return Promise.all([
                    this.sleep(this.delay),
                    this.preCrossFade(),
                ]);
            })
            .then(() => {
                inactiveFaderContainer.fadeOut();
                activeFaderContainer.fadeIn();

                return this.sleep(this.animationTime);
            })
            .then(() => {
                return this.postCrossFade();
            })
            .finally(() => {
                inactiveFaderContainer.fadeIn(true);
            });
    }

    private async sleep(value: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, value));
    }
}
