import {Injectable} from '@angular/core';
import {
    MatBottomSheet,
    MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import {ComponentType} from '@angular/cdk/overlay';
import {MatBottomSheetConfig} from '@angular/material/bottom-sheet/bottom-sheet-config';

@Injectable()
export class BottomSheetService {
    constructor(private matBottomSheet: MatBottomSheet) {}

    public open<T>(
        component: ComponentType<T>,
        config?: MatBottomSheetConfig
    ): MatBottomSheetRef<T> {
        return this.matBottomSheet.open(component, config);
    }
}
