import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {ComponentType, OverlayContainer} from '@angular/cdk/overlay';
import {BottomSheetService} from 'app/cdk/service/bottom-sheet/bottom-sheet.service';
import {BottomSheetComponentInterface} from 'app/cdk/classes/component/bottom-sheet/bottom-sheet.component';
import {FullScreenOverlayContainer} from 'app/cdk/classes/overlay/full-screen-overlay.container';
import {Injectable} from '@angular/core';

export enum BackDropClass {
    Blurred = 'cdk-overlay-backdrop--blurred',
    Solid = 'cdk-overlay-backdrop--solid',
    Transparent = 'cdk-overlay-backdrop--transparent',
}

@Injectable()
export abstract class AbstractBottomSheetService {
    protected reference!: MatBottomSheetRef<BottomSheetComponentInterface>;
    protected abstract disableClose(): boolean;
    protected abstract getBackDropClass(): BackDropClass;
    protected abstract getComponentType(): ComponentType<BottomSheetComponentInterface>;

    constructor(
        private bottomSheetService: BottomSheetService,
        private overlayContainer: OverlayContainer
    ) {}

    public open(): void {
        this.reference = this.bottomSheetService.open(this.getComponentType(), {
            disableClose: this.disableClose(),
            backdropClass: this.getBackDropClass(),
            panelClass: 'mat-bottom-sheet-container--digibord',
        });

        const referenceInstance = this.reference.instance;

        referenceInstance.subscribeToDismiss(result =>
            this.reference.dismiss(result)
        );

        if (undefined !== referenceInstance.onAfterOpen) {
            referenceInstance.onAfterOpen();
        }
    }

    protected toggleOverlay(): void {
        if (this.isFullScreenOverlayContainer(this.overlayContainer)) {
            this.overlayContainer.toggle();
        }
    }

    private isFullScreenOverlayContainer(
        overlayContainer: OverlayContainer | FullScreenOverlayContainer
    ): overlayContainer is FullScreenOverlayContainer {
        return overlayContainer instanceof FullScreenOverlayContainer;
    }
}
