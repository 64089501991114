import {ClockIndex} from 'app/tool-layer/tools/analog-clock/class/clock-index.class';
import {HandMoveEnum} from 'app/tool-layer/tools/analog-clock/class/clock.enum';

export class ClockAnalog {
    private static readonly step = 360 / 60;
    private index = new ClockIndex(180, 180, 180);

    private constructor(
        private hourDegrees: number = 0,
        private minuteDegrees: number = 0,
        private secondDegrees: number = 0
    ) {}

    public static createForCurrentDate(): ClockAnalog {
        const date = new Date();

        const hourDegrees = (date.getHours() * 30) % 360;
        const minuteDegrees = date.getMinutes() * ClockAnalog.step;
        const secondDegrees = date.getSeconds() * ClockAnalog.step;

        return new ClockAnalog(hourDegrees, minuteDegrees, secondDegrees);
    }

    public static createForClock(clock: ClockAnalog): ClockAnalog {
        const newClock = new ClockAnalog();
        newClock.updateByClock(clock);

        return newClock;
    }

    public updateByClock(clock: ClockAnalog): void {
        this.secondDegrees = clock.sDegrees;
        this.minuteDegrees = clock.sDegrees / 60 + clock.mDegrees;
        this.hourDegrees =
            clock.sDegrees / (60 * 60) + clock.mDegrees / 12 + clock.hDegrees;
    }

    public updateTimeByEnumAndDegrees(part: HandMoveEnum, index: number): void {
        switch (part) {
            case HandMoveEnum.hours:
                this.adjustForHours(index);
                break;

            case HandMoveEnum.minutes:
                this.adjustForMinutes(index);
                break;

            case HandMoveEnum.seconds:
                this.adjustForSeconds(index);
                break;
        }
    }

    private adjustForHours(index: number): void {
        if (index % 30 === 0) {
            this.hourDegrees = index;
        }
    }

    private adjustForMinutes(index: number): void {
        if (Math.abs((index - this.index.mIndex) / 6) > 30) {
            const direction = index > this.index.mIndex ? -1 : 1;

            this.hourDegrees += ClockAnalog.step * 5 * direction;
        }

        this.minuteDegrees = index;
        this.index.mIndex = this.minuteDegrees;
    }

    private adjustForSeconds(index: number): void {
        if (Math.abs((index - this.index.sIndex) / 6) > 30) {
            const direction = index > this.index.sIndex ? -1 : 1;

            this.minuteDegrees += ClockAnalog.step * direction;

            if (Math.abs(this.minuteDegrees / 360) >= 1) {
                this.hourDegrees += ClockAnalog.step * 5 * direction;
            }

            this.minuteDegrees %= 360;
            this.index.mIndex = this.minuteDegrees;
        }

        this.secondDegrees = index;
        this.index.sIndex = index;
    }

    public get hDegrees(): number {
        return this.hourDegrees;
    }

    public get mDegrees(): number {
        return this.minuteDegrees;
    }

    public get sDegrees(): number {
        return this.secondDegrees;
    }
}
