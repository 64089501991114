import {
    ContentChildren,
    Directive,
    DoCheck,
    ElementRef,
    HostBinding,
    QueryList,
} from '@angular/core';
import {PdfAssetComponent} from 'app/content/screen/components/pdf-asset/pdf-asset.component';

@Directive({
    selector: '.section--pdf',
})
export class ScreenInnerPdfScalingDirective implements DoCheck {
    @ContentChildren(PdfAssetComponent, {descendants: true})
    private pdfComponents!: QueryList<PdfAssetComponent>;

    private readonly baseElementSelector = 'app-content, .slide__screen';

    private baseWidth!: number;
    private baseHeight!: number;
    private elementWidth!: number;
    private elementHeight!: number;

    private widthFactor!: number;
    private heightFactor!: number;
    private scale!: number;

    @HostBinding('style.transform')
    private transformStyleProperty?: string;

    constructor(private elementRef: ElementRef) {}

    public ngDoCheck(): void {
        this.calculateAndSetTransformations();
    }

    private calculateAndSetTransformations(): void {
        // Update dimensions
        this.updateAppContentDimensions();
        this.updateCurrentElementDimensions();

        // Know the factors
        if (!this.calculateFactorsAndCheckIfDifferent()) {
            return;
        }

        // Apply transformations
        this.applyTransformations();
    }

    private updateAppContentDimensions(): void {
        // We want to find based on the parent element selector
        const appComponent = this.elementRef.nativeElement.closest(
            this.baseElementSelector
        ) as HTMLElement;

        if (appComponent === null) {
            throw new Error(
                `No base element "${this.baseElementSelector}" found?`
            );
        }

        this.baseWidth = appComponent.offsetWidth;
        this.baseHeight = appComponent.offsetHeight;
    }

    private updateCurrentElementDimensions(): void {
        const component = this.elementRef.nativeElement as HTMLElement;

        this.elementWidth = component.offsetWidth;
        this.elementHeight = component.offsetHeight;
    }

    private calculateFactorsAndCheckIfDifferent(): boolean {
        const updatedWidthFactor = this.baseWidth / this.elementWidth;
        const updatedHeightFactor = this.baseHeight / this.elementHeight;

        if (
            this.widthFactor === updatedWidthFactor &&
            this.heightFactor === updatedHeightFactor
        ) {
            return false;
        }

        this.widthFactor = updatedWidthFactor;
        this.heightFactor = updatedHeightFactor;
        this.scale = Math.min(this.widthFactor, this.heightFactor);

        return true;
    }

    private applyTransformations(): void {
        this.transformStyleProperty = `scale(${this.scale}) translateZ(0)`;

        if (this.pdfComponents) {
            this.pdfComponents.forEach(
                component => (component.scale = this.scale)
            );
        }
    }
}
