import {Injectable} from '@angular/core';
import {ApiMapInterface} from 'app/interface/api-map.interface';
import {ApiRequestService} from 'app/service/api-request/api-request.service';
import {Resource} from 'app/service/resource/classes/resource.class';

@Injectable()
export class DeeplinkService {
    public constructor(
        private apiRequestService: ApiRequestService,
        private location: Location
    ) {
        this.redirectHashtagLocation();
    }

    public getResourceForDeeplinkPath(deeplinkPath: string): Promise<Resource> {
        return this.apiRequestService
            .get<ApiMapInterface>(`/api/v2/deeplink/${deeplinkPath}`)
            .toPromise()
            .then(apiMap => Resource.fromApiMap(apiMap))
            .catch();
    }

    private redirectHashtagLocation(): void {
        if (!this.locationHasHashtagRoute()) {
            return;
        }

        this.location.href = `/deeplink/${this.getHashlessRoute()}`;
    }

    public locationHasHashtagRoute(): boolean {
        if (!this.location.hash) {
            return false;
        }

        const hash = this.location.hash.trim();

        return hash.length > 2 && hash.startsWith('#/');
    }

    private getHashlessRoute(): string {
        const hash = this.location.hash.trim();

        return hash.substring(2);
    }
}
