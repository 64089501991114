import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {DialogService} from 'app/cdk/service/dialog/dialog.service';
import {catchError} from 'rxjs/operators';
import {HeaderService} from 'app/header/service/header.service';

@Injectable()
export class LicenseInterceptor implements HttpInterceptor {
    constructor(
        private dialogService: DialogService,
        private headerService: HeaderService
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return next
            .handle(request)
            .pipe(catchError(error => this.handleError(error)));
    }

    private handleError(
        error: HttpErrorResponse
    ): Observable<HttpEvent<ErrorEvent>> {
        if (401 === error.status && error.error && error.error.detail) {
            this.showNoLicenseDialog(error.error.detail);
            this.goBack();
        }
        return throwError(error);
    }

    private showNoLicenseDialog(text: string): void {
        this.dialogService.alert({
            title: 'Geen toegang',
            text,
        });
    }

    private goBack(): void {
        this.headerService.handleLogoClick();
    }
}
