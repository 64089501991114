import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {ComponentModule} from 'app/component/component.module';
import {JumpingComponent} from 'app/tool-layer/tools/jumping/jumping.component';

@NgModule({
    imports: [CommonModule, ComponentModule],
    declarations: [JumpingComponent],
    entryComponents: [JumpingComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(JumpingComponent),
            multi: true,
        },
    ],
})
export class JumpingModule {}
