import {HostBinding} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

export interface BottomSheetComponentInterface {
    onAfterOpen?: () => void;
    dismiss(result?: unknown): void;
    subscribeToDismiss(fn: (result?: unknown) => void): Subscription;
}

export abstract class AbstractBottomSheetComponent
    implements BottomSheetComponentInterface
{
    private dismissSubject: Subject<unknown | undefined | void> = new Subject();

    @HostBinding('class')
    private className = 'bottom-sheet-component';

    public subscribeToDismiss(fn: (result?: unknown) => void): Subscription {
        return this.dismissSubject.subscribe(fn);
    }

    public dismiss(result?: unknown): void {
        this.dismissSubject.next(result);
    }
}
