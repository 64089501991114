import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {StopwatchComponent} from 'app/tool-layer/tools/stopwatch/stopwatch.component';
import {DirectiveModule} from 'app/directive/directive.module';

@NgModule({
    imports: [CommonModule, DirectiveModule],
    declarations: [StopwatchComponent],
    entryComponents: [StopwatchComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(StopwatchComponent),
            multi: true,
        },
    ],
})
export class StopwatchModule {}
