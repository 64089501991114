import {Component} from '@angular/core';
import {AbstractKeyboard} from 'app/drawing/component/keyboards/abstract-keyboard.class';

@Component({
    selector: 'app-keyboard',
    templateUrl: './keyboard.component.html',
    styleUrls: [
        './keyboard.component.scss',
        '../abstract-keyboard.styles.scss',
    ],
})
export class KeyboardComponent extends AbstractKeyboard {}
