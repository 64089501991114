import {ChangeDetectorRef, Component, ViewChildren} from '@angular/core';
import {AbstractBottomSheetComponent} from 'app/cdk/classes/component/bottom-sheet/bottom-sheet.component';
import {DialogService} from 'app/cdk/service/dialog/dialog.service';
import {
    ApiToolInterface,
    ApiToolScreenshotInterface,
} from 'app/interface/api-tool.interface';
import {ToolService} from 'app/service/tool/tool.service';
import {ScrollComponent} from 'app/component/scroll/scroll.component';
import {BarCloseStateEnum} from 'app/tool-layer/tools/image/bar/close-state.enum';

@Component({
    selector: 'app-tool-image-bar-component',
    templateUrl: 'bar.component.html',
    styleUrls: ['bar.component.scss'],
})
export class ImageBarComponent extends AbstractBottomSheetComponent {
    public readonly CloseStateEnum = BarCloseStateEnum;
    public tool?: ApiToolInterface;

    @ViewChildren('scrollItems')
    private scrollComponents?: ScrollComponent[];

    public constructor(
        private toolService: ToolService,
        private dialogService: DialogService,
        private changeRef: ChangeDetectorRef
    ) {
        super();
    }

    public setTool(tool: ApiToolInterface): void {
        this.tool = tool;
        this.changeRef.detectChanges();

        if (this.scrollComponents) {
            this.scrollComponents.forEach(c => c.update());
        }
    }

    public onScreenshotClick(screenshot: ApiToolScreenshotInterface): void {
        this.dismiss(screenshot.id);
    }

    public onChildClick(child: ApiToolInterface): void {
        this.dismiss(child);
    }

    public onDeleteScreenshotClick(
        event: Event,
        screenshot: ApiToolScreenshotInterface
    ): void {
        event.preventDefault();
        event.stopPropagation();

        this.dialogService
            .confirm({
                title: 'Tool verwijderen',
                text: 'Weet je zeker dat je deze tool wilt verwijderen?',
            })
            .subscribe(confirmed => {
                if (confirmed) {
                    this.deleteScreenshot(screenshot);
                }
            });
    }

    public onRenameClick(
        event: Event,
        screenshot: ApiToolScreenshotInterface
    ): void {
        event.preventDefault();
        event.stopPropagation();

        this.dialogService
            .input({
                title: 'Tool hernoemen',
                text: 'Geef je tool een (nieuwe) naam',
                userInput: screenshot.title,
            })
            .subscribe(userInput => {
                if (!userInput) {
                    return;
                }

                this.updateToolName(screenshot, userInput);
            });
    }

    private deleteScreenshot(screenshot: ApiToolScreenshotInterface): void {
        this.toolService.deleteTool(screenshot.id).then(() => {
            if (!this.tool || !this.tool.toolScreenshots) {
                return;
            }

            const index = this.tool.toolScreenshots.indexOf(screenshot);
            this.tool.toolScreenshots.splice(index, 1);
            this.changeRef.detectChanges();

            if (this.scrollComponents) {
                this.scrollComponents.forEach(c => c.update());
            }
        });
    }

    private updateToolName(
        screenshot: ApiToolScreenshotInterface,
        title: string
    ): void {
        this.toolService.renameTool(screenshot.id, title).then(() => {
            screenshot.title = title;
            this.alertAfterRename();
        });
    }

    private alertAfterRename(): void {
        this.dialogService.alert({
            title: 'Gelukt!',
            text: 'Je tekening is hernoemd.',
        });
    }
}
