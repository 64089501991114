import {Directive, ElementRef, HostBinding, Input} from '@angular/core';

@Directive({
    selector: '[appFader]',
})
export class FaderDirective {
    @HostBinding('class')
    public className = 'fader';

    @Input()
    @HostBinding('class.fader--no-transition')
    public noTransitionClass = false;

    @Input()
    @HostBinding('class.fader--fade-in')
    public fadedInClass = true;

    private nativeElement: HTMLElement;

    constructor(private elementRef: ElementRef) {
        this.nativeElement = this.elementRef.nativeElement;
    }

    public fadeOut(noTransition = false): void {
        this.noTransitionClass = noTransition;
        this.fadedInClass = false;
    }

    public fadeIn(noTransition = false): void {
        this.noTransitionClass = noTransition;
        this.fadedInClass = true;
    }
}
