import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Inject,
} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface AlertDialogData {
    title: string;
    text: string;
    label?: string;
}

@Component({
    selector: 'app-alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['../dialog.styles.scss'],
})
export class AlertDialogComponent implements AfterViewInit {
    public title!: string;
    public text!: string;
    public label!: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: AlertDialogData,
        private cd: ChangeDetectorRef
    ) {
        this.title = data.title;
        this.text = data.text;
        this.label = data.label || 'Ok';
    }

    public ngAfterViewInit() {
        this.cd.detectChanges();
    }
}
