import {Component, Input} from '@angular/core';

export interface KaraokeEntryComponentInterface {
    textEntryId: string;
}

@Component({
    selector: 'karaoke-entry',
    templateUrl: './karaoke-entry.component.html',
    styleUrls: ['./karaoke-entry.component.scss'],
})
export class KaraokeEntryComponent implements KaraokeEntryComponentInterface {
    @Input('data-text-entry-id')
    public textEntryId!: string;
}
