import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'unit',
})
export class UnitPipe implements PipeTransform {
    public transform(value?: number, unit = 'rem'): string | null {
        return value !== undefined ? `${value}${unit}` : null;
    }
}
