import {
    AfterViewInit,
    Component,
    ContentChildren,
    QueryList,
} from '@angular/core';
import {DialogEntryComponent} from 'app/content/screen/components/dialog/dialog-entry/dialog-entry.component';
import {ContentService} from 'app/service/content/content.service';
import {BbvmsEnum} from 'app/content/screen/components/bbvms/bbvms.enum';

@Component({
    selector: 'dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements AfterViewInit {
    public isPlaying = false;

    @ContentChildren(DialogEntryComponent)
    private dialogEntryComponents!: QueryList<DialogEntryComponent>;

    constructor(private contentService: ContentService) {
        this.contentService.subscribeToCurrentResource(() => this.stopAll());
    }

    public ngAfterViewInit() {
        this.dialogEntryComponents.forEach(c => {
            c.subscribeToEvents(e => this.handleAudioEvent(c, e));
            c.clicked.subscribe(() => {
                this.stopAll();
                c.play();
            });
        });
    }

    public toggle(): void {
        if (this.isPlaying) {
            this.stopAll();
        } else {
            this.playAll();
        }
    }

    public stopAll(): void {
        this.setEntriesDisabled(false);
        this.stopAllAudio();
        this.isPlaying = false;
    }

    public playAll(): Promise<void> {
        this.stopAllAudio();
        this.setEntriesDisabled(true);

        return new Promise((resolve, reject) => {
            if (this.isPlaying) {
                this.isPlaying = false;
                reject('Cannot start playback when already playing');
            } else {
                this.playDialogEntries().then(() => {
                    this.setEntriesDisabled(false);
                    resolve();
                });
            }
        });
    }

    private async playDialogEntries(): Promise<void> {
        this.isPlaying = true;

        for (const dialogEntryComponent of this.dialogEntryComponents) {
            if (!this.isPlaying) {
                break;
            }
            await dialogEntryComponent.play();
        }

        this.isPlaying = false;
    }

    private handleAudioEvent(
        entry: DialogEntryComponent,
        event: BbvmsEnum
    ): void {
        if (event === BbvmsEnum.Playing && !this.isPlaying) {
            this.stopAllOtherAudio(entry);
        }
    }

    private stopAllOtherAudio(entry: DialogEntryComponent) {
        this.dialogEntryComponents
            .filter(e => e !== entry)
            .forEach(e => {
                if (e.highlighted) {
                    e.stop();
                }
            });
    }

    private stopAllAudio(): void {
        if (!this.dialogEntryComponents) {
            return;
        }

        this.dialogEntryComponents.forEach(c => {
            if (c.highlighted) {
                c.stop();
            }
        });
    }

    private setEntriesDisabled(isDisabled: boolean): void {
        if (!this.dialogEntryComponents) {
            return;
        }

        this.dialogEntryComponents.forEach(c => (c.setDisabled = isDisabled));
    }
}
