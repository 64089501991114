import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';
import {SvgElementsEnum} from 'app/enum/svg.enum';
import {SvgOptionInterface} from 'app/component/svg-input/svg-input.component';

@Component({
    selector: 'app-position-on-the-number-line',
    templateUrl: './position-on-the-number-line.component.html',
    styleUrls: ['./position-on-the-number-line.component.scss'],
})
export class PositionOnTheNumberLineComponent extends AbstractTool {
    public static readonly id = 'plaats_op_de_getallenlijn';
    public static readonly width = 1024;
    public static readonly height = 57.5;

    public length = '0_100';
    public showMarkers = true;
    public showNumbers = true;
    public showUnits = true;
    public numberLineOptions = [
        ['0', '50'],
        ['0', '100'],
        ['0', '1000'],
        ['0', '500'],
        ['500', '1000'],
    ];

    public svgList: SvgOptionInterface[] = [
        {
            path: SvgElementsEnum.Label,
            icon: 'fas fa-tag',
        },
    ];
}
