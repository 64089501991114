import {Component, Output, EventEmitter} from '@angular/core';
import {Observable} from 'rxjs';
import {DrawingRootService} from 'app/drawing/service/drawing/drawing-root.service';
import {Color} from 'app/drawing/interface/color.interface';

@Component({
    selector: 'app-pen',
    templateUrl: './pen.component.html',
    styleUrls: ['./pen.component.scss'],
})
export class PenComponent {
    @Output()
    public closed = new EventEmitter<void>();

    size$: Observable<number>;
    color$: Observable<Color>;

    constructor(private drawingRootService: DrawingRootService) {
        this.size$ = this.drawingRootService.lineWidth$;
        this.color$ = this.drawingRootService.lineColor$;
    }

    public close(): void {
        this.closed.emit();
    }

    public onDiameterChange(diameter: number) {
        this.drawingRootService.setLineWidth(diameter);
    }

    public onColorChange(color: Color) {
        this.drawingRootService.setLineColor(color);
    }
}
