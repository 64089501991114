import {
    AfterContentInit,
    AfterViewInit,
    Component,
    ContentChildren,
    OnDestroy,
    QueryList,
    ViewChildren,
} from '@angular/core';
import {PdfAssetComponent} from 'app/content/screen/components/pdf-asset/pdf-asset.component';
import {AbstractResourceComponentClass} from 'app/content/screen/factory/resource-service';
import {Resource} from 'app/service/resource/classes/resource.class';
import {Subscription} from 'rxjs';

@Component({
    selector: 'screen-content-wrapper',
    templateUrl: 'screen-content-wrapper.component.html',
})
export class ScreenContentWrapperComponent
    implements AfterViewInit, AfterContentInit, OnDestroy
{
    @ViewChildren(AbstractResourceComponentClass)
    private resourceChildChildren!: QueryList<AbstractResourceComponentClass>;

    @ContentChildren(AbstractResourceComponentClass, {descendants: true})
    private resourceContentChildren!: QueryList<AbstractResourceComponentClass>;
    @ContentChildren(PdfAssetComponent, {descendants: true})
    public pdfAssetComponents!: QueryList<PdfAssetComponent>;

    private resource?: Resource;
    private readonly subscriptions: Subscription[] = [];

    public ngAfterViewInit(): void {
        this.subscriptions.push(
            this.resourceChildChildren.changes.subscribe(() => {
                this.updateResourcesNeededInstances();
            })
        );
        this.subscriptions.push(
            this.resourceContentChildren.changes.subscribe(() => {
                this.updateResourcesNeededInstances();
            })
        );

        this.updateResourcesNeededInstances();
    }

    public ngAfterContentInit(): void {
        this.updateResourcesNeededInstances();
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public setResource(resource: Resource): void {
        this.resource = resource;

        this.updateResourcesNeededInstances();
    }

    private updateResourcesNeededInstances(): void {
        const resource = this.resource;
        if (!resource) {
            return;
        }

        if (this.resourceChildChildren) {
            this.resourceChildChildren.forEach(instance => {
                instance.setResource(resource);
            });
        }

        if (this.resourceContentChildren) {
            this.resourceContentChildren.forEach(instance => {
                instance.setResource(resource);
            });
        }
    }
}
