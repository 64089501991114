import {Color} from 'app/drawing/interface/color.interface';

export const DEFAULT_ERASER_DIAMETER_OPTIONS = [30, 40, 50];

export const DEFAULT_ERASER_DIAMETER_OPTION =
    DEFAULT_ERASER_DIAMETER_OPTIONS[1];

export const DEFAULT_HIGHLIGHTER_DIAMETER_OPTIONS = [10, 20, 30];

export const DEFAULT_HIGHLIGHTER_DIAMETER_OPTION =
    DEFAULT_HIGHLIGHTER_DIAMETER_OPTIONS[1];

export const DEFAULT_DIAMETER_OPTIONS = [1, 2, 3, 4, 5];

export const DEFAULT_DIAMETER_OPTION = DEFAULT_DIAMETER_OPTIONS[2];

export const COLORS: Color[] = [
    {name: 'black', value: '#000000'},
    {name: 'white', value: '#ffffff'},
    {name: 'red', value: '#e73132'},
    {name: 'blue', value: '#0095db'},
    {name: 'green', value: '#32ac5c'},
    {name: 'purple', value: '#7e65aa'},
    {name: 'yellow', value: '#ffd747'},
    {name: 'orange', value: '#fa9a00'},
];

const DEFAULT_COLOR_INDEX = COLORS.findIndex(color => color.name === 'black');

export const YELLOW_COLOR_INDEX = COLORS.findIndex(
    color => color.name === 'yellow'
);

export const DEFAULT_COLOR_OPTION = COLORS[DEFAULT_COLOR_INDEX];

export const DEFAULT_HIGHLIGHTER_COLOR_OPTION = COLORS[YELLOW_COLOR_INDEX];
