import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';

@Component({
    selector: 'app-hte',
    templateUrl: './hte.component.html',
    styleUrls: ['./hte.component.scss'],
})
export class HteComponent extends AbstractTool {
    public static readonly id = 'hte';
    public static readonly width = 140;
    public static readonly height = 90;
    public rangeModel = {
        H: 0,
        T: 0,
        E: 0,
    };

    constructor() {
        super();
    }
}
