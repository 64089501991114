import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentModule} from 'app/component/component.module';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {Chain100Component} from 'app/tool-layer/tools/chain-100/chain-100.component';

@NgModule({
    imports: [CommonModule, ComponentModule],
    declarations: [Chain100Component],
    entryComponents: [Chain100Component],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(Chain100Component),
            multi: true,
        },
    ],
})
export class Chain100Module {}
