import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {DeeplinkService} from 'app/service/deeplink/deeplink.service';

@Injectable({
    providedIn: 'root',
})
export class LocationGuard implements CanActivate {
    constructor(private deeplinkService: DeeplinkService) {}

    canActivate(): boolean {
        return !this.deeplinkService.locationHasHashtagRoute();
    }
}
