import {NgModule} from '@angular/core';
import {ComponentFactoryFactory} from 'app/content/screen/factory/component/component-factory.factory';
import {ScreenComponent} from 'app/content/screen/component/screen.component';
import {CommonModule} from '@angular/common';

// const compilerOption: CompilerOptions = {
//     useJit: true,
//     preserveWhitespaces: true,
// };

// export function createJitCompiler() {
//     return new JitCompilerFactory([compilerOption]).createCompiler([
//         compilerOption,
//     ]);
// }

@NgModule({
    imports: [CommonModule],
    declarations: [ScreenComponent],
    providers: [
        ComponentFactoryFactory,
        // {provide: Compiler, useFactory: createJitCompiler},
    ],
    exports: [ScreenComponent],
})
export class ScreenModule {}
