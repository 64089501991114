import {Injector, NgModule} from '@angular/core';
import {RouterModule, Routes, UrlSegment} from '@angular/router';
import {DeeplinkComponent} from 'app/page/deeplink/deeplink.component';
import {NavigationComponent} from 'app/page/navigation/component/navigation.component';
import {ContentComponent} from 'app/content/component/content.component';
import {RouteMatcherService} from 'app/service/route-matcher/route-matcher.service';
import {ServiceModule} from 'app/service/service.module';
import {ResourceService} from 'app/service/resource/resource.service';
import {NotFoundComponent} from 'app/page/not-found/not-found.component';
import {UserGuard} from './guard/user.guard';
import {LocationGuard} from './guard/location.guard';

let appInjector: Injector;

const routes: Routes = [
    {
        path: '',
        canActivate: [LocationGuard, UserGuard],
        children: [
            {
                path: 'deeplink',
                component: DeeplinkComponent,
                children: [
                    {
                        path: '**',
                        component: DeeplinkComponent,
                    },
                ],
            },
            {
                matcher: (segments: UrlSegment[]) => {
                    return appInjector
                        .get<RouteMatcherService>(RouteMatcherService)
                        .forContentComponent(segments);
                },
                component: ContentComponent,
                resolve: {
                    resource: ResourceService,
                },
            },
            {
                matcher: (segments: UrlSegment[]) => {
                    return appInjector
                        .get<RouteMatcherService>(RouteMatcherService)
                        .forNavigationComponent(segments);
                },
                component: NavigationComponent,
                resolve: {
                    resource: ResourceService,
                },
            },
            {
                path: '**',
                component: NotFoundComponent,
            },
        ],
    },
];

@NgModule({
    providers: [RouteMatcherService],
    imports: [
        RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'}),
        ServiceModule,
    ],
    exports: [RouterModule],
})
export class RoutingModule {
    constructor(private injector: Injector) {
        appInjector = this.injector;
    }
}
