import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ComponentModule as RootComponentModule} from 'app/component/component.module';
import {DrawingModule} from 'app/drawing/drawing.module';
import {ToolDrawingCanvasComponent} from 'app/tool-layer/component/canvas/tool-canvas.component';
import {ToolInstancesComponent} from 'app/tool-layer/component/instances/tool-instances.component';
import {ToolSettingsComponent} from 'app/tool-layer/component/settings/tool-settings.component';
import {ToolLayerComponent} from 'app/tool-layer/component/layer/tool-layer.component';
import {ServiceModule} from 'app/tool-layer/service/service.module';
import {ToolsModule} from 'app/tool-layer/tools/tools.module';
import {ToolModule} from 'app/tool/tool.module';

@NgModule({
    imports: [
        BrowserModule,
        PortalModule,
        DragDropModule,
        ToolModule,
        ToolsModule,
        ServiceModule,
        RootComponentModule,
        DrawingModule,
    ],
    declarations: [
        ToolLayerComponent,
        ToolSettingsComponent,
        ToolInstancesComponent,
        ToolDrawingCanvasComponent,
    ],
    exports: [ToolLayerComponent],
})
export class ComponentModule {}
