import {EventEmitter, Injectable} from '@angular/core';
import {ContentService} from 'app/service/content/content.service';
import {Subscription} from 'rxjs';
import {ReadSpeaker} from './read-speaker.window';

export enum ReadSpeakerEventsEnum {
    StartedPlaying = 'StartedPlaying',
    StoppedPlaying = 'StoppedPlaying',
}

export interface ReadSpeakerEvent {
    status: ReadSpeakerEventsEnum;
    readId?: string;
}

declare global {
    interface Window {
        ReadSpeaker?: ReadSpeaker;
    }
}

@Injectable({
    providedIn: 'root',
})
export class ReadSpeakerService {
    private readonly emitter = new EventEmitter<ReadSpeakerEvent>();

    private afterSyncExitSubscribed = false;

    public constructor(private contentService: ContentService) {
        contentService.subscribeToCurrentResource(() => {
            this.stoppedPlaying();
            this.stopPlaying();
        });
    }

    public setupService(): void {
        if (!window.ReadSpeaker) {
            return;
        }

        window.ReadSpeaker.ui.addClickEvents();

        if (!this.afterSyncExitSubscribed) {
            window.ReadSpeaker.q(() => {
                this.registerReadSpeakerEvents();
            });

            this.afterSyncExitSubscribed = true;
        }
    }

    public subscribeToEvents(
        callback: (readId: ReadSpeakerEvent) => void
    ): Subscription {
        return this.emitter.subscribe(callback);
    }

    public startedPlaying(readId?: string): void {
        this.emitter.emit({
            status: ReadSpeakerEventsEnum.StartedPlaying,
            readId,
        });
    }

    public stoppedPlaying(readId?: string): void {
        this.emitter.emit({
            status: ReadSpeakerEventsEnum.StoppedPlaying,
            readId,
        });
    }

    public stopPlaying(): void {
        if (!window.ReadSpeaker) {
            return;
        }

        window.ReadSpeaker.PlayerAPI.stop();
    }

    private registerReadSpeakerEvents(): void {
        if (!window.ReadSpeaker) {
            return;
        }

        window.ReadSpeaker.Common.addEvent('onAfterSyncExit', () =>
            this.stoppedPlaying()
        );
    }
}
