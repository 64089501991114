import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {SecondScreenService} from 'app/second-screen/service/second-screen.service';
import {ContentService} from 'app/service/content/content.service';
import {ResourceService} from 'app/service/resource/resource.service';
import {Resource} from 'app/service/resource/classes/resource.class';
import {Subscription} from 'rxjs';
import {TocResourceState} from 'app/enum/toc.enum';
import {ScrollComponent} from 'app/component/scroll/scroll.component';

@Component({
    selector: 'app-second-screen-slides',
    templateUrl: './second-screen-slides.component.html',
    styleUrls: ['./second-screen-slides.component.scss'],
})
export class SecondScreenSlidesComponent implements OnDestroy, OnInit {
    public activeResource?: Resource;

    private contentSubscription?: Subscription;
    private resourceSubscription?: Subscription;
    private resources?: Resource[];

    @ViewChild('scroll')
    private scrollComponent?: ScrollComponent;

    constructor(
        private secondScreenService: SecondScreenService,
        private resourceService: ResourceService,
        private contentService: ContentService,
        private cd: ChangeDetectorRef
    ) {}

    public ngOnInit() {
        this.contentSubscription =
            this.contentService.subscribeToCurrentResource(resource =>
                this.handleContentChange(resource)
            );
        this.resourceSubscription =
            this.resourceService.subscribeToCurrentResource(resource =>
                this.handleResourceChange(resource)
            );
        this.handleContentChange(this.contentService.getCurrentResource());
        this.handleResourceChange(this.resourceService.getCurrentResource());
    }

    public ngOnDestroy(): void {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
        if (this.resourceSubscription) {
            this.resourceSubscription.unsubscribe();
        }
    }

    public get slides(): Resource[] {
        return this.resources || [];
    }

    public get showBack(): boolean {
        if (this.activeResource) {
            return !this.activeResource.isFirstChild(true);
        }
        return false;
    }

    public get showNext(): boolean {
        if (this.activeResource) {
            return !this.activeResource.isLastChild(true);
        }
        return false;
    }

    public back(): void {
        if (!this.activeResource || !this.resources) {
            return;
        }

        const currentIndex = this.resources.indexOf(this.activeResource);
        const previousActiveIndex = this.getActiveIndexes()
            .reverse()
            .find(i => i < currentIndex);

        if (undefined === previousActiveIndex) {
            return;
        }

        const previousResource = this.resources[previousActiveIndex];
        this.contentService.navigateToResource(previousResource);
    }

    public next(): void {
        if (!this.activeResource || !this.resources) {
            return;
        }

        const currentIndex = this.resources.indexOf(this.activeResource);
        const nextActiveIndex = this.getActiveIndexes().find(
            i => i > currentIndex
        );

        if (undefined === nextActiveIndex) {
            return;
        }

        const nextResource = this.resources[nextActiveIndex];
        this.contentService.navigateToResource(nextResource);
    }

    public print(): void {
        window.print();
    }

    public handleClick(resource: Resource): void {
        this.contentService.navigateToResource(resource);
    }

    private getActiveIndexes(): number[] {
        return this.slides
            .filter(r => r.getState() !== TocResourceState.Inactive)
            .map(r => this.slides.indexOf(r));
    }

    private handleContentChange(resource: Resource | undefined): void {
        if (undefined === resource) {
            return;
        }

        this.activeResource = resource;
    }

    private handleResourceChange(resource: Resource | undefined): void {
        if (undefined === resource) {
            return;
        }

        this.resources = resource.getChildren();
        this.cd.detectChanges();

        if (this.scrollComponent) {
            this.scrollComponent.update();
        }
    }
}
