import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MethodMappingPipe} from 'app/pipe/method-mapping/method-mapping.pipe';
import {PrependZerosPipe} from 'app/pipe/prepend-zeros/prepend-zeros.pipe';
import {UnitPipe} from 'app/pipe/unit/unit.pipe';
import {SanitizeHtmlPipe} from 'app/pipe/sanitize-html/sanitize-html.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [
        MethodMappingPipe,
        UnitPipe,
        SanitizeHtmlPipe,
        PrependZerosPipe,
    ],
    exports: [MethodMappingPipe, UnitPipe, SanitizeHtmlPipe, PrependZerosPipe],
})
export class PipeModule {}
