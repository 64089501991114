import {
    Component,
    DoCheck,
    EventEmitter,
    Input,
    IterableDiffer,
    IterableDiffers,
    OnInit,
    Output,
} from '@angular/core';
import {ToolInstance} from 'app/tool-layer/class/tool-instance';
import {ActiveToolService} from 'app/tool-layer/service/active/active-tool.service';
import {ToolboxModalService} from 'app/toolbox/service/modal/modal.service';

interface ToolSettingsTabInterface {
    label: string;
    type: string;
}

@Component({
    selector: 'app-tool-settings',
    templateUrl: './tool-settings.component.html',
    styleUrls: ['./tool-settings.component.scss'],
})
export class ToolSettingsComponent implements OnInit, DoCheck {
    @Input()
    public tools?: Map<string, Array<ToolInstance>>;

    @Input()
    public activeTool?: ToolInstance;
    @Output()
    public activeToolChange = new EventEmitter<ToolInstance>();

    public tabs: Array<ToolSettingsTabInterface> = [];
    public transparencyEnabled!: boolean;

    private iterableDiffer!: IterableDiffer<string>;

    public constructor(
        private activeToolService: ActiveToolService,
        private toolboxModalService: ToolboxModalService,
        private iterableDiffers: IterableDiffers
    ) {
        this.iterableDiffer = iterableDiffers.find([]).create();
    }

    public ngOnInit(): void {
        this.transparencyEnabled =
            this.activeToolService.hasTransparencyEnabled();
    }

    public ngDoCheck(): void {
        if (!this.tools) {
            return;
        }

        const keys = Array.from(this.tools.keys());
        if (this.iterableDiffer.diff(keys)) {
            this.tabs = keys.map(e => this.buildTabInterfaceObject(e));
        }
    }

    public closeByType(tab: string): void {
        if (!this.activeTool || !this.tools) {
            return;
        }

        this.activeToolService.closeByType(tab);
    }

    public activateByType(tab: string): void {
        this.activeToolService.activateByType(tab);
    }

    public addByType(tool: ToolInstance): void {
        this.activeToolService.openByTool(tool);
    }

    public onToggleTransparency(): void {
        this.activeToolService.toggleTransparency(this.transparencyEnabled);
    }

    public openToolbox(): void {
        this.toolboxModalService.open(
            this.activeTool ? this.activeTool.getToolCategory() : undefined
        );
    }

    private buildTabInterfaceObject(key: string): ToolSettingsTabInterface {
        const typeInstances = this.tools && this.tools.get(key);
        const label =
            typeInstances && typeInstances[0] ? typeInstances[0].name : key;

        return {
            type: key,
            label,
        };
    }
}
