import {Injectable} from '@angular/core';
import {
    ApiRequestService,
    ParamsInterface,
} from 'app/service/api-request/api-request.service';
import {
    ApiUserInterface,
    ApiUserScreenInterface,
    ApiUserScreensActiveStatusInterface,
    ApiUserScreensInterface,
} from 'app/interface/api-user-response.interface';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

@Injectable()
export class UserService {
    private userSubject = new BehaviorSubject<ApiUserInterface | undefined>(
        undefined
    );

    public readonly user$ = this.userSubject.asObservable();

    constructor(private apiRequestService: ApiRequestService) {}

    public fetchUser(): Observable<ApiUserInterface | undefined> {
        return this.apiRequestService
            .get<ApiUserInterface>('/api/v2/user')
            .pipe(tap(user => this.userSubject.next(user)));
    }

    public get(): Promise<ApiUserScreensInterface> {
        return this.apiRequestService
            .get<ApiUserScreensInterface>('/api/v2/user/screens')
            .toPromise();
    }

    public getUserScreensByIds(
        screenIds: string[] = []
    ): Observable<ApiUserScreensActiveStatusInterface> {
        return this.apiRequestService
            .get<ApiUserScreensInterface>(
                '/api/v2/user/screens?screenIds=' + JSON.stringify(screenIds)
            )
            .pipe(
                map((result: ApiUserScreensInterface) => {
                    const idMap: ApiUserScreensActiveStatusInterface = {};
                    Object.entries(result).forEach(
                        (screen: [string, ApiUserScreenInterface]) =>
                            (idMap[screen[1].thipId] = screen[1].isActive)
                    );
                    return idMap;
                })
            );
    }

    public toggle(
        contentIdentifier: string,
        isActive: boolean
    ): Observable<ApiUserScreenInterface> {
        const params: ParamsInterface = {
            urlParams: new Map<string, string>([
                ['contentIdentifier', contentIdentifier],
            ]),
        };

        return this.apiRequestService.post<
            ApiUserScreenInterface,
            {isActive: boolean}
        >('/api/v2/user/screens/:contentIdentifier/toggle', {isActive}, params);
    }
}
