import {Component, ContentChildren, forwardRef, QueryList} from '@angular/core';
import {
    AssignmentHottextOptionComponent,
    AssignmentHottextOptionInterface,
} from 'app/content/screen/components/assignment-hottext/assignment-hottext-option/assignment-hottext-option.component';
import {ExerciseComponent} from 'app/content/screen/exercise/exercise.component';

@Component({
    selector: 'assignment-hottext',
    templateUrl: './assignment-hottext.component.html',
    styleUrls: ['./assignment-hottext.component.scss'],
    providers: [
        {
            provide: ExerciseComponent,
            useExisting: forwardRef(() => AssignmentHottextComponent),
        },
    ],
})
export class AssignmentHottextComponent extends ExerciseComponent {
    @ContentChildren(AssignmentHottextOptionComponent, {descendants: true})
    private options!: QueryList<AssignmentHottextOptionInterface>;

    public check(): boolean {
        let allOk = true;

        this.options.forEach(option => {
            if (!option.checkAnswerState()) {
                allOk = false;
            }
        });

        return allOk;
    }

    public solve(): void {
        this.options.forEach(option => {
            option.highlightIfCorrect();
            option.checkAnswerState();
        });
    }

    public reset(): void {
        this.options.forEach(option => {
            option.reset();
        });
    }

    public hasAnswer(): boolean {
        return !!this.options.some(option => option.isHighlighted);
    }

    public hasWrong(): boolean {
        return this.options.some(option => option.isWrong());
    }

    public retry(): void {
        this.options.forEach(option => {
            if (option.isWrong()) {
                option.reset();
            }
        });
    }
}
