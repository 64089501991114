import {Injectable} from '@angular/core';
import {DrawingActionEnum} from 'app/drawing/enum/action.enum';
import {DrawingStateEnum} from 'app/drawing/enum/state.enum';
import {DrawingService} from 'app/drawing/service/drawing/drawing.service';
import {SvgFontAwesomeEnum} from 'app/enum/svg.enum';
import {ToolRingInterface} from 'app/tool/classes/tool-ring.class';
import {ColorInterpolationFilterEnum} from 'app/tool/enum/filter.enum';
import {Radius} from 'app/tool/enum/radius.enum';
import {
    ToolRingConfigInterface,
    ToolRingFactory,
} from 'app/tool/factory/tool-ring/tool-ring.factory';
import {AbstractToolRingService} from 'app/tool/rings/base/abstract-tool-ring.service';
import {RingIds} from 'app/tool/rings/ring-ids.enum';
import {ToolboxModalService} from 'app/toolbox/service/modal/modal.service';
import {ToolboxService} from 'app/toolbox/service/toolbox/toolbox.service';
import {ContentService} from 'app/service/content/content.service';

@Injectable()
export class EditRingService extends AbstractToolRingService {
    private readonly toggle!: ToolRingInterface;
    private readonly inner!: ToolRingInterface;
    private readonly outer!: ToolRingInterface;
    private showAnswerActive = false;
    private pencilActive = false;

    constructor(
        protected toolRingFactory: ToolRingFactory,
        protected toolboxService: ToolboxService,
        protected toolboxModalService: ToolboxModalService,
        private drawingService: DrawingService,
        private contentService: ContentService
    ) {
        super(toolRingFactory, toolboxService, toolboxModalService);

        [this.toggle, this.inner, this.outer] = this.toolRingFactory.createAll(
            this.config()
        );
    }

    public getId(): RingIds {
        return RingIds.EditRing;
    }

    public render(): ToolRingInterface[] {
        return [this.toggle, this.inner, this.outer];
    }

    private config(): ToolRingConfigInterface[] {
        return [
            {
                id: 'small-circle',
                radius: Radius.Small,
                visible: true,
                buttons: [
                    {
                        id: 'content-times',
                        icon: SvgFontAwesomeEnum.SolidTimes,
                        classes: ['svg__button--green-light'],
                        callback: () => this.onToggleButtonClick(),
                    },
                ],
            },
            {
                id: 'small-circle',
                classes: ['svg__circle--grey'],
                radius: Radius.Medium,
                visible: true,
                buttons: [
                    {
                        id: 'content-times',
                        icon: '',
                        classes: ['svg__button--grey'],
                        callback: () => this.onToggleButtonClick(),
                    },
                ],
            },
            {
                id: 'edit-ring-large-circle',
                classes: ['svg__circle--grey-light'],
                radius: Radius.Large,
                rotate: 353,
                visible: true,
                buttons: [
                    {
                        id: 'pen',
                        icon: SvgFontAwesomeEnum.SolidPenAlt,
                        iconFilter: ColorInterpolationFilterEnum.White,
                        classes: ['svg__button--grey-light'],
                        isActiveCallback: () =>
                            this.isActiveDrawingState(DrawingStateEnum.Pen),
                        callback: () => this.draw(DrawingStateEnum.Pen),
                    },
                    {
                        id: 'highlighter',
                        icon: SvgFontAwesomeEnum.SolidHighLighter,
                        iconFilter: ColorInterpolationFilterEnum.White,
                        classes: ['svg__button--grey-light'],
                        isActiveCallback: () =>
                            this.isActiveDrawingState(
                                DrawingStateEnum.Highlighter
                            ),
                        callback: () => this.draw(DrawingStateEnum.Highlighter),
                    },
                    {
                        id: 'eraser',
                        icon: SvgFontAwesomeEnum.SolidEraser,
                        iconFilter: ColorInterpolationFilterEnum.White,
                        classes: ['svg__button--grey-light'],
                        isActiveCallback: () =>
                            this.isActiveDrawingState(DrawingStateEnum.Eraser),
                        callback: () => this.draw(DrawingStateEnum.Eraser),
                    },
                    {
                        id: 'keyboard',
                        icon: SvgFontAwesomeEnum.SolidKeyboard,
                        classes: ['svg__button--grey-light'],
                        isActiveCallback: () =>
                            this.isActiveDrawingState(
                                DrawingStateEnum.Keyboard
                            ),
                        callback: () => this.draw(DrawingStateEnum.Keyboard),
                    },
                    {
                        id: 'calculator',
                        icon: SvgFontAwesomeEnum.SolidCalculator,
                        classes: ['svg__button--grey-light'],
                        isActiveCallback: () =>
                            this.isActiveDrawingState(
                                DrawingStateEnum.Calculator
                            ),
                        callback: () => this.draw(DrawingStateEnum.Calculator),
                    },
                    {
                        id: 'pdf-magic-wand',
                        icon: SvgFontAwesomeEnum.SolidMagicWand,
                        classes: ['svg__button--grey-light'],
                        isActiveCallback: () =>
                            this.isActiveDrawingState(
                                DrawingStateEnum.PdfMagicWand
                            ),
                        isDisabledCallback: () =>
                            !this.isCurrentResourcePdfType(),
                        callback: () => this.onMagicWandButtonClick(),
                    },
                    {
                        id: 'pdf-show-answer',
                        icon: SvgFontAwesomeEnum.SolidEye,
                        classes: ['svg__button--grey-light'],
                        isActiveCallback: () => this.isShowAnswerActive(),
                        isDisabledCallback: () =>
                            !this.isCurrentResourcePdfType(),
                        callback: () => this.onShowAnswerButtonClick(),
                    },
                    {
                        id: 'clear',
                        icon: SvgFontAwesomeEnum.SolidTimes,
                        classes: ['svg__button--grey-light'],
                        callback: () => this.onClearButtonClick(),
                    },
                    {
                        id: 'undo',
                        icon: SvgFontAwesomeEnum.SolidUndoAlt,
                        classes: ['svg__button--grey-light'],
                        callback: () => this.action(DrawingActionEnum.Undo),
                    },
                    {
                        id: 'redo',
                        icon: SvgFontAwesomeEnum.SolidRedoAlt,
                        classes: ['svg__button--grey-light'],
                        callback: () => this.action(DrawingActionEnum.Redo),
                    },
                ],
            },
        ];
    }

    private isShowAnswerActive(): boolean {
        return this.showAnswerActive;
    }

    private onShowAnswerButtonClick(): void {
        this.showAnswerActive = !this.showAnswerActive;

        if (this.showAnswerActive) {
            this.draw(DrawingStateEnum.PdfShowAnswer);
        } else {
            this.draw(DrawingStateEnum.Disabled);
        }
    }

    private onToggleButtonClick(): void {
        if (this.pencilActive) {
            this.pencilActive = false;
        } else {
            this.draw(DrawingStateEnum.Disabled);
        }

        this.toggleRingByServiceId(RingIds.NavigationRing);
    }

    private onMagicWandButtonClick(): void {
        this.showAnswerActive = false;
        this.draw(DrawingStateEnum.PdfMagicWand);
    }

    private onClearButtonClick(): void {
        this.showAnswerActive = false;

        if (this.drawingService.state !== DrawingStateEnum.Disabled) {
            this.draw(DrawingStateEnum.Disabled);
        }

        this.action(DrawingActionEnum.Clear);
    }

    private draw(value: DrawingStateEnum): void {
        if (value !== DrawingStateEnum.PdfShowAnswer && this.showAnswerActive) {
            this.showAnswerActive = false;
        }

        if (this.drawingService.state === value) {
            this.drawingService.state = DrawingStateEnum.Reset;
            this.disableDrawing();
        } else {
            this.drawingService.state = value;
            this.action(DrawingActionEnum.StateSwitch);
        }
    }

    private disableDrawing(): void {
        this.pencilActive = true;
        this.draw(DrawingStateEnum.Disabled);
    }

    private action(action: DrawingActionEnum): void {
        this.drawingService.action(action);
    }

    private isActiveDrawingState(value: DrawingStateEnum): boolean {
        return this.drawingService.state === value;
    }

    private isCurrentResourcePdfType(): boolean {
        const resource = this.contentService.getCurrentResource();
        return resource ? resource.isPdfTypeWithAnswers() : false;
    }
}
