import {Component, Renderer2} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';
import {SvgOptionInterface} from 'app/component/svg-input/svg-input.component';
import {SvgElementsEnum} from 'app/enum/svg.enum';

@Component({
    selector: 'app-chain-20',
    templateUrl: './chain-20.component.html',
    styleUrls: ['./chain-20.component.scss'],
})
export class Chain20Component extends AbstractTool {
    public static readonly id = '20-ketting';
    public static readonly width = 1024;
    public static readonly height = 142;

    public showNumberLine = true;
    public showNumbers = true;
    public showUnits = true;

    private isMouseDown = false;

    public svgList: SvgOptionInterface[] = [
        {
            path: SvgElementsEnum.Label,
            icon: 'fas fa-tag',
        },
    ];

    constructor(private renderer: Renderer2) {
        super();
    }

    public onBallClick(event: MouseEvent): void {
        this.showHideBalls(event);
    }

    public onBallMouseDown(): void {
        this.isMouseDown = true;
    }

    public onBallMouseOver(event: MouseEvent): void {
        if (this.isMouseDown) {
            this.showHideBalls(event);
        }
    }

    public onBallMouseUp(): void {
        this.isMouseDown = false;
    }

    public onMouseLeave(): void {
        this.isMouseDown = false;
    }

    private showHideBalls(event: MouseEvent): void {
        const element = event.currentTarget as Element;
        this.showPrevious(element);
        this.hideNext(element.nextElementSibling);
    }

    private showPrevious(element: Element | null): void {
        if (element) {
            this.renderer.removeClass(element, 'chain20-hide-ball');
            this.showPrevious(element.previousElementSibling);
        }
    }

    private hideNext(element?: Element | null): void {
        if (element) {
            this.renderer.addClass(element, 'chain20-hide-ball');
            this.hideNext(element.nextElementSibling);
        }
    }
}
