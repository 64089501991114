import {
    Component,
    AfterContentInit,
    QueryList,
    ContentChildren,
    OnDestroy,
} from '@angular/core';
import {
    SelectedEvent,
    WordsEntryComponent,
    WordsEntryComponentInterface,
} from 'app/content/screen/components/words/words-entry/words-entry.component';
import {Subscription} from 'rxjs';

@Component({
    selector: 'words',
    templateUrl: './words.component.html',
    styleUrls: ['./words.component.scss'],
})
export class WordsComponent implements AfterContentInit, OnDestroy {
    @ContentChildren(WordsEntryComponent)
    private wordEntries!: QueryList<WordsEntryComponentInterface>;

    public image?: string;
    private subscriptionArray: Array<Subscription> = [];

    public ngAfterContentInit(): void {
        this.wordEntries.forEach(wordEntry => {
            this.subscriptionArray.push(
                wordEntry.selectedChange.subscribe((event: SelectedEvent) => {
                    this.handleWordsEntrySelected(event);
                })
            );
        });
    }

    public ngOnDestroy(): void {
        this.subscriptionArray.forEach(subscription =>
            subscription.unsubscribe()
        );
    }

    private handleWordsEntrySelected(event: SelectedEvent): void {
        this.wordEntries.forEach(wordEntry => wordEntry.resetSelection(event));

        this.image = event.image;
    }
}
