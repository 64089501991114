import {HostListener, Injectable} from '@angular/core';
import {
    GoogleAnalyticsActionsEnum,
    GoogleAnalyticsCategoriesEnum,
} from 'app/service/google-analytics/categories.enum';
import {GoogleAnalyticsService} from 'app/service/google-analytics/google-analytics.service';
import {Subject, Subscription} from 'rxjs';
import {PointInterface} from 'app/classes/point.class';
import {ZoomEnum} from 'app/enum/zoom.enum';
import {UserSelectionArea} from 'app/interface/selection.interface';
import {DrawingStateEnum} from 'app/drawing/enum/state.enum';
import {DrawingService} from 'app/drawing/service/drawing/drawing.service';

export enum ZoomableViewEventTypeEnum {
    Zoom,
    Scale,
}

export interface ZoomableViewValueInterface {
    point?: PointInterface;
    selection?: UserSelectionArea;
    amount: number;
}

export interface ZoomableViewEventInterface {
    type: ZoomableViewEventTypeEnum;
    value?: ZoomableViewValueInterface;
    enabled: boolean;
}

@Injectable()
export class ZoomableViewService {
    private subject: Subject<ZoomableViewEventInterface> = new Subject();
    private enabled = false;

    private scissorsSubject: Subject<boolean> = new Subject();
    private scissorsEnabled = false;

    private zoomSubject: Subject<number> = new Subject();
    private zoomEnabled = false;
    private zooming = false;
    private zoom = 1;

    private scaleEnabled = false;
    private scaling = false;
    private scale = 1;

    public constructor(
        private gaService: GoogleAnalyticsService,
        private drawingService: DrawingService
    ) {}

    private static getZoomTypeGaAction(
        zoom: ZoomEnum
    ): GoogleAnalyticsActionsEnum {
        switch (zoom) {
            case ZoomEnum.In:
                return GoogleAnalyticsActionsEnum.ZoomIn;
            case ZoomEnum.Out:
                return GoogleAnalyticsActionsEnum.ZoomOut;
            case ZoomEnum.Reset:
                return GoogleAnalyticsActionsEnum.ZoomReset;
        }
    }

    public subscribe(
        fn: (event: ZoomableViewEventInterface) => void
    ): Subscription {
        return this.subject.subscribe(fn);
    }

    public subscribeToZoom(fn: (value: ZoomEnum) => void): Subscription {
        return this.zoomSubject.subscribe(fn);
    }

    public subscribeToScissors(fn: (value: boolean) => void): Subscription {
        return this.scissorsSubject.subscribe(fn);
    }

    public setEnabled(value: boolean): void {
        this.enabled = value;
        this.setZoomEnabled(value);
        this.setScaleEnabled(value);
    }

    public isEnabled(): boolean {
        return this.enabled;
    }

    public toggleScissors(enabled?: boolean): void {
        this.scissorsEnabled = enabled ? enabled : !this.scissorsEnabled;
        this.drawingService.state = this.scissorsEnabled
            ? DrawingStateEnum.Cut
            : DrawingStateEnum.Disabled;

        this.scissorsSubject.next(this.scissorsEnabled);
    }

    public isScissorsEnabled(): boolean {
        return this.scissorsEnabled;
    }

    public zoomToSelection(selection: UserSelectionArea): void {
        this.triggerValueEvent(
            ZoomableViewEventTypeEnum.Zoom,
            2,
            undefined,
            selection
        );

        this.gaService.event(
            GoogleAnalyticsActionsEnum.ZoomFit,
            GoogleAnalyticsCategoriesEnum.Zoom
        );

        this.toggleScissors(false);
    }

    public zoomInOutReset(zoom: ZoomEnum): void {
        this.gaService.event(
            ZoomableViewService.getZoomTypeGaAction(zoom),
            GoogleAnalyticsCategoriesEnum.Zoom
        );

        this.zoomSubject.next(zoom);
    }

    public setScale(amount: number): void {
        this.scale = amount;
        this.scaling = 1 !== amount;
        this.triggerValueEvent(ZoomableViewEventTypeEnum.Scale, amount);
    }

    public getScale(): number {
        return this.scale;
    }

    public isScaleEnabled(): boolean {
        return this.scaleEnabled;
    }

    public isScaling(): boolean {
        return this.scaling;
    }

    public setZoom(amount: number, point: PointInterface): void {
        this.zooming = 1 !== amount;
        this.zoom = amount;
        this.triggerValueEvent(
            ZoomableViewEventTypeEnum.Zoom,
            this.zoom,
            point
        );
    }

    public isZoomEnabled(): boolean {
        return this.zoomEnabled;
    }

    public isZooming(): boolean {
        return this.zooming;
    }

    public getZoom(): number {
        return this.zoom;
    }

    public setScaleAmount(amount: number): void {
        this.scale = amount;
    }

    private setScaleEnabled(value: boolean): void {
        this.scaleEnabled = value;
        this.triggerEvent(ZoomableViewEventTypeEnum.Scale);
    }

    private setZoomEnabled(value: boolean): void {
        this.zoomEnabled = this.enabled ? value : false;
        this.triggerEvent(ZoomableViewEventTypeEnum.Zoom);
    }

    private getEnabledByType(type: ZoomableViewEventTypeEnum): boolean {
        if (!this.enabled) {
            return false;
        }

        return ZoomableViewEventTypeEnum.Zoom === type
            ? this.zoomEnabled
            : this.scaleEnabled;
    }

    private triggerValueEvent(
        type: ZoomableViewEventTypeEnum,
        amount: number,
        point?: PointInterface,
        selection?: UserSelectionArea
    ): void {
        this.triggerEvent(type, {amount, point, selection});
    }

    private triggerEvent(
        type: ZoomableViewEventTypeEnum,
        value?: ZoomableViewValueInterface
    ): void {
        this.subject.next({
            type,
            value,
            enabled: this.getEnabledByType(type),
        });
    }
}
