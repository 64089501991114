import {Component, Input} from '@angular/core';

@Component({
    selector: 'pdf-download-button',
    templateUrl: './pdf-download-button.component.html',
    styleUrls: ['./pdf-download-button.component.scss'],
})
export class PdfDownloadButtonComponent {
    @Input('data-src')
    public src!: string;
}
