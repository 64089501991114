import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentModule} from 'app/component/component.module';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {SplitsDiagramComponent} from 'app/tool-layer/tools/splits-diagram/splits-diagram.component';

@NgModule({
    imports: [ComponentModule, CommonModule],
    declarations: [SplitsDiagramComponent],
    entryComponents: [SplitsDiagramComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(SplitsDiagramComponent),
            multi: true,
        },
    ],
})
export class SplitsDiagramModule {}
