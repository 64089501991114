import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {DeeplinkService} from 'app/service/deeplink/deeplink.service';

@Component({
    selector: 'app-deeplink-page',
    template: '',
})
export class DeeplinkComponent implements OnInit {
    private readonly forbiddenUrlParameters = ['scherm'];
    private readonly UUIDRegex = new RegExp(
        /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
    );

    public constructor(
        private deeplinkService: DeeplinkService,
        private router: Router,
        private currentRoute: ActivatedRoute
    ) {}

    public ngOnInit(): void {
        this.getRedirectPathByCurrentRoute().then(redirectUrl =>
            this.router.navigateByUrl(`/${redirectUrl}`)
        );
    }

    private getRedirectPathByCurrentRoute(): Promise<string> {
        return new Promise<string>(resolve => {
            const deeplinkPath = this.getDeeplinkPath();

            this.deeplinkService
                .getResourceForDeeplinkPath(deeplinkPath)
                .then(resource => resolve(resource.getRoute()))
                .catch(() => resolve(''));
        });
    }

    private getDeeplinkPath(): string {
        return this.currentRoute.snapshot.children
            .map(child => this.getUrlForChildRouteSnapshot(child))
            .join('/');
    }

    private getUrlForChildRouteSnapshot(child: ActivatedRouteSnapshot): string {
        return child.url
            .filter(
                urlSegment =>
                    !this.forbiddenUrlParameters.includes(urlSegment.path)
            )
            .map(urlSegment => this.getURIEncodedUrlSegment(urlSegment.path))
            .join('/');
    }

    private getURIEncodedUrlSegment(path: string): string {
        if (path.match(this.UUIDRegex)) {
            path = `thip.${path}`;
        }

        return encodeURIComponent(path);
    }
}
