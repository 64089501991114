export enum IconEnum {
    /* FontAwesome icons */
    FasSolidToolbox = 'fas fa-toolbox',
    FasSolidRuler = 'fas fa-ruler',
    FasSolidClock = 'fas fa-clock',
    FasSolidMoneyBill = 'fas fa-money-bill',
    FasSolidStopwatch = 'fas fa-stopwatch',
    FasSolidZoom = 'fas fa-search-plus',
    FasSolidZoomOut = 'fas fa-search-minus',

    /** Custom icons */
    ImLanguage = 'im im--language',
    ImCalculate = 'im im--calculate',
    ImChevrons = 'im im--chevrons',
}
