import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentModule} from 'app/component/component.module';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {WordFlasherComponent} from './word-flasher.component';
import {FlasherService} from 'app/tool-layer/tools/word-flasher/service/flasher.service';
import {WordFlasherPlayerComponent} from './components/word-flasher-player/word-flasher-player.component';
import {ContentComponentsModule} from 'app/content/screen/components/components.module';

@NgModule({
    imports: [ComponentModule, CommonModule, ContentComponentsModule],
    declarations: [WordFlasherComponent, WordFlasherPlayerComponent],
    entryComponents: [WordFlasherComponent, WordFlasherPlayerComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(WordFlasherComponent),
            multi: true,
        },
        FlasherService,
    ],
})
export class WordFlasherModule {}
