import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';

@Component({
    selector: 'app-mhdtddhte',
    templateUrl: './mhdtddhte.component.html',
    styleUrls: ['./mhdtddhte.component.scss'],
})
export class MhdtddhteComponent extends AbstractTool {
    public static readonly id = 'mhdtddhte';
    public static readonly width = 180;
    public static readonly height = 95;
    public rangeModel = {
        M: 0,
        Hd: 0,
        Td: 0,
        D: 0,
        H: 0,
        T: 0,
        E: 0,
    };

    constructor() {
        super();
    }
}
