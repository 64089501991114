import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';
import {SvgElementsEnum} from 'app/enum/svg.enum';

@Component({
    selector: 'app-floating-svg',
    templateUrl: './floating-svg.component.html',
    styleUrls: ['./floating-svg.component.scss'],
})
export class FloatingSvgComponent extends AbstractTool {
    public static readonly id = 'floating_svg';
    public static readonly width = 0;
    public static readonly height = 0;

    public path?: SvgElementsEnum;

    public set injectedData(data: {svg: SvgElementsEnum}) {
        this.path = data.svg ? data.svg : undefined;
    }
}
