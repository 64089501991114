import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SvgElementsEnum} from 'app/enum/svg.enum';
import {ActiveToolService} from 'app/tool-layer/service/active/active-tool.service';
import {ToolInstance} from 'app/tool-layer/class/tool-instance';

export interface SvgOptionInterface {
    path: SvgElementsEnum;
    icon?: string;
    zIndex?: number;
}

export const FLOATING_SVG_REMOVE_CLASSNAME = 'js-floating-svg-remove';

@Component({
    selector: 'app-svg-input',
    templateUrl: './svg-input.component.html',
    styleUrls: ['./svg-input.component.scss'],
})
export class SvgInputComponent {
    @Input()
    public options: SvgOptionInterface[] = [];

    @Input()
    public parent?: ToolInstance;

    @Output()
    public readonly selected = new EventEmitter<SvgOptionInterface>();

    public readonly removeSvgClassname = FLOATING_SVG_REMOVE_CLASSNAME;

    constructor(private activeToolService: ActiveToolService) {}

    public select(option: SvgOptionInterface): void {
        if (this.parent) {
            this.activeToolService.openTool({
                toolId: 'floating_svg',
                toolConfig: {
                    hideHandles: true,
                    parent: this.parent,
                    data: {
                        svg: option.path,
                        zIndex: option.zIndex,
                    },
                },
            });
        }
        this.selected.emit(option);
    }
}
