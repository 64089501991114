import {AfterContentInit, AfterViewInit, ViewChild} from '@angular/core';
import {ScreenContentWrapperComponent} from 'app/content/screen/components/screen-content-wrapper/screen-content-wrapper.component';
import {ScreenInterface} from 'app/content/screen/factory/interfaces/screen.interface';
import {Resource} from 'app/service/resource/classes/resource.class';

export class JitComponentClass
    implements ScreenInterface, AfterViewInit, AfterContentInit
{
    @ViewChild(ScreenContentWrapperComponent)
    private contentWrapperComponent!: ScreenContentWrapperComponent;

    private resource!: Resource;

    public ngAfterContentInit(): void {
        this.updateResources();
    }

    public ngAfterViewInit(): void {
        this.updateResources();
    }

    public setResource(resource: Resource): void {
        this.resource = resource;

        this.updateResources();
    }

    private updateResources(): void {
        if (!this.resource) {
            return;
        }

        if (this.contentWrapperComponent) {
            this.contentWrapperComponent.setResource(this.resource);
        }
    }
}
