import {ToolRing, ToolRingInterface} from 'app/tool/classes/tool-ring.class';
import {Radius} from 'app/tool/enum/radius.enum';
import {
    ToolRingButton,
    ToolRingButtonInterface,
} from 'app/tool/classes/tool-ring-button.class';
import {
    ToolRingButtonIcon,
    ToolRingButtonIconConfigInterface,
} from 'app/tool/classes/tool-ring-button-icon.class';
import {ColorInterpolationFilterEnum, Shadow} from 'app/tool/enum/filter.enum';
import {CartesianPoint, CartesianPointInterface} from 'app/classes/point.class';
import * as _ from 'lodash';

export interface ToolRingButtonConfig {
    id: string;
    icon: string | ToolRingButtonIconConfigInterface;
    iconFilter?: ColorInterpolationFilterEnum;
    callback: (self: ToolRingButtonInterface) => void;
    classes?: string[];
    offset?: number;
    event?: string;
    isActiveCallback?: (self: ToolRingButtonInterface) => boolean;
    isDisabledCallback?: (self: ToolRingButtonInterface) => boolean;
}

export interface ToolRingConfigInterface {
    id: string;
    radius: Radius;
    shadow?: Shadow;
    buttons?: ToolRingButtonConfig[];
    rotate?: number;
    classes?: string[];
    visible?: boolean;
    slices?: number;
}

export class ToolRingFactory {
    public createAll(
        toolRingConfig: ToolRingConfigInterface[]
    ): ToolRingInterface[] {
        return toolRingConfig.map(ring => this.create(ring));
    }

    public create(toolRingConfig: ToolRingConfigInterface): ToolRingInterface {
        const buttonConfigs: ToolRingButtonConfig[] =
            undefined !== toolRingConfig.buttons ? toolRingConfig.buttons : [];
        const buttonSliceCount: number = this.getSliceCount(buttonConfigs);
        const customSliceCount: number | undefined = toolRingConfig.slices;

        if (
            undefined !== customSliceCount &&
            buttonSliceCount > customSliceCount
        ) {
            throw new Error(
                'Number of buttons + offsets cannot exceed ring slices value'
            );
        }

        // We're using radians to calculate the arcs, convert degree to radian angle first
        const sliceCount: number =
            undefined !== customSliceCount
                ? customSliceCount
                : buttonSliceCount;
        const onePercent: number = 1 / sliceCount;
        const radius: number = toolRingConfig.radius;
        const offsetAngle: number =
            ((undefined !== toolRingConfig.rotate ? toolRingConfig.rotate : 0) *
                Math.PI) /
            180.0;

        let percentageIndex = 1; // We're using a different index due to offset values
        const buttons: ToolRingButtonInterface[] = buttonConfigs.map(
            (toolRingButtonConfig: ToolRingButtonConfig) => {
                if (undefined !== toolRingButtonConfig.offset) {
                    percentageIndex += toolRingButtonConfig.offset;
                }

                const startPercentage: number =
                    onePercent * (percentageIndex - 1);
                const startPoint: CartesianPointInterface = new CartesianPoint(
                    radius,
                    startPercentage * (2 * Math.PI) + offsetAngle
                );

                // We can't draw circles using arcs, so we'll use this to draw two arcs instead
                const isCircle: boolean = 1 === sliceCount;

                const percentage: number = isCircle
                    ? 0.5
                    : onePercent * percentageIndex;
                const endPointAngle: number =
                    percentage * (2 * Math.PI) + offsetAngle;

                const endPoint: CartesianPointInterface = new CartesianPoint(
                    radius,
                    endPointAngle
                );

                // This is the center of the edge of the pie
                const iconPercentage = percentage - onePercent / 2;
                const iconPoint: CartesianPointInterface = isCircle
                    ? new CartesianPoint(0, 0)
                    : new CartesianPoint(
                          this.getIconRadius(radius),
                          iconPercentage * (2 * Math.PI) + offsetAngle
                      );

                percentageIndex++; // increase index

                return this.createButton(
                    toolRingButtonConfig,
                    radius,
                    startPoint,
                    endPoint,
                    iconPoint
                );
            }
        );

        return new ToolRing(
            toolRingConfig.id,
            radius,
            buttons,
            toolRingConfig.visible,
            toolRingConfig.shadow,
            toolRingConfig.classes
        );
    }

    private createButton(
        config: ToolRingButtonConfig,
        radius: number,
        startPoint: CartesianPoint,
        endPoint: CartesianPoint,
        iconPoint: CartesianPoint
    ): ToolRingButtonInterface {
        const icon: ToolRingButtonIcon = this.isButtonIconConfig(config.icon)
            ? ToolRingButtonIcon.fromConfig(config.icon)
            : ToolRingButtonIcon.fromString(config.icon);

        return new ToolRingButton(
            config.id,
            radius,
            icon,
            config.iconFilter || ColorInterpolationFilterEnum.White,
            startPoint,
            endPoint,
            iconPoint,
            config.callback,
            config.isActiveCallback,
            config.isDisabledCallback,
            config.classes
        );
    }

    private getIconRadius(radius: Radius): number {
        if (Radius.Large === radius) {
            return Radius.Large - (Radius.Large - Radius.Medium) / 2;
        }

        return Radius.Medium - (Radius.Medium - Radius.Small) / 2;
    }

    private isButtonIconConfig(
        value: string | ToolRingButtonIconConfigInterface
    ): value is ToolRingButtonIconConfigInterface {
        return !_.isString(value);
    }

    private getSliceCount(buttonConfigs: ToolRingButtonConfig[]): number {
        let sliceCount: number = buttonConfigs.length;

        buttonConfigs.forEach(config => {
            if (undefined !== config.offset) {
                sliceCount += config.offset;
            }
        });

        return sliceCount;
    }
}
