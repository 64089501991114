import {Injectable} from '@angular/core';
import {ComponentType} from '@angular/cdk/overlay';
import {Observable} from 'rxjs';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {
    AlertDialogComponent,
    AlertDialogData,
} from 'app/cdk/component/dialogs/alert-dialog/alert-dialog.component';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from 'app/cdk/component/dialogs/confirm-dialog/confirm-dialog.component';
import {
    InputDialogComponent,
    InputDialogData,
} from 'app/cdk/component/dialogs/input-dialog/input-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    constructor(public dialog: MatDialog) {}

    /**
     * Opens an alert dialog with dismiss button.
     */
    public alert(
        data?: AlertDialogData,
        config?: MatDialogConfig
    ): Observable<void> {
        return this.openDialog(AlertDialogComponent, data, config);
    }

    /**
     * Opens a confirmation dialog with cancel and confirm buttons.
     * @returns true if confirmed.
     */
    public confirm(
        data?: ConfirmDialogData,
        config?: MatDialogConfig
    ): Observable<boolean> {
        return this.openDialog(ConfirmDialogComponent, data, config);
    }

    /**
     * Opens a user input dialog with a text input field.
     * @returns string if user inputted something.
     */
    public input(
        data?: InputDialogData,
        config?: MatDialogConfig
    ): Observable<string | undefined> {
        return this.openDialog(InputDialogComponent, data, config);
    }

    private openDialog(
        component: ComponentType<unknown>,
        data?: unknown,
        config?: MatDialogConfig
    ) {
        return this.dialog
            .open(component, {
                disableClose: true,
                backdropClass: 'dialog-backdrop',
                width: '48rem',
                data,
                ...config,
            })
            .afterClosed();
    }
}
