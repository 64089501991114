export interface ToolRingButtonIconConfigInterface {
    path: string;
    altPath: string;
}

export interface ToolRingButtonIconInterface {
    path: string;
    useAltPath(use: boolean): void;
}

export class ToolRingButtonIcon implements ToolRingButtonIconInterface {
    protected constructor(
        private defaultPath: string,
        private altPath?: string
    ) {
        this.path = defaultPath;
    }
    public path: string;

    public static fromConfig(
        config: ToolRingButtonIconConfigInterface
    ): ToolRingButtonIcon {
        return new ToolRingButtonIcon(config.path, config.altPath);
    }

    public static fromString(value: string): ToolRingButtonIcon {
        return new ToolRingButtonIcon(value);
    }

    public useAltPath(use = false): void {
        if (undefined === this.altPath) {
            return;
        }

        this.path = use ? this.altPath : this.defaultPath;
    }
}
