import {NgModule} from '@angular/core';
import {ScaleApplyDirective} from 'app/zoomable-view/directive/scale-apply/scale-apply.directive';
import {ScaleDirective} from 'app/zoomable-view/directive/scale/scale.directive';
import {ZoomDirective} from 'app/zoomable-view/directive/zoom/zoom.directive';
import {ZoomableViewComponent} from 'app/zoomable-view/component/zoomable-view.component';
import {ZoomableViewService} from 'app/zoomable-view/service/zoomable-view/zoomable-view.service';
import {DirectiveModule} from 'app/directive/directive.module';
import {CommonModule} from '@angular/common';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DrawingModule} from 'app/drawing/drawing.module';

@NgModule({
    imports: [DirectiveModule, CommonModule, DragDropModule, DrawingModule],
    declarations: [
        ScaleDirective,
        ZoomDirective,
        ZoomableViewComponent,
        ScaleApplyDirective,
    ],
    providers: [ZoomableViewService],
    exports: [ZoomableViewComponent, ScaleApplyDirective],
})
export class ZoomableViewModule {}
