import {NgModule} from '@angular/core';
import {OverlayContainer} from '@angular/cdk/overlay';
import {BottomSheetTitleComponent} from 'app/cdk/component/bottom-sheet-title/bottom-sheet-title.component';
import {FullScreenOverlayContainer} from 'app/cdk/classes/overlay/full-screen-overlay.container';
import {BottomSheetService} from 'app/cdk/service/bottom-sheet/bottom-sheet.service';
import {DropListComponent} from 'app/cdk/component/drop-list/drop-list.component';
import {DraggablesDirective} from 'app/cdk/directive/draggables/draggables.directive';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ContentDirectiveModule} from 'app/content/screen/directive/directive.module';
import {CommonModule} from '@angular/common';
import {DragDropService} from 'app/cdk/service/drag-drop/drag-drop.service';
import {DialogModule} from 'app/cdk/component/dialogs/dialog.module';

@NgModule({
    imports: [
        DragDropModule,
        ContentDirectiveModule,
        CommonModule,
        DialogModule,
    ],
    declarations: [
        BottomSheetTitleComponent,
        DropListComponent,
        DraggablesDirective,
    ],
    exports: [
        BottomSheetTitleComponent,
        DropListComponent,
        DraggablesDirective,
    ],
    providers: [
        BottomSheetService,
        {
            provide: OverlayContainer,
            useClass: FullScreenOverlayContainer,
        },
        {
            provide: DragDropService,
            useClass: DragDropService,
        },
    ],
})
export class CdkModule {}
