import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {ComponentModule} from 'app/component/component.module';
import {CountUpAndSubstractComponent} from 'app/tool-layer/tools/count-up-and-substract/count-up-and-substract.component';

@NgModule({
    imports: [CommonModule, ComponentModule],
    declarations: [CountUpAndSubstractComponent],
    entryComponents: [CountUpAndSubstractComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(CountUpAndSubstractComponent),
            multi: true,
        },
    ],
})
export class CountUpAndSubstractModule {}
