import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CalculatorComponent} from 'app/drawing/component/keyboards/calculator/calculator.component';
import {KeyboardComponent} from 'app/drawing/component/keyboards/keyboard/keyboard.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ComponentModule} from 'app/component/component.module';

@NgModule({
    imports: [BrowserModule, ComponentModule, DragDropModule],
    declarations: [CalculatorComponent, KeyboardComponent],
    exports: [CalculatorComponent, KeyboardComponent],
})
export class KeyboardsModule {}
