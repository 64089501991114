import {PortalModule} from '@angular/cdk/portal';
import {NgModule} from '@angular/core';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ComponentModule} from 'app/component/component.module';
import {ToolInnerComponent} from 'app/tool/component/inner/inner.component';
import {ToolComponent} from 'app/tool/component/tool.component';
import {CommonModule} from '@angular/common';
import {RingsModule} from 'app/tool/rings/rings.module';
import {ToolService} from 'app/tool/service/tool.service';
import {ToolRingFactory} from 'app/tool/factory/tool-ring/tool-ring.factory';
import {UrlPipe} from 'app/tool/pipe/svg/url/url.pipe';
import {PipeModule} from 'app/pipe/pipe.module';
import {HexToMatrixPipe} from 'app/tool/pipe/svg/hex-to-matrix/hex-to-matrix.pipe';

@NgModule({
    imports: [
        DragDropModule,
        CommonModule,
        PipeModule,
        RingsModule,
        PortalModule,
        ComponentModule,
    ],
    declarations: [ToolComponent, ToolInnerComponent, UrlPipe, HexToMatrixPipe],
    entryComponents: [ToolInnerComponent],
    providers: [ToolService, ToolRingFactory],
    exports: [ToolComponent],
})
export class ToolModule {}
