import {Component} from '@angular/core';
import {
    DigitalClock,
    TimeSegmentEnum,
} from 'app/tool-layer/tools/digital-clock/classes/digital-clock.class';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';

@Component({
    selector: 'digital-clock',
    templateUrl: './digital-clock.component.html',
    styleUrls: ['./digital-clock.component.scss'],
})
export class DigitalClockComponent extends AbstractTool {
    public static readonly id = 'digital_clock';
    public static readonly width = 210;
    public static readonly height = 90;
    public readonly timeSegmentEnum = TimeSegmentEnum;

    public clock = DigitalClock.forNow();

    public zoomSize = 'default';

    public hoursModel: number;
    public minutesModel: number;
    public secondsModel: number;
    public showSeconds = false;

    constructor() {
        super();

        this.hoursModel = this.clock.hoursSegments.value;
        this.minutesModel = this.clock.minutesSegments.value;
        this.secondsModel = this.clock.secondsSegments.value;
    }

    public rangeChange(item: number, segment: TimeSegmentEnum): void {
        this.clock.updateTime(item, segment);
    }

    public setZoomSize(size: string): void {
        this.zoomSize = size;
    }
}
