import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-line-svg',
    templateUrl: './line-svg.component.html',
    styleUrls: ['./line-svg.component.scss'],
})
export class LineSvgComponent {
    @Input()
    public length = '0_100';

    @Input()
    public showMarkers = false;

    @Input()
    public showNumbers = false;

    @Input()
    public showUnits = false;
}
