import {Component, Output, EventEmitter} from '@angular/core';
import {Observable} from 'rxjs';
import {DrawingRootService} from 'app/drawing/service/drawing/drawing-root.service';
import {DEFAULT_ERASER_DIAMETER_OPTIONS} from 'app/drawing/consts';

@Component({
    selector: 'app-eraser',
    templateUrl: './eraser.component.html',
    styleUrls: ['./eraser.component.scss'],
})
export class EraserComponent {
    @Output()
    public closed = new EventEmitter<void>();

    options = DEFAULT_ERASER_DIAMETER_OPTIONS;

    size$: Observable<number>;

    constructor(private drawingRootService: DrawingRootService) {
        this.size$ = this.drawingRootService.eraserLineWidth$;
    }

    public close(): void {
        this.closed.emit();
    }

    public onDiameterChange(diameter: number) {
        this.drawingRootService.setEraserLineWidth(diameter);
    }
}
