import {Injectable} from '@angular/core';
import {DialogService} from 'app/cdk/service/dialog/dialog.service';
import {DrawingActionEnum} from 'app/drawing/enum/action.enum';
import {DrawingStateEnum} from 'app/drawing/enum/state.enum';
import {
    GoogleAnalyticsActionsEnum,
    GoogleAnalyticsCategoriesEnum,
    GoogleAnalyticsLabelEnum,
} from 'app/service/google-analytics/categories.enum';
import {GoogleAnalyticsService} from 'app/service/google-analytics/google-analytics.service';
import {Observable, Subject, Subscription} from 'rxjs';

@Injectable()
export class DrawingService {
    private readonly stateSubject = new Subject<DrawingStateEnum>();
    private readonly actionSubjects: Subject<DrawingActionEnum>[] = [];
    private currentState = DrawingStateEnum.Disabled;

    public constructor(
        private gaService: GoogleAnalyticsService,
        private dialogService: DialogService
    ) {}

    private static getDrawingStateGaCategory(
        state: DrawingStateEnum
    ): GoogleAnalyticsCategoriesEnum {
        switch (state) {
            case DrawingStateEnum.PdfMagicWand:
            case DrawingStateEnum.PdfShowAnswer:
                return GoogleAnalyticsCategoriesEnum.Answers;

            default:
                return GoogleAnalyticsCategoriesEnum.Draw;
        }
    }

    private static getDrawingStateGaAction(
        state: DrawingStateEnum
    ): GoogleAnalyticsActionsEnum {
        switch (state) {
            case DrawingStateEnum.Pen:
                return GoogleAnalyticsActionsEnum.DrawStatePen;

            case DrawingStateEnum.Highlighter:
                return GoogleAnalyticsActionsEnum.DrawStatePenMarker;

            case DrawingStateEnum.PdfMagicWand:
                return GoogleAnalyticsActionsEnum.DrawPdfMagicWand;
            case DrawingStateEnum.PdfShowAnswer:
                return GoogleAnalyticsActionsEnum.DrawPdfShowAnswers;

            default:
                return GoogleAnalyticsActionsEnum.Empty;
        }
    }

    private static getDrawingStateGaLabel(
        state: DrawingStateEnum
    ): GoogleAnalyticsLabelEnum {
        switch (state) {
            case DrawingStateEnum.Pen:
                return GoogleAnalyticsLabelEnum.DrawStatePen;

            case DrawingStateEnum.Highlighter:
                return GoogleAnalyticsLabelEnum.DrawStateMarker;

            default:
                return GoogleAnalyticsLabelEnum.Empty;
        }
    }
    private static getDrawingActionGaAction(
        action: DrawingActionEnum
    ): GoogleAnalyticsActionsEnum {
        switch (action) {
            case DrawingActionEnum.Clear:
                return GoogleAnalyticsActionsEnum.DrawActionErase;
            case DrawingActionEnum.Undo:
                return GoogleAnalyticsActionsEnum.DrawActionUndo;
            case DrawingActionEnum.Redo:
                return GoogleAnalyticsActionsEnum.DrawActionRedo;
            default:
                return GoogleAnalyticsActionsEnum.DrawActionStart;
        }
    }

    public isPen(state: DrawingStateEnum): boolean {
        return [DrawingStateEnum.Pen].includes(state);
    }

    public isHighlighter(state: DrawingStateEnum): boolean {
        return [DrawingStateEnum.Highlighter].includes(state);
    }

    public isEraser(state: DrawingStateEnum): boolean {
        return [DrawingStateEnum.Eraser].includes(state);
    }

    public isDrawingActive(state: DrawingStateEnum): boolean {
        return [
            DrawingStateEnum.Pen,
            DrawingStateEnum.Highlighter,
            DrawingStateEnum.PdfMagicWand,
            DrawingStateEnum.Calculator,
            DrawingStateEnum.Keyboard,
            DrawingStateEnum.Eraser,
        ].includes(state);
    }

    public get state(): DrawingStateEnum {
        return this.currentState;
    }

    public set state(state: DrawingStateEnum) {
        this.currentState = state;
        this.stateSubject.next(this.currentState);

        // TODO: Add Thas Event
        this.gaService.event(
            DrawingService.getDrawingStateGaAction(state),
            DrawingService.getDrawingStateGaCategory(state),
            DrawingService.getDrawingStateGaLabel(state)
        );
    }

    public subscribeToStateChange(
        fn: (change: DrawingStateEnum) => void
    ): Subscription {
        return this.stateSubject.subscribe(fn);
    }

    public subscribeToActions(
        fn: (change: DrawingActionEnum) => void
    ): Subscription {
        const subject = new Subject<DrawingActionEnum>();
        this.actionSubjects.push(subject);

        return subject.subscribe(fn);
    }

    public unsubscribeFromActions(): void {
        const subject = this.actionSubjects.pop();
        if (!subject) {
            return;
        }

        subject.unsubscribe();
    }

    public action(action: DrawingActionEnum): void {
        const length = this.actionSubjects.length;
        if (length === 0) {
            return;
        }

        // TODO: Add Thas Event
        this.gaService.event(
            DrawingService.getDrawingActionGaAction(action),
            GoogleAnalyticsCategoriesEnum.Draw
        );

        this.actionSubjects[length - 1].next(action);
    }

    public requestToClear(): Observable<boolean> {
        const subject = new Subject<boolean>();

        this.dialogService
            .confirm({
                title: 'Weet je het zeker?',
                text: 'Wil je de aantekeningen verwijderen?',
            })
            .subscribe(confirmed => {
                subject.next(confirmed);
                subject.complete();
            });

        return subject.asObservable();
    }
}
