export const AudioOptionMapping = [
    {
        value: 3249404,
        name: 'Alarm',
    },
    {
        value: 3249411,
        name: 'Kookwekker',
    },
    {
        value: 3249413,
        name: 'Kerkklok',
    },
    {
        value: 3249407,
        name: 'Fietsbel',
    },
    {
        value: 3249406,
        name: 'Bel',
    },
    {
        value: 3249412,
        name: 'Toeter',
    },
    {
        value: 3249405,
        name: 'Koe',
    },
    {
        value: 3249410,
        name: 'Ezel',
    },
    {
        value: 3249409,
        name: 'Hond',
    },
    {
        value: 3249408,
        name: 'Applaus',
    },
    {
        value: undefined,
        name: 'Geen geluid',
    },
];
