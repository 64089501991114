import {Component, Input, Output, EventEmitter} from '@angular/core';
import {COLORS} from 'app/drawing/consts';
import {Color} from 'app/drawing/interface/color.interface';

@Component({
    selector: 'app-color-selector',
    templateUrl: './color-selector.component.html',
    styleUrls: ['./color-selector.component.scss'],
})
export class ColorSelectorComponent {
    @Input()
    value!: Color;

    @Input()
    options: Color[] = COLORS;

    @Output()
    public readonly changed: EventEmitter<InputEvent> = new EventEmitter();

    public onChange(option: InputEvent) {
        this.changed.emit(option);
    }
}
