import {Injectable} from '@angular/core';

@Injectable()
export class BbvmsService {
    private isPlayerFinished = '';

    public setPlayerState(state: string): void {
        this.isPlayerFinished = state;
    }
    public getPlayerState(): string {
        return this.isPlayerFinished;
    }
}
