import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    DrawingCursorMap,
    DrawingLineColorMap,
    DrawingLineWidthMap,
} from 'app/drawing/enum/line-color.enum';
import {DrawingStateEnum} from 'app/drawing/enum/state.enum';
import {DrawingService} from 'app/drawing/service/drawing/drawing.service';
import {ZoomableViewService} from 'app/zoomable-view/service/zoomable-view/zoomable-view.service';
import {Subscription} from 'rxjs';
import {
    UserSelectionArea,
    UserSelectionStartCoords,
} from 'app/interface/selection.interface';
import {KeyCodeEnum} from 'app/enum/key-code.enum';
import {DrawingRootService} from 'app/drawing/service/drawing/drawing-root.service';
import {Color} from 'app/drawing/interface/color.interface';

// eslint-disable-next-line
declare let fabric: any | never;

@Component({
    selector: 'app-drawing-canvas',
    templateUrl: 'canvas.component.html',
    styleUrls: ['canvas.component.scss'],
})
export class DrawingCanvasComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    @ViewChild('wrapperElement')
    private wrapperElement?: ElementRef<HTMLElement>;
    @ViewChild('canvas')
    private canvasElement!: ElementRef<HTMLCanvasElement>;

    @Input()
    public isDrawingMode = true;

    @Input()
    public clearCanvasAfterDrawing = true;

    @Output()
    public readonly drawn = new EventEmitter<fabric.Object>();

    @Output()
    public readonly selected = new EventEmitter<UserSelectionArea>();

    public lineWidth!: number;
    public lineColor!: string;
    public highlighterLineWidth!: number;
    public highlighterLineColor!: string;
    public eraserLineWidth!: number;
    public drawingCursor!: string;
    public width!: number;
    public height!: number;

    private currentState!: DrawingStateEnum;
    private drawingStateSubscription?: Subscription;
    private zoomSubscription?: Subscription;
    private lineWidthSubscription?: Subscription;
    private lineColorSubscription?: Subscription;
    private highlighterLineWidthSubscription?: Subscription;
    private highlighterLineColorSubscription?: Subscription;
    private eraserLineWidthSubscription?: Subscription;
    private mouseDownCoords?: UserSelectionStartCoords;

    private canvasFabric?: fabric.Canvas;
    private renderCanvasFabric?: fabric.StaticCanvas;
    private selectedObject?: fabric.Object;

    static isKeyboardState(state: DrawingStateEnum): boolean {
        return [
            DrawingStateEnum.Keyboard,
            DrawingStateEnum.Calculator,
        ].includes(state);
    }

    static getDrawingModeByState(state: DrawingStateEnum): boolean {
        return ![DrawingStateEnum.Disabled, DrawingStateEnum.Cut].includes(
            state
        );
    }

    // tslint:disable:semicolon
    private readonly objectAddedHandler = (event: fabric.IEvent) =>
        this.handleCanvasObjectAdded(event);
    private readonly mouseDownHandler = (event: fabric.IEvent) =>
        this.handleCanvasMouseDown(event);
    private readonly mouseUpHandler = (event: fabric.IEvent) =>
        this.handleCanvasMouseUp(event);
    private readonly objectSelectedHandler = (event: fabric.IEvent) =>
        this.handleObjectSelected(event);
    private readonly objectDeselectedHandler = (event: fabric.IEvent) =>
        this.handleObjectDeselected(event);
    // tslint:enable:semicolon

    public constructor(
        private drawingService: DrawingService,
        private zoomService: ZoomableViewService,
        private changeRef: ChangeDetectorRef,
        private drawingRootService: DrawingRootService
    ) {}

    public get showKeyboard(): boolean {
        return this.currentState === DrawingStateEnum.Keyboard;
    }

    public get showCalculator(): boolean {
        return this.currentState === DrawingStateEnum.Calculator;
    }

    public ngOnInit(): void {
        this.drawingStateChanged(this.drawingService.state);
        this.drawingStateSubscription =
            this.drawingService.subscribeToStateChange(change =>
                this.drawingStateChanged(change)
            );
        this.zoomSubscription = this.zoomService.subscribe(() =>
            this.updateCanvasValues()
        );
    }

    public ngAfterViewInit(): void {
        this.buildCanvas();
    }

    private lineWidthChanged(size: number) {
        this.lineWidth = size;
        this.updateCanvasValues();
    }

    private lineColorChange(color: Color) {
        this.lineColor = color.value;
        this.updateCanvasValues();
    }

    private highlighterLineColorChange(color: Color) {
        this.lineColor = `${color.value}80`; // set opacity to 80%
        this.updateCanvasValues();
    }

    private buildCanvas(): void {
        this.canvasFabric = new fabric.Canvas(
            this.canvasElement.nativeElement,
            {
                isDrawingMode: this.isDrawingMode,
                enableRetinaScaling: false,
                renderOnAddRemove: true,
            }
        );

        this.renderCanvasFabric = new fabric.StaticCanvas(null, {
            width: Number(this.width),
            height: Number(this.height),
            enableRetinaScaling: false,
        });

        if (!this.canvasFabric) {
            return;
        }

        this.updateCanvasValues();
        this.addObject(new fabric.Rect());
        this.canvasFabric.clear();

        this.canvasFabric.on('mouse:down', this.mouseDownHandler);
        this.canvasFabric.on('mouse:up', this.mouseUpHandler);
    }

    public ngOnDestroy(): void {
        if (this.drawingStateSubscription) {
            this.drawingStateSubscription.unsubscribe();
        }

        if (this.zoomSubscription) {
            this.zoomSubscription.unsubscribe();
        }

        if (this.lineWidthSubscription) {
            this.lineWidthSubscription.unsubscribe();
        }

        if (this.lineColorSubscription) {
            this.lineColorSubscription.unsubscribe();
        }

        if (this.highlighterLineWidthSubscription) {
            this.highlighterLineWidthSubscription.unsubscribe();
        }

        if (this.highlighterLineColorSubscription) {
            this.highlighterLineColorSubscription.unsubscribe();
        }

        if (this.eraserLineWidthSubscription) {
            this.eraserLineWidthSubscription.unsubscribe();
        }

        if (this.renderCanvasFabric) {
            this.renderCanvasFabric.clear();
            this.renderCanvasFabric.dispose();
            this.renderCanvasFabric = undefined;
        }

        if (this.canvasFabric) {
            this.canvasFabric.off('mouse:down', this.mouseDownHandler);
            this.canvasFabric.off('mouse:up', this.mouseUpHandler);
            this.canvasFabric.clear();
            this.canvasFabric.dispose();
            this.canvasFabric = undefined;
        }
    }

    public clear(): void {
        if (!this.canvasFabric) {
            return;
        }

        this.canvasFabric.clear();
        this.canvasFabric.dispose();

        this.buildCanvas();
    }

    public addObject(object: fabric.Object, setActive = false): void {
        if (!this.canvasFabric) {
            return;
        }

        this.canvasFabric.off('object:added', this.objectAddedHandler);
        this.canvasFabric.off('erasing:end', this.objectAddedHandler);
        this.canvasFabric.add(object);
        this.canvasFabric.on('object:added', this.objectAddedHandler);
        this.canvasFabric.on('erasing:end', this.objectAddedHandler);

        if (setActive) {
            object.on('selected', this.objectSelectedHandler);
            object.on('deselected', this.objectDeselectedHandler);
            this.canvasFabric.setActiveObject(object);
        }
    }

    public removeObject(object: fabric.Object): void {
        if (!this.canvasFabric) {
            return;
        }

        this.canvasFabric.remove(object);
    }

    public toJson(): string | undefined {
        if (!this.canvasFabric) {
            return undefined;
        }

        return JSON.stringify(this.canvasFabric.toObject());
    }

    public fromJson(data: string | object): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const canvas = this.canvasFabric;
            if (!canvas) {
                reject('CanvasFabric is not defined.');

                return;
            }

            canvas.loadFromJSON(data, canvas.renderAll.bind(canvas), () => {
                resolve();
            });
        });
    }

    public getPreview(): string | undefined {
        if (!this.canvasFabric) {
            return undefined;
        }

        return this.canvasFabric.toDataURL();
    }

    public onKeyboardClose(): void {
        this.drawingService.state = DrawingStateEnum.Pen;
    }

    public onKeyboardOutput(output: string): void {
        if (!this.selectedObject || !this.canvasFabric) {
            return;
        }

        const object = this.selectedObject as fabric.Text;
        let text = object.text || '';

        if (output === KeyCodeEnum.Backspace) {
            text = text.slice(0, -1);
        } else {
            text += output;
        }

        object.text = text;
        this.canvasFabric.renderAll();
    }

    private drawingStateChanged(change: DrawingStateEnum): void {
        if (this.canvasFabric) {
            this.canvasFabric.discardActiveObject();
        }
        this.currentState = change;

        this.isDrawingMode = DrawingCanvasComponent.getDrawingModeByState(
            this.currentState
        );
        this.drawingCursor = DrawingCursorMap.get(this.currentState) as string;

        if (this.drawingService.isPen(this.currentState)) {
            this.subscribePenTools();
        } else if (this.drawingService.isHighlighter(this.currentState)) {
            this.subscribeHighlighterTools();
        } else if (this.drawingService.isEraser(this.currentState)) {
            this.subscribeEraserTools();
        } else {
            this.lineColor = DrawingLineColorMap.get(
                this.currentState
            ) as string;
            this.lineWidth = DrawingLineWidthMap.get(
                this.currentState
            ) as number;
        }

        this.updateCanvasValues();
    }

    private subscribePenTools() {
        this.lineColorSubscription =
            this.drawingRootService.lineColor$.subscribe(color =>
                this.lineColorChange(color)
            );
        this.lineWidthSubscription =
            this.drawingRootService.lineWidth$.subscribe(size => {
                this.lineWidthChanged(size);
            });
    }

    private subscribeHighlighterTools() {
        this.highlighterLineColorSubscription =
            this.drawingRootService.highlighterLineColor$.subscribe(color =>
                this.highlighterLineColorChange(color)
            );
        this.highlighterLineWidthSubscription =
            this.drawingRootService.highlighterLineWidth$.subscribe(size =>
                this.lineWidthChanged(size)
            );
    }

    private subscribeEraserTools() {
        this.eraserLineWidthSubscription =
            this.drawingRootService.eraserLineWidth$.subscribe(size =>
                this.lineWidthChanged(size)
            );
    }

    private updateCanvasValues(): void {
        if (!this.renderCanvasFabric || !this.canvasFabric) {
            return;
        }

        if (this.wrapperElement) {
            const element = this.wrapperElement.nativeElement;
            this.width = element.offsetWidth;
            this.height = element.offsetHeight;
            this.changeRef.detectChanges();
        }

        if (this.width && this.height) {
            this.renderCanvasFabric.setWidth(Number(this.width));
            this.renderCanvasFabric.setHeight(Number(this.height));
            this.renderCanvasFabric.renderAll();

            this.canvasFabric.setWidth(Number(this.width));
            this.canvasFabric.setHeight(Number(this.height));
        }

        this.canvasFabric.isDrawingMode = this.isDrawingMode;

        if (this.handleDrawingModeByState()) {
            this.canvasFabric.isDrawingMode = false;
        }
        if (!this.handleDrawingModeByState()) {
            this.canvasFabric.freeDrawingBrush =
                this.currentState === DrawingStateEnum.Eraser
                    ? new fabric.EraserBrush(this.canvasFabric)
                    : new fabric.PencilBrush(this.canvasFabric);
        }

        if (this.lineColor) {
            this.canvasFabric.freeDrawingBrush.color = String(this.lineColor);
        }

        if (this.highlighterLineColor) {
            this.canvasFabric.freeDrawingBrush.color = String(
                this.highlighterLineColor
            );
        }

        if (this.lineWidth) {
            this.canvasFabric.freeDrawingBrush.width = Number(this.lineWidth);
        }

        this.canvasFabric.defaultCursor =
            this.currentState === DrawingStateEnum.Cut
                ? this.drawingCursor
                : 'default';

        this.canvasFabric.freeDrawingCursor = this.drawingCursor;
        this.canvasFabric.renderAll();
    }

    private handleDrawingModeByState(): boolean {
        return (
            this.currentState === DrawingStateEnum.Calculator ||
            this.currentState === DrawingStateEnum.Keyboard
        );
    }

    private addTextElement(x: number, y: number): void {
        if (!this.canvasFabric) {
            return;
        }

        const textElement = new fabric.Textbox('', {
            top: y,
            left: x,
            fontFamily: 'Arial',
            editable: false,
            lockScalingY: true,
            lockScalingFlip: true,
            padding: 5,
        });

        this.addObject(textElement, true);
    }

    private handleCanvasObjectAdded(event: fabric.IEvent): void {
        const object = event.target;
        if (undefined === object || !this.canvasFabric) {
            return;
        }

        object.selectable = false;
        this.drawn.emit(object);

        if (this.clearCanvasAfterDrawing) {
            this.clear();
        }
    }

    private handleCanvasMouseDown(event: fabric.IEvent): void {
        if (!event.pointer) {
            return;
        }

        if (
            DrawingCanvasComponent.isKeyboardState(this.currentState) &&
            event.target !== this.selectedObject
        ) {
            this.addTextElement(event.pointer.x, event.pointer.y);
        }

        this.mouseDownCoords = {
            x1: event.pointer.x,
            y1: event.pointer.y,
        };
    }

    private handleCanvasMouseUp(event: fabric.IEvent): void {
        if (!event.pointer || !this.mouseDownCoords) {
            return;
        }

        const {x1, y1} = this.mouseDownCoords;
        const x2 = event.pointer.x;
        const y2 = event.pointer.y;

        if (x1 === x2 || y1 === y2) {
            return;
        }

        const coords = {
            x1: x1 < x2 ? x1 : x2,
            x2: x2 > x1 ? x2 : x1,
            y1: y1 < y2 ? y1 : y2,
            y2: y2 > y1 ? y2 : y1,
        } as UserSelectionArea;

        this.selected.emit(coords);
    }

    private handleObjectSelected(event: fabric.IEvent): void {
        if (!event.target || !this.canvasFabric) {
            return;
        }

        this.selectedObject = event.target;
    }

    private handleObjectDeselected(event: fabric.IEvent): void {
        if (!event.target || !this.canvasFabric) {
            return;
        }

        event.target.off('selected', this.objectSelectedHandler);
        event.target.off('deselected', this.objectDeselectedHandler);

        this.selectedObject = undefined;
        this.canvasFabric.discardActiveObject();
        this.handleCanvasObjectAdded(event);
    }
}
