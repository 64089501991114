import {ToolInstance} from 'app/tool-layer/class/tool-instance';

export enum ChangeTypeEnum {
    AddTool,
    DeleteTool,
    DeleteType,
    Transparency,
    ActivateTool,
    CloseAll,
}

export class ActiveChangeEvent {
    private constructor(
        public readonly change: ChangeTypeEnum,
        public readonly tool?: ToolInstance,
        public readonly type?: string
    ) {}

    public static forAddTool(tool: ToolInstance): ActiveChangeEvent {
        return new ActiveChangeEvent(ChangeTypeEnum.AddTool, tool);
    }

    public static forDeleteTool(tool: ToolInstance): ActiveChangeEvent {
        return new ActiveChangeEvent(ChangeTypeEnum.DeleteTool, tool);
    }

    public static forDeleteType(type: string): ActiveChangeEvent {
        return new ActiveChangeEvent(
            ChangeTypeEnum.DeleteType,
            undefined,
            type
        );
    }

    public static forTransparency(): ActiveChangeEvent {
        return new ActiveChangeEvent(ChangeTypeEnum.Transparency);
    }

    public static forActivateTool(tool?: ToolInstance): ActiveChangeEvent {
        return new ActiveChangeEvent(ChangeTypeEnum.ActivateTool, tool);
    }

    public static forCloseAllTools(): ActiveChangeEvent {
        return new ActiveChangeEvent(ChangeTypeEnum.CloseAll);
    }
}
