import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {init, routingInstrumentation} from '@sentry/angular';
import {Integrations} from '@sentry/tracing';
import {environment} from 'app/environment';
import {AppModule} from 'app/app.module';
import {enableUsabilla} from 'usabilla';

init({
    dsn: environment.sentryDsn,
    environment: environment.environment,
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: ['localhost', environment.apiUrl],
            routingInstrumentation,
        }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,
});

if (environment.production) {
    enableProdMode();
}

if (environment.environment !== 'e2e') {
    enableUsabilla();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
