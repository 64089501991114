import {StringUtil} from 'app/util/string/string.util';

export class DigitalClockSegments {
    public value!: number;
    public segmentZero!: string[];
    public segmentOne!: string[];

    public constructor() {
        this.updateTimeSegment(0);
    }

    public updateTimeSegment(timeSegment: number): void {
        this.value = timeSegment;

        const prependedSegment = StringUtil.prependWithZeros(
            String(timeSegment),
            2
        );
        this.segmentZero = this.getTimeSegments(prependedSegment[0]);
        this.segmentOne = this.getTimeSegments(prependedSegment[1]);
    }

    private getTimeSegments(segment: string): string[] {
        switch (segment) {
            case '0':
                return ['a', 'b', 'c', 'e', 'f', 'g'];
            case '1':
                return ['f', 'g'];
            case '2':
                return ['b', 'c', 'd', 'e', 'f'];
            case '3':
                return ['c', 'd', 'e', 'f', 'g'];
            case '4':
                return ['a', 'd', 'f', 'g'];
            case '5':
                return ['a', 'c', 'd', 'e', 'g'];
            case '6':
                return ['a', 'b', 'c', 'd', 'e', 'g'];
            case '7':
                return ['c', 'f', 'g'];
            case '8':
                return ['a', 'b', 'c', 'd', 'e', 'f', 'g'];
            case '9':
                return ['a', 'c', 'd', 'e', 'f', 'g'];
            default:
                return ['d'];
        }
    }
}
