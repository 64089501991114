import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import {PointerEventInterface} from 'app/interface/event.interface';
import {Point, PointInterface} from 'app/classes/point.class';

export enum PanPointEventTypeEnum {
    Init = 'panPointInit',
    Start = 'panPointStart',
    Move = 'panPointMove',
    End = 'panPointEnd',
}

export interface PanPointScrollPercentageInterface {
    scrollXPercentage: number;
    scrollYPercentage: number;
}

export class PanPointEvent extends Event {
    constructor(
        type: PanPointEventTypeEnum,
        public readonly point: PointInterface,
        public readonly percentage: PanPointScrollPercentageInterface
    ) {
        super(type);
    }
}

@Directive({
    selector: '[appPanPoint]',
})
export class PanPointDirective implements AfterViewInit {
    @Output()
    public readonly panPoint: EventEmitter<PanPointEvent> = new EventEmitter();

    @Input()
    public drawingActive = false;

    private clientStartPoint!: PointInterface;
    private panStartPoint!: PointInterface;
    private panCurrentPoint: PointInterface = new Point();

    constructor(public readonly elementRef: ElementRef) {}

    public ngAfterViewInit(): void {
        this.dispatchPointEvent(
            PanPointEventTypeEnum.Init,
            this.getStartPoint()
        );
    }

    private getStartPoint(): PointInterface {
        const element: HTMLElement = this.getNativeElement();
        const domRect: DOMRect = element.getBoundingClientRect();
        const parentDomRect: DOMRect = (
            element.parentNode as HTMLElement
        ).getBoundingClientRect();

        return new Point(
            domRect.x - parentDomRect.x,
            domRect.y - parentDomRect.y
        );
    }

    @HostListener('panstart', ['$event'])
    private onPanStart(event: PointerEventInterface): void {
        if (this.drawingActive) {
            return;
        }

        this.panStartPoint = this.getStartPoint();
        this.clientStartPoint = new Point(
            Math.round(event.srcEvent.clientX),
            Math.round(event.srcEvent.clientY)
        );

        this.dispatchPointEvent(
            PanPointEventTypeEnum.Start,
            this.panCurrentPoint
        );
    }

    @HostListener('panmove', ['$event'])
    private onPanMove(event: PointerEventInterface): void {
        if (this.drawingActive) {
            return;
        }

        const clientDistanceX: number =
            this.clientStartPoint.x - Math.round(event.srcEvent.clientX);
        const clientDistanceY: number =
            this.clientStartPoint.y - Math.round(event.srcEvent.clientY);

        this.panCurrentPoint = this.getTransformPoint(
            this.panStartPoint.x - clientDistanceX,
            this.panStartPoint.y - clientDistanceY
        );

        this.dispatchPointEvent(
            PanPointEventTypeEnum.Move,
            this.panCurrentPoint
        );
    }

    @HostListener('panend', ['$event'])
    private onPanEnd(): void {
        if (this.drawingActive) {
            return;
        }

        this.dispatchPointEvent(
            PanPointEventTypeEnum.End,
            this.panCurrentPoint
        );
    }

    private getTransformPoint(x: number, y: number): PointInterface {
        const element: HTMLElement = this.getNativeElement();
        const elementDomRect: DOMRect = element.getBoundingClientRect();
        const parentDomRect: DOMRect = (
            element.parentNode as HTMLElement
        ).getBoundingClientRect();
        const scale: number = elementDomRect.width / element.offsetWidth;

        return new Point(
            this.getTranslateValue(
                x,
                element.scrollWidth * scale - parentDomRect.width
            ),
            this.getTranslateValue(
                y,
                element.scrollHeight * scale - parentDomRect.height
            )
        );
    }

    private getTranslateValue(pointValue: number, maxOffset: number): number {
        // Point value can never be higher than zero
        if (pointValue >= 0) {
            return 0;
        }

        if (Math.abs(pointValue) >= maxOffset) {
            return -maxOffset;
        }

        return pointValue;
    }

    private dispatchPointEvent(
        type: PanPointEventTypeEnum,
        point: PointInterface
    ): void {
        const element: HTMLElement = this.elementRef.nativeElement;
        const parentDomRect: DOMRect = (
            element.parentNode as HTMLElement
        ).getBoundingClientRect();
        const scrollXPercentage: number = this.getScrollXPercentage(
            element,
            parentDomRect
        );
        const scrollYPercentage: number = this.getScrollYPercentage(
            element,
            parentDomRect
        );
        const panPointEvent: PanPointEvent = new PanPointEvent(type, point, {
            scrollXPercentage,
            scrollYPercentage,
        });

        this.elementRef.nativeElement.dispatchEvent(panPointEvent);
        this.panPoint.emit(panPointEvent);
    }

    private getNativeElement(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    public getScrollXPercentage(
        element: HTMLElement,
        parentDomRect: DOMRect
    ): number {
        if (element.scrollWidth <= parentDomRect.width) {
            return -1;
        }

        const totalDistance: number = element.scrollWidth - parentDomRect.width;
        const domRect: DOMRect = element.getBoundingClientRect();
        const elementDiff: number = parentDomRect.right - domRect.right;

        return Math.round((100 / totalDistance) * elementDiff);
    }

    public getScrollYPercentage(
        element: HTMLElement,
        parentDomRect: DOMRect
    ): number {
        if (element.scrollHeight <= parentDomRect.height) {
            return -1;
        }

        const totalDistance: number =
            element.scrollHeight - parentDomRect.height;
        const domRect: DOMRect = element.getBoundingClientRect();
        const elementDiff: number = parentDomRect.bottom - domRect.bottom;

        return Math.round((100 / totalDistance) * elementDiff);
    }
}
