import {Component, Input, Output} from '@angular/core';
import {EventEmitter} from '@angular/core';
@Component({
    selector: 'app-check-box',
    templateUrl: './check-box.component.html',
    styleUrls: ['./check-box.component.scss'],
})
export class CheckBoxComponent {
    @Input()
    public checked = false;
    @Input()
    public label?: string;
    @Output()
    public checkedChange = new EventEmitter<boolean>();

    public toggle(event: Event): void {
        event.preventDefault();
        this.checked = !this.checked;
        this.checkedChange.emit(this.checked);
    }
}
