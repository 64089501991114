import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';

@Component({
    selector: 'app-splits-flat',
    templateUrl: './splits-flat.component.html',
})
export class SplitsFlatComponent extends AbstractTool {
    public static readonly id = 'splitsflat';
    public static readonly width = 620;
    public static readonly height = 500;

    public numLayers = 4; // default for a new tool instance
    public numLayersMin = 4;
    public numLayersMax = 9;

    public numCircles = 5; // default for a new tool instance
    public numCirclesMin = 5;
    public nnumCirclesMax = 10;

    public addOrSubstract = 'add'; // default for a new tool instance

    public activeField = 0; // set focus on index 0 straight away after creation
    public values: {[key: number]: string} = {};

    public setActiveField(index: number): void {
        this.activeField = index;
    }

    public setAddOrSubstract(addOrSub: string): void {
        this.addOrSubstract = addOrSub;
    }

    public toggleCircle(event: MouseEvent): void {
        const target = event.target as HTMLElement;
        target.classList.toggle('circle-selected');
    }

    public handleClear(): void {
        this.values = {};
    }

    public handleUndo(): void {
        if (this.activeField === null || !(this.activeField in this.values)) {
            return;
        }

        const currentValue = this.values[this.activeField];
        if (currentValue && currentValue.length > 1) {
            this.values[this.activeField] = currentValue.slice(0, -1);
        } else {
            delete this.values[this.activeField];
        }
    }

    public handleNumberChoice(value: number): void {
        if (this.activeField === null) {
            return;
        }

        const fieldExists = this.activeField in this.values;
        const currentValue = this.values[this.activeField];

        if (!fieldExists || !currentValue || currentValue === '0') {
            this.values[this.activeField] = value.toString();
        } else if (currentValue.length < 3) {
            this.values[this.activeField] += value.toString();
        }
    }
}
