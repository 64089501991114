import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {TrafficLightComponent} from './traffic-light.component';

@NgModule({
    imports: [CommonModule],
    declarations: [TrafficLightComponent],
    entryComponents: [TrafficLightComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(TrafficLightComponent),
            multi: true,
        },
    ],
})
export class TrafficLightModule {}
