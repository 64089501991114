import {Injectable} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {Location} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private rawUrlPart?: string;
    private currentTheme?: string;
    private currentThemeSubject: Subject<string | undefined> = new Subject();

    constructor(private location: Location) {
        location.onUrlChange(url => this.handleLocationChange(url));
    }

    public subscribeToCurrentTheme(
        fn: (theme: string | undefined) => void
    ): Subscription {
        return this.currentThemeSubject.subscribe(fn);
    }

    private handleLocationChange(url: string | undefined): void {
        if (!url || url === '/') {
            this.currentTheme = undefined;
            this.rawUrlPart = undefined;
            this.currentThemeSubject.next();
            return;
        }

        const rawUrlPart = url.split('/')[1];

        if (this.rawUrlPart === rawUrlPart) {
            return;
        }

        this.rawUrlPart = rawUrlPart;
        const slug = decodeURIComponent(rawUrlPart)
            .toLowerCase()
            .replace(/\W+/g, ' ')
            .trim()
            .replace(/\s/g, '-');

        if (this.currentTheme === slug) {
            return;
        }

        this.currentThemeSubject.next(slug);
    }
}
