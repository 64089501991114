import {Component, HostBinding, Input, OnChanges} from '@angular/core';

export enum StateIconEnum {
    Correct = 'fa-check',
    InCorrect = 'fa-times',
}

@Component({
    selector: 'app-state-icon',
    templateUrl: './state-icon.component.html',
    styleUrls: ['./state-icon.component.scss'],
})
export class StateIconComponent implements OnChanges {
    @HostBinding('class')
    public class?: string;

    @Input()
    public state?: StateIconEnum;

    private static getStateNameInsteadOfValue(state: StateIconEnum): string {
        const index = Object.values(StateIconEnum).indexOf(state);

        return Object.keys(StateIconEnum)[index].toLowerCase();
    }

    public ngOnChanges() {
        this.handleStateChange();
    }

    private handleStateChange(): void {
        let classes = '';

        if (this.state) {
            const iconName = StateIconComponent.getStateNameInsteadOfValue(
                this.state
            );

            classes = `assignment-state-icon--${iconName}`;
        }

        this.class = `assignment-state-icon ${classes}`;
    }
}
