import {
    AfterContentInit,
    Component,
    ComponentFactoryResolver,
    ContentChildren,
    forwardRef,
    QueryList,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {AssignmentGapMatchComponent} from 'app/content/screen/components/assignment-gap-match/assignment-gap-match.component';
import {ExerciseComponent} from 'app/content/screen/exercise/exercise.component';
import {AssignmentGapMatchRowComponent} from 'app/content/screen/components/assignment-gap-match/assignment-gap-match-row/assignment-gap-match-row.component';

@Component({
    selector: 'assignment-gap-match-game',
    templateUrl: './assignment-gap-match-game.component.html',
    styleUrls: ['./assignment-gap-match-game.component.scss'],
    providers: [
        {
            provide: ExerciseComponent,
            useExisting: forwardRef(() => AssignmentGapMatchGameComponent),
        },
    ],
})
export class AssignmentGapMatchGameComponent
    extends AssignmentGapMatchComponent
    implements AfterContentInit
{
    @ContentChildren(AssignmentGapMatchRowComponent)
    private contentRows!: QueryList<AssignmentGapMatchRowComponent>;

    @ViewChild('optionsTarget', {read: ViewContainerRef})
    private optionsViewContainerRef!: ViewContainerRef;

    constructor(componentFactoryResolver: ComponentFactoryResolver) {
        super(componentFactoryResolver);
    }

    public ngAfterContentInit(): void {
        this.contentRows.forEach(
            contentRow => (contentRow.centerContent = true)
        );
    }

    protected getOptionsViewContainerRef(): ViewContainerRef {
        return this.optionsViewContainerRef;
    }
}
