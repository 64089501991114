import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'hexToMatrix',
})
export class HexToMatrixPipe implements PipeTransform {
    public transform(hex: string): string {
        const rgb: string[] = [];
        const hexCode: string = hex.replace('#', '');

        for (let i = 0; i < hexCode.length; i += 2) {
            const firstDigit = parseInt(hexCode[i], 16);
            const firstDigitPartial = firstDigit * 16;
            const RGBValue = parseInt(hexCode[i + 1], 16) + firstDigitPartial;

            rgb.push((RGBValue / 255).toFixed(2));
        }

        return [
            `0 0 0 0 ${rgb[0]}`,
            `0 0 0 0 ${rgb[1]}`,
            `0 0 0 0 ${rgb[2]}`,
            '0 0 0 1 0',
        ].join(' ');
    }
}
