import {
    Component,
    Input,
    EventEmitter,
    Output,
    HostListener,
    HostBinding,
} from '@angular/core';

@Component({
    selector: 'app-radio-input',
    templateUrl: './radio-input.component.html',
    styleUrls: ['./radio-input.component.scss'],
})
export class RadioInputComponent {
    @Input()
    public value!: string | number;

    @Input()
    public model!: string | number;

    @Output()
    public selected = new EventEmitter<string | number>();

    @HostBinding('class.selected')
    public get isSelected(): boolean {
        return this.value === this.model;
    }

    @HostListener('click')
    public select(): void {
        this.selected.emit(this.value);
    }
}
