import {Component} from '@angular/core';
import {AbstractTool} from 'app/tool-layer/class/abstract-tool';

@Component({
    selector: 'app-dhte',
    templateUrl: './dhte.component.html',
    styleUrls: ['./dhte.component.scss'],
})
export class DhteComponent extends AbstractTool {
    public static readonly id = 'dhte';
    public static readonly width = 170;
    public static readonly height = 95;
    public rangeModel = {
        D: 0,
        H: 0,
        T: 0,
        E: 0,
    };

    constructor() {
        super();
    }
}
