import {Injectable} from '@angular/core';
import {AnswersService} from 'app/service/answers/answers.service';
import {ContentService} from 'app/service/content/content.service';
import {ToolRingInterface} from 'app/tool/classes/tool-ring.class';
import {Radius} from 'app/tool/enum/radius.enum';
import {
    ToolRingConfigInterface,
    ToolRingFactory,
} from 'app/tool/factory/tool-ring/tool-ring.factory';
import {SvgFontAwesomeEnum} from 'app/enum/svg.enum';
import {AbstractToolRingService} from 'app/tool/rings/base/abstract-tool-ring.service';
import {RingIds} from 'app/tool/rings/ring-ids.enum';
import {ToolboxModalService} from 'app/toolbox/service/modal/modal.service';
import {ToolboxService} from 'app/toolbox/service/toolbox/toolbox.service';
import {ColorInterpolationFilterEnum} from 'app/tool/enum/filter.enum';
import {OuterRingService} from 'app/tool/service/outer-ring.service';

@Injectable()
export class ExerciseRingService extends AbstractToolRingService {
    private exercise!: ToolRingInterface;

    constructor(
        protected toolRingFactory: ToolRingFactory,
        protected toolboxService: ToolboxService,
        protected toolboxModalService: ToolboxModalService,
        private contentService: ContentService,
        private answersService: AnswersService,
        private outerRingService: OuterRingService
    ) {
        super(toolRingFactory, toolboxService, toolboxModalService);

        this.setRingInstance();
    }

    public getId(): RingIds {
        return RingIds.ExerciseRing;
    }

    public render(): ToolRingInterface[] {
        this.syncToolboxButtons(this.config(), this.exercise, 'blue');

        return [this.exercise];
    }

    protected handleCategoriesChanged(): void {
        this.syncToolboxButtons(this.config(), this.exercise, 'blue');

        super.handleCategoriesChanged();
    }

    private activateZoomRing(): void {
        this.outerRingService.activateRing(RingIds.ZoomRing);
    }

    private config(): ToolRingConfigInterface {
        return {
            id: 'exercise',
            radius: Radius.Large,
            rotate: 198,
            visible: false,
            slices: 10,
            buttons: [
                {
                    id: 'activate-zoom',
                    icon: SvgFontAwesomeEnum.SolidEllipsisHorizontal,
                    iconFilter: ColorInterpolationFilterEnum.BlueDark,
                    classes: ['svg__button--blue-light'],
                    callback: () => this.activateZoomRing(),
                },
                {
                    id: 'undo',
                    icon: SvgFontAwesomeEnum.SolidUndoAlt,
                    classes: ['svg__button--blue-light'],
                    callback: () =>
                        this.answersService.undo(
                            this.contentService.getCurrentResource()
                        ),
                },
                {
                    id: 'is-ok',
                    icon: SvgFontAwesomeEnum.SolidCheck,
                    classes: ['svg__button--blue-light'],
                    callback: () =>
                        this.answersService.checkIsOk(
                            this.contentService.getCurrentResource()
                        ),
                },
                {
                    id: 'show-correct',
                    icon: SvgFontAwesomeEnum.SolidEye,
                    classes: ['svg__button--blue-light'],
                    callback: () =>
                        this.answersService.showCorrectAnswer(
                            this.contentService.getCurrentResource()
                        ),
                },
            ],
        };
    }

    private setRingInstance(): void {
        this.exercise = this.toolRingFactory.create(this.config());
    }
}
