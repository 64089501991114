import {Component} from '@angular/core';
import {AbstractKeyboard} from 'app/drawing/component/keyboards/abstract-keyboard.class';

@Component({
    selector: 'app-calculator',
    templateUrl: './calculator.component.html',
    styleUrls: [
        './calculator.component.scss',
        '../abstract-keyboard.styles.scss',
    ],
})
export class CalculatorComponent extends AbstractKeyboard {}
