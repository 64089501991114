import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {StateIconEnum} from 'app/component/state-icon/state-icon.component';
import {StringUtil} from 'app/util/string/string.util';

export interface AssignmentSelectOptionInterface {
    readonly selectedChange: EventEmitter<AssignmentSelectOptionInterface>;
    groupId: string;
    multipleSelect: boolean;

    reset(): void;
    highlightIfCorrect(): void;
    checkAnswerState(): boolean;
    isWrong(): boolean;
    isChecked(): boolean;
    shouldHaveBeenChecked(): boolean;
}

@Component({
    selector:
        'assignment-single-select-option, assignment-multiple-choice-option',
    templateUrl: './assignment-select-option.component.html',
    styleUrls: ['./assignment-select-option.component.scss'],
})
export class AssignmentSelectOptionComponent
    implements AssignmentSelectOptionInterface, OnInit
{
    public readonly answerStateEnum = StateIconEnum;

    @Output()
    public readonly selectedChange =
        new EventEmitter<AssignmentSelectOptionInterface>();

    @Input()
    public id!: string;

    @Input()
    public groupId!: string;

    @Input()
    public multipleSelect = false;

    @Input('data-correct')
    public isCorrect!: string | boolean;

    @ViewChild('formControl')
    private formControl!: ElementRef;

    public answerState?: StateIconEnum;

    public ngOnInit() {
        if (!this.id) {
            this.id = StringUtil.random(6);
        }
    }

    public checkAnswerState(): boolean {
        if (!this.isChecked()) {
            this.answerState = undefined;

            return false;
        }

        if (this.isCorrectOption()) {
            this.answerState = StateIconEnum.Correct;

            return true;
        } else {
            this.answerState = StateIconEnum.InCorrect;

            return false;
        }
    }

    public isWrong(): boolean {
        return (
            undefined !== this.answerState &&
            !(this.isChecked() && StateIconEnum.Correct === this.answerState)
        );
    }

    public shouldHaveBeenChecked(): boolean {
        return !this.isChecked() && this.isCorrectOption();
    }

    public highlightIfCorrect(): void {
        this.getElement().checked = this.isCorrectOption();
    }

    public reset(): void {
        this.answerState = undefined;
        this.getElement().checked = false;
    }

    public isChecked(): boolean {
        return this.getElement().checked;
    }

    @HostListener('click', ['$event'])
    private handleClick(event: MouseEvent): void {
        event.preventDefault();

        const checked = this.multipleSelect ? !this.isChecked() : true;
        this.setFormElementChecked(checked);

        if (!this.multipleSelect) {
            this.selectedChange.emit(this);
        }
    }

    private setFormElementChecked(checked: boolean): void {
        this.getElement().checked = checked;
    }

    private isCorrectOption(): boolean {
        return this.isCorrect === true || this.isCorrect === 'true';
    }

    private getElement(): HTMLInputElement {
        return this.formControl.nativeElement;
    }
}
