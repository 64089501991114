import {
    AfterContentInit,
    Component,
    ContentChildren,
    forwardRef,
    OnDestroy,
    QueryList,
    ViewChild,
} from '@angular/core';
import {OkStampComponent} from 'app/content/screen/components/ok-stamp/ok-stamp.component';
import {ExerciseComponentInterface} from 'app/content/screen/exercise/exercise-component.interface';
import {ExerciseComponent} from 'app/content/screen/exercise/exercise.component';
import {AbstractResourceComponentClass} from 'app/content/screen/factory/resource-service';
import {
    AnswersService,
    AnswersStateEnum,
    AnswersUpdateEventInterface,
} from 'app/service/answers/answers.service';
import {Resource} from 'app/service/resource/classes/resource.class';
import {Subscription} from 'rxjs';
import {DialogService} from 'app/cdk/service/dialog/dialog.service';

@Component({
    selector: '[interactionContainer]',
    templateUrl: 'interaction-container.component.html',
    providers: [
        {
            provide: AbstractResourceComponentClass,
            useExisting: forwardRef(() => ContentInteractionContainerComponent),
        },
    ],
})
export class ContentInteractionContainerComponent
    extends AbstractResourceComponentClass
    implements AfterContentInit, OnDestroy
{
    @ContentChildren(ExerciseComponent)
    private exerciseComponents!: QueryList<ExerciseComponentInterface>;

    @ViewChild(OkStampComponent)
    private okStampComponent!: OkStampComponent;

    private subscription?: Subscription;
    private resource?: Resource;

    constructor(
        private answersService: AnswersService,
        private dialogService: DialogService
    ) {
        super();
    }

    public ngAfterContentInit(): void {
        this.subscription = this.answersService.subscribe(event =>
            this.handleAnswerServiceEvent(event)
        );
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public setResource(resource: Resource): void {
        this.resource = resource;
    }

    private handleAnswerServiceEvent(event: AnswersUpdateEventInterface): void {
        if (!this.eventIsForThisResourceInteraction(event.resource())) {
            return;
        }

        this.runEmitForEachExerciseComponent(event.state());
    }

    private eventIsForThisResourceInteraction(resource: Resource): boolean {
        return resource === this.resource;
    }

    private runEmitForEachExerciseComponent(state: AnswersStateEnum): void {
        this.exerciseComponents.forEach((item: ExerciseComponentInterface) => {
            this.runEmitForExerciseComponent(item, state);
        });
    }

    private runEmitForExerciseComponent(
        component: ExerciseComponentInterface,
        state: AnswersStateEnum
    ): void {
        switch (state) {
            case AnswersStateEnum.Undo:
                this.resetComponent(component);
                break;
            case AnswersStateEnum.ShowCorrectAnswer:
                this.solveComponent(component);
                break;
            case AnswersStateEnum.CheckIsOk:
                this.checkComponent(component);
                break;
        }
    }

    private resetComponent(component: ExerciseComponentInterface): void {
        if (component.hasWrong()) {
            component.retry();
        } else {
            component.reset();
        }

        this.resetOkStamp();
    }

    private solveComponent(component: ExerciseComponentInterface): void {
        component.solve();
    }

    private checkComponent(component: ExerciseComponentInterface): void {
        if (!component.hasAnswer()) {
            this.dialogService.alert({
                title: 'Geen antwoord',
                text: 'De oefening is nog niet ingevuld.',
            });
            return;
        }

        if (component.check()) {
            this.showOkStamp();
        } else {
            this.resetOkStamp();
        }
    }

    private resetOkStamp(): void {
        if (this.okStampComponent) {
            this.okStampComponent.showOkStamp = false;
        }
    }

    private showOkStamp(): void {
        if (this.okStampComponent) {
            this.okStampComponent.showOkStamp = true;
        }
    }
}
