import {
    AfterContentInit,
    Component,
    ContentChildren,
    forwardRef,
    Input,
    QueryList,
} from '@angular/core';
import {
    AssignmentMultipleSelectGroupComponent,
    AssignmentMultipleSelectGroupInterface,
} from 'app/content/screen/components/assignment-multiple-select/assignment-multiple-select-options/assignment-multiple-select-group.component';
import {ExerciseComponent} from 'app/content/screen/exercise/exercise.component';

@Component({
    selector: 'assignment-multiple-choice',
    templateUrl: './assignment-multiple-select.component.html',
    styleUrls: ['./assignment-multiple-select.component.scss'],
    providers: [
        {
            provide: ExerciseComponent,
            useExisting: forwardRef(() => AssignmentMultipleSelectComponent),
        },
    ],
})
export class AssignmentMultipleSelectComponent
    extends ExerciseComponent
    implements AfterContentInit
{
    @Input()
    public id!: string;

    @ContentChildren(AssignmentMultipleSelectGroupComponent)
    private options!: QueryList<AssignmentMultipleSelectGroupInterface>;

    public ngAfterContentInit(): void {
        this.options.forEach(option => {
            option.groupId = this.id;

            option.setup();
        });
    }

    public check(): boolean {
        let allOk = true;

        this.options.forEach(option => {
            if (!option.check()) {
                allOk = false;
            }
        });

        return allOk;
    }

    public reset(): void {
        this.options.forEach(option => option.reset());
    }

    public solve(): void {
        this.options.forEach(option => option.solve());
    }

    public hasAnswer(): boolean {
        return this.options.some(option => option.hasAnswer());
    }

    public hasWrong(): boolean {
        return this.options.some(option => option.hasWrong());
    }

    public retry(): void {
        this.options.forEach(option => option.retry());
    }
}
