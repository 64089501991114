import {Injectable} from '@angular/core';
import {ToolRingInterface} from 'app/tool/classes/tool-ring.class';
import {Radius} from 'app/tool/enum/radius.enum';
import {
    ToolRingConfigInterface,
    ToolRingFactory,
} from 'app/tool/factory/tool-ring/tool-ring.factory';
import {SvgFontAwesomeEnum} from 'app/enum/svg.enum';
import {AbstractToolRingService} from 'app/tool/rings/base/abstract-tool-ring.service';
import {RingIds} from 'app/tool/rings/ring-ids.enum';
import {ToolboxModalService} from 'app/toolbox/service/modal/modal.service';
import {ToolboxService} from 'app/toolbox/service/toolbox/toolbox.service';
import {ZoomableViewService} from 'app/zoomable-view/service/zoomable-view/zoomable-view.service';
import {ZoomEnum} from 'app/enum/zoom.enum';

@Injectable()
export class ViewRingService extends AbstractToolRingService {
    private view!: ToolRingInterface;

    constructor(
        protected toolRingFactory: ToolRingFactory,
        protected toolboxService: ToolboxService,
        protected toolboxModalService: ToolboxModalService,
        private zoomableViewService: ZoomableViewService
    ) {
        super(toolRingFactory, toolboxService, toolboxModalService);

        this.setRingInstance();
    }

    public getId(): RingIds {
        return RingIds.ViewRing;
    }

    public render(): ToolRingInterface[] {
        this.syncToolboxButtons(this.config(), this.view, 'green-dark');

        return [this.view];
    }

    protected handleCategoriesChanged(): void {
        this.syncToolboxButtons(this.config(), this.view, 'green-dark');

        super.handleCategoriesChanged();
    }

    private config(): ToolRingConfigInterface {
        return {
            id: 'view',
            radius: Radius.Large,
            rotate: 198,
            visible: false,
            slices: 10,
            classes: ['svg__circle--green-dark'],
            buttons: [
                {
                    id: 'scissors',
                    icon: SvgFontAwesomeEnum.SolidCut,
                    classes: ['svg__button--green'],
                    isDisabledCallback: () =>
                        this.zoomableViewService.isZooming(),
                    isActiveCallback: () =>
                        this.zoomableViewService.isScissorsEnabled(),
                    callback: () => this.zoomableViewService.toggleScissors(),
                },
                {
                    id: 'zoom-in',
                    icon: SvgFontAwesomeEnum.SolidZoomIn,
                    classes: ['svg__button--green'],
                    callback: () =>
                        this.zoomableViewService.zoomInOutReset(ZoomEnum.In),
                },
                {
                    id: 'zoom-out',
                    icon: SvgFontAwesomeEnum.SolidZoomOut,
                    classes: ['svg__button--green'],
                    callback: () =>
                        this.zoomableViewService.zoomInOutReset(ZoomEnum.Out),
                },
                {
                    id: 'minimize',
                    icon: SvgFontAwesomeEnum.SolidMinimize,
                    classes: ['svg__button--green'],
                    callback: () =>
                        this.zoomableViewService.zoomInOutReset(ZoomEnum.Reset),
                },
            ],
        };
    }

    private setRingInstance(): void {
        this.view = this.toolRingFactory.create(this.config());
    }
}
