import {Component, OnDestroy} from '@angular/core';
import {HeaderService} from 'app/header/service/header.service';
import {Subscription} from 'rxjs';
import {Resource} from 'app/service/resource/classes/resource.class';
import {ContentService} from 'app/service/content/content.service';
import {SecondScreenService} from 'app/second-screen/service/second-screen.service';
import {ActiveToolService} from 'app/tool-layer/service/active/active-tool.service';
import {DrawingService} from 'app/drawing/service/drawing/drawing.service';
import {DrawingStateEnum} from 'app/drawing/enum/state.enum';

export interface BreadcrumbInterface {
    value: string;
    href: string;
}

export interface SlideNameInterface {
    prefix: string;
    affix: string;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
    public breadCrumbs: BreadcrumbInterface[] = [];
    public slideName?: SlideNameInterface;
    public totalResources?: number;
    public currentResourceIndex?: number;

    private resource?: Resource;
    private subs: Array<Subscription> = [];

    constructor(
        private headerService: HeaderService,
        private contentService: ContentService,
        private activeToolService: ActiveToolService,
        private drawingService: DrawingService,
        private secondScreenService: SecondScreenService
    ) {
        this.subs.push(
            contentService.subscribeToCurrentResource(resource =>
                this.update(resource)
            ),
            headerService.breadcrumbsSubscribe(
                breadcrumbs => (this.breadCrumbs = breadcrumbs)
            )
        );
    }

    public get isContentResource(): boolean {
        return this.breadCrumbs.length > 0;
    }

    public onItemClicked(): void {
        this.activeToolService.closeAllTools();
        this.secondScreenService.toggleEnabled(false);
        this.drawingService.state = DrawingStateEnum.Disabled;
    }

    public handleLogoClick(): void {
        this.onItemClicked();
        this.headerService.handleLogoClick();
    }

    public ngOnDestroy(): void {
        this.subs.filter(s => s !== undefined).forEach(s => s.unsubscribe());
    }

    private update(resource?: Resource): void {
        if (undefined === resource) {
            this.slideName = undefined;
            this.breadCrumbs = [];

            return;
        }

        this.resource = resource;

        this.totalResources = resource
            .getParent()
            .getVisibleChildren(true).length;
        this.currentResourceIndex =
            resource.getParent().indexOf(resource, true) + 1;
        this.slideName = {
            prefix: resource.getCategory(),
            affix: resource.getName(),
        };
    }
}
