import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentModule} from 'app/component/component.module';
import {AbstractToolWrapper} from 'app/tool-layer/class/tool-wrapper';
import {AbacusComponent} from 'app/tool-layer/tools/abacus/abacus.component';

@NgModule({
    imports: [ComponentModule, CommonModule],
    declarations: [AbacusComponent],
    entryComponents: [AbacusComponent],
    providers: [
        {
            provide: AbstractToolWrapper,
            useValue: AbstractToolWrapper.create(AbacusComponent),
            multi: true,
        },
    ],
})
export class AbacusModule {}
