import {Component, HostBinding, OnDestroy} from '@angular/core';
import {SvgFontAwesomeEnum} from 'app/enum/svg.enum';
import {Subscription} from 'rxjs';
import {ThemeService} from 'app/service/theme/theme.service';
import {SecondScreenService} from 'app/second-screen/service/second-screen.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
    public fa = SvgFontAwesomeEnum;
    private themeSubscription!: Subscription;
    private theme?: string;

    constructor(
        themeService: ThemeService,
        private secondScreenService: SecondScreenService
    ) {
        this.themeSubscription = themeService.subscribeToCurrentTheme(theme => {
            this.theme = theme;
        });
    }

    public ngOnDestroy() {
        this.themeSubscription.unsubscribe();
    }

    public print(): void {
        window.print();
    }

    @HostBinding('class')
    public get hostClassName(): string | undefined {
        return this.theme
            ? `theme-${this.theme} theme-wrapper`
            : 'no-theme theme-wrapper';
    }

    @HostBinding('class.presentation-mode')
    public get presentationMode(): boolean {
        return this.secondScreenService.getIsEnabled();
    }
}
