import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentModule} from 'app/component/component.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule} from '@angular/forms';
import {ContentComponentsModule} from 'app/content/screen/components/components.module';
import {SelectAudioOptionComponent} from 'app/tool-layer/tools/select-audio-option/select-audio-option.component';

@NgModule({
    imports: [
        ComponentModule,
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        ContentComponentsModule,
    ],
    declarations: [SelectAudioOptionComponent],
    entryComponents: [SelectAudioOptionComponent],
    providers: [],
    exports: [SelectAudioOptionComponent],
})
export class SelectAudioOptionModule {}
