import {DrawingStateEnum} from 'app/drawing/enum/state.enum';

export const DrawingLineColorMap = new Map<DrawingStateEnum, string>([
    [DrawingStateEnum.PdfMagicWand, 'rgb(68,68,68,.2)'],
    [DrawingStateEnum.PdfShowAnswer, 'rgb(255,255,255,0)'],
    [DrawingStateEnum.Eraser, 'rgb(255,255,255,0)'],
]);

export const DrawingLineWidthMap = new Map<DrawingStateEnum, number>([
    [DrawingStateEnum.PdfMagicWand, 20],
    [DrawingStateEnum.PdfShowAnswer, 0],
]);

export const DrawingCursorMap = new Map<DrawingStateEnum, string>([
    [DrawingStateEnum.Pen, 'url(assets/svg/drawing/pen.svg) 0 30, pointer'],
    [
        DrawingStateEnum.Eraser,
        'url(assets/svg/drawing/erase.svg) 0 30, pointer',
    ],
    [
        DrawingStateEnum.Highlighter,
        'url(assets/svg/drawing/marker.svg) 0 24, pointer',
    ],
    [
        DrawingStateEnum.PdfMagicWand,
        'url(assets/svg/drawing/wand.svg) 0 36, pointer',
    ],
    [
        DrawingStateEnum.Cut,
        'url(assets/svg/drawing/cut-small.svg) 0 30, pointer',
    ],
]);
