import {Component, Input, Output, EventEmitter} from '@angular/core';
import {DEFAULT_DIAMETER_OPTIONS} from 'app/drawing/consts';

@Component({
    selector: 'app-control-diameter',
    templateUrl: './control-diameter.component.html',
    styleUrls: ['./control-diameter.component.scss'],
})
export class ControlDiameterComponent {
    @Input()
    value!: number;

    @Input()
    options: number[] = DEFAULT_DIAMETER_OPTIONS;

    /**
     * Control the size of the inner dot of the diameter control.
     * This scale can be used to upscale when dots are too small.
     */
    @Input()
    cssVariableScale = 1;

    @Output()
    public readonly changed: EventEmitter<InputEvent> = new EventEmitter();

    public cssVariableValue(value: number): string {
        return `${value * this.cssVariableScale}px`;
    }

    public onChange(option: InputEvent) {
        this.changed.emit(option);
    }
}
