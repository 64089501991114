import {Pipe, PipeTransform} from '@angular/core';
import {StringUtil} from 'app/util/string/string.util';

@Pipe({
    name: 'prependZeros',
})
export class PrependZerosPipe implements PipeTransform {
    public transform(value: string | number, count = 2): string {
        return StringUtil.prependWithZeros(String(value), count);
    }
}
