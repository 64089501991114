import {Injectable} from '@angular/core';
import {KeyCodeEnum} from 'app/enum/key-code.enum';
import {ApiResourceEnum} from 'app/interface/api-resource.interface';
import {
    ApiRequestService,
    ParamsInterface,
} from 'app/service/api-request/api-request.service';
import {
    GoogleAnalyticsActionsEnum,
    GoogleAnalyticsCategoriesEnum,
} from 'app/service/google-analytics/categories.enum';
import {GoogleAnalyticsService} from 'app/service/google-analytics/google-analytics.service';
import {Resource} from 'app/service/resource/classes/resource.class';
import {Subject, Subscription} from 'rxjs';

@Injectable()
export class ContentService {
    private readonly navigateSubject: Subject<KeyCodeEnum> = new Subject();
    private readonly resourceNavigationSubject: Subject<Resource> =
        new Subject();
    private readonly currentResourceSubject: Subject<Resource | undefined> =
        new Subject();
    private readonly screensUpdateSubject: Subject<void> = new Subject();

    private currentResource?: Resource;

    constructor(
        private apiRequestService: ApiRequestService,
        private gaService: GoogleAnalyticsService
    ) {}

    private static getSlideActionGaAction(
        keyCode: KeyCodeEnum
    ): GoogleAnalyticsActionsEnum {
        switch (keyCode) {
            case KeyCodeEnum.ArrowRight:
                return GoogleAnalyticsActionsEnum.NavigationNext;
            case KeyCodeEnum.ArrowLeft:
                return GoogleAnalyticsActionsEnum.NavigationPrevious;
            default:
                return GoogleAnalyticsActionsEnum.Empty;
        }
    }

    public get(
        arrangementCode: string,
        contentIdentifier: string,
        teacher = false
    ): Promise<string> {
        const url = `/api/v2/${
            teacher ? 'teacher-content' : 'content'
        }/:arrangementCode/:contentIdentifier`;

        const params: ParamsInterface = {
            urlParams: new Map<string, string>([
                ['arrangementCode', arrangementCode],
                ['contentIdentifier', contentIdentifier],
            ]),
            options: {
                responseType: 'text',
            },
        };

        return this.apiRequestService.get<string>(url, params).toPromise();
    }

    public subscribeToCurrentResource(
        fn: (resource: Resource | undefined) => void
    ): Subscription {
        return this.currentResourceSubject.subscribe(fn);
    }

    public subscribeToNavigateToResource(
        fn: (resource: Resource) => void
    ): Subscription {
        return this.resourceNavigationSubject.subscribe(fn);
    }

    public subscribeToNavigate(
        fn: (keyCode: KeyCodeEnum) => void
    ): Subscription {
        return this.navigateSubject.subscribe(fn);
    }

    public subscribeToScreensUpdate(fn: () => void): Subscription {
        return this.screensUpdateSubject.subscribe(fn);
    }

    public triggerScreensUpdate(): void {
        this.screensUpdateSubject.next();
    }

    public clearCurrentResource(): void {
        this.currentResource = undefined;
        this.currentResourceSubject.next();
    }

    public setCurrentResource(resource: Resource): void {
        const resourceEnumValues: string[] = Object.values(
            ApiResourceEnum
        ) as string[];

        if (resourceEnumValues.includes(resource.getType())) {
            throw new Error(
                `Current resource cannot be of type: ${resourceEnumValues.join(
                    ', '
                )}`
            );
        }

        this.currentResource = resource;
        this.currentResourceSubject.next(resource);
    }

    public getCurrentResource(): Resource | undefined {
        return this.currentResource;
    }

    public navigateSlide(keyCode: KeyCodeEnum | string): void {
        if (!(Object.values(KeyCodeEnum) as string[]).includes(keyCode)) {
            return;
        }

        this.gaService.event(
            ContentService.getSlideActionGaAction(keyCode as KeyCodeEnum),
            GoogleAnalyticsCategoriesEnum.Content
        );

        this.navigateSubject.next(keyCode as KeyCodeEnum);
    }

    public navigateToResource(resource: Resource): void {
        this.resourceNavigationSubject.next(resource);
    }
}
