export class Bead {
    private readonly step = 5; // The procent(%) of the distance;
    private readonly maxDistance = 77;

    private constructor(
        public readonly index: number,
        public readonly id: string = Bead.guid(),
        public orientation: number = -1,
        public position: number = 0,
        public startPosition: number = 0,
        public isMoving: boolean = false
    ) {}

    public static create(index: number): Bead {
        return new Bead(index);
    }

    private static guid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return (
            s4() +
            s4() +
            '-' +
            s4() +
            '-' +
            s4() +
            '-' +
            s4() +
            '-' +
            s4() +
            s4() +
            s4()
        );
    }

    public startMoving(): void {
        this.position = 0;
        this.isMoving = true;
    }

    public isMaxPosition(): boolean {
        return Math.abs(this.position) >= this.maxDistance;
    }

    public getTranslateOffset(): number {
        return this.startPosition + this.position * this.orientation;
    }

    public increasePosition(): void {
        this.position += (this.maxDistance * this.step) / 100;
    }

    public updateStartPosition(): void {
        this.startPosition =
            this.startPosition + this.position * this.orientation;
        this.orientation *= -1;
        this.isMoving = false;
    }
}
